import store from '@/app/stores';
import { selectReportComparisonDashboardMetadataById } from '@/bundles/Shared/entities/dashboard/model/slices/comparisonSlice';
import {
  addSectionsToLayout,
  buildLayoutsId,
  EagleEyeBoard,
  mapLayoutToLayoutDto,
  removeSectionFromLayout,
  ReportDashboardType,
  selectReportEagleEyeDashboardSettingsById,
  selectReportObjectDashboardMetadataById,
  useDashboardContext,
} from 'bundles/Shared/entities/dashboard';
import {
  usePutApiSettingsReportComparisonDashboardsByComparisonDashboardIdBoardsAndBoardIdWidgetSectionsIdMoveToBoardMutation,
  usePutApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdWidgetSectionsIdMoveToBoardMutation,
  usePutApiSettingsReportObjectDashboardsByObjectDashboardIdBoardsAndBoardIdWidgetSectionsIdMoveToBoardMutation,
} from '@/shared/api/dashboardSettingsEnhancedApi';
import { useCallback, useMemo } from 'react';

export * from 'bundles/Shared/features/dashboard/moveToBoard/ui/MoveToBoardButton';
export const useMoveToBoard = () => {
  const { dashboardId, dashboardType } = useDashboardContext();
  const objectMutation =
    usePutApiSettingsReportObjectDashboardsByObjectDashboardIdBoardsAndBoardIdWidgetSectionsIdMoveToBoardMutation();
  const eagleEyeMutation =
    usePutApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdWidgetSectionsIdMoveToBoardMutation();
  const comparisonMutation =
    usePutApiSettingsReportComparisonDashboardsByComparisonDashboardIdBoardsAndBoardIdWidgetSectionsIdMoveToBoardMutation();

  const [moveToBoardWidget] = useMemo(() => {
    switch (dashboardType) {
      case ReportDashboardType.OBJECT: {
        return objectMutation;
      }
      case ReportDashboardType.EAGLE_EYE: {
        return eagleEyeMutation;
      }
      case ReportDashboardType.COMPARISON_MODE: {
        return comparisonMutation;
      }
    }
  }, [dashboardType]);

  return useCallback(
    ({
      fromBoardId,
      toBoardId,
      sectionId,
    }: {
      fromBoardId: EagleEyeBoard['id'];
      toBoardId: EagleEyeBoard['id'];
      sectionId: string;
    }) => {
      const moveToBoard = moveToBoardWidget;

      const resolveStateSelector = () => {
        switch (dashboardType) {
          case ReportDashboardType.OBJECT: {
            return selectReportObjectDashboardMetadataById;
          }
          case ReportDashboardType.EAGLE_EYE: {
            return selectReportEagleEyeDashboardSettingsById;
          }
          case ReportDashboardType.COMPARISON_MODE: {
            return selectReportComparisonDashboardMetadataById;
          }
        }
      };
      const stateSelector = resolveStateSelector();

      const { layouts: fromBoardLayout } = stateSelector(
        store.getState(),
        buildLayoutsId({
          boardId: fromBoardId,
          dashboardId,
        }),
      )!;

      const { layouts: toBoardLayout } = stateSelector(
        store.getState(),
        buildLayoutsId({
          boardId: toBoardId,
          dashboardId,
        }),
      )!;

      const layoutItem = fromBoardLayout!.lg.find((l) => l.i === sectionId)!;

      moveToBoard({
        id: sectionId,
        boardId: fromBoardId,
        eagleEyeDashboardId: dashboardId,
        objectDashboardId: dashboardId,
        comparisonDashboardId: dashboardId,
        body: {
          destination_board_id: toBoardId,
          destination_board_layout: mapLayoutToLayoutDto(
            addSectionsToLayout(toBoardLayout!.lg, [layoutItem]),
          ),
          origin_board_layout: mapLayoutToLayoutDto(
            removeSectionFromLayout(fromBoardLayout!.lg, sectionId),
          ),
        },
      });
    },
    [moveToBoardWidget],
  );
};

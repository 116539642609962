import { BaseFinancialTableWidgetConfigBaseColumnDto } from '@/shared/api/dashboardsSettingsGeneratedApi';
import { PERIOD_SHIFT_SCHEMA } from '@/bundles/Shared/widgets/dashboard/widgets/common/config';
import { TableVizConfigColumn } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/table/model';
import { DEFAULT_VALUE_DISPLAY_OPTIONS } from '@/shared/lib/formatting/displayOptions';
import { transformPeriodShiftDtoToForm } from 'bundles/Shared/widgets/dashboard/widgets/common';
import {
  NUMERIC_COLUMN_SETTINGS_FORM_SCHEMA,
  transformColumnSettingsToForm,
} from 'bundles/Shared/widgets/dashboard/widgets/common/ui/table/form';
import * as yup from 'yup';
import {
  DEFAULT_FINANCIAL_TABLE_ADJUSTMENT_FORM,
  FINANCIAL_TABLE_ADJUSTMENT_SCHEMA,
} from 'bundles/Shared/widgets/dashboard/widgets/common/features/adjustments/config/financial';
import { transformAdjustmentDtoToForm } from 'bundles/Shared/widgets/dashboard/widgets/common/features/adjustments/lib';

export const FINANCIAL_TABLE_WIDGET_CONFIG_COLUMN_SCHEMA =
  NUMERIC_COLUMN_SETTINGS_FORM_SCHEMA.shape({
    key: yup.number(),
    label: yup.string().required(),
    period_shift: PERIOD_SHIFT_SCHEMA.nullable().optional(),
  }).concat(FINANCIAL_TABLE_ADJUSTMENT_SCHEMA);

export type FinancialTableWidgetConfigColumnForm = yup.InferType<
  typeof FINANCIAL_TABLE_WIDGET_CONFIG_COLUMN_SCHEMA
>;

export const transformFinancialTableWidgetColumnConfigToForm = (
  columnConfig: BaseFinancialTableWidgetConfigBaseColumnDto,
  columnSettings?: TableVizConfigColumn,
): Pick<
  FinancialTableWidgetConfigColumnForm,
  | 'adjustment_expression'
  | 'adjustment_type'
  | 'key'
  | 'period_shift'
  | 'value_display_options'
  | 'header'
> => ({
  key: columnConfig.key,
  label: columnConfig.label,
  value_display_options:
    columnConfig.value_display_options ?? DEFAULT_VALUE_DISPLAY_OPTIONS,
  period_shift: transformPeriodShiftDtoToForm(columnConfig.period_shift),
  header: columnConfig.header,
  ...transformAdjustmentDtoToForm(columnConfig),
  ...(columnSettings && transformColumnSettingsToForm(columnSettings)),
});

export const DEFAULT_FINANCIAL_TABLE_WIDGET_CONFIG_COLUMN: Partial<FinancialTableWidgetConfigColumnForm> =
  {
    ...DEFAULT_FINANCIAL_TABLE_ADJUSTMENT_FORM,
    value_display_options: DEFAULT_VALUE_DISPLAY_OPTIONS,
    period_shift: {
      key: 'weeks',
      value: 0,
    },
  };

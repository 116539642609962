import {
  EmptyWidget,
  resolveWidgetSectionPositionClassName,
} from '@/bundles/Shared/widgets/dashboard/widgets/common';
import { AssetsReportBuilderTextAreaWidgetDto } from '@/entities/report/reportBuilder/api/coreAssetsReportBuilderReportsGeneratedApi';
import { DEFAULT_SANITIZE_HTML_OPTIONS } from '@/shared/lib/browser/config';
import { cn } from '@/shared/lib/css/cn';
import { WidgetProps } from 'bundles/Shared/widgets/dashboard/widgets/model';
import { isEmpty } from 'lodash-es';
import sanitizeHtml from 'sanitize-html';

export function TextAreaWidget({
  data,
  isLoading,
  className,
  widgetSection,
}: WidgetProps<AssetsReportBuilderTextAreaWidgetDto>) {
  if (!isLoading && isEmpty(data?.text)) {
    return (
      <EmptyWidget
        className={resolveWidgetSectionPositionClassName(
          widgetSection.position ?? {
            w: 100,
          },
        )}
      >
        Text Area
      </EmptyWidget>
    );
  }
  return (
    <div
      dangerouslySetInnerHTML={
        data && {
          __html: sanitizeHtml(data.text, DEFAULT_SANITIZE_HTML_OPTIONS),
        }
      }
      className={cn(
        'tiptap-content minimal-tiptap-editor inline-regular h-max rounded-[16px] bg-neutral-000 text-neutral-900 print:bg-[initial]',
        className,
      )}
    />
  );
}

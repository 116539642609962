import { COLUMN_CONFIGURATION_LABEL_INFO_TEXT } from '@/bundles/Shared/widgets/dashboard/widgets/common/config';
import { DialogProps } from '@/shared/lib/hooks/useModal';
import { Field } from '@/stories/Field/Field';
import { InlineAlert } from '@/stories/InlineAlert/InlineAlert';
import { Input } from '@/stories/FormControls/Inputs/Input/Input';
import { yupResolver } from '@hookform/resolvers/yup';
import { EAGLE_EYE_DASHBOARD_SECTION_TYPE } from 'bundles/Shared/entities/dashboard';
import {
  CreateNewForm,
  EditPanelActions,
  FormulaField,
  PeriodShiftField,
  PeriodTypeField,
  TextVariableField,
  useUpdateWidgetConfig,
  useWidgetConfig,
} from 'bundles/Shared/widgets/dashboard/widgets/common';
import { ColumnHeaderField } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/fields/ColumnHeaderField';
import { NumericColumnSettingsFields } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/fields/ColumnSettingsFields';
import { TotalCalculationStrategyField } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/fields/TotalCalculationStrategyField';
import { TableVizConfigColumn } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/table/model';
import { KpiTableSingleDateWidgetConfigColumn } from 'bundles/Shared/widgets/dashboard/widgets/kpiTableSingleDate';
import { upsertColumn } from 'bundles/Shared/widgets/dashboard/widgets/kpiTableSingleDate/config/updaters';
import { FormProvider, useForm } from 'react-hook-form';
import { FieldsContainer } from 'stories/Field/Field';
import {
  SectionField,
  SectionFieldsContainer,
} from 'stories/Field/FieldsWrappers';
import { SidePanel } from 'stories/Modals/Modal/Modal';
import {
  KPI_TABLE_SINGLE_DATE_WIDGET_CONFIG_NUMERIC_COLUMN_SCHEMA,
  KPI_TABLE_SINGLE_DATE_WIDGET_CONFIG_TEXT_COLUMN_SCHEMA,
  KpiTableSingleDateWidgetConfigColumnForm,
  transformConfigToColumnForm,
} from '@/bundles/Shared/widgets/dashboard/widgets/kpiTableSingleDate/config/column.form';
import { TextDisplayStrategyField } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/fields/TextDisplayStrategyField';
import { commonTableWidgetUpdaters } from '@/bundles/Shared/widgets/dashboard/widgets/common/updaters';
import { SparklineSettingsField } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/fields/SparklineSettingsField';
import { AdjustmentField } from '@/bundles/Shared/widgets/dashboard/widgets/common/features/adjustments/ui/AdjustmentField';

interface Props
  extends DialogProps<{
    createNew: boolean;
  }> {
  type: 'create' | 'edit';
  columnType: KpiTableWidgetColumnType;
  columnConfig?: KpiTableSingleDateWidgetConfigColumn;
  columnSettings?: TableVizConfigColumn;
  groupId?: string;
}

export function KpiTableSingleDateWidgetConfigColumnPanel({
  type,
  columnConfig,
  columnSettings,
  groupId,
  columnType,
  onClose,
  onSubmit,
}: Props) {
  const resolver = yupResolver(
    columnType === 'numeric'
      ? KPI_TABLE_SINGLE_DATE_WIDGET_CONFIG_NUMERIC_COLUMN_SCHEMA
      : KPI_TABLE_SINGLE_DATE_WIDGET_CONFIG_TEXT_COLUMN_SCHEMA,
  );
  const { widget } =
    useWidgetConfig<
      typeof EAGLE_EYE_DASHBOARD_SECTION_TYPE.KPI_TABLE_SINGLE_DATE
    >();

  const group = widget.widgetConfig.viz_config.column_groups.find(
    (cg) => cg.group_id === groupId,
  );

  const cantOverridePeriod = group?.child_can_override_period === false;

  const methods = useForm<
    KpiTableSingleDateWidgetConfigColumnForm & CreateNewForm
  >({
    resolver,
    mode: 'all',
    defaultValues: {
      ...transformConfigToColumnForm({
        columnConfig,
        columnSettings,
        groupId,
        widgetConfig: widget.widgetConfig,
        columnType,
      }),
    },
  });
  const { control, register, handleSubmit } = methods;

  const [updateConfig] = useUpdateWidgetConfig(
    EAGLE_EYE_DASHBOARD_SECTION_TYPE.KPI_TABLE_SINGLE_DATE,
  );

  const handleRemove = () => {
    updateConfig({
      config: commonTableWidgetUpdaters.removeColumn(
        {
          columnKey: columnConfig!.key.toString(),
          groupId,
        },
        widget.widgetConfig,
      ),
    });
    onClose();
  };

  const handleSubmitClick = handleSubmit(({ ...values }) => {
    updateConfig({
      config: upsertColumn(values, widget.widgetConfig, {
        groupId,
      }),
    });

    onSubmit?.({
      createNew: values.createNew,
    });
  });

  const renderPeriodFields = () => {
    return (
      <SectionFieldsContainer>
        <SectionField labelText="Specify Period">
          {cantOverridePeriod && (
            <InlineAlert
              status="attention"
              message="The period has been set for all columns in the group configuration"
            />
          )}
          <PeriodTypeField
            disabled={cantOverridePeriod}
            control={control}
            name="period_type"
          />
          <Field labelText="Period shift">
            <PeriodShiftField
              disabled={cantOverridePeriod}
              control={control}
              name="period_shift"
            />
          </Field>
        </SectionField>
        <ColumnHeaderField name="header" control={control} />
      </SectionFieldsContainer>
    );
  };

  const renderNumericColumnFields = () => {
    return (
      <>
        <FormulaField control={control} name="expression" required />
        <AdjustmentField control={control} name="adjustment" />
        {renderPeriodFields()}
        <TotalCalculationStrategyField
          name="total_calculation_strategy"
          control={control}
        />
        <NumericColumnSettingsFields
          columnsConfig={widget.widgetConfig.columns}
          vizConfig={widget.widgetConfig.viz_config}
          control={control}
        />
      </>
    );
  };

  const renderTextColumnFields = () => {
    return (
      <>
        <TextVariableField control={control} name="expression" required />
        {renderPeriodFields()}
        <TextDisplayStrategyField
          control={control}
          name="text_display_strategy"
        />
      </>
    );
  };

  const renderSparklineColumnFields = () => {
    return (
      <>
        <FormulaField control={control} name="expression" required />
        {renderPeriodFields()}
        <SparklineSettingsField control={control} />
      </>
    );
  };

  const renderColumnFields = () => {
    switch (columnType) {
      case 'numeric':
        return renderNumericColumnFields();
      case 'text':
        return renderTextColumnFields();
      case 'sparkline':
        return renderSparklineColumnFields();
      default:
        return null;
    }
  };

  return (
    <FormProvider {...methods}>
      <SidePanel
        header={type === 'create' ? 'Create' : 'Edit Column'}
        toggle={onClose}
        actions={
          <EditPanelActions
            type={type}
            onClose={onClose}
            onSubmit={handleSubmitClick}
            onRemove={handleRemove}
          />
        }
      >
        <FieldsContainer>
          <Field note={COLUMN_CONFIGURATION_LABEL_INFO_TEXT} labelText="Title">
            <Input
              placeholder="Enter Primary Title"
              className="w-full"
              {...register('label')}
            />
          </Field>
          {renderColumnFields()}
        </FieldsContainer>
      </SidePanel>
    </FormProvider>
  );
}

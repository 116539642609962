import React, { useMemo } from 'react';
import { useReportSettingsDashboardByIdOrSlug } from 'bundles/Settings/components/REport/Dashboards/Dashboard/lib';
import { DashboardLayout } from 'bundles/Shared/components/layouts/dashboard/DashboardLayout';
import { ReportDashboardType } from 'bundles/Shared/entities/dashboard';
import { SettingsReportEagleEyeDashboardPage } from 'bundles/Settings/components/REport/Dashboards/Dashboard/widgets/SettingsReportEagleEyeDashboard';
import { SettingsReportObjectLevelDashboardPage } from 'bundles/Settings/components/REport/Dashboards/Dashboard/widgets/SettingsReportObjectLevelDashboard';
import { SettingsReportUnsupportedDashboard } from '@/bundles/Settings/components/REport/Dashboards/Dashboard/widgets/SettingsReportUnsupportedDashboard';
import { SettingsReportComparisonDashboardPage } from '@/bundles/Settings/components/REport/Dashboards/Dashboard/widgets/SettingsReportComparisonDashboard';
import { useReportSettingsDashboardScreenParams } from '@/shared/lib/hooks/navigation/dashboardsNavitation';

const DASHBOARD_TYPE_TO_COMPONENT_MAP = {
  [ReportDashboardType.EAGLE_EYE]: SettingsReportEagleEyeDashboardPage,
  [ReportDashboardType.OBJECT]: SettingsReportObjectLevelDashboardPage,
  [ReportDashboardType.COMPARISON_MODE]: SettingsReportComparisonDashboardPage,
};

export function SettingsReportDashboard() {
  const { dashboardId } = useReportSettingsDashboardScreenParams();
  const { data, isLoading } = useReportSettingsDashboardByIdOrSlug(dashboardId);
  const DashboardPageComponent = useMemo(() => {
    if (data?.type && DASHBOARD_TYPE_TO_COMPONENT_MAP[data.type]) {
      return DASHBOARD_TYPE_TO_COMPONENT_MAP[data.type];
    }
    return SettingsReportUnsupportedDashboard;
  }, [data?.type]);

  if (isLoading) {
    return <DashboardLayout.LoadingPlaceholder />;
  }

  return <DashboardPageComponent />;
}

import {
  ReportEagleEyeDashboard,
  selectReportDashboardSelectedObjectLegalEntityIds,
  updateObjectLegalEntityIds,
} from 'bundles/Shared/entities/dashboard';
import { useAppDispatch, useAppSelector } from '@/shared/lib/hooks/redux';
import { LegalEntity } from '@/entities/core/legalEntity';
import { Icon } from '@/stories/Icon/Icon';
import { LegalEntityManagmentPeriods } from '@/bundles/Settings/components/Portal/LegalEntities/components/LegalEntityManagmentPeriods';

interface Props {
  dashboardId: ReportEagleEyeDashboard['id'];
  objectId: number;
  legalEntities: LegalEntity[];
}

export function ObjectDashboardLegalEntitiesFilter({
  dashboardId,
  objectId,
  legalEntities,
}: Props) {
  const dispatch = useAppDispatch();
  const objectLegalEntityIds = useAppSelector((state) =>
    selectReportDashboardSelectedObjectLegalEntityIds(state, {
      dashboardId,
      selectedObjectId: objectId,
    }),
  );

  const handleToggle = (legalEntity: LegalEntity) => {
    dispatch(
      updateObjectLegalEntityIds({
        legalEntityIds: objectLegalEntityIds.includes(legalEntity.id)
          ? objectLegalEntityIds.filter((id) => id !== legalEntity.id)
          : [...objectLegalEntityIds, legalEntity.id],
        objectId,
        dashboardId,
      }),
    );
  };

  return (
    <div className="inline-flex max-w-max divide-x divide-solid divide-neutral-150 rounded-[8px] border border-light-5 bg-white shadow-[0px_2px_2px_0px_rgba(12,25,54,0.08)]">
      <div className="inline-flex h-[1.5rem] shrink-0 items-center px-2 text-sm font-semibold leading-none">
        Legal Entities:
      </div>
      <div className="-mr-px flex w-auto flex-wrap overflow-hidden bg-[linear-gradient(to_bottom,var(--neutral-150)_1px,transparent_1px)] bg-[size:calc(1.5rem+1px)_calc(1.5rem+1px)] bg-bottom">
        {legalEntities.map((legalEntity) => (
          <div
            key={legalEntity.id}
            className="-ml-px inline-flex h-[1.5rem] shrink-0 flex-grow-0 items-center gap-x-2 border-l border-r  border-neutral-150 pe-4 ps-2 text-sm font-semibold leading-none text-neutral-850"
          >
            <button
              className="m-0 flex size-5 shrink-0 items-center justify-center border-none bg-transparent p-0 text-info-055 transition-colors duration-100 hover:text-info-070"
              onClick={() => handleToggle(legalEntity)}
            >
              <Icon
                className="size-4 leading-none"
                iconName={
                  objectLegalEntityIds.includes(legalEntity.id)
                    ? 'eye'
                    : 'eyeSlash'
                }
              />
            </button>
            <span>{legalEntity.name}</span>
            <div className="h-1/3 w-[2px] shrink-0 rounded-sm bg-neutral-300 last:hidden" />
            <LegalEntityManagmentPeriods
              row={legalEntity}
              showNumber
              hideInactive
            />
          </div>
        ))}
      </div>
    </div>
  );
}

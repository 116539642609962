import { updateReportComparisonDashboardDate } from '@/bundles/Shared/entities/dashboard/model/slices/comparisonSlice';
import { updateReportBuilderTemplateDate } from '@/bundles/Shared/entities/dashboard/model/slices/reportBuilderTemplatesSlice';
import { useReportBuilderTemplateContext } from '@/bundles/Shared/widgets/dashboard/widgets/common/lib/reportBuilderTemplateContext';
import { formatToDateStringForRequest } from '@/shared/lib/converters';
import { formatDate } from '@/shared/lib/formatting/dates';
import { useAppDispatch } from '@/shared/lib/hooks/redux';
import { IconButton } from '@/stories/IconButton/IconButton';
import { FilterBlock } from 'bundles/Settings/components/REport/Dashboards/Dashboard/widgets/SettingsReportObjectLevelDashboard';
import {
  ReportDashboardType,
  updateReportObjectDashboardDate,
  useDashboardContext,
} from 'bundles/Shared/entities/dashboard';
import dayjs from 'dayjs';
import React, { useMemo } from 'react';
import Calendar from 'stories/FlexibleFilterByPeriods/calendar/Calendar';

interface Props
  extends Omit<
    React.ComponentProps<typeof Calendar>,
    'value' | 'onChange' | 'selectionMode'
  > {
  date: string | null;
}

export function ReportDashboardDateFilter({ date, ...calendarProps }: Props) {
  const dispatch = useAppDispatch();
  const { dashboardType, dashboardId } = useDashboardContext();
  const reportBuilderTemplateCtx = useReportBuilderTemplateContext();

  const updateDashboardDate = useMemo(() => {
    switch (dashboardType) {
      case ReportDashboardType.OBJECT: {
        return updateReportObjectDashboardDate;
      }
      case ReportDashboardType.EAGLE_EYE: {
        throw new Error("This date filter doesn't support Eagle Eye Dashboard");
      }
      case ReportDashboardType.COMPARISON_MODE: {
        return updateReportComparisonDashboardDate;
      }
      case ReportDashboardType.REPORT_BUILDER_TEMPLATE:
      default: {
        return updateReportBuilderTemplateDate;
      }
    }
  }, []);

  return (
    <Calendar
      value={[dayjs(date ?? new Date())]}
      onChange={([item]) => {
        dispatch(
          updateDashboardDate({
            templateId: reportBuilderTemplateCtx?.templateId,
            date: formatToDateStringForRequest(item),
            dashboardId,
          }),
        );
      }}
      selectionMode="daily"
      {...calendarProps}
    />
  );
}

export const ReportDashboardDateFilterBlock = ({
  date,
}: {
  date: string | null;
}) => {
  return (
    <ReportDashboardDateFilter
      date={date ?? null}
      popoverProps={{
        children: (
          <FilterBlock
            iconName="calendar"
            label="Date"
            value={
              date
                ? formatDate(date as DateString, 'MM-DD-YYYY')
                : 'Select Date'
            }
          >
            <IconButton iconName="bottom" />
          </FilterBlock>
        ),
      }}
    />
  );
};

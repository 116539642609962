/* eslint-disable @typescript-eslint/member-ordering */
import { buildTableCellStyles } from '@/bundles/Shared/components/AgGrid/Table/utils/useTableCellStyleApplier';
import { getSparklineCellRendererComponentObject } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/renderers/SparklineCellRenderer';
import { WidgetTableTextCellRenderer } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/renderers/TextCellRenderer';
import { CssVar } from '@/shared/config/cssVar';
import { contrastingTextColor, convertColorToHex } from '@/shared/lib/colors';
import { cn } from '@/shared/lib/css/cn';
import {
  NumericValueDisplayOptions,
  ValueDisplayOptions,
} from '@/shared/lib/formatting/displayOptions';
import { getExcelStyleNumberFormat } from '@/shared/lib/formatting/excel';
import {
  createAgGridTableFormattedColDef,
  FormattedColDef,
  StyledBasicCellRendererProps,
} from '@/shared/lib/formatting/table';
import { joinWithDash } from '@/shared/lib/string';
import {
  ColDef,
  ColGroupDef,
  FilterChangedEvent,
  ICellRendererParams,
  IHeaderParams,
  ValueFormatterParams,
} from 'ag-grid-community';
import { CELL_CLASS_NAMES } from 'bundles/Shared/components/AgGrid/Table/classNames';
import { SetFilterComponent } from 'bundles/Shared/components/AgGrid/Table/filters/SetFilterComponent';
import {
  DEFAULT_GROUP_BG_COLOR,
  DEFAULT_GROUP_BORDER_COLOR_RGB,
  DEFAULT_WIDGET_TABLE_PROPS,
  FiltersWidgetState,
  PinColumnAction,
  WidgetTableGroupHeaderGroup,
} from 'bundles/Shared/widgets/dashboard/widgets/common';
import { resolveComparisonTextColorForColDef } from 'bundles/Shared/widgets/dashboard/widgets/common/lib/comparison';
import { getThresholdBackgroundColor } from 'bundles/Shared/widgets/dashboard/widgets/common/lib/thresholds';
import { resolveHeaderWithSubheaderComponentProps } from 'bundles/Shared/widgets/dashboard/widgets/common/lib/utils';
import {
  HEADER_CELL_WRAPPER_BG_COLOR_CSS_CUSTOM_PROPERTY,
  HeaderComponentWithSubHeader,
  HeaderWithSubHeaderParams,
} from 'bundles/Shared/widgets/dashboard/widgets/common/ui/table/HeaderComponentWithSubHeader';
import {
  isNumericTableVizConfigColumn,
  isSparklineTableVizConfigColumn,
  isTextTableVizConfigColumn,
  SparklineTableVizConfigColumn,
  TablePeriodConfigColumn,
  TableVizConfig,
  TableVizConfigColorBackground,
  TableVizConfigColumn,
  TableVizConfigColumnGroup,
  TableVizConfigComparison,
  TableVizConfigGradientBackground,
  TextTableVizConfigColumn,
} from 'bundles/Shared/widgets/dashboard/widgets/common/ui/table/model';
import {
  buildExcelStyleId,
  INDENTATION_EXCEL_STYLES,
  TABLE_EXCEL_STYLE_IDS,
  TABLE_EXCEL_STYLES,
} from 'bundles/Shared/widgets/dashboard/widgets/common/ui/table/useTableWidgetExportFeature';
import { WidgetTableHeaderGroupComponentParams } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/table/WidgetHeaderGroup';
import { columnToColumnSettingsByKeyMatcher } from 'bundles/Shared/widgets/dashboard/widgets/kpiTable';
import { WidgetViewMode } from 'bundles/Shared/widgets/dashboard/widgets/model';
import { produce } from 'immer';
import { omit, orderBy } from 'lodash-es';
import { CSSProperties } from 'react';
import { IconsId } from 'types/sre-icons';

const COLORED_BORDER_WIDTH = '2px';
const TOTAL_COLOR_STYLES = {
  background: CssVar.neutral850,
  color: CssVar.neutral000,
  borderColor: CssVar.neutral700,
};
export const isTotalRow = (params: Pick<ICellRendererParams, 'data'>) =>
  params.data?.type === 'total';
const shouldApplyGradient = (params: ICellRendererParams) =>
  params.context.groupingType === 'assets'
    ? params.data?.type === 'asset'
    : params.data?.type === 'segment';

export const contrastingCellTextColor = (color: string) => {
  return contrastingTextColor(color, {
    dark: 'var(--next-get-table-cell-color)',
    light: CssVar.neutral000,
  });
};

export const resolveBackgroundAndTextColor = ({
  comparison,
  background,
  params,
  direction = 'column',
  totalColors,
  ...args
}: {
  params: ICellRendererParams;
  background?:
    | TableVizConfigGradientBackground
    | TableVizConfigColorBackground
    | undefined;
  comparison?: TableVizConfigComparison;
  shouldApplyGradient?: (params: ICellRendererParams) => boolean;
  direction?: 'row' | 'column';
  totalColors?: typeof TOTAL_COLOR_STYLES;
}) => {
  if (isTotalRow?.(params)) {
    return totalColors;
  }
  if (comparison) {
    return {
      color: resolveComparisonTextColorForColDef(
        comparison,
        params,
        (rowData, key) => rowData?.[key],
      ),
    };
  }

  if (background && args.shouldApplyGradient?.(params)) {
    const getBackgroundAndContrastingTextColor = (color: string) => {
      return {
        background: color,
        color: contrastingCellTextColor(color),
      };
    };
    if (background.type === 'color') {
      return getBackgroundAndContrastingTextColor(background.color);
    }
    const { value } = params;

    if (value == null || (background.ignore_zeros && value === 0)) {
      return {};
    }

    const backgroundColor = getThresholdBackgroundColor({
      background,
      minMaxValues: params.context.minMaxValues,
      value,
      columnKey:
        direction === 'row'
          ? params.data.key?.toString()
          : params.column!.getColId(),
    });

    return getBackgroundAndContrastingTextColor(backgroundColor);
  }

  return {};
};

export const defaultSortComparator: ColDef['comparator'] = (
  a,
  b,
  _?,
  __?,
  isDescending = false,
  // eslint-disable-next-line max-params
) => {
  if (typeof a === 'string' && typeof b === 'string') {
    return a.localeCompare(b);
  }
  if (b === 0 || b == null) {
    return isDescending ? 1 : -1;
  }
  if (a === 0 || a == null) {
    return isDescending ? -1 : 1;
  }
  return a - b;
};

export const buildGroupId = (id: string) => {
  return joinWithDash(['group', id]);
};

export type OverrideColDefFunction = (args: {
  columnSettings: TableVizConfigColumn;
  column?: {
    key: string;
  };
  mode: WidgetViewMode;
}) => Partial<ColDef>;

export type OverrideColDefObject = Partial<ColDef>;

export type OverrideColDef = OverrideColDefFunction | OverrideColDefObject;

export class ColDefBuilder<
  Column extends {
    label: string;
    key: number;
  },
> {
  private mode: WidgetViewMode;
  private totalColors: typeof TOTAL_COLOR_STYLES = TOTAL_COLOR_STYLES;
  private columnsConfig?: TablePeriodConfigColumn[];
  private override?: OverrideColDefFunction;
  private headerBackgroudOverride?: string | null | undefined;
  private onPinColumn?: (colId: string) => unknown;
  private filterLabelOverrides?: Record<string, Record<string, string>>;

  subHeaderName?: (params: {
    mode: WidgetViewMode;
    columnSettings: TableVizConfigColumn;
    params: IHeaderParams;
    column?: Column;
  }) => string;

  constructor({
    mode,
    onPinColumn,
    columnsConfig,
  }: {
    mode: WidgetViewMode;
    onPinColumn?: (colId: string) => unknown;
    columnsConfig?: TablePeriodConfigColumn[];
  }) {
    this.mode = mode;
    this.columnsConfig = columnsConfig;
    this.onPinColumn = onPinColumn;
  }

  withTotalColors(totalColors: typeof TOTAL_COLOR_STYLES) {
    this.totalColors = totalColors;
    return this;
  }

  // TODO replace with ColGroupStyleBuilder in FE-3921
  withHeaderBackgroudOverride(
    headerBackgroudOverride: string | null | undefined,
  ) {
    this.headerBackgroudOverride = headerBackgroudOverride;
    return this;
  }

  withFilterLabelOverrides(
    filterLabelOverrides: Record<string, Record<string, string>>,
  ) {
    this.filterLabelOverrides = filterLabelOverrides;
    return this;
  }

  withSubHeaderName(
    subHeaderName: typeof ColDefBuilder.prototype.subHeaderName,
  ) {
    this.subHeaderName = subHeaderName;
    return this;
  }

  withOverride(override: OverrideColDef) {
    this.override = typeof override === 'function' ? override : () => override;
    return this;
  }

  isSparklineColumn({
    columnSettings,
  }: {
    columnSettings: TableVizConfigColumn;
    params?: Pick<ICellRendererParams, 'data'>;
  }) {
    return isSparklineTableVizConfigColumn(columnSettings);
  }

  private resolveCellBorderStyles = ({
    columnSettings,
    params,
  }: {
    columnSettings: TableVizConfigColumn;
    params: ICellRendererParams;
  }): CSSProperties => {
    const isTotal = isTotalRow(params);
    const sideBordersWidth = columnSettings.border_color
      ? COLORED_BORDER_WIDTH
      : undefined;
    const sideBordersColor =
      columnSettings.border_color ??
      (isTotal ? this.totalColors.borderColor : undefined);
    return {
      borderRightWidth: sideBordersWidth,
      borderLeftWidth: sideBordersWidth,
      borderBottomWidth: isTotal ? COLORED_BORDER_WIDTH : undefined,
      borderLeftColor: sideBordersColor,
      borderRightColor: sideBordersColor,
      borderBottomColor: isTotal
        ? columnSettings.border_color ?? this.totalColors.borderColor
        : undefined,
    };
  };

  buildAlignmentCellParams(
    align: 'left' | 'right' | 'center' | undefined,
    params: ICellRendererParams,
  ) {
    return {
      classes: {
        inner: cn(CELL_CLASS_NAMES.CommonCell.inner.basic, {
          '!justify-end': align === 'right',
          '!justify-center': align === 'center',
          '!justify-start': align === 'left' || align === undefined,
          // workaround for truncated last row in pdf
          '!items-start': this.mode === 'pdf' && params.node.lastChild,
        }),
      },
    };
  }

  buildCustomCellRenderer({
    columnSettings,
    params,
    column,
  }: {
    columnSettings: TextTableVizConfigColumn | SparklineTableVizConfigColumn;
    params: ICellRendererParams;
    column?: Column;
  }) {
    if (this.isSparklineColumn({ columnSettings, params })) {
      return getSparklineCellRendererComponentObject({
        params,
        displayOptions:
          columnSettings.value_display_options as NumericValueDisplayOptions,
        column,
        columnConfig: this.columnsConfig?.find(
          (c) => c.key.toString() === column?.key.toString(),
        ),
        config: columnSettings.cell_renderer!,
      });
    }

    return {
      component: WidgetTableTextCellRenderer,
      params: {
        ...params,
        config: columnSettings.cell_renderer,
      },
    };
  }

  private buildCellProperties({
    columnSettings,
    column,
  }: {
    columnSettings: TableVizConfigColumn;
    column?: Column;
  }): Pick<
    ColDef,
    | 'cellRendererSelector'
    | 'type'
    | 'cellClass'
    | 'cellClassRules'
    | 'cellRendererParams'
    | 'equals'
    | 'colId'
    | 'valueGetter'
    | 'field'
    | 'cellStyle'
  > &
    FormattedColDef {
    const { value_display_options } = columnSettings;

    const formattedColDef =
      value_display_options &&
      createAgGridTableFormattedColDef(value_display_options);

    return {
      colId: columnSettings.col_id,
      field: columnSettings.key,
      valueGetter: (params) => {
        const { data, colDef } = params;
        const value = data?.[colDef.field!];
        const shouldHideNegativeValue =
          isNumericTableVizConfigColumn(columnSettings) &&
          columnSettings.comparison?.hide_negative_values === true &&
          value < 0;

        if (shouldHideNegativeValue) {
          return null;
        }
        return value;
      },
      equals: (a, b) => {
        // temporary fix for text columns (cell isn't updated when value changes)
        if (
          columnSettings &&
          columnSettings.value_display_options &&
          (isTextTableVizConfigColumn(columnSettings) ||
            this.isSparklineColumn({ columnSettings }))
        ) {
          return false;
        }
        return a === b;
      },
      cellRendererSelector: (params: ICellRendererParams) => {
        if (
          columnSettings &&
          (isTextTableVizConfigColumn(columnSettings) ||
            this.isSparklineColumn({ columnSettings, params }))
        ) {
          return this.buildCustomCellRenderer({
            columnSettings,
            params,
            column,
          });
        }

        return {
          component:
            formattedColDef?.cellRenderer ??
            DEFAULT_WIDGET_TABLE_PROPS.defaultColDef.cellRenderer,
          params,
        };
      },
      type: formattedColDef?.type,
      cellClassRules: {
        [TABLE_EXCEL_STYLE_IDS.totalBackground]: (params) => isTotalRow(params),
      },
      displayOptions: value_display_options,
      cellClass: ({ colDef }) =>
        colDef.colId && buildExcelStyleId({ id: colDef.colId }),
      cellStyle: (params) => {
        if (
          !this.isSparklineColumn({ columnSettings, params }) ||
          isTotalRow(params)
        ) {
          return undefined;
        }
        // sparkline don't use BasicCellRenderer so we need to add a border
        return {
          border: `1px solid ${CssVar.neutral150}`,
        };
      },
      cellRendererParams: (params: ICellRendererParams) => {
        const isTotal = isTotalRow(params);
        const hasLabelColor = isTotal;
        const { comparison, align = 'right' } = columnSettings;
        const compareColor = comparison
          ? resolveComparisonTextColorForColDef(
              comparison,
              params,
              (rowData, key) => rowData?.[key],
            )
          : undefined;

        return {
          labelColor: hasLabelColor ? compareColor : undefined,
          ...this.buildAlignmentCellParams(align, params),
          styles: {
            ...resolveBackgroundAndTextColor({
              background: columnSettings.background,
              comparison,
              params,
              shouldApplyGradient,
              totalColors: this.totalColors,
            }),
            ...this.resolveCellBorderStyles({
              columnSettings,
              params,
            }),
            fontWeight: isTotal ? 'bold' : columnSettings.font_weight,
          },
        } satisfies StyledBasicCellRendererProps;
      },
    };
  }

  private buildHeaderProperties({
    columnSettings,
    column,
  }: {
    columnSettings: TableVizConfigColumn;
    column?: Column;
  }): Pick<
    ColDef,
    'headerComponentParams' | 'headerComponent' | 'headerClass'
  > {
    return {
      headerComponent: HeaderComponentWithSubHeader,
      headerClass: (params) => {
        const parent = params.column?.getParent();

        const colGroupDef = parent?.getColGroupDef();

        return (
          colGroupDef?.groupId &&
          buildExcelStyleId({
            id: colGroupDef.groupId,
            type: 'headerGroup',
          })
        );
      },
      headerComponentParams: (params: IHeaderParams) => {
        const { hide_subtitle, hide_title, subtitle } =
          columnSettings.header ?? {};
        const subHeaderName =
          subtitle ??
          this.subHeaderName?.({
            column,
            columnSettings,
            params,
            mode: this.mode,
          });
        const parent = params.column?.getParent();

        const colGroupDef = parent?.getColGroupDef();
        const { style: groupStyle } =
          colGroupDef?.headerGroupComponentParams ?? {};

        // TODO replace with ColGroupStyleBuilder in FE-3921
        const tableStyles = buildTableCellStyles({
          background: this.headerBackgroudOverride,
        });
        const fallbackBorderColor =
          this.headerBackgroudOverride != null
            ? tableStyles.borderColor
            : DEFAULT_GROUP_BORDER_COLOR_RGB;

        const borderColor =
          columnSettings.border_color ??
          groupStyle?.borderColor ??
          fallbackBorderColor;

        const backgroundColor =
          this.headerBackgroudOverride ??
          groupStyle?.backgroundColor ??
          DEFAULT_GROUP_BG_COLOR;

        return {
          ...resolveHeaderWithSubheaderComponentProps({
            headerName: column?.label,
            subHeaderName,
            hide_subtitle,
            hide_title,
          }),
          style: {
            ...groupStyle,
            borderWidth: columnSettings.border_color
              ? COLORED_BORDER_WIDTH
              : undefined,
            borderTopColor: borderColor,
            borderLeftColor: borderColor,
            borderRightColor: borderColor,

            // TODO remove in FE-3921
            ...omit(tableStyles, ['borderColor', 'background']),

            [HEADER_CELL_WRAPPER_BG_COLOR_CSS_CUSTOM_PROPERTY.name]:
              this.headerBackgroudOverride ??
              groupStyle?.backgroundColor ??
              DEFAULT_GROUP_BG_COLOR,
            backgroundColor,
          },
          actions: (
            <PinColumnAction
              {...params}
              mode={this.mode}
              onPinColumn={this.onPinColumn}
            />
          ),
        } satisfies HeaderWithSubHeaderParams;
      },
    };
  }

  private buildFilterProperties({
    columnSettings,
    column,
    filterOptions,
  }: {
    columnSettings: TableVizConfigColumn;
    column?: Column;
    filterOptions?: FilterOption[];
  }): Pick<ColDef, 'filter' | 'filterParams'> {
    const filterOption = filterOptions?.find((o) => o.column === column?.label);
    if (!filterOption || this.mode === 'pdf') {
      return {};
    }
    return {
      filter: SetFilterComponent,
      filterParams: {
        values: filterOption.options ?? [],
        valueFormatter: (params: ValueFormatterParams) =>
          this.filterLabelOverrides?.[columnSettings.key]?.[params.value] ??
          params.value,
      },
    };
  }

  buildSortProperties({
    columnSettings,
  }: {
    columnSettings: TableVizConfigColumn;
  }): Pick<ColDef, 'initialSort' | 'comparator' | 'sortable'> {
    return {
      sortable:
        !isSparklineTableVizConfigColumn(columnSettings) && this.mode != 'pdf',
      initialSort: columnSettings.initial_sort,
      comparator: defaultSortComparator,
    };
  }

  build({
    column,
    columnSettings,
    filterOptions,
  }: {
    columnSettings: TableVizConfigColumn;
    column?: Column;
    filterOptions?: FilterOption[];
  }): ColDef {
    return {
      ...this.buildCellProperties({ columnSettings, column }),
      ...this.buildHeaderProperties({ column, columnSettings }),
      ...this.buildFilterProperties({ columnSettings, filterOptions, column }),
      ...this.buildSortProperties({ columnSettings }),
      suppressMovable: true,
      suppressMenu: true,
      resizable: true,
      flex: undefined,
      headerName: column?.label,
      initialHide: columnSettings.hidden,
      maxWidth: columnSettings?.max_width,
      minWidth: columnSettings?.min_width,
      columnGroupShow: columnSettings.column_group_show ?? undefined,

      ...(this.override?.({
        column,
        columnSettings,
        mode: this.mode,
      }) ?? {}),
    };
  }
}

export class ColGroupDefBuilder {
  private mode: WidgetViewMode;
  private headerBackgroudOverride?: string | null | undefined;
  private headerName: (params: {
    group: TableVizConfigColumnGroup;
    mode: WidgetViewMode;
  }) => string;

  constructor({ mode }: { mode: WidgetViewMode }) {
    this.mode = mode;
  }

  withHeaderName(
    headerName: (params: {
      group: TableVizConfigColumnGroup;
      mode: WidgetViewMode;
    }) => string,
  ) {
    this.headerName = headerName;
    return this;
  }

  // TODO replace with ColGroupStyleBuilder in FE-3921
  withHeaderBackgroudOverride(
    headerBackgroudOverride: string | null | undefined,
  ) {
    this.headerBackgroudOverride = headerBackgroudOverride;
    return this;
  }

  build(group: TableVizConfigColumnGroup): Omit<ColGroupDef, 'children'> {
    const headerName =
      this.headerName?.({
        group,
        mode: this.mode,
      }) ?? group.header_name;
    return {
      groupId: buildGroupId(group.group_id),
      headerClass: buildExcelStyleId({
        id: buildGroupId(group.group_id),
        type: 'headerGroup',
      }),
      headerGroupComponent: WidgetTableGroupHeaderGroup,
      headerGroupComponentParams: {
        icon: group.icon as IconsId,
        style: {
          // TODO replace with ColGroupStyleBuilder in FE-3921
          ...(this.headerBackgroudOverride != null
            ? buildTableCellStyles({
                background: this.headerBackgroudOverride,
              })
            : {
                backgroundColor:
                  this.headerBackgroudOverride ??
                  group.background ??
                  DEFAULT_GROUP_BG_COLOR,
                borderColor: group.border_color,
              }),
        },
        mode: this.mode,
      } satisfies WidgetTableHeaderGroupComponentParams,
      headerName,
    };
  }
}

type ExcelStyleBuilderSource = 'columns' | 'rows' | 'columns-rows';

export class ExcelStyleBuilder {
  private vizConfig: TableVizConfig;
  private mode: WidgetViewMode;
  private source: ExcelStyleBuilderSource = 'columns';
  private indentation: boolean;
  private valueDisplayOptions: ValueDisplayOptions;

  constructor({
    vizConfig,
    mode,
  }: {
    vizConfig: TableVizConfig;
    mode: WidgetViewMode;
  }) {
    this.vizConfig = vizConfig;
    this.mode = mode;
  }

  withSource(source: ExcelStyleBuilderSource) {
    this.source = source;
    return this;
  }

  withIndentationStyles() {
    this.indentation = true;
    return this;
  }

  withDefaultValueDisplayOptionsStyles(
    valueDisplayOptions: ValueDisplayOptions,
  ) {
    this.valueDisplayOptions = valueDisplayOptions;
    return this;
  }

  buildExcelStyles() {
    let vizConfigStyles: ReturnType<typeof this.getExcelStylesFromRows> = [];

    switch (this.source) {
      case 'columns':
        vizConfigStyles = this.getExcelStylesFromColumns();
        break;
      case 'columns-rows':
        vizConfigStyles = this.getExcelStylesFromColumns().concat(
          this.getExcelStylesFromRows(),
        );
        break;
      case 'rows':
        vizConfigStyles = this.getExcelStylesFromRows();
        break;
    }
    const { header_background } = this.vizConfig;
    let defaultStyles = TABLE_EXCEL_STYLES;
    if (header_background) {
      defaultStyles = produce(defaultStyles, (draft) => {
        const headerStyle = draft.find(
          (style) => style.id === TABLE_EXCEL_STYLE_IDS.header,
        );
        if (headerStyle) {
          headerStyle.interior.color = convertColorToHex(header_background);
        }
      });
    }
    return vizConfigStyles
      .concat(defaultStyles)
      .concat(this.indentation ? INDENTATION_EXCEL_STYLES : [])
      .concat(this.getExcelStylesFromColumnGroups());
  }

  private getExcelStylesFromColumns() {
    return this.vizConfig.columns.map((column) => {
      const valueDisplayOptions =
        column.value_display_options ?? this.valueDisplayOptions;
      return {
        // TODO: move id matching to one place
        id: buildExcelStyleId({ id: column.col_id.toString() }),
        ...(valueDisplayOptions &&
          getExcelStyleNumberFormat(valueDisplayOptions)),
      };
    });
  }

  private getExcelStylesFromRows() {
    return (this.vizConfig.rows ?? []).map((row) => {
      const valueDisplayOptions =
        row.value_display_options ?? this.valueDisplayOptions;
      return {
        id: buildExcelStyleId({
          id: row.key.toString(),
          type: 'row',
        }),
        ...(valueDisplayOptions &&
          getExcelStyleNumberFormat(valueDisplayOptions)),
      };
    });
  }

  private getExcelStylesFromColumnGroups() {
    return (this.vizConfig.column_groups ?? []).map((group) => ({
      id: buildExcelStyleId({
        id: buildGroupId(group.group_id),
        type: 'headerGroup',
      }),
      ...(group.background && {
        interior: {
          color: convertColorToHex(group.background),
          pattern: 'Solid',
        },
      }),
    }));
  }
}

export class ColumnDefsBuilder<
  Column extends {
    label: string;
    key: number;
  },
> {
  columnDefs: (ColDef | ColGroupDef)[];
  vizConfig: TableVizConfig;
  mode: WidgetViewMode;
  colDefBuilder: ColDefBuilder<Column>;
  colGroupDefBuilder: ColGroupDefBuilder;
  excelStyleBuilder: ExcelStyleBuilder;
  columnMatcher: (
    column: Column,
    columnSettings: TableVizConfigColumn,
  ) => boolean = columnToColumnSettingsByKeyMatcher;

  constructor({
    vizConfig,
    mode,
    colDefBuilder,
    colGroupDefBuilder,
    excelStyleBuilder,
    columnMatcher,
  }: {
    vizConfig: TableVizConfig;
    mode: WidgetViewMode;
    colGroupDefBuilder: ColGroupDefBuilder;
    colDefBuilder: ColDefBuilder<Column>;
    excelStyleBuilder?: ExcelStyleBuilder;
    columnMatcher?: (
      column: Column,
      columnSettings: TableVizConfigColumn,
    ) => boolean;
  }) {
    this.colGroupDefBuilder = colGroupDefBuilder;
    this.colDefBuilder = colDefBuilder;
    this.excelStyleBuilder =
      excelStyleBuilder ?? new ExcelStyleBuilder({ vizConfig, mode });
    this.columnDefs = [];
    this.vizConfig = vizConfig;
    this.mode = mode;
    this.columnMatcher = columnMatcher ?? columnToColumnSettingsByKeyMatcher;
  }

  buildExcelStyles() {
    return this.excelStyleBuilder?.buildExcelStyles() ?? [];
  }

  // TODO: we shouldn't use FiltersWidgetState here, move to intermediate service
  buildFilterChangeEventStateApplier({
    columns,
  }: {
    columns: Column[];
  }): (e: FilterChangedEvent) => FiltersWidgetState {
    return (e: FilterChangedEvent) => {
      // Object colId -> filterValues
      const filterModel = e.api.getFilterModel();
      const filters = Object.entries(filterModel).map(
        ([colId, filterValues]) => {
          // TODO: move id matching to one place
          const columnSettings = this.vizConfig.columns.find(
            (c) => c.col_id.toString() === colId,
          )!;
          const column = columns.find((c) =>
            this.columnMatcher(c, columnSettings),
          );

          return {
            column: column?.label,
            values: filterValues,
          };
        },
      );

      return {
        filters,
      };
    };
  }

  build({
    columns,
    filterOptions,
  }: {
    columns: Column[];
    filterOptions?: FilterOption[];
  }) {
    if (columns.length === 0 && this.vizConfig.columns.length === 0) {
      return [];
    }
    const transformColumnSettingsToColDef = (cs: TableVizConfigColumn) => {
      const column = columns.find((c) => this.columnMatcher(c, cs))!;

      return this.colDefBuilder.build({
        column,
        columnSettings: cs,
        filterOptions,
      });
    };
    const ungroupedColumns = orderBy(
      this.vizConfig.columns.filter((column) => !column.group_id),
      'order',
    );
    const colGroupDefs = orderBy(this.vizConfig.column_groups, 'order').map(
      (group) => {
        const columnsSettings = orderBy(this.vizConfig.columns, 'order').filter(
          (c) => c.group_id === group.group_id,
        );
        return {
          ...this.colGroupDefBuilder.build(group),
          children: columnsSettings.map(transformColumnSettingsToColDef),
        };
      },
    );

    return [
      ...ungroupedColumns.map(transformColumnSettingsToColDef),
      ...colGroupDefs,
    ].filter((column) => column !== null) as (ColDef | ColGroupDef)[];
  }
}

export type FilterOption = {
  column?: string;
  options?: string[];
};

import { FilterByLeClassification } from '@/features/leClassification/filter';
import { useItemsFilterByText } from '@/shared/lib/hooks/useItemsFilterByText';
import { DialogProps } from '@/shared/lib/hooks/useModal';
import {
  getOptionsValues,
  includesInLowerCase,
  mapItemsToListOption,
} from '@/shared/lib/listHelpers';
import { SearchInput } from '@/stories/FormControls/Inputs/SearchInput/SearchInput';
import { ShownInfoLine } from 'bundles/Shared/components/Table/pagination/TablePagination';
import {
  DashboardFilterObject,
  DashboardFilterObjectTypes,
  ReportDashboardAsset,
} from 'bundles/Shared/entities/dashboard';
import { LeClassification } from 'bundles/Shared/entities/leClasssification';
import { capitalize, sortBy } from 'lodash-es';
import pluralize from 'pluralize';
import { useMemo, useState, type ComponentProps } from 'react';
import CheckList from 'stories/Checkbox/CheckList';
import { SidePanel } from 'stories/Modals/Modal/Modal';

type Props = DialogProps & {
  onChange: (objectIds: ReportDashboardAsset['id'][]) => void;
  objectType: DashboardFilterObjectTypes;
  objects: DashboardFilterObject[];
  defaultObjectIds?: ReportDashboardAsset['id'][];
  objectIds?: ReportDashboardAsset['id'][];
  sidePanelProps?: Pick<
    ComponentProps<typeof SidePanel>,
    'position' | 'size' | 'actions'
  >;
  hasClassFilter?: boolean;
};

export function ObjectSelectionModal({
  onClose,
  objectIds = [],
  defaultObjectIds,
  onChange,
  objectType,
  objects,
  sidePanelProps,
  hasClassFilter = true,
}: Props) {
  const [internalAssetIds, setInternalCheckedIds] = useState(defaultObjectIds);
  const [leClassifications, setLeClassifications] = useState<
    LeClassification[]
  >([]);
  const items = useMemo(
    () =>
      sortBy(objects, 'name').map((object) => ({
        id: object.id,
        label: object.name,
        leClassification: object.leClassification,
      })),
    [objects],
  );

  const filterBy = useMemo(
    () =>
      (
        item: {
          id: number;
          label: string;
          leClassification: LeClassification[];
        },
        searchText: string,
      ) => {
        const byName = includesInLowerCase(item.label, searchText);
        const byLeClassification = leClassifications.length
          ? item.leClassification.some((lc) => leClassifications.includes(lc))
          : true;
        return byName && byLeClassification;
      },
    [leClassifications],
  );

  const { filteredItems, inputProps } = useItemsFilterByText(items, filterBy, {
    skipWhenTextIsEmpty: false,
  });

  const handleCheckedItemsChanged = (newIds: number[]) => {
    if (defaultObjectIds) {
      setInternalCheckedIds(newIds);
    }
    onChange(newIds);
  };

  const finalObjectIds = internalAssetIds ?? objectIds ?? [];
  const finalSetObjectIds = defaultObjectIds
    ? handleCheckedItemsChanged
    : onChange;

  const checkedItems =
    objects.length === 0
      ? []
      : finalObjectIds
          .map((id) => items.find((o) => o.id === id)!)
          .filter(Boolean);

  return (
    <SidePanel
      closeOnOutsideClick
      header={pluralize(capitalize(objectType))}
      toggle={onClose}
      classes={{
        body: 'flex flex-col gap-4 bg-neutral-050',
      }}
      {...sidePanelProps}
    >
      <SearchInput placeholder="Search by name" {...inputProps} />
      <div className="flex items-center justify-between">
        <ShownInfoLine size="m" totalSize={filteredItems.length} name="Items" />
        {hasClassFilter && (
          <FilterByLeClassification
            value={leClassifications}
            onChange={setLeClassifications}
          />
        )}
      </div>
      <CheckList
        selectAll
        selectAllLabel="All"
        className="rounded-[8px] bg-neutral-000 p-4 shadow-z-080"
        items={mapItemsToListOption(filteredItems, 'label')}
        value={mapItemsToListOption(checkedItems, 'label')}
        onChange={(newItems) => finalSetObjectIds(getOptionsValues(newItems))}
      />
    </SidePanel>
  );
}

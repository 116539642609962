import RecapPageButton from '@/bundles/Shared/components/RecapPageButton';
import { useWidgetFlags } from '@/bundles/Shared/widgets/dashboard/widgets/common/lib/useWidgetFlags';
import {
  createMapBy,
  filterDefaultGridIntervalsByGranularity,
  getDefaultBaseIntervalForGranularity,
} from '@/bundles/Shared/widgets/dashboard/widgets/common/lib/utils';
import { ChartContainer } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/ChartContainer';
import { useWidgetFullScreen } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/WidgetFullScreen';
import { XYChartUtils } from '@/bundles/Shared/widgets/dashboard/widgets/xyChart/lib';
import { formatToDateStringForRequest } from '@/shared/lib/converters';
import { cn } from '@/shared/lib/css/cn';
import { getAmchartNumberFormatByDisplayOptions } from '@/shared/lib/formatting/charts';
import { mapListToIds } from '@/shared/lib/listHelpers';
import { GrowDiv } from '@/shared/ui/GrowDiv';
import { FlexibleFilterByPeriods } from '@/stories/FlexibleFilterByPeriods/FlexibleFilterByPeriods';
import { ThinTabGroup } from '@/stories/Tabs/ThinTabGroup/ThinTabGroup';
import { generatePrevPeriods } from '@/stories/FlexibleFilterByPeriods/consts';
import * as am5plugins_exporting from '@amcharts/amcharts5/plugins/exporting';
import * as am5plugins_json from '@amcharts/amcharts5/plugins/json';
import am5themesAnimated from '@amcharts/amcharts5/themes/Animated';
import { ColumnSeries, XYChart } from '@amcharts/amcharts5/xy';
import { PIPELINE_CHART_COLOR_SET } from 'bundles/Scoreboard/Pipeline/components/chartUtils';
import {
  ReportDashboardAsset,
  ReportDashboardSegment,
  ReportDashboardType,
  useDashboardContext,
  WidgetDateGranularity,
} from 'bundles/Shared/entities/dashboard';
import { XyChartSingleKpiWidgetDto } from '@/shared/api/dashboardsGeneratedApi';
import {
  DashboardWidgetCard,
  DateRangeWidgetState,
  WidgetStateGranularity,
} from 'bundles/Shared/widgets/dashboard/widgets/common';
import { ExportChartButton } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/ExportChartButton';
import { WidgetStateCalendarRangeSelector } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/state/WidgetStateCalendarRangeSelector';
import {
  WidgetContextProps,
  WidgetProps,
  WidgetStateProps,
} from 'bundles/Shared/widgets/dashboard/widgets/model';
import { KPIS_DROPDOWN_MIN_SEARCHABLE_OPTIONS } from 'bundles/Shared/widgets/dashboard/widgets/xyChartSingleKpi/constants';
import {
  addAdaptersToColumnSeries,
  findTemplateSeriesRef,
  findXAxisRef,
  GROUPING_TYPE_TO_OBJECT_TYPE,
  GroupingType,
  idToObjectMapper,
  isChartCategorical,
  ObjectType,
  orderItems,
  sleepUntilSeriesAreReady,
  TAB_ITEMS,
} from 'bundles/Shared/widgets/dashboard/widgets/xyChartSingleKpi/lib/common';
import { addExportToXySingleKpiChart } from 'bundles/Shared/widgets/dashboard/widgets/xyChartSingleKpi/lib/export';
import { XYChartSingleKpiWidgetSection } from 'bundles/Shared/widgets/dashboard/widgets/xyChartSingleKpi/model';
import { KpisDropdown } from 'bundles/Shared/widgets/dashboard/widgets/xyChartSingleKpi/ui/KpisDropdown';
import { MixedDropdown } from 'bundles/Shared/widgets/dashboard/widgets/xyChartSingleKpi/ui/MixedDropdown';
import { EagleEyeDashboardWidgetContext } from 'bundles/Shared/widgets/dashboard/widgetsHelpers';
import dayjs from 'dayjs';
import { produce } from 'immer';
import { useAmchart } from 'lib/amcharts/useAmchart';
import { chartDateMapper, getReturnDashboardTheme } from 'lib/amcharts/utils';
import { camelCase, groupBy, intersection, orderBy, uniqBy } from 'lodash-es';
import { useMemo, useRef } from 'react';
import AnimationLoader from 'stories/AnimationLoader/AnimationLoader';
import { IPeriodItem } from 'stories/FlexibleFilterByPeriods/types';

export type XYChartSingleKpiWidgetState = DateRangeWidgetState & {
  granularity: WidgetDateGranularity;
  assets: ReportDashboardAsset['id'][];
  segments: ReportDashboardSegment['id'][];
  groupingType: GroupingType;
  kpi?: number;
};

export function EagleEyeXYChartWidget(
  props: WidgetProps<XyChartSingleKpiWidgetDto, XYChartSingleKpiWidgetSection> &
    WidgetStateProps<XYChartSingleKpiWidgetState> &
    WidgetContextProps<EagleEyeDashboardWidgetContext> &
    PropsWithClassName,
) {
  const {
    data,
    state,
    onStateChange,
    context,
    widgetSection,
    mode,
    className,
  } = props;
  const { dashboardType } = useDashboardContext();
  const { shouldDisplayLoader, shouldDisplayData } = useWidgetFlags(props);

  const isCategorical = isChartCategorical(
    widgetSection.widgetConfig.am_chart_config,
  );
  const selectedKpi = isCategorical
    ? null
    : widgetSection.widgetConfig.kpis.find((kpi) => kpi.key === state.kpi);
  const ref = useRef<{ exporting: am5plugins_exporting.Exporting }>(null);
  const chartRef = useRef<XYChart>(null);
  const wrapperDivRef = useRef<HTMLDivElement>(null);
  const widgetStateFullScreenFeature = useWidgetFullScreen(wrapperDivRef);

  const objectList = useMemo(() => {
    const getObjectList = (
      key: GroupingType,
    ): {
      id: number;
      type: ObjectType;
    }[] => {
      if (key === 'mixed') {
        return [
          ...state.assets.map(idToObjectMapper('asset')),
          ...state.segments.map(idToObjectMapper('segment')),
        ];
      }
      const objectMapper = idToObjectMapper(GROUPING_TYPE_TO_OBJECT_TYPE[key]);
      const contextObjectIds = mapListToIds(context[key] ?? []);
      if (state[key] != null) {
        // filter set object can include extra objects, which are not in the dashboard context
        // TODO: move to slice
        return intersection(state[key], contextObjectIds).map(objectMapper);
      }
      return contextObjectIds.map(
        idToObjectMapper(GROUPING_TYPE_TO_OBJECT_TYPE[key]),
      );
    };
    return getObjectList(state.groupingType);
  }, [state.groupingType, state.assets, state.segments, context]);

  const items = useMemo(() => {
    const mappedItems = data?.data.map(chartDateMapper('dateFrom'));

    const filteredItems = mappedItems?.filter(
      (item) =>
        (selectedKpi == null || item.kpiKey === selectedKpi?.key.toString()) &&
        objectList.some(
          (object) =>
            object.type === item.objectType &&
            object.id === Number(item.objectId),
        ),
    );
    return orderBy(filteredItems ?? [], ['dateFrom', 'asc']);
  }, [data, selectedKpi, objectList]);

  const contextMap = useMemo(() => {
    const map = {
      assets: new Map<string, ReportDashboardAsset>(),
      segments: new Map<string, ReportDashboardSegment>(),
    };

    if (context?.assets == null || context?.segments == null) {
      return map;
    }

    map.assets = createMapBy(context.assets, 'id');
    map.segments = createMapBy(context.segments, 'id');

    return map;
  }, [context]);

  useAmchart(
    ref,
    (root) => {
      if (
        context.assets == null ||
        context.segments == null ||
        items.length === 0
      ) {
        return;
      }
      const objectNames = objectList
        .map((object) =>
          object.type === 'asset'
            ? contextMap.assets.get(String(object.id))?.name ?? null
            : contextMap.segments.get(String(object.id))?.title ?? null,
        )
        .filter(Boolean)
        .toSorted();

      const { exportingSettings, onWorkbookReady } =
        addExportToXySingleKpiChart({
          state,
          widgetConfig: widgetSection.widgetConfig,
          widgetTitle: widgetSection.title,
          widgetId: widgetSection.id,
          groupingObjects: objectList,
          context,
          items,
        });
      ref.current!.exporting = am5plugins_exporting.Exporting.new(
        root,
        exportingSettings,
      );
      ref.current!.exporting.events.on('workbookready', onWorkbookReady);
      const myTheme = getReturnDashboardTheme(root);
      myTheme.rule('ColorSet').set('colors', PIPELINE_CHART_COLOR_SET);
      root.setThemes([am5themesAnimated.new(root), myTheme]);

      const parser = am5plugins_json.JsonParser.new(root);
      const config = widgetSection.widgetConfig.am_chart_config;
      const orderedByKpiAndValue = orderItems(items);
      const assetRefs = groupBy(items, (item) => camelCase(item.objectName));
      const kpiRefs = groupBy(orderedByKpiAndValue, (item) =>
        item.kpiKey.toString(),
      );

      const dataRef = {
        data: items,
        ...assetRefs,
        ...kpiRefs,
        xAxisData: uniqBy(orderedByKpiAndValue, 'objectName'),
        selectedKpi,
      };
      const chartConfig = produce(config, (draft) => {
        draft.refs.unshift(dataRef);
        const templateSeriesRef = findTemplateSeriesRef(draft);
        if (templateSeriesRef != null) {
          const [template] = templateSeriesRef.templateSeries;
          templateSeriesRef.templateSeries = objectNames.map((s) => ({
            ...template,
            settings: {
              ...template.settings,
              name: s,
            },
            properties: {
              ...template.properties,
              data: `#${camelCase(s)}`,
            },
          }));
        }
        if (!isCategorical) {
          const xAxisRef = findXAxisRef(draft);
          if (xAxisRef != null) {
            xAxisRef.xAxis.settings.baseInterval =
              getDefaultBaseIntervalForGranularity(state.granularity);
            xAxisRef.xAxis.settings.gridIntervals =
              filterDefaultGridIntervalsByGranularity(state.granularity);
          }
        }
      });
      parser
        .parse(chartConfig, {
          parent: root.container,
        })
        .then(async function (chart: XYChart) {
          chartRef.current = chart;
          await sleepUntilSeriesAreReady();

          const xyChartUtils = new XYChartUtils(chart);

          if (mode === 'pdf') {
            xyChartUtils.hideScrollbars();
            xyChartUtils.appearWithDelay();
          }

          chart.series.each((series) => {
            if (series.isType('ColumnSeries')) {
              addAdaptersToColumnSeries(series as ColumnSeries);
            }
          });

          chart.root.numberFormatter.setAll({
            numberFormat: getAmchartNumberFormatByDisplayOptions(
              selectedKpi?.value_display_options ?? null,
              {
                abbreviate: false,
              },
            ),
          });
        });
    },
    [items, state.granularity],
  );

  const handleGroupingTypeChange = (newGroupingType: GroupingType) => {
    onStateChange({
      ...state,
      groupingType: newGroupingType,
      assets:
        newGroupingType === 'mixed' || newGroupingType === 'assets'
          ? mapListToIds(
              widgetSection.defaultOptions.objects?.filter(
                (obj) => obj.type === 'asset',
              ) ?? context.assets,
            )
          : [],
      segments:
        newGroupingType === 'mixed' || newGroupingType === 'segments'
          ? mapListToIds(
              widgetSection.defaultOptions.objects?.filter(
                (obj) => obj.type === 'segment',
              ) ?? context.segments,
            )
          : [],
    });
  };

  const renderDatePicker = () => {
    if (
      isCategorical &&
      (state.granularity === 'year' || state.granularity === 'month')
    ) {
      const periodItems = [
        {
          type: state.granularity === 'year' ? 'ytd' : 'mtd',
          period: formatToDateStringForRequest(state.dateFrom),
        },
      ];

      const handleUpdatePeriodItems = (newItems: IPeriodItem[]) => {
        onStateChange({
          ...state,
          dateFrom: formatToDateStringForRequest(
            dayjs(newItems[0].period).startOf(state.granularity),
          ),
          dateTo: formatToDateStringForRequest(
            dayjs(newItems[0].period).endOf(state.granularity),
          ),
        });
      };
      return (
        <FlexibleFilterByPeriods
          filterByPeriodsType={state.granularity === 'year' ? 'ytd' : 'mtd'}
          isSingleSelection
          possiblePeriods={generatePrevPeriods()}
          periodItems={periodItems}
          onUpdatePeriodItems={handleUpdatePeriodItems}
        />
      );
    }
    return (
      <WidgetStateCalendarRangeSelector
        state={state}
        onStateChange={onStateChange}
      />
    );
  };

  const kpisDropdownItems = useMemo(() => {
    if (!widgetSection.widgetConfig.kpis) return [];

    return widgetSection.widgetConfig.kpis.map((kpi) => ({
      label: kpi.label,
      value: kpi.key,
    }));
  }, [widgetSection.widgetConfig.kpis]);

  const dropdownSelectedKpi = useMemo(() => {
    return kpisDropdownItems.find((kpi) => kpi.value === state.kpi);
  }, [kpisDropdownItems, state.kpi]);

  return (
    <DashboardWidgetCard
      {...props}
      className={cn(mode === 'edit' && 'h-[500px]', className)}
      ref={wrapperDivRef}
    >
      <DashboardWidgetCard.Header className="gap-2">
        <div className="flex">
          <DashboardWidgetCard.Header.Title>
            {widgetSection.title}
          </DashboardWidgetCard.Header.Title>
          {mode !== 'pdf' && (
            <RecapPageButton
              name={widgetSection.recapPage?.name}
              slug={widgetSection.recapPage?.slug}
            />
          )}
        </div>
        {mode !== 'pdf' && (
          <>
            <GrowDiv />
            <ThinTabGroup
              selectedItem={state.groupingType}
              onSelectedItemChange={(newItem) => {
                handleGroupingTypeChange(newItem.id);
              }}
              items={TAB_ITEMS.filter(
                (item) =>
                  widgetSection.defaultOptions?.groupingTypes == null ||
                  widgetSection.defaultOptions?.groupingTypes.includes(item.id),
              )}
            />
            <widgetStateFullScreenFeature.IconButton />
          </>
        )}
      </DashboardWidgetCard.Header>
      {mode !== 'pdf' && (
        <DashboardWidgetCard.Panel>
          {!isCategorical && (
            <KpisDropdown
              items={kpisDropdownItems}
              selectedItem={dropdownSelectedKpi}
              minSearchableOptions={KPIS_DROPDOWN_MIN_SEARCHABLE_OPTIONS}
              getMainFieldTitle={(item) => item.label}
              onSelectedItemChange={(item) => {
                onStateChange({
                  ...state,
                  kpi: item?.value,
                });
              }}
            />
          )}
          {dashboardType !== ReportDashboardType.REPORT_BUILDER_TEMPLATE &&
            dashboardType !==
              ReportDashboardType.REPORT_BUILDER_TEMPLATE_EAGLE_EYE && (
              <MixedDropdown
                context={context}
                state={state}
                onStateChange={onStateChange}
              />
            )}
          {!isCategorical && (
            <>
              <WidgetStateGranularity
                state={state}
                onStateChange={onStateChange}
                chartRef={chartRef}
                granularities={
                  widgetSection.defaultOptions?.granularities ??
                  widgetSection.widgetConfig.default_options?.granularities
                }
              />
            </>
          )}
          {renderDatePicker()}
          <div className="grow" />
          <ExportChartButton
            chartRef={ref}
            widgetSectionId={widgetSection.id}
          />
        </DashboardWidgetCard.Panel>
      )}
      {shouldDisplayLoader && <AnimationLoader />}
      {shouldDisplayData && <ChartContainer ref={ref} />}
    </DashboardWidgetCard>
  );
}

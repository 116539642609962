import type { ReportBuilderTemplateWidgetSectionTypes } from '@/bundles/Shared/entities/dashboard';
import {
  DEFAULT_ALL_REPORT_BUILDER_CONFIG_MAP,
  WIDGETS_CONFIG_MAP,
} from '@/bundles/Shared/widgets/dashboard/widgets/config';
import {
  usePostApiSettingsReportBuilderEagleEyeTemplatesByEagleEyeTemplateIdGroupsAndGroupIdWidgetSectionsMutation,
  usePostApiSettingsReportBuilderTemplatesByTemplateIdGroupsAndGroupIdWidgetSectionsMutation,
} from '@/entities/report/reportBuilder/api/settingsReportBuilderTemplatesEnhancedApi';
import { useReportingEntityKindContext } from '@/entities/reporting/context/entityKind';

export const useCreateReportBuilderTemplateWidget = () => {
  const [createWidgetMutation, createOptions] =
    usePostApiSettingsReportBuilderTemplatesByTemplateIdGroupsAndGroupIdWidgetSectionsMutation();
  const [createEagleEyeWidgetMutation, createOptionsEagleEye] =
    usePostApiSettingsReportBuilderEagleEyeTemplatesByEagleEyeTemplateIdGroupsAndGroupIdWidgetSectionsMutation();
  const templateKind = useReportingEntityKindContext();

  const getOptions = () => {
    switch (templateKind) {
      case 'eagle_eye':
        return createOptionsEagleEye;
      default:
        return createOptions;
    }
  };

  const handleCreateWidget = async <
    T extends ReportBuilderTemplateWidgetSectionTypes,
  >(params: {
    type: T[];
    groupId: string;
    templateId: string;
  }) => {
    const { templateId, groupId, type } = params;
    const [oneType] = type;

    const body = {
      widget_type: oneType,
      width_percent: 100,
      config: DEFAULT_ALL_REPORT_BUILDER_CONFIG_MAP[oneType] ?? {},
      title: `${WIDGETS_CONFIG_MAP[oneType].title}`,
    };

    if (templateKind === 'eagle_eye') {
      return await createEagleEyeWidgetMutation({
        groupId,
        eagleEyeTemplateId: templateId,
        body,
      });
    }

    return await createWidgetMutation({
      groupId,
      templateId,
      body,
    });
  };

  return [handleCreateWidget, getOptions()] as const;
};

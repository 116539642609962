import {
  IThinTabItem,
  ThinTabGroup,
} from 'stories/Tabs/ThinTabGroup/ThinTabGroup';
import React, { useState } from 'react';
import {
  GranularityField,
  PeriodShiftField,
  UpdateWidgetTitle,
  useUpdateListPropertyWidgetConfig,
  useUpdateWidgetConfigEffect,
  useWidgetConfig,
} from 'bundles/Shared/widgets/dashboard/widgets/common';
import { OBJECT_DASHBOARD_SECTION_TYPE } from 'bundles/Shared/entities/dashboard';
import { WidgetSettingsListLayout } from 'bundles/Shared/components/layouts/dashboard/WidgetLayout';
import Button from 'stories/Button/Button';
import { DragDropContext } from '@hello-pangea/dnd';
import { Field } from '@/stories/Field/Field';
import { IconButton } from '@/stories/IconButton/IconButton';
import { isFormulaByExpression } from 'bundles/Shared/entities/formula';
import { InspectFormulaButton } from 'bundles/Shared/features/formula/editFormula';
import { XYChartWidgetConfigRow } from 'bundles/Shared/widgets/dashboard/widgets/xyChartSingleKpi/config/row';
import { FormProvider, useForm } from 'react-hook-form';
import { FieldsContainer } from 'stories/Field/Field';
import { DefaultDateRangeField } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/DefaultDateRangeField';
import { useTabs } from 'stories/Tabs/useTabs';
import {
  transformXYChartWidgetConfigToForm,
  XYChartWidgetConfigForm,
} from 'bundles/Shared/widgets/dashboard/widgets/xyChart/config/component.form';
import { updateDefaultOptions } from '@/bundles/Shared/widgets/dashboard/widgets/xyChart/config/updaters';
import { XYChartWidgetConfigKpi } from '@/bundles/Shared/widgets/dashboard/widgets/xyChartSingleKpi/model';

import { useRemoveKpiFromConfig } from 'bundles/Shared/widgets/dashboard/widgets/xyChartSingleKpi/lib/common';
import { RecapPageField } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/fields/RecapPageField';

const TABS = [
  {
    id: 'basic',
    label: 'Basic',
  },
  {
    id: 'data',
    label: 'Data',
  },
] as const satisfies readonly IThinTabItem[];
const BasicTab = () => {
  const { widget } =
    useWidgetConfig<typeof OBJECT_DASHBOARD_SECTION_TYPE.XY_CHART>();
  const methods = useForm<XYChartWidgetConfigForm>({
    defaultValues: {
      ...transformXYChartWidgetConfigToForm(widget.widgetConfig),
    },
  });

  const { control } = methods;

  useUpdateWidgetConfigEffect<
    XYChartWidgetConfigForm,
    typeof OBJECT_DASHBOARD_SECTION_TYPE.XY_CHART
  >(methods, updateDefaultOptions);

  const defaultDateRange = methods.watch('defaultDateRange');

  return (
    <FormProvider {...methods}>
      <FieldsContainer>
        <UpdateWidgetTitle />
        <Field labelText="Period Shift">
          <PeriodShiftField control={control} name="periodShiftTo" />
        </Field>
        <Field labelText="Group By">
          <GranularityField control={control} name="granularity" />
        </Field>
        <Field labelText="Default Range">
          <DefaultDateRangeField
            periodShiftName="periodShiftFrom"
            control={control}
            name="defaultDateRange"
          />
        </Field>
        {defaultDateRange === 'none' && (
          <Field labelText="Period Shift (Date From)">
            <PeriodShiftField control={control} name="periodShiftFrom" />
          </Field>
        )}
        <RecapPageField />
      </FieldsContainer>
    </FormProvider>
  );
};
const DataTab = () => {
  const [modalState, setModalState] = useState<
    'create' | XYChartWidgetConfigKpi | null
  >(null);
  const { widget } =
    useWidgetConfig<typeof OBJECT_DASHBOARD_SECTION_TYPE.XY_CHART>();
  const { kpis = [] } = widget.widgetConfig;

  const { moveItem: moveRow, cloneItem: cloneRow } =
    useUpdateListPropertyWidgetConfig(
      OBJECT_DASHBOARD_SECTION_TYPE.XY_CHART,
      'kpis',
    );
  const removeKpi = useRemoveKpiFromConfig();

  return (
    <WidgetSettingsListLayout>
      <WidgetSettingsListLayout.Header
        label={kpis.length === 0 ? 'No Items Yet' : `${kpis.length} ITEMS`}
      >
        <Button
          onClick={() => {
            setModalState('create');
          }}
          size="xs"
          variant="secondary"
        >
          Add
        </Button>
      </WidgetSettingsListLayout.Header>
      <DragDropContext
        onDragEnd={({ source, destination }) => {
          if (!destination) return;
          moveRow(source.index, destination.index);
        }}
      >
        <WidgetSettingsListLayout.SortableList>
          {kpis.map((row, index) => (
            <WidgetSettingsListLayout.SortableListItem
              index={index}
              draggableId={row.key.toString()}
              key={row.key}
              listIndex={index}
              className="group/item"
            >
              <WidgetSettingsListLayout.ListItem.Text>
                {row.label ?? row.expression}
              </WidgetSettingsListLayout.ListItem.Text>
              <div className="grow" />
              <WidgetSettingsListLayout.ListItem.ActionsPanel>
                <IconButton
                  iconName="copy"
                  onClick={() => {
                    cloneRow(row.key);
                  }}
                />
                {isFormulaByExpression(row.expression) && (
                  <InspectFormulaButton reference={row.expression} />
                )}
                <IconButton
                  onClick={() => {
                    setModalState(row);
                  }}
                  iconName="edit"
                />
                <IconButton
                  onClick={() => {
                    removeKpi(row.key);
                  }}
                  iconName="trash"
                />
              </WidgetSettingsListLayout.ListItem.ActionsPanel>
            </WidgetSettingsListLayout.SortableListItem>
          ))}
        </WidgetSettingsListLayout.SortableList>
      </DragDropContext>
      {modalState != null && (
        <XYChartWidgetConfigRow
          type={modalState === 'create' ? 'create' : 'edit'}
          rowConfig={modalState === 'create' ? null : modalState}
          onClose={() => setModalState(null)}
          onSubmit={(res) => {
            setModalState(null);
            if (res?.createNew) {
              setTimeout(() => {
                setModalState('create');
              });
            }
          }}
        />
      )}
    </WidgetSettingsListLayout>
  );
};

export function ObjectDashboardXYChartWidgetConfig() {
  const { tab, thinTabGroupProps } = useTabs(TABS, TABS[0]);
  return (
    <div className="flex flex-col gap-4">
      <ThinTabGroup fullWidth {...thinTabGroupProps} />
      <div className="p-1">
        {tab?.id === 'basic' && <BasicTab />}
        {tab?.id === 'data' && <DataTab />}
      </div>
    </div>
  );
}

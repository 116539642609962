/* eslint-disable indent */
/* eslint-disable react/jsx-indent */
import { createUser } from 'bundles/UserManagement/actions/User';
import { fetchUserStatistics } from 'bundles/UserManagement/actions/UserStatistics';
import {
  deleteUserRole,
  fetchUserRoles,
  updateUserRole,
} from 'bundles/UserManagement/reducers/userRolesSlice';
import { useAppDispatch } from '@/shared/lib/hooks/redux';
import { usePermissions } from '@/app/lib';
import { isEqual, sortBy } from 'lodash-es';
import { useEffect, useMemo, useState } from 'react';
import { Badge } from 'stories/Badges/Badge/Badge';
import { Button } from 'stories/Button/Button';
import { ConfirmationModal } from 'stories/Modals/ConfirmationModal/ConfirmationModal';
import { Icon } from 'stories/Icon/Icon';
import { IconButton } from 'stories/IconButton/IconButton';
import { LinkButton } from 'stories/LinkButton/LinkButton';
import { Tooltip } from 'stories/Tooltip/Tooltip';
import type { IProduct, IProductPermissionKeys, IUserRole } from 'types/User';
import CreateUserModal from '@/bundles/UserManagement/components/CreateUserModal';
import {
  CONFIRM_DELETE_ROLE_MODAL_PROPS,
  NoUsersJSX,
} from '@/bundles/UserManagement/components/roles/constants';
import { genChangedProducts } from '@/bundles/UserManagement/components/roles/helpers/genChangedProducts';
import { genUpdatedRole } from '@/bundles/UserManagement/components/roles/helpers/genUpdatedRole';
import type * as Types from '@/bundles/UserManagement/components/roles/types';
import { userNamesResolver } from '@/bundles/UserManagement/components/roles/utils';
import { RolePermissions } from '@/bundles/UserManagement/components/roles/RightBar/RolePermissions';
import styles from '@/bundles/UserManagement/components/roles/RightBar/styles.module.scss';

interface Props {
  activeRole: IUserRole;
  resolveActiveRoleId: () => void;
}

export function DetailsTab({ activeRole, resolveActiveRoleId }: Props) {
  const dispatch = useAppDispatch();
  const { canConfigureUserRoles } = usePermissions();
  const [createUserModal, setCreateUserModal] = useState(false);
  const [isUsersShown, setIsUsersShown] = useState(false);
  const [detailsTabState, setDetailsTabState] =
    useState<Types.DetailsTabStateType>('idle');
  const [roleData, setRoleData] = useState({ ...activeRole });

  const shownUsers = useMemo(
    () => (isUsersShown ? activeRole?.users : activeRole?.users?.slice(0, 1)),
    [activeRole, isUsersShown],
  );
  const { userNames, hiddenNamesLen } = useMemo(
    () => userNamesResolver(activeRole?.users),
    [activeRole],
  );

  const handleChangedState = () => {
    setDetailsTabState('changed');
  };

  const resetState = () => {
    setDetailsTabState('idle');
  };

  const checkForChanges = (newRole: typeof roleData) => {
    const preparedRole = {
      ...activeRole,
      products: sortBy(activeRole.products, ['name']),
    };
    setDetailsTabState(isEqual(preparedRole, newRole) ? 'idle' : 'changed');
  };

  const toggleDataPermission = (
    key: IProductPermissionKeys,
    productId: IProduct['id'],
  ) => {
    setRoleData((prevRole) => {
      const newRole = genUpdatedRole(prevRole, key, productId);

      checkForChanges(newRole);

      return newRole;
    });
  };

  const changedProducts = useMemo(
    () => genChangedProducts(roleData.products, activeRole.products),
    [roleData, activeRole],
  );

  const handleResolveConfirmDelete = (status: boolean) => {
    if (status) {
      dispatch(deleteUserRole(activeRole.id));
      resolveActiveRoleId(); // handle changing active role after deleting current
      return;
    }
    checkForChanges(roleData);
  };

  useEffect(() => {
    resetState();
    setRoleData(activeRole);
  }, [activeRole]);

  const toggleCreateUserModal = () => {
    setCreateUserModal(!createUserModal);
  };

  const handleSaveUpdates = () => {
    setDetailsTabState('saving');
    const data = { products: changedProducts };
    dispatch(updateUserRole({ id: activeRole.id, data }));
  };

  const isRoleDeletable =
    !activeRole.readonly && canConfigureUserRoles && !activeRole.users.length;

  const isSavingDisabled = detailsTabState !== 'changed';

  const isTooltipOnSaveBtnDisabled = !isSavingDisabled || activeRole.readonly;

  return (
    <div className={styles.detailsTab}>
      <div data-name="users" className="mb-m">
        <div className="mb-s flex items-center justify-between">
          <span className="light-90 inline-semibold">Users</span>
          <LinkButton
            onClick={toggleCreateUserModal}
            fontWeight="bold"
            className="label-semibold"
          >
            <Icon iconName="addSmall" className="light-60" />
            Add User
          </LinkButton>
        </div>

        <ul className="grid gap overflow-hidden rounded-[8px]">
          {activeRole?.users?.length === 0 && NoUsersJSX}
          {activeRole?.users?.length > 0 &&
            shownUsers?.map(({ id, fullName }, idx) => (
              <li
                key={id}
                className="dark-40 inline-regular flex h-[3rem] items-center justify-between gap-s bg-white px-m py-s"
              >
                {isUsersShown || activeRole.users.length <= 1 ? (
                  fullName
                ) : (
                  <>
                    <span className="light-60 label-semibold">{userNames}</span>
                    {hiddenNamesLen !== 0 && (
                      <Badge
                        className="ml-auto"
                        backgroundColor="var(--violet)"
                      >
                        + {hiddenNamesLen}
                      </Badge>
                    )}
                  </>
                )}
                {activeRole.users.length > 1 && idx === 0 && (
                  <IconButton
                    className="bg-transparent"
                    onClick={() => setIsUsersShown(!isUsersShown)}
                    iconName={isUsersShown ? 'arrowTop' : 'arrowBottom'}
                  />
                )}
              </li>
            ))}
        </ul>
      </div>

      <RolePermissions
        readonly={activeRole.readonly || !canConfigureUserRoles}
        roleDataState={roleData}
        actions={{ handleChangedState, resetState, toggleDataPermission }}
        isSaving={detailsTabState === 'saving'}
      />
      <Tooltip
        disabled={isTooltipOnSaveBtnDisabled}
        mainText="Saving will be available if you make a change in the selected role"
        arrowPosition="center"
        maxWidth="100%"
        classes={{
          spanContainer: 'mt-6',
        }}
      >
        <Button
          fluid
          size="l"
          onClick={handleSaveUpdates}
          disabled={isSavingDisabled}
          variant="success"
        >
          Save Updates
        </Button>
      </Tooltip>
      {isRoleDeletable && (
        <Button
          className="ghost mx-auto mt-m border-0 shadow-none"
          size="s"
          onClick={() => setDetailsTabState('show-confirm-delete-role')}
        >
          <Icon iconName="trash" />
          Delete Role
        </Button>
      )}

      {detailsTabState === 'show-confirm-delete-role' && (
        <ConfirmationModal
          {...CONFIRM_DELETE_ROLE_MODAL_PROPS}
          handleResolve={handleResolveConfirmDelete}
        />
      )}

      {createUserModal && (
        <CreateUserModal
          onSubmit={async (data) => {
            await createUser(data);
            fetchUserRoles();
            dispatch(fetchUserStatistics());
            toggleCreateUserModal();
          }}
          toggleCreateUserModal={toggleCreateUserModal}
        />
      )}
    </div>
  );
}

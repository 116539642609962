/* eslint-disable new-cap */
import AlbumsList, {
  IFolder,
} from '@/bundles/Assets/components/Folders/AlbumsList';
import NewAlbumModal from '@/bundles/Assets/components/Folders/NewAlbumModal';
import {
  useCreateBulkSharedFileMutation,
  useDeleteBulkFoldersMutation,
  useDeleteBulkSharedFileMutation,
  useGetObjectFoldersQuery,
  useGetSharedFilesQuery,
  type SharedFilesResponse,
} from '@/bundles/Assets/components/Media/api/objectFoldersApi';
import BulkUploaderModal from '@/bundles/Assets/components/SharedFiles/BulkUploaderModal';
import MediaItem from '@/bundles/Assets/components/SharedFiles/MediaItem';
import { snakeCaseKeys } from '@/bundles/Construction/components/helpers';
import InputNumber from '@/bundles/Shared/components/GroupForm/FormItems/InputNumber';
import { SharedSelect } from '@/bundles/Shared/components/GroupForm/FormItems/new/SharedSelect';
import NoDataOverlay from '@/bundles/Shared/components/NoDataOverlay';
import VerticalSeparator from '@/bundles/Shared/components/VerticalSeparator/VerticalSeparator';
import EntityContext, {
  type EntityContextType,
} from '@/bundles/Shared/EntityContext';
import { resolveWidgetSectionPositionClassName } from '@/bundles/Shared/widgets/dashboard/widgets/common';
import { widgetHasData } from '@/bundles/Shared/widgets/dashboard/widgets/financialTableSingeDate/lib';
import {
  MEDIA_WIDGET_OBJECT_FIT_OPTIONS,
  MEDIA_WIDGET_OBJECT_POSITION_OPTIONS,
  type MediaWidgetDisplayOptions,
} from '@/bundles/Shared/widgets/dashboard/widgets/media/config';
import { getMediaWidgetDisplayOptions } from '@/bundles/Shared/widgets/dashboard/widgets/media/lib';
import { CaptionSettingsModal } from '@/bundles/Shared/widgets/dashboard/widgets/media/ui/CaptionSettingsModal';
import { useUpdateMediaWidthBySelectedFilesLenEffect } from '@/bundles/Shared/widgets/dashboard/widgets/media/ui/lib';
import { MediaWidget } from '@/bundles/Shared/widgets/dashboard/widgets/media/ui/MediaWidget';
import { MediaWidgetFigure } from '@/bundles/Shared/widgets/dashboard/widgets/media/ui/MediaWidgetFigure';
import { MediaWidgetPreviewUI } from '@/bundles/Shared/widgets/dashboard/widgets/media/ui/MediaWidgetPreviewUI';
import { WidgetConfigCardProps } from '@/bundles/Shared/widgets/dashboard/widgets/model';
import { usePutApiCoreAssetsByAssetIdReportBuilderReportsAndReportIdWidgetSectionsWidgetSectionIdSnapshotMutation } from '@/entities/report/reportBuilder';
import type {
  AssetsReportBuilderMediaWidgetDto,
  AssetsReportBuilderReportWidgetDto,
} from '@/entities/report/reportBuilder/api/coreAssetsReportBuilderReportsGeneratedApi';
import { BaseWidgetConfigCard } from '@/entities/report/reportBuilder/ui/BaseWidgetConfigCard';
import { currentUserAllowedTo } from '@/lib/permissions';
import { cn } from '@/shared/lib/css/cn';
import useBoolean from '@/shared/lib/hooks/useBoolean';
import { DialogProps, useModal } from '@/shared/lib/hooks/useModal';
import { GrowDiv } from '@/shared/ui/GrowDiv';
import { PdfUI } from '@/shared/ui/PdfUI';
import { Button } from '@/stories/Button/Button';
import { Icon } from '@/stories/Icon/Icon';
import { IconButton } from '@/stories/IconButton/IconButton';
import { ImageCard } from '@/stories/ImageCard/ImageCard';
import { Modal } from '@/stories/Modals/Modal/Modal';
import { ModalHeaderWithSubtitle } from '@/stories/Modals/ModalHeader/ModalHeaderWithSubtitle/ModalHeaderWithSubtitle';
import { ThinTabGroup } from '@/stories/Tabs/ThinTabGroup/ThinTabGroup';
import { Tumbler } from '@/stories/Tumbler/Tumbler';
import SkeletonBlock from '@/stories/ProjectCard/SkeletonBlock';
import {
  TabsContextProvider,
  useTabs,
  useTabsContext,
} from '@/stories/Tabs/useTabs';
import { IAsset } from '@/types/Asset';
import { ISharedFolder } from '@/types/SharedFolder';
import {
  DragDropContext,
  Draggable,
  Droppable,
  type DraggableProvided,
  type DropResult,
} from '@hello-pangea/dnd';
import { arrayMoveImmutable } from 'array-move';
import pluralize from 'pluralize';
import { useMemo, useState } from 'react';

const TABS = [
  {
    id: 'select-media',
    label: 'Select Media',
  },
  {
    id: 'media-settings',
    label: 'Media Settings',
  },
] as const;

const MediaFinder = ({
  assetId,
  isItemSelected,
  onItemSelect,
}: {
  assetId: IAsset['id'];
  isItemSelected: (id: string) => boolean;
  onItemSelect: (media: SharedFilesResponse['sharedFiles'][number]) => void;
}) => {
  const { thinTabGroupProps, tab } = useTabsContext();
  const { confirm } = useModal();
  const [uploadModalOpened, setUploadModalOpened] = useState(false);
  const [albumModalIsOpen, setAlbumModalIsOpen] = useState(false);
  const [createBulkSharedFiles] = useCreateBulkSharedFileMutation();
  const [deleteBulkSharedFiles] = useDeleteBulkSharedFileMutation();
  const [deleteBulkFoldersFiles] = useDeleteBulkFoldersMutation();

  const [currentFolder, setCurrentFolder] = useState<ISharedFolder | null>(
    null,
  );
  const {
    data,
    isFetching: isFoldersDataLoading,
    refetch,
  } = useGetObjectFoldersQuery({
    entityId: assetId,
    entityType: 'Asset',
    folderType: 'media',
    parentId: currentFolder?.id,
  });

  const entityContextValue = useMemo<EntityContextType>(() => {
    return { entity: { id: assetId }, type: 'Asset' };
  }, [assetId]);

  const handleRemoveFile = async (
    media: SharedFilesResponse['sharedFiles'][number],
  ) => {
    const result = await confirm({
      title: 'Remove file',
      subtitle: <p>Are you sure you want to remove file?</p>,
    });

    if (result) {
      await deleteBulkSharedFiles({ ids: [media.id] });

      if (isItemSelected(media.id)) {
        onItemSelect(media);
      }
    }
  };

  const handleFolderDelete = async (item: IFolder) => {
    const { childrenCount } = item;
    const hasChildren = childrenCount > 0;

    const result = await confirm({
      title: `Remove album`,
      subtitle: hasChildren ? (
        <div className="text-left">
          <p>
            {`The following ${pluralize('album', childrenCount)} ${pluralize(
              'has',
              childrenCount,
            )} items`}
          </p>
        </div>
      ) : (
        <p>Are you sure?</p>
      ),
    });

    if (!result) return;

    await deleteBulkFoldersFiles({ ids: [item.id] });
  };

  const { data: sharedFilesData, isFetching: isSharedFilesLoading } =
    useGetSharedFilesQuery(
      {
        folder_id: currentFolder?.id,
        entity_id: assetId,
        entity_type: 'Asset',
        document_types: 'media',
      },
      {
        skip: currentFolder == null,
        refetchOnMountOrArgChange: true,
      },
    );

  const folders = data ?? [];
  const sharedFiles = sharedFilesData?.sharedFiles ?? [];

  const handleCreate = async (params) => {
    await createBulkSharedFiles(params);
    setUploadModalOpened(false);
  };

  const hasFolders = folders.length > 0;
  const hasSharedFiles = sharedFiles.length > 0;
  const noFolders =
    !hasFolders &&
    !hasSharedFiles &&
    !isFoldersDataLoading &&
    !isSharedFilesLoading;

  const noSharedFilesInThisFolder = !hasSharedFiles && !isSharedFilesLoading;

  const currentUserCanManage = currentUserAllowedTo(
    'manage',
    'asset_portal',
    'Asset',
    assetId,
  );

  return (
    <EntityContext.Provider value={entityContextValue}>
      <div className="flex flex-col gap-4">
        <div className="flex">
          <ThinTabGroup fullWidth {...thinTabGroupProps} />
        </div>

        {tab?.id === TABS[0].id && (
          <>
            <div className="flex items-center gap-4">
              <Button
                size="s"
                iconName="arrowLeft"
                disabled={currentFolder == null}
                className="max-w-max"
                onClick={() => {
                  setCurrentFolder(null);
                }}
              >
                All Albums
              </Button>
              <GrowDiv />
              {currentUserCanManage && (
                <>
                  <Button
                    size="s"
                    variant="secondary"
                    onClick={() => setAlbumModalIsOpen(true)}
                  >
                    {currentFolder?.parentId
                      ? 'Create Sub Album'
                      : 'Create Album'}
                  </Button>
                  <Button
                    size="s"
                    variant="primary"
                    onClick={() => setUploadModalOpened(true)}
                  >
                    Upload Media
                  </Button>
                </>
              )}
            </div>
            {albumModalIsOpen && (
              <NewAlbumModal
                setOpened={setAlbumModalIsOpen}
                fetchAlbums={refetch}
                parentId={currentFolder?.id}
              />
            )}
            {uploadModalOpened && (
              <BulkUploaderModal
                investmentObject={{
                  entity: {
                    id: assetId,
                  },
                  type: 'Asset',
                }}
                defaultFolderId={currentFolder?.id}
                folderType="media"
                setOpened={setUploadModalOpened}
                handleCreate={handleCreate}
              />
            )}
            {!isFoldersDataLoading && hasFolders && (
              <div
                className={cn('flex flex-col gap-2 pb-4', {
                  'border-b border-neutral-150 ': noFolders,
                })}
              >
                <p className="inline-semibold text-neutral-550">
                  {folders.length} {pluralize('Album', folders.length)}
                </p>

                <AlbumsList
                  folders={folders as unknown as IFolder[]}
                  canManage={currentUserCanManage}
                  onClick={(id) => {
                    setCurrentFolder(folders.find((f) => f.id === id)!);
                  }}
                  onDelete={(folder) => {
                    handleFolderDelete(folder);
                  }}
                />
              </div>
            )}
            {noFolders && (
              <NoDataOverlay title="There is no media and albums available, you can upload to the gallery" />
            )}
            {noSharedFilesInThisFolder &&
              !noFolders &&
              currentFolder != null && (
                <NoDataOverlay
                  title="There is no media available in this album, you can upload to the
          gallery"
                />
              )}
            {isFoldersDataLoading && (
              <div className="flex flex-col gap-2 border-b border-neutral-150 pb-4">
                <p className="inline-semibold text-neutral-550">Albums</p>

                <div className="flex gap-6">
                  {Array.from({ length: 4 }, (_, i) => (
                    <SkeletonBlock
                      key={i}
                      className="h-[224px] w-[320px] rounded-[16px]"
                    />
                  ))}
                </div>
              </div>
            )}

            {!isSharedFilesLoading &&
              currentFolder != null &&
              hasSharedFiles && (
                <>
                  <p className="inline-semibold text-neutral-550">
                    {sharedFiles.length} Media{' '}
                    {pluralize('file', sharedFiles.length)}
                  </p>

                  <div className="grid gap-4 border-b border-neutral-150 pb-4 md:grid-cols-4 3xl:grid-cols-5 4xl:grid-cols-6">
                    {sharedFiles.map((m) => (
                      <div key={m.id}>
                        <ImageCard
                          selected={isItemSelected(m.id)}
                          image={m.smallImageUrl ?? m.url}
                          subtitle={m.description ?? ''}
                          onSelect={() => {
                            onItemSelect(m);
                          }}
                          actions={
                            currentUserCanManage
                              ? [
                                  {
                                    icon: 'trash',
                                    onClick: () => handleRemoveFile(m),
                                  },
                                ]
                              : []
                          }
                        />
                      </div>
                    ))}
                  </div>
                </>
              )}
            {isSharedFilesLoading && currentFolder != null && (
              <>
                <p className="inline-semibold text-neutral-550">Media files</p>

                <div className="flex gap-4">
                  {Array.from({ length: currentFolder.childrenCount }).map(
                    (_, i) => (
                      <SkeletonBlock
                        key={i}
                        className="h-[160px] w-[240px] rounded-[16px]"
                      />
                    ),
                  )}
                </div>
              </>
            )}
          </>
        )}
      </div>
    </EntityContext.Provider>
  );
};

type SelectedMedia = Pick<
  AssetsReportBuilderMediaWidgetDto['items'][number],
  'id' | 'caption' | 'dataUri'
>;

const MediaModal = (
  props: DialogProps<{
    items: SelectedMedia[];
    displayOptions: MediaWidgetDisplayOptions;
    initialSelectedFiles?: SelectedMedia[];
  }> & {
    assetId: IAsset['id'];
    widgetSection: AssetsReportBuilderReportWidgetDto;
    initialSelectedFiles?: SelectedMedia[];
    initialDisplayOptions?: MediaWidgetDisplayOptions;
  },
) => {
  const [displayOptions, setDisplayOptions] =
    useState<MediaWidgetDisplayOptions>(
      getMediaWidgetDisplayOptions(props.initialDisplayOptions),
    );
  const { value: showContainerBorder, toggle } = useBoolean(true);
  const { tab, thinTabGroupProps, setTab } = useTabs(TABS, 0);
  const { openModal } = useModal();
  const [selectedFiles, setSelectedFiles] = useState<SelectedMedia[]>(
    props.initialSelectedFiles ?? [],
  );

  useUpdateMediaWidthBySelectedFilesLenEffect(
    selectedFiles.length,
    (newValue) => {
      setDisplayOptions((prev) => ({
        ...prev,
        widthPercentage: newValue,
      }));
    },
  );

  const handleItemSelect = (media: SelectedMedia) => {
    setSelectedFiles((prev) => {
      return prev.some((item) => item.id === media.id)
        ? prev.filter((item) => item.id !== media.id)
        : [...prev, media];
    });
  };

  const handleDragEnd = (result: DropResult) => {
    const oldIndex = result.source.index;

    if (result.destination == null) return;
    const newIndex = result.destination.index;
    const visibleSorted = arrayMoveImmutable(selectedFiles, oldIndex, newIndex);
    setSelectedFiles([...visibleSorted]);
  };

  const handleDeleteMedia = (media: SelectedMedia) => {
    setSelectedFiles((prev) => prev.filter((item) => item.id !== media.id));
  };

  const handleEditCaption = async (media: SelectedMedia) => {
    const res = await openModal(CaptionSettingsModal, {
      initialForm: {
        alignment: media.caption?.alignment ?? 'left',
        captionText: media.caption?.text ?? '',
      },
    });

    if (res == null) return;

    setSelectedFiles((prev) => {
      return prev.map((item) => {
        if (item.id === media.id) {
          return {
            ...item,
            caption: {
              text: res.captionText,
              alignment: res.alignment,
            },
          };
        }
        return item;
      });
    });
  };

  const renderActions = (
    providedDraggable: DraggableProvided,
    item: SelectedMedia,
  ) => (
    <div className="absolute bottom-4 left-4 flex gap-2">
      <div className="flex" {...providedDraggable.dragHandleProps}>
        <IconButton
          className="pointer-events-none"
          variant="secondary"
          iconName="move"
        />
      </div>
      <IconButton iconName="trash" onClick={() => handleDeleteMedia(item)} />
      <IconButton iconName="edit" onClick={() => handleEditCaption(item)} />
    </div>
  );

  const renderSelectedFile = (item: SelectedMedia, index: number) => (
    <Draggable key={item.id} draggableId={item.id} index={index}>
      {(providedDraggable) => (
        <div
          className="mx-2 w-min"
          ref={providedDraggable.innerRef}
          {...providedDraggable.draggableProps}
        >
          <MediaWidgetFigure
            className="relative max-w-[240px]"
            caption={item.caption}
          >
            <MediaItem
              media={{
                ...item,
                smallImageUrl: item.url ?? item.dataUri,
              }}
              actions={renderActions(providedDraggable, item)}
              hideActions
              selectedItems={[]}
              size="m"
            />
          </MediaWidgetFigure>
        </div>
      )}
    </Draggable>
  );

  return (
    <Modal
      header={<ModalHeaderWithSubtitle title="Add Media" />}
      size="huge"
      toggle={props.onClose}
      classes={{
        body: 'flex flex-col gap-4',
      }}
      actions={
        <div className="flex w-full justify-between">
          <Button variant="secondary" onClick={props.onClose}>
            Cancel
          </Button>
          <Button
            variant="success"
            onClick={() => {
              props.onSubmit?.({
                items: selectedFiles,
                displayOptions,
              });
            }}
          >
            Save
          </Button>
        </div>
      }
    >
      <TabsContextProvider
        value={{
          thinTabGroupProps,
          tab,
          setTab,
        }}
      >
        <MediaFinder
          assetId={props.assetId}
          isItemSelected={(id) =>
            Boolean(selectedFiles.find((i) => i.id === id))
          }
          onItemSelect={handleItemSelect}
        />
        {tab?.id === TABS[1].id && (
          <MediaWidgetPreviewUI>
            <MediaWidgetPreviewUI.Form>
              <MediaWidgetPreviewUI.FormField
                label="Media Container Border"
                slot={
                  <Icon
                    iconName="info"
                    tooltipProps={{
                      mainText: (
                        <div className="flex flex-col gap-2">
                          <p>Applies only to the Media Widget Preview.</p>
                        </div>
                      ),
                    }}
                  />
                }
              >
                <Tumbler
                  checked={showContainerBorder}
                  onChange={() => {
                    toggle();
                  }}
                />
              </MediaWidgetPreviewUI.FormField>

              <VerticalSeparator />

              <MediaWidgetPreviewUI.FormField label="Scaling Behaviour">
                <SharedSelect
                  className="w-[260px]"
                  isMulti={false}
                  menuPortalTarget={document.body}
                  value={MEDIA_WIDGET_OBJECT_FIT_OPTIONS.find(
                    (option) => option.value === displayOptions.objectFit,
                  )}
                  options={MEDIA_WIDGET_OBJECT_FIT_OPTIONS}
                  onChange={(o) => {
                    setDisplayOptions((prev) => ({
                      ...prev,
                      objectFit: o?.value ?? 'cover',
                    }));
                  }}
                />
              </MediaWidgetPreviewUI.FormField>
              <VerticalSeparator />

              <MediaWidgetPreviewUI.FormField label="Media Position">
                <SharedSelect
                  className="w-[160px]"
                  isMulti={false}
                  menuPortalTarget={document.body}
                  value={MEDIA_WIDGET_OBJECT_POSITION_OPTIONS.find(
                    (option) => option.value === displayOptions.objectPosition,
                  )}
                  options={MEDIA_WIDGET_OBJECT_POSITION_OPTIONS}
                  onChange={(o) => {
                    setDisplayOptions((prev) => ({
                      ...prev,
                      objectPosition: o?.value ?? 'center',
                    }));
                  }}
                />
              </MediaWidgetPreviewUI.FormField>

              <VerticalSeparator />

              <MediaWidgetPreviewUI.FormField
                label="Media Height (px)"
                slot={
                  <Icon
                    iconName="info"
                    tooltipProps={{
                      mainText: (
                        <div className="flex flex-col gap-2">
                          <p>Minimum height is 200px</p>
                          <p>Maximum height is 1000px</p>
                        </div>
                      ),
                    }}
                  />
                }
              >
                <InputNumber
                  size="s"
                  className="w-[64px]"
                  allowNegative={false}
                  max={1000}
                  disabled={displayOptions.isFullHeight}
                  min={200}
                  value={displayOptions.height}
                  onValueChange={(e) => {
                    setDisplayOptions((prev) => ({
                      ...prev,
                      height: Math.round(e.floatValue ?? 0),
                    }));
                  }}
                />
              </MediaWidgetPreviewUI.FormField>
              <VerticalSeparator />

              <MediaWidgetPreviewUI.FormField
                label="Responsive height"
                slot={
                  <Icon
                    iconName="info"
                    tooltipProps={{
                      mainText: (
                        <div className="flex flex-col gap-2">
                          <p>
                            Media height will adjust to match the height of
                            adjacent elements.
                          </p>
                          <p>
                            It’s best to use Responsive Height with a single
                            image.
                          </p>
                        </div>
                      ),
                    }}
                  />
                }
              >
                <Tumbler
                  checked={displayOptions.isFullHeight}
                  onChange={() => {
                    setDisplayOptions((prev) => ({
                      ...prev,
                      isFullHeight: !prev.isFullHeight,
                    }));
                  }}
                />
              </MediaWidgetPreviewUI.FormField>
              <VerticalSeparator />

              <MediaWidgetPreviewUI.FormField
                label="Media Width (%)"
                slot={
                  <Icon
                    iconName="info"
                    tooltipProps={{
                      mainText: (
                        <div className="flex flex-col gap-2">
                          <p>Minimum width is 20%</p>
                          <p>Maximum width is 100%</p>
                        </div>
                      ),
                    }}
                  />
                }
              />
              <InputNumber
                size="s"
                className="w-[64px]"
                allowNegative={false}
                max={100}
                min={0}
                value={displayOptions.widthPercentage}
                onValueChange={(e) => {
                  setDisplayOptions((prev) => ({
                    ...prev,
                    widthPercentage: Math.round(e.floatValue ?? 0),
                  }));
                }}
              />
            </MediaWidgetPreviewUI.Form>

            <PdfUI.WidgetGroup
              id="1"
              hidePlaceholders={false}
              pageOrientation={'landscape'}
              className="!w-full max-w-[initial] rounded-[8px]"
            >
              <MediaWidget
                showContainerBorder={showContainerBorder}
                isLoading={false}
                data={{
                  items: selectedFiles,
                  widgetType: 'media',
                  displayOptions,
                }}
                className={cn(
                  resolveWidgetSectionPositionClassName(
                    props.widgetSection.position,
                  ),
                  'overflow-auto',
                )}
                pageOrientation="landscape"
                widgetSection={{
                  id: '1',
                  position: props.widgetSection.position,
                  widgetType: 'media',
                  title: 'Media',
                }}
              />
            </PdfUI.WidgetGroup>
          </MediaWidgetPreviewUI>
        )}

        {selectedFiles.length > 0 && (
          <>
            <p className="inline-semibold z-[1051] text-neutral-550">
              {selectedFiles.length} Selected Media{' '}
              {pluralize('file', selectedFiles.length)}
            </p>

            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId="droppableId" direction="horizontal">
                {(rootProvided) => (
                  <div
                    className="-mx-2 flex min-h-[184px] overflow-x-auto"
                    ref={rootProvided.innerRef}
                    {...rootProvided.droppableProps}
                  >
                    {selectedFiles.map(renderSelectedFile)}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </>
        )}
      </TabsContextProvider>
    </Modal>
  );
};

export const MediaWidgetConfigCard = (props: WidgetConfigCardProps) => {
  const { openModal } = useModal();
  const [updateWidget] =
    usePutApiCoreAssetsByAssetIdReportBuilderReportsAndReportIdWidgetSectionsWidgetSectionIdSnapshotMutation();

  const items =
    props.data?.widget != null && 'items' in props.data.widget
      ? (props.data?.widget?.items ?? [])
      : [];

  return (
    <BaseWidgetConfigCard
      {...props}
      title={
        <div className="flex items-center gap-2">
          <p>{props.title}</p>
          {(items.length ?? 0) > 0 && (
            <div className="secondary-semibold flex h-[24px] w-[24px] items-center justify-center rounded-[8px] bg-neutral-100 text-neutral-900">
              {items.length}
            </div>
          )}
        </div>
      }
      hasData={widgetHasData(props.data)}
      classes={{
        label: '',
      }}
      labelSlot={
        <BaseWidgetConfigCard.Actions>
          <IconButton
            disabled={props.disabled}
            onClick={async () => {
              const res = await openModal(MediaModal, {
                assetId: props.assetId,
                initialSelectedFiles: items,
                widgetSection: props.widgetSection,
                initialDisplayOptions: props.data?.widget?.displayOptions,
              });

              if (res == null) return;

              updateWidget({
                assetId: String(props.assetId),
                widgetSectionId: props.widgetSection.id,
                body: {
                  snapshot_data: {
                    items: res.items.map((i) => ({
                      id: i.id,
                      type: 'shared_file',
                      caption: i.caption,
                    })),
                    display_options: snakeCaseKeys(res.displayOptions),
                  },
                },
                reportId: props.reportId,
              });
            }}
            iconName="edit"
          />
        </BaseWidgetConfigCard.Actions>
      }
    />
  );
};

import { cn } from '@/shared/lib/css/cn';
import { AllWidgetTypes } from 'bundles/Shared/entities/dashboard/model';
import { WidgetPreview } from 'bundles/Shared/widgets/dashboard/widgets/common';
import pluralize from 'pluralize';
import React, { useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { Checkbox } from 'stories/Checkbox/Checkbox';
import { IconButton } from 'stories/IconButton/IconButton';

interface Props {
  widgetType: AllWidgetTypes;
  onClick?: JSX.IntrinsicElements['div']['onClick'];
  withCheckbox?: boolean;
  checked?: boolean;
  title?: string;
  onChange?: (checked: boolean) => void;
  hidePreview?: boolean;
}

export const BaseWidgetCard = ({
  children,
  onClick,
  className,
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    onClick={onClick}
    className={cn(
      'group/item flex cursor-pointer items-center gap-4 bg-neutral-000 p-4',
      className,
    )}
  >
    {children}
  </div>
);

export function WidgetCard({
  widgetType,
  children,
  onClick,
  withCheckbox,
  checked,
  hidePreview,
  onChange,
  title: widgetTitle,
}: React.PropsWithChildren<Props>) {
  return (
    <BaseWidgetCard
      className="first:rounded-t-xl last:rounded-b-xl"
      onClick={onClick}
    >
      {withCheckbox && (
        <Checkbox
          onClick={(e) => e.stopPropagation()}
          onChange={(e) => {
            onChange?.(e.target.checked);
          }}
          checked={checked}
        />
      )}
      {!hidePreview && (
        <WidgetPreview
          classes={{
            default: 'group-hover/item:opacity-0',
            hovered: 'group-hover/item:opacity-100',
          }}
          type={widgetType}
        />
      )}
      <span className="body-semibold text-neutral-800">{widgetTitle}</span>
      {children}
    </BaseWidgetCard>
  );
}

const List = ({ children }: React.PropsWithChildren) => (
  <div className="flex flex-col gap ">{children}</div>
);

const ListHeader = ({
  children,
  withCheckbox,
  widgetCount,
  checked,
  onChange,
}: React.PropsWithChildren<{
  widgetCount: number;
  withCheckbox?: boolean;
  checked?: boolean;
  onChange?: (checked: boolean) => void;
}>) => (
  <div className="flex items-center px-4">
    <label className="inline-semibold flex items-center gap-2 text-neutral-550">
      {withCheckbox && (
        <Checkbox
          id="checkbox"
          onChange={(e) => onChange?.(e.target.checked)}
          checked={checked}
        />
      )}
      {widgetCount} {pluralize('Widget', widgetCount)}
    </label>
    {children}
  </div>
);

const Group = ({
  children,
  title,
  count,
}: React.PropsWithChildren<{
  title: string;
  count?: number;
}>) => {
  const [opened, setOpened] = useState(true);
  return (
    <div className="flex flex-col rounded-xl bg-neutral-000">
      <div className="flex items-center gap-2 border-b border-b-neutral-100 p-4">
        <span className="body-semibold text-neutral-800">{title}</span>
        {count && <div className="rounded bg-neutral-150 px-1">{count}</div>}
        <div className="grow" />
        <IconButton
          onClick={() => setOpened(!opened)}
          iconName={opened ? 'top' : 'bottom'}
        />
      </div>
      <AnimateHeight
        contentClassName="flex flex-col gap-2 mt-4"
        delay={100}
        duration={300}
        height={opened ? 'auto' : 0}
      >
        {children}
      </AnimateHeight>
    </div>
  );
};

List.Header = ListHeader;
WidgetCard.List = List;
WidgetCard.Group = Group;

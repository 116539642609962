import { useReportEagleEyeDashboardAssetsQuery } from '@/bundles/Shared/entities/dashboard';
import {
  useIncludedLegalEntities,
  useSelectedAssets,
} from '@/bundles/Shared/features/dashboard/create/lib';
import { LegalEntitiesSelectionModal } from '@/bundles/Shared/features/dashboard/create/ui/LegalEntitiesSelectionModal';
import type { EagleEyeObjectSelectionForm } from '@/bundles/Shared/features/dashboard/update/eagleEye';
import type { LegalEntity } from '@/entities/core/legalEntity';
import { useReportBuilderEagleEyeTemplateMeta } from '@/entities/report/reportBuilder/eagleEye/lib';
import type { ReportBuilderWidgetEagleEyeObjectSettingsSchema } from '@/entities/report/reportBuilder/lib/reportBuilderTemplateEagleEyeObjectSettingsConfig';
import useBoolean from '@/shared/lib/hooks/useBoolean';
import { excludeLegalEntityHandlers } from '@/shared/lib/legalEntitiy/lib';
import { mapListToIds } from '@/shared/lib/listHelpers';
import type { IAsset } from '@/types/Asset';
import type { ComponentProps } from 'react';
import {
  Controller,
  useController,
  useFormContext,
  type UseFormReturn,
} from 'react-hook-form';

export const useExcludeLegalEntityHandlers = ({
  methods,
  allAssets,
  allLegalEntities,
}: {
  allAssets: Pick<IAsset, 'id' | 'name' | 'legalEntities'>[];
  allLegalEntities: Pick<
    LegalEntity,
    'id' | 'name' | 'classification' | 'code'
  >[];
  methods: UseFormReturn<ReportBuilderWidgetEagleEyeObjectSettingsSchema>;
}) => {
  const { field: assetIdsField } = useController({
    control: methods.control,
    name: 'asset_ids',
  });
  const { field } = useController({
    control: methods.control,
    name: 'excluded_legal_entity_ids',
  });

  const selectedAssetIds = methods.watch('asset_ids');

  const excludedLegalEntityIds = methods.watch('excluded_legal_entity_ids');

  const selectedAssets = useSelectedAssets(allAssets, selectedAssetIds);

  const includedLegalEntities = useIncludedLegalEntities(
    selectedAssets,
    excludedLegalEntityIds,
  );

  const {
    changeAll,
    excludeAsset,
    excludeLegalEntity,
    includeAsset,
    includeLegalEntity,
    excludeAssetAndFilterOutExcludedLegalEntities,
  } = excludeLegalEntityHandlers({
    allLegalEntityIds: mapListToIds(allLegalEntities),
    excludedLegalEntityIds,
    selectedAssetIds,
  });

  const onChangeAll = (type: 'excluded' | 'included') => {
    field.onChange(changeAll(type));
  };

  const onIncludeAsset = (
    assetId: IAsset['id'],
    assetExcludedLegalEntityIds: LegalEntity['id'][],
  ) => {
    const res = includeAsset(assetId, assetExcludedLegalEntityIds);
    if (res.type === 'excludedLegalEntityChange') {
      return field.onChange(res.result);
    }
    assetIdsField.onChange(res.result);
  };

  const onIncludeLegalEntity = (id: LegalEntity['id']) => {
    field.onChange(includeLegalEntity(id));
  };

  const onExcludeAsset = (id: IAsset['id']) => {
    assetIdsField.onChange(excludeAsset(id));
  };

  const onExcludeLegalEntity = (id: LegalEntity['id']) => {
    field.onChange(excludeLegalEntity(id));
  };

  const onExcludeAssetAndFilterOutExcludedLegalEntities = (
    assetId: IAsset['id'],
    assetLegalEntityIds: LegalEntity['id'][],
  ) => {
    const res = excludeAssetAndFilterOutExcludedLegalEntities(
      assetId,
      assetLegalEntityIds,
    );

    assetIdsField.onChange(res.assetIds);
    field.onChange(res.excludedLegalEntityIds);
  };

  return {
    excludedLegalEntityIds,
    includedLegalEntities,
    selectedAssets,
    onChangeAll,
    onIncludeAsset,
    onIncludeLegalEntity,
    onExcludeAsset,
    onExcludeLegalEntity,
    onExcludeAssetAndFilterOutExcludedLegalEntities,
  };
};

const LegalEntitiesSelectionController = ({
  allAssets,
  allLegalEntities,
  sidePanelProps,
}: {
  allAssets: Pick<IAsset, 'id' | 'name' | 'legalEntities'>[];
  allLegalEntities: Pick<
    LegalEntity,
    'id' | 'name' | 'code' | 'classification'
  >[];
  sidePanelProps?: ComponentProps<
    typeof LegalEntitiesSelectionModal
  >['sidePanelProps'];
}) => {
  const methods = useFormContext<EagleEyeObjectSelectionForm>();

  const params = useExcludeLegalEntityHandlers({
    allLegalEntities,
    allAssets,
    methods,
  });

  const { value: modalOpened, toggle: toggleModal } = useBoolean();
  return (
    <Controller
      control={methods.control}
      name="excluded_legal_entity_ids"
      render={() => {
        return (
          <LegalEntitiesSelectionModal.Wrapper
            legalEntitiesCounter={params.includedLegalEntities.length}
            toggleLegalEntitiesModalOpened={toggleModal}
          >
            {modalOpened && (
              <LegalEntitiesSelectionModal
                sidePanelProps={sidePanelProps}
                onClose={toggleModal}
                {...params}
              />
            )}
          </LegalEntitiesSelectionModal.Wrapper>
        );
      }}
    />
  );
};

export function ReportBuilderEagleEyeLegalEntitiesField() {
  const { legalEntities, data } = useReportBuilderEagleEyeTemplateMeta();

  return (
    <LegalEntitiesSelectionController
      allAssets={data?.assets ?? []}
      sidePanelProps={{
        position: 'left',
        size: '500',
      }}
      allLegalEntities={legalEntities}
    />
  );
}

export function EagleEyeDashboardLegalEntitiesField() {
  const { assets, legalEntities } = useReportEagleEyeDashboardAssetsQuery();

  return (
    <LegalEntitiesSelectionController
      allAssets={assets}
      allLegalEntities={legalEntities}
    />
  );
}

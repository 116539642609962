import { FinancialTableSingleDateWidgetConfigDto } from '@/shared/api/dashboardsSettingsGeneratedApi';
import {
  maxIdGenerator,
  transformPeriodShiftFormToDto,
  transformPeriodTypeFormToDto,
  upsertColumnSettings,
} from '@/bundles/Shared/widgets/dashboard/widgets/common';
import { financialTableUpdaters } from '@/bundles/Shared/widgets/dashboard/widgets/common/financialTable/config/updaters';
import { DEFAULT_WIDGET_DRILLDOWN } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/fields/DrilldownField';
import { FinansialTableSingleDateBasicTabForm } from '@/bundles/Shared/widgets/dashboard/widgets/financialTableSingeDate/config/component.form';
import { FinancialTableSingleDateWidgetSection } from '@/bundles/Shared/widgets/dashboard/widgets/financialTableSingeDate/model';
import { FinancialTableSinglePeriodWidgetConfigColumnForm } from '@/bundles/Shared/widgets/dashboard/widgets/financialTableSingePeriod/config/column.form';
import { produce } from 'immer';
import { transformFinancialTableWidgetAdjustmentFormToConfig } from '@/bundles/Shared/widgets/dashboard/widgets/common/features/adjustments/lib';

const upsertColumn = (
  {
    columnId,
    columnForm: column,
    groupId,
  }: {
    groupId: string | null;
    columnForm: FinancialTableSinglePeriodWidgetConfigColumnForm;
    columnId?: string;
  },
  config: FinancialTableSingleDateWidgetSection['widgetConfig'],
): FinancialTableSingleDateWidgetSection['widgetConfig'] => {
  return produce(config, (draft) => {
    let columnIndex = draft.columns.findIndex(
      (c) => c.key.toString() === String(columnId),
    );
    if (columnIndex === -1) {
      columnIndex = draft.columns.length;
    }
    const columnKey = String(
      column.key ?? maxIdGenerator(draft.columns, 'key'),
    );

    draft.columns[columnIndex] = {
      key: Number(columnKey),
      label: column.label,
      period_shift: transformPeriodShiftFormToDto(
        column.period_shift ?? undefined,
      ),
      period_type: transformPeriodTypeFormToDto(column.period_type),
      ...transformFinancialTableWidgetAdjustmentFormToConfig(column),
    };
    upsertColumnSettings(column, draft, {
      key: columnKey,
      groupId,
    });
  });
};

const updateDefaultOptions = (
  values: FinansialTableSingleDateBasicTabForm,
  widgetConfig: FinancialTableSingleDateWidgetConfigDto,
): FinancialTableSingleDateWidgetConfigDto => {
  return produce(widgetConfig, (draft) => {
    draft.default_options.date.period_shift = transformPeriodShiftFormToDto(
      values.period_shift,
    );
    draft.viz_config!.header_background = values.header_background ?? undefined;
    draft.enable_drill_down =
      values.enable_drill_down ?? DEFAULT_WIDGET_DRILLDOWN;
  });
};

export const financialTableSingleDateUpdaters = {
  ...financialTableUpdaters,
  upsertColumn,
  updateDefaultOptions,
};

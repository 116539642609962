import { IInvestmentEntity } from '@/types/IInvestmentEntity';
import { IUserTag } from '@/types/IUserTag';
import { GetArrayElementType } from '@/types/utils';

export enum TUserInviteStatus {
  INVITED_LONG_TIME_AGO = 'invited_long_time_ago',
  INVITED = 'invited',
  CREATED = 'created',
  DEACTIVATED = 'deactivated',
  ACTIVE = 'active',
}

export interface IUser {
  id: string;
  fullName: string;
  initials: string;
  email: string;
  phone: string;
  tags: IUserTag[];
  /**
   * @deprecated use `tags` instead
   */
  userTags: IUserTag[];
  emailMessagesCount: number;
  lastInvitationFailed: boolean;
  createdAt: number;
  external: boolean;
  impersonated: boolean;
  investmentEntities?: IInvestmentEntity[];
  inviteStatus?: FlattenEnum<TUserInviteStatus>;
  lastSignInAt?: number;
  invitationSentAt?: number;
  deactivatedAt?: number;
  lastSeenAt?: number;
  undeselectable?: boolean; // Currently it's only "Super Admin" users
  role?: IUserRole;
  userRoleId?: IUserRole['id'];
  info?: string | null;
}

export enum TUserRoleCategory {
  INTERNAL = 'internal',
  EXTERNAL = 'external',
  SYMMETRE = 'symmetre',
  SRE_ADMIN = 'sre_admin', // probably outdated
}

export interface IProduct {
  id: string;
  name: string;
  canView: boolean;
  canManage: boolean;
  canConfigure: boolean;
}

export type IProductPermission = Omit<IProduct, 'id' | 'name'>;
export type IProductPermissionKeys = keyof IProductPermission;

export interface IUserRole {
  id: string;
  name: string;
  group: string | null;
  category: FlattenEnum<TUserRoleCategory>;
  users: Pick<IUser, 'id' | 'fullName'>[];
  products: IProduct[];
  readonly: boolean;
  undeselectable?: boolean; // Currently it's only "Super Admin" users
}

export const USER_FIRE_STATUSES = [
  TUserInviteStatus.INVITED_LONG_TIME_AGO,
  TUserInviteStatus.CREATED,
];
export type TUserFireStatuses = GetArrayElementType<typeof USER_FIRE_STATUSES>;

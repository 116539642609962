import EmailTemplateFormInputGroup from '@/bundles/EmailBuilder/components/EmailTemplateFormInputGroup';
import NoDataOverlay from '@/bundles/Shared/components/NoDataOverlay';
import { PermissionListLine } from '@/bundles/Shared/components/Permissions/PermissionListLine';
import { DashboardIcon } from '@/bundles/Shared/entities/dashboard';
import { settingsEmailsCustomTemplateEnhancedApi } from '@/entities/emails/customTemplates/api/settingsEmailsCustomTemplateEnhancedApi';
import {
  INITIAL_RRULE,
  RRULE_DROPDOWN_ITEMS,
} from '@/entities/emails/customTemplates/config';
import { rruleToText } from '@/entities/emails/customTemplates/lib/rruleToText';
import type { EmailCustomTemplate } from '@/entities/emails/customTemplates/model';
import type { ReportBuilderTemplate } from '@/entities/report/reportBuilder';
import {
  isFormEmailTemplateReportUniqueId,
  useEmailsCustomTemplateAddToFormAttachments,
} from '@/features/emails/custom/addAttachments/lib';
import { useEditTemplatePermitted } from '@/features/emails/shared/editTemplatePermitted/lib';
import { useViewTemplatePermittedUsers } from '@/features/emails/shared/viewTemplatePermittedUsers/lib';
import { useModal } from '@/shared/lib/hooks/useModal';
import { filterByDestroyFalse } from '@/shared/lib/listHelpers/filter';
import { sortByPosition } from '@/shared/lib/listHelpers/toSorted';
import { joinWithDash } from '@/shared/lib/string';
import { BuilderUI } from '@/shared/ui/BuilderUI';
import { GrowDiv } from '@/shared/ui/GrowDiv';
import { useTabsContext } from '@/stories/Tabs/useTabs';
import type { IAsset } from '@/types/Asset';
import { useEmailCustomTemplateFormSchemaFormContext } from '@/widgets/email/custom/template/lib';
import { arrayMoveMutable } from 'array-move';
import { TextEditor } from '@symmetre-web/text-editor';
import { capitalize } from 'lodash-es';
import React, { useMemo } from 'react';
import { RRule } from 'rrule';
import { Button } from '@/stories/Button/Button';
import { Dropdown } from '@/stories/Dropdown/Dropdown';
import { DropdownItem } from '@/stories/Dropdown/DropdownItem/DropdownItem';
import { Icon } from '@/stories/Icon/Icon';
import { IconButton } from '@/stories/IconButton/IconButton';
import { Input } from '@/stories/FormControls/Inputs/Input/Input';
import { ThinTabGroup } from '@/stories/Tabs/ThinTabGroup/ThinTabGroup';
import TemplateEditor from '@/bundles/Settings/components/EmailSettings/editor/TemplateEditor';
import ReccurenceModal from '@/bundles/Settings/components/EmailSettings/ReccurenceModal';
import {
  TOOLBAR_GROUPS_ALIGNMENTS,
  TOOLBAR_GROUPS_VARIABLES_AND_ALIGNMENTS,
} from '@/bundles/Settings/components/EmailSettings/utils/consts';

import { generateUrl, ROUTES_ROOT } from '@/shared/lib/hooks/useNavigation';
interface Props {
  isLoading?: boolean;
}

export const EmailCustomTemplateItems = ({ isLoading }: Props) => {
  const { data: variablesData } =
    settingsEmailsCustomTemplateEnhancedApi.useGetEmailCustomTemplateVariablesQuery();
  const variables = variablesData ?? [];
  const tabsContext = useTabsContext();

  const methods = useEmailCustomTemplateFormSchemaFormContext();
  const formWatch = methods.watch();
  const sections = formWatch.sections ?? [];
  const attachments = formWatch.reports ?? [];
  const activeAttachments = attachments.filter(filterByDestroyFalse);

  const attachmentsEntriesByReportBuilderTemplateId = useMemo(() => {
    // move to utils. Maybe use pipe syntax
    return Object.entries(
      Object.groupBy(activeAttachments, (a) => a.reportBuilderTemplate.id),
    )
      .map(([reportBuilderTemplateId, reports]) =>
        reports
          ? ([
              reportBuilderTemplateId,
              {
                assets: reports.map((r) => r.asset),
                reportBuilderTemplate: reports[0].reportBuilderTemplate,
              },
            ] as const)
          : null,
      )
      .filter(Boolean);
  }, [attachments]);

  const handleAddAttachment = useEmailsCustomTemplateAddToFormAttachments();

  const setSections = (newSections: EmailCustomTemplate['sections']) => {
    methods.setValue('sections', newSections, {
      shouldDirty: true,
      shouldTouch: true,
      shouldValidate: true,
    });
  };

  const handleAddSection = (
    section: EmailCustomTemplate['sections'][number],
  ) => {
    setSections([...sections, section]);
  };

  const handleAddSectionByType = (type: 'text' | 'header') => {
    const lastPosition = sections[sections.length - 1]?.position ?? 0;
    handleAddSection({
      position: lastPosition + 1,
      type,
    });
  };

  const handleRemoveSection = (
    section: EmailCustomTemplate['sections'][number],
  ) => {
    const newSections = [...sections];
    const index = newSections.findIndex((s) => s.position === section.position);
    newSections[index] = {
      ...newSections[index],
      _destroy: true,
    };

    setSections(newSections);
  };

  const activeSections = sections
    .filter(filterByDestroyFalse)
    .toSorted(sortByPosition);

  const handleMoveSection = (
    section: EmailCustomTemplate['sections'][number],
    direction: 'up' | 'down',
  ) => {
    let newSections = sections.map((o) => ({ ...o }));
    const index = sections.findIndex((s) => s.position === section.position);
    const indexShift = direction === 'up' ? -1 : 1;
    arrayMoveMutable(newSections, index, index + indexShift);
    newSections = newSections.map((s, i) => ({ ...s, position: i + 1 }));
    setSections(newSections);
  };

  const updateSection = (position: number, data) => {
    const newSections = [...sections];
    const sectionIndex = newSections.findIndex((s) => s.position === position);
    newSections[sectionIndex] = { ...newSections[sectionIndex], ...data };

    setSections(newSections);
  };

  const isSectionsTab =
    tabsContext.tab?.id === tabsContext.thinTabGroupProps.items[0].id;
  const isAttachmentsTab =
    tabsContext.tab?.id === tabsContext.thinTabGroupProps.items[1].id;

  const addAttachmentDropdown = (
    <Dropdown
      items={
        <>
          <DropdownItem
            onClick={() => {
              handleAddAttachment();
            }}
          >
            <div className="flex items-center gap-2">Object Level</div>
          </DropdownItem>
          <DropdownItem disabled>
            <div className="flex items-center gap-2">Eagle Eye</div>
          </DropdownItem>
        </>
      }
    >
      <Button size="xs" disabled={isLoading}>
        Add Attachment
      </Button>
    </Dropdown>
  );

  const addSectionDropdown = (
    <Dropdown
      items={
        <>
          <DropdownItem
            onClick={() => {
              handleAddSectionByType('text');
            }}
          >
            <div className="flex items-center gap-2">
              <Icon iconName="inspectFunctionVariable" />
              Custom Text
            </div>
          </DropdownItem>
          <DropdownItem
            onClick={() => {
              handleAddSectionByType('header');
            }}
          >
            <div className="flex items-center gap-2">
              <Icon iconName="textHeader" />
              Custom Header
            </div>
          </DropdownItem>
        </>
      }
    >
      <Button size="xs" disabled={isLoading}>
        Add Section
      </Button>
    </Dropdown>
  );

  const handleRemoveAttachment = ({
    assetId,
    reportBuilderTemplateId,
  }: {
    reportBuilderTemplateId: ReportBuilderTemplate['id'];
    assetId?: IAsset['id'];
  }) => {
    const newReports = attachments
      .map((attachment) => {
        if (
          (assetId != null ? attachment.asset.id === assetId : true) &&
          attachment.reportBuilderTemplate.id === reportBuilderTemplateId
        ) {
          if (isFormEmailTemplateReportUniqueId(attachment.id)) {
            return null;
          }

          return {
            ...attachment,
            _destroy: true,
          };
        }

        return attachment;
      })
      .filter(Boolean);
    methods.setValue('reports', newReports, {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    });
  };

  return (
    <>
      <BuilderUI.Settings.WidgetLayout.Header>
        <ThinTabGroup {...tabsContext.thinTabGroupProps} />
        {isSectionsTab && addSectionDropdown}
        {isAttachmentsTab && addAttachmentDropdown}
      </BuilderUI.Settings.WidgetLayout.Header>
      <BuilderUI.Settings.WidgetLayout>
        {isSectionsTab &&
          activeSections.length > 0 &&
          activeSections.map((section, i) => (
            <div
              key={joinWithDash([section.type, section.position.toString()])}
            >
              <EmailTemplateFormInputGroup
                title={
                  <div className="flex items-center gap-2">
                    <p className="inline-semibold text-neutral-850">
                      {capitalize(section.type ?? 'section')}
                    </p>
                  </div>
                }
                isLoading={isLoading}
                onClose={() => handleRemoveSection(section)}
                onMoveUp={
                  i !== 0 ? () => handleMoveSection(section, 'up') : undefined
                }
                onMoveDown={
                  activeSections.length !== i + 1
                    ? () => handleMoveSection(section, 'down')
                    : undefined
                }
              >
                {section.type === 'text' && (
                  <TextEditor
                    placeholder="Enter Text"
                    value={section.textSection?.text}
                    onChange={(text) =>
                      updateSection(section.position, { textSection: { text } })
                    }
                    toolbarGroups={TOOLBAR_GROUPS_ALIGNMENTS}
                  />
                )}
                {section.type === 'header' && (
                  <TemplateEditor
                    placeholder="Enter Header"
                    text={section.headerSection?.text}
                    setText={(text) =>
                      updateSection(section.position, {
                        headerSection: { text },
                      })
                    }
                    variables={variables.header}
                    toolbarGroups={TOOLBAR_GROUPS_VARIABLES_AND_ALIGNMENTS}
                    forceHeading
                  />
                )}
              </EmailTemplateFormInputGroup>
            </div>
          ))}

        {isAttachmentsTab &&
          attachmentsEntriesByReportBuilderTemplateId.length > 0 &&
          attachmentsEntriesByReportBuilderTemplateId.map(
            ([reportBuilderTemplateId, { assets, reportBuilderTemplate }]) => (
              <BuilderUI.Settings.WidgetLayout.WidgetGroup
                key={reportBuilderTemplateId}
              >
                <div className="flex items-center gap-2 p-4 pr-[26px] text-neutral-550">
                  <DashboardIcon
                    iconName="objectLevelDashboard"
                    className="h-6 w-6"
                  />
                  <BuilderUI.Settings.WidgetLayout.WidgetGroup.Title>
                    {reportBuilderTemplate.name}
                  </BuilderUI.Settings.WidgetLayout.WidgetGroup.Title>
                  <GrowDiv />
                  <IconButton
                    iconName="trash"
                    onClick={() => {
                      handleRemoveAttachment({
                        reportBuilderTemplateId,
                      });
                    }}
                  />
                </div>
                <BuilderUI.Settings.WidgetLayout.WidgetGroup.Children>
                  {assets.map((asset) => (
                    <BuilderUI.Settings.WidgetLayout.WidgetGroup.WidgetCard
                      key={asset.id}
                    >
                      <Icon iconName="asset" />
                      <BuilderUI.Settings.WidgetLayout.WidgetGroup.WidgetCard.Title>
                        {asset.name}
                      </BuilderUI.Settings.WidgetLayout.WidgetGroup.WidgetCard.Title>
                      <GrowDiv />
                      <a
                        target="_blank"
                        href={generateUrl(
                          ROUTES_ROOT.reportBuilderTemplates
                            .reportBuilderTemplate.fullPath,
                          {
                            pathParams: {
                              templateId: reportBuilderTemplate.id,
                            },
                            queryParams: {
                              assetId: asset.id,
                            },
                          },
                        )}
                      >
                        <Button variant="secondary" size="xs">
                          Preview
                        </Button>
                      </a>
                      <IconButton
                        iconName="trash"
                        onClick={() => {
                          handleRemoveAttachment({
                            assetId: asset.id,
                            reportBuilderTemplateId,
                          });
                        }}
                      />
                    </BuilderUI.Settings.WidgetLayout.WidgetGroup.WidgetCard>
                  ))}
                </BuilderUI.Settings.WidgetLayout.WidgetGroup.Children>
              </BuilderUI.Settings.WidgetLayout.WidgetGroup>
            ),
          )}
      </BuilderUI.Settings.WidgetLayout>
      {isSectionsTab && activeSections.length === 0 && (
        <NoDataOverlay title="No sections added yet" />
      )}
      {isAttachmentsTab && activeAttachments.length === 0 && (
        <NoDataOverlay title="No attachments added yet" />
      )}
    </>
  );
};

const CustomTemplateBaseForm = () => {
  const methods = useEmailCustomTemplateFormSchemaFormContext();
  const watchFields = methods.watch();
  const onScheduleChange = (newSchedule: (typeof watchFields)['schedule']) => {
    methods.setValue('schedule', newSchedule, {
      shouldDirty: true,
      shouldTouch: true,
      shouldValidate: true,
    });
  };
  const onPermittedChange = (permitted: (typeof watchFields)['permitted']) => {
    methods.setValue('permitted', permitted, {
      shouldDirty: true,
      shouldTouch: true,
      shouldValidate: true,
    });
  };
  const { openModal } = useModal();

  const currentRRule = watchFields.schedule
    ? RRule.fromString(watchFields.schedule)
    : INITIAL_RRULE;

  const handleOpenRRuleModal = async (ruleOptions?: RRule['options']) => {
    const res = await openModal(ReccurenceModal, {
      rrule: new RRule(ruleOptions ?? currentRRule.options),
    });

    if (res) {
      onScheduleChange(res.toString());
    }
  };

  const [handleViewPermissions] = useViewTemplatePermittedUsers({
    permitted: watchFields.permitted,
  });

  const editPermitted = useEditTemplatePermitted({
    permitted: watchFields.permitted,
  });

  const handleEditPermitted = async () => {
    const res = await editPermitted();

    if (res == null) return;

    onPermittedChange({
      directRoles: res.directRoles,
      directUsers: res.directUsers,
      directInvestmentEntities: res.directInvestmentEntities,
      directTags: res.directTags,
    });
  };

  return (
    <>
      <BuilderUI.Settings.Main.Basic.Field required label="Template Name">
        <Input placeholder="Template Name" {...methods.register('name')} />
      </BuilderUI.Settings.Main.Basic.Field>
      <BuilderUI.Settings.Main.Basic.Field required label="Email Subject">
        <Input placeholder="Enter Subject" {...methods.register('subject')} />
      </BuilderUI.Settings.Main.Basic.Field>

      <BuilderUI.Settings.Main.Basic.Field label="Recipients">
        <div className="inline-flex">
          <PermissionListLine
            permissions={{
              ...watchFields.permitted,
              isPublic: false,
            }}
            showEmptyFallback={false}
            onEdit={() => handleEditPermitted()}
            onClick={() => handleViewPermissions()}
          />
        </div>
      </BuilderUI.Settings.Main.Basic.Field>
      <BuilderUI.Settings.Main.Basic.Hr className="-mt-2" />

      <BuilderUI.Settings.Main.Basic.Field required label="Reccurence Rules">
        {watchFields.schedule && (
          <div className="flex items-center justify-between gap-2">
            <div className="font-weight-400 light-90 inline-regular">
              {rruleToText(currentRRule.options)}
            </div>
            <div className="flex gap-2">
              <IconButton
                iconName="edit"
                onClick={() => handleOpenRRuleModal()}
              />
              <IconButton
                variant="secondary"
                iconName="trash"
                onClick={() => {
                  onScheduleChange(null);
                }}
              />
            </div>
          </div>
        )}
        {!watchFields.schedule && (
          <div>
            <Dropdown
              placement="bottom-start"
              maxWidth="17.25rem"
              items={RRULE_DROPDOWN_ITEMS.map((item) => (
                <DropdownItem
                  key={item.label}
                  onClick={() => {
                    handleOpenRRuleModal(item.rule);
                  }}
                >
                  {item.label}
                </DropdownItem>
              ))}
            >
              <Button
                iconName="bottom"
                size="s"
                variant="secondary"
                iconPosition="right"
              >
                Add
              </Button>
            </Dropdown>
          </div>
        )}
      </BuilderUI.Settings.Main.Basic.Field>
    </>
  );
};

export default CustomTemplateBaseForm;

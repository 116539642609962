import React from 'react';
import { Dropdown } from '@/stories/Dropdown/Dropdown';
import { DropdownItem } from '@/stories/Dropdown/DropdownItem/DropdownItem';
import {
  BoardModal,
  useDeleteBoard,
} from 'bundles/Shared/features/dashboard/boardCRUD';
import { EagleEyeBoard } from 'bundles/Shared/entities/dashboard';
import { useModal } from '@/shared/lib/hooks/useModal';

interface Props extends Omit<React.ComponentProps<typeof Dropdown>, 'items'> {
  board: Pick<EagleEyeBoard, 'id' | 'name' | 'sections' | 'slug'> | null;
  onBoardDelete: () => void;
  onBoardUpdate: (board: EagleEyeBoard) => void;
}

export function DashboardBoardActions({
  children,
  board,
  onBoardDelete,
  onBoardUpdate,
  ...props
}: React.PropsWithChildren<Props>) {
  const { openModal, modalList } = useModal();
  const [deleteBoard] = useDeleteBoard();
  const handleEditBoard = async () => {
    if (!board) return;

    const res = await openModal(
      BoardModal,
      {
        board,
      },
      { topLevelRender: false },
    );
    if (res) {
      onBoardUpdate(res);
    }
  };

  const handleDeleteBoard = async () => {
    if (!board) return;

    await deleteBoard({
      boardId: board.id,
    });
    onBoardDelete();
  };

  return (
    <>
      <Dropdown
        className="sre-dropdown-v2"
        items={
          <>
            <DropdownItem iconName="edit" onClick={handleEditBoard}>
              Rename
            </DropdownItem>
            <DropdownItem iconName="trash" onClick={handleDeleteBoard}>
              Delete
            </DropdownItem>
          </>
        }
        {...props}
      />
      {modalList}
    </>
  );
}

import {
  TableVizConfigColumn,
  TableVizConfigColumnGroup,
} from 'bundles/Shared/widgets/dashboard/widgets/common/ui/table/model';
import React, { useState } from 'react';
import { WidgetSettingsListLayout } from 'bundles/Shared/components/layouts/dashboard/WidgetLayout';
import {
  DragDropContext,
  Draggable,
  Droppable,
  OnDragEndResponder,
} from '@hello-pangea/dnd';
import {
  buildDraggableId,
  GROUPS_DROPPABLE_ID,
  KpiTableWidgetColumnType,
  KpiTableWidgetConfigColumn,
  KpiTableWidgetConfigColumnGroup,
  KpiTableWidgetConfigColumnPanel,
} from 'bundles/Shared/widgets/dashboard/widgets/kpiTable';
import { Dropdown } from '@/stories/Dropdown/Dropdown';
import { DropdownItem } from '@/stories/Dropdown/DropdownItem/DropdownItem';
import { Icon } from '@/stories/Icon/Icon';
import { IconButton } from '@/stories/IconButton/IconButton';
import { IconsId } from 'types/sre-icons';
import {
  EAGLE_EYE_DASHBOARD_SECTION_TYPE,
  OBJECT_DASHBOARD_SECTION_TYPE,
  UNGROUPED_GROUP_KEY,
} from 'bundles/Shared/entities/dashboard';
import { DialogProps } from '@/shared/lib/hooks/useModal';
import { KpiTableSingleDateWidgetDataColumn } from 'bundles/Shared/widgets/dashboard/widgets/kpiTableSingleDate';
import { UnitTypeTableWidgetConfigColumn } from 'bundles/Shared/widgets/dashboard/widgets/unitTypeTable/model';
import { commonTableWidgetUpdaters } from 'bundles/Shared/widgets/dashboard/widgets/common/updaters';
import {
  DEFAULT_GROUP_TEXT_CLASS,
  useUpdateWidgetConfig,
  useWidgetConfig,
} from 'bundles/Shared/widgets/dashboard/widgets/common';
import { capitalize, orderBy } from 'lodash-es';
import { isFormulaByExpression } from 'bundles/Shared/entities/formula';
import { InspectFormulaButton } from 'bundles/Shared/features/formula/editFormula';
import pluralize from 'pluralize';
import Button from 'stories/Button/Button';
import { cn } from '@/shared/lib/css/cn';
import {
  resolveColumnSettingsType,
  WIDGET_TABLE_WIDGET_COLUMN_TYPES,
  WidgetTableColumnSettingsColumnType,
} from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/table/form';

export const ColumnGroupTitle = ({
  children,
  color,
}: React.PropsWithChildren<{
  color?: string;
}>) => {
  return (
    <span
      className={cn(
        'inline-semibold overflow-hidden text-ellipsis whitespace-nowrap text-nowrap',
        DEFAULT_GROUP_TEXT_CLASS,
      )}
      style={{
        color,
      }}
    >
      {children}
    </span>
  );
};
export const ColumnGroupItem = ({
  columnGroup,
  actionsPanel,
  onChange,
  index,
  children,
}: {
  columnGroup: TableVizConfigColumnGroup;
  actionsPanel?: React.ReactNode;
  index: number;
} & React.PropsWithChildren<
  Pick<
    React.ComponentProps<typeof WidgetSettingsListLayout.ListItem>,
    'checked' | 'onChange'
  >
>) => {
  const color = columnGroup.background;
  return (
    <Draggable
      key={columnGroup.group_id}
      draggableId={buildDraggableId(columnGroup.group_id.toString(), 'group')}
      index={index}
    >
      {(provided) => (
        <div ref={provided.innerRef} {...provided.draggableProps}>
          <WidgetSettingsListLayout.SortableList
            droppableId={columnGroup.group_id}
          >
            <WidgetSettingsListLayout.ListItem
              checked={!columnGroup.hidden}
              onChange={onChange}
            >
              <WidgetSettingsListLayout.DragHandle
                {...provided.dragHandleProps}
              />
              <Icon
                style={{
                  color,
                }}
                iconName={(columnGroup.icon ?? 'groupByFilled') as IconsId}
              />
              <ColumnGroupTitle color={color}>
                {columnGroup.header_name}
              </ColumnGroupTitle>
              <div className="grow" />
              {actionsPanel}
            </WidgetSettingsListLayout.ListItem>
            {children}
          </WidgetSettingsListLayout.SortableList>
        </div>
      )}
    </Draggable>
  );
};
ColumnGroupItem.Title = ColumnGroupTitle;

const UngroupedColumnGroupItem = ({ children }: React.PropsWithChildren) => {
  return (
    <WidgetSettingsListLayout.SortableList droppableId={UNGROUPED_GROUP_KEY}>
      <WidgetSettingsListLayout.ListItem>
        <Icon iconName="groupByFilled" />
        <ColumnGroupTitle>Ungrouped Columns</ColumnGroupTitle>
      </WidgetSettingsListLayout.ListItem>
      {children}
    </WidgetSettingsListLayout.SortableList>
  );
};
const GroupsDroppableContainer = ({ children }: React.PropsWithChildren) => {
  return (
    <Droppable type="groups" droppableId={GROUPS_DROPPABLE_ID}>
      {(provided) => (
        <div
          className="flex flex-col gap-2"
          ref={provided.innerRef}
          {...provided.droppableProps}
        >
          {children}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};
const ColumnItem = ({
  columnSettings,
  colIndex,
  column,
  actionsPanel,
  onChange,
}: {
  columnSettings: TableVizConfigColumn;
  column: {
    key: string;
    label: string;
    hidden: boolean;
  };
  colIndex: number;
  groupId: string;
  actionsPanel: React.ReactNode;
} & React.PropsWithChildren<
  Pick<
    React.ComponentProps<typeof WidgetSettingsListLayout.ListItem>,
    'checked' | 'onChange'
  >
>) => {
  return (
    <WidgetSettingsListLayout.SortableListItem
      draggableId={buildDraggableId(columnSettings.col_id, 'column')}
      index={colIndex}
      listIndex={colIndex}
      key={column.key}
      checked={!columnSettings.hidden}
      onChange={onChange}
    >
      <WidgetSettingsListLayout.ListItem.Text>
        {column.label}
      </WidgetSettingsListLayout.ListItem.Text>
      <div className="grow" />
      {actionsPanel}
    </WidgetSettingsListLayout.SortableListItem>
  );
};
export const DefaultColumnsTab = <
  WidgetType extends
    | typeof EAGLE_EYE_DASHBOARD_SECTION_TYPE.KPI_TABLE
    | typeof EAGLE_EYE_DASHBOARD_SECTION_TYPE.KPI_TABLE_SINGLE_DATE
    | typeof OBJECT_DASHBOARD_SECTION_TYPE.HISTORICAL_REVIEW_TABLE
    | typeof OBJECT_DASHBOARD_SECTION_TYPE.UNIT_TYPE_TABLE,
>({
  widgetType,
  ColumnModal,
  BulkGroupModal,
  actions,
  supportedColumnTypes,
}: {
  widgetType:
    | typeof EAGLE_EYE_DASHBOARD_SECTION_TYPE.KPI_TABLE
    | typeof EAGLE_EYE_DASHBOARD_SECTION_TYPE.KPI_TABLE_SINGLE_DATE
    | typeof OBJECT_DASHBOARD_SECTION_TYPE.HISTORICAL_REVIEW_TABLE
    | typeof OBJECT_DASHBOARD_SECTION_TYPE.UNIT_TYPE_TABLE;
  ColumnModal: React.FC<
    DialogProps<{
      createNew: boolean;
    }> & {
      columnConfig?:
        | KpiTableWidgetConfigColumn
        | KpiTableSingleDateWidgetDataColumn
        | UnitTypeTableWidgetConfigColumn;
    }
  >;
  BulkGroupModal?: React.FC<DialogProps & { groupId: string }>;
  supportedColumnTypes?: KpiTableWidgetColumnType[];
  // todo think about action-reducer pattern
  actions: typeof commonTableWidgetUpdaters;
}) => {
  const [columnBulkGroupModalState, setColumnBulkGroupModalState] = useState<{
    groupId: string;
  } | null>();
  const [columnGroupModalState, setColumnGroupModalState] = useState<Omit<
    React.ComponentProps<typeof KpiTableWidgetConfigColumnGroup>,
    'onClose'
  > | null>(null);
  const [columnModalState, setColumnModalState] = useState<Omit<
    React.ComponentProps<typeof KpiTableWidgetConfigColumnPanel>,
    'onClose'
  > | null>(null);
  const { widget } = useWidgetConfig<WidgetType>();
  const { columns = [], viz_config } = widget.widgetConfig;
  const [updateConfig] = useUpdateWidgetConfig(widgetType);

  const groups = orderBy(viz_config!.column_groups ?? [], 'order');
  const getColumnGroupColumns = (groupId: string) => {
    return orderBy(
      (viz_config!.columns ?? []).filter((c) =>
        groupId === UNGROUPED_GROUP_KEY
          ? c.group_id == null
          : c.group_id == groupId,
      ),
      'order',
    );
  };
  const handleSortEnd: OnDragEndResponder = ({ source, destination }) => {
    if (!destination) return;
    if (source.droppableId === GROUPS_DROPPABLE_ID) {
      updateConfig({
        config: actions?.moveGroup(
          {
            fromIndex: source.index,
            toIndex: destination.index,
          },
          widget.widgetConfig,
        ),
      });
      return;
    }
    updateConfig({
      config: actions?.moveColumn(
        {
          fromIndex: source.index,
          toIndex: destination.index,
          sourceGroupId: source.droppableId,
          targetGroupId: destination.droppableId,
        },
        widget.widgetConfig,
      ),
    });
  };

  const renderColumnCreateActions = ({ groupId }: { groupId: string }) => {
    const handleCreateColumn = (
      columnType: WidgetTableColumnSettingsColumnType,
    ) => {
      setColumnModalState({
        type: 'create',
        columnType,
        groupId,
      });
    };
    const isSupportedColumnType = (
      columnType: WidgetTableColumnSettingsColumnType,
    ) => {
      return (
        supportedColumnTypes == null ||
        supportedColumnTypes.includes(columnType)
      );
    };
    return (
      <>
        {[
          WIDGET_TABLE_WIDGET_COLUMN_TYPES.NUMERIC,
          WIDGET_TABLE_WIDGET_COLUMN_TYPES.TEXT,
          WIDGET_TABLE_WIDGET_COLUMN_TYPES.SPARKLINE,
        ].map((columnType) =>
          isSupportedColumnType(columnType) ? (
            <DropdownItem
              key={columnType}
              onClick={() => {
                handleCreateColumn(columnType);
              }}
            >
              {columnType === 'numeric'
                ? 'Num Column'
                : `${capitalize(columnType)} Column`}
            </DropdownItem>
          ) : null,
        )}
      </>
    );
  };

  const renderColumn = ({
    columnSettings,
    colIndex,
    groupId,
  }: {
    columnSettings: TableVizConfigColumn;
    colIndex: number;
    groupId: string;
  }) => {
    const column = columns.find(
      (c) => c.key.toString() === columnSettings.key,
    )!;

    if (column == null) return null;

    const handleRemoveColumn = () => {
      updateConfig({
        config: actions?.removeColumn(
          {
            columnKey: column.key.toString(),
          },
          widget.widgetConfig,
        ),
      });
    };

    const handleEditColumn = () => {
      setColumnModalState({
        type: 'edit',
        columnSettings,
        columnConfig: column,
        groupId,
        columnType: resolveColumnSettingsType(columnSettings),
      });
    };
    return (
      <ColumnItem
        columnSettings={columnSettings}
        colIndex={colIndex}
        groupId={groupId}
        column={column}
        onChange={() => {
          updateConfig({
            config: actions?.toggleColumnHidden(
              {
                columnId: column.key.toString(),
              },
              widget.widgetConfig,
            ),
          });
        }}
        actionsPanel={
          <WidgetSettingsListLayout.ListItem.ActionsPanel>
            <IconButton
              iconName="copy"
              onClick={() => {
                updateConfig({
                  config: actions?.cloneColumn(
                    {
                      columnKey: column.key,
                      groupId:
                        groupId === UNGROUPED_GROUP_KEY ? undefined : groupId,
                    },
                    widget.widgetConfig,
                  ),
                });
              }}
            />
            {column.expression && isFormulaByExpression(column.expression) && (
              <InspectFormulaButton reference={column.expression} />
            )}
            <IconButton onClick={handleEditColumn} iconName="edit" />
            <IconButton onClick={handleRemoveColumn} iconName="trash" />
          </WidgetSettingsListLayout.ListItem.ActionsPanel>
        }
      />
    );
  };

  const renderGroup = (groupId: string, index: number) => {
    const colGroup = groups.find((g) => g.group_id === groupId)!;
    const colGroupColumns = getColumnGroupColumns(groupId);

    const handleRemove = () => {
      updateConfig({
        config: actions?.removeGroup({ groupId }, widget.widgetConfig),
      });
    };
    const handleEdit = () => {
      setColumnGroupModalState({
        type: 'edit',
        group: colGroup,
        widgetType,
      });
    };
    const handleClone = () => {
      updateConfig({
        config: actions?.cloneGroup({ groupId }, widget.widgetConfig),
      });
    };

    return (
      <ColumnGroupItem
        index={index}
        key={colGroup.group_id}
        onChange={() => {
          updateConfig({
            config: actions?.toggleGroupHidden(
              { groupId },
              widget.widgetConfig,
            ),
          });
        }}
        columnGroup={colGroup}
        actionsPanel={
          <WidgetSettingsListLayout.ListItem.ActionsPanel>
            {BulkGroupModal && (
              <IconButton
                disabled={colGroupColumns.length === 0}
                onClick={() =>
                  setColumnBulkGroupModalState({
                    groupId: colGroup.group_id,
                  })
                }
                iconName="configurations"
              />
            )}
            <Dropdown
              className={'sre-dropdown-v2'}
              items={
                <>
                  <DropdownItem iconName="edit" onClick={handleEdit}>
                    Edit
                  </DropdownItem>
                  <DropdownItem iconName="copy" onClick={handleClone}>
                    Clone
                  </DropdownItem>
                  <DropdownItem iconName="trash" onClick={handleRemove}>
                    Remove
                  </DropdownItem>
                </>
              }
            >
              <IconButton iconName="more" />
            </Dropdown>
            <Dropdown
              className={'sre-dropdown-v2'}
              items={renderColumnCreateActions({ groupId })}
            >
              <IconButton iconName="addSmall" />
            </Dropdown>
          </WidgetSettingsListLayout.ListItem.ActionsPanel>
        }
      >
        {colGroupColumns.map((columnSettings, colIndex) =>
          renderColumn({
            columnSettings,
            colIndex,
            groupId,
          }),
        )}
      </ColumnGroupItem>
    );
  };

  const allColumnsShown =
    actions?.toggleAllColumnsHidden != null
      ? !viz_config?.columns?.some((c) => c.hidden)
      : undefined;
  const handleToggleAllColumnsHidden = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const config = actions?.toggleAllColumnsHidden?.(
      e.target.checked,
      widget.widgetConfig,
    );
    updateConfig({ config });
  };

  return (
    <>
      <WidgetSettingsListLayout>
        <WidgetSettingsListLayout.Header
          checked={allColumnsShown}
          onChange={
            actions?.toggleAllColumnsHidden != null
              ? handleToggleAllColumnsHidden
              : undefined
          }
          label={
            columns.length === 0
              ? 'No Columns Yet'
              : `${columns.length} ${pluralize('COLUMN', columns.length)}`
          }
        >
          <Dropdown
            className="sre-dropdown-v2"
            items={
              <>
                <DropdownItem
                  onClick={() => {
                    setColumnGroupModalState({
                      type: 'create',
                      widgetType,
                    });
                  }}
                >
                  Group
                </DropdownItem>
                {renderColumnCreateActions({ groupId: UNGROUPED_GROUP_KEY })}
              </>
            }
          >
            <Button
              size="xs"
              variant="secondary"
              iconPosition="right"
              iconName="bottom"
            >
              Add
            </Button>
          </Dropdown>
        </WidgetSettingsListLayout.Header>
        <DragDropContext onDragEnd={handleSortEnd}>
          <UngroupedColumnGroupItem>
            {getColumnGroupColumns(UNGROUPED_GROUP_KEY).map(
              (columnSettings, colIndex) =>
                renderColumn({
                  columnSettings,
                  colIndex,
                  groupId: UNGROUPED_GROUP_KEY,
                }),
            )}
          </UngroupedColumnGroupItem>
          <GroupsDroppableContainer>
            {groups.map((group, index) => renderGroup(group.group_id, index))}
          </GroupsDroppableContainer>
        </DragDropContext>
      </WidgetSettingsListLayout>
      {columnModalState != null && (
        <ColumnModal
          {...columnModalState}
          onClose={() => setColumnModalState(null)}
          onSubmit={(res) => {
            setColumnModalState(null);
            if (res?.createNew) {
              setTimeout(() => {
                setColumnModalState({
                  type: 'create',
                  columnType: 'numeric',
                });
              });
            }
          }}
        />
      )}
      {columnBulkGroupModalState != null && BulkGroupModal != null && (
        <BulkGroupModal
          {...columnBulkGroupModalState}
          onClose={() => setColumnBulkGroupModalState(null)}
        />
      )}
      {columnGroupModalState != null && (
        <KpiTableWidgetConfigColumnGroup
          {...columnGroupModalState}
          onClose={() => setColumnGroupModalState(null)}
        />
      )}
    </>
  );
};

import { useWidgetFullScreen } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/WidgetFullScreen';
import { useAgGridRef, useLoadingOverlayEffect } from 'lib/ag-grid/utils';
import { useModal } from '@/shared/lib/hooks/useModal';
import { ReportObjectDashboardSection } from 'bundles/Shared/entities/dashboard';
import { GlobalLeaseTableWidgetDto } from '@/shared/api/dashboardsGeneratedApi';
import {
  columnToColumnSettingsVizConfigKeyMatcher,
  DashboardWidgetCard,
  DashboardWidgetTableCard,
  DateRangeWidgetState,
  isWidgetSectionPositionWidthFullSize,
  PaginationWidgetState,
  postProcessPopup,
  QueryWidgetState,
  sleepUntilAgGridSetsRowDataInTime,
  useWidgetTableDefaultColDef,
  WidgetStateColumns,
} from 'bundles/Shared/widgets/dashboard/widgets/common';
import { WidgetStateCalendarRangeSelector } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/state/WidgetStateCalendarRangeSelector';
import {
  WidgetTable,
  WidgetTablePlaceholder,
} from 'bundles/Shared/widgets/dashboard/widgets/common/ui/table/WidgetTable';
import {
  COL_DEF_OVERRIDES,
  FILTER_COLUMN_LABELS,
} from 'bundles/Shared/widgets/dashboard/widgets/globalLeaseTable/index';
import {
  WidgetConfigProps,
  WidgetProps,
  WidgetStateProps,
} from 'bundles/Shared/widgets/dashboard/widgets/model';
import { useMemo, useRef } from 'react';
import { UnknownRecord } from 'type-fest/source/internal';
import { WidgetStateTablePagination } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/state/WidgetStateTablePagination';
import { GrowDiv } from '@/shared/ui/GrowDiv';
import { WidgetStateSearchInput } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/state/WidgetStateSearchInput';
import { useTableWidgetExportFeature } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/table/useTableWidgetExportFeature';
import {
  ColDefBuilder,
  ColGroupDefBuilder,
  ColumnDefsBuilder,
} from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/table/ColumnDefsBuilder';
import { TableVizConfig } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/table/model';
import { ColDef, ColGroupDef, FilterChangedEvent } from 'ag-grid-community';
import { GlobalLeaseLtoState } from '@/bundles/Shared/widgets/dashboard/widgets/globalLeaseTable/ui/GlobalLeaseLtoState';
import { cn } from '@/shared/lib/css/cn';
import { useWidgetFlags } from '@/bundles/Shared/widgets/dashboard/widgets/common/lib/useWidgetFlags';
import { AUTO_GROUP_COLUMN_KEY } from '@/lib/ag-grid/constants';
import {
  useKpiWidgetColumnVisibilityState,
  useWidgetTableColumnSize,
} from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/table/tableStateSync';

export type GlobalLeaseTableWidgetState = PaginationWidgetState &
  DateRangeWidgetState &
  QueryWidgetState & {
    useLtoThresholds: boolean | undefined;
  };

export function GlobalLeaseTableWidget(
  props: WidgetProps<GlobalLeaseTableWidgetDto, ReportObjectDashboardSection> &
    WidgetConfigProps<UnknownRecord> &
    WidgetStateProps<GlobalLeaseTableWidgetState>,
) {
  const {
    widgetSection,
    data,
    state,
    onStateChange,
    isLoading,
    isFetching,
    mode,
    context,
  } = props;

  const { shouldDisplayPlaceholder, shouldDisplayData } = useWidgetFlags(props);

  const { openModal } = useModal();
  const gridRef = useAgGridRef();
  const wrapperDivRef = useRef<HTMLDivElement>(null);
  const widgetStateFullScreenFeature = useWidgetFullScreen(wrapperDivRef);
  const exportFeature = useTableWidgetExportFeature({
    gridRef,
    mode,
    widgetTitle: widgetSection.title,
    widgetId: widgetSection.id,
    state,
  });
  const columnSizeFeature = useWidgetTableColumnSize({
    gridRef,
    state,
    onStateChange,
  });

  const columnVisibilityFeature = useKpiWidgetColumnVisibilityState({
    grid: gridRef.current,
    state,
    onStateChange,
  });

  useLoadingOverlayEffect({
    isLoading: isFetching,
    grid: gridRef.current,
  });
  const mappedData = useMemo(
    () =>
      data
        ? {
            ...data,
            rows: data.rows.map((row) => ({
              ...row,
              values: Object.fromEntries(
                row.values.map((v) => [v.key, v.value]),
              ),
            })),
          }
        : undefined,
    [data],
  );

  const columnDefsBuilder = useMemo(() => {
    const colDefBuilder = new ColDefBuilder({
      mode,
    });

    colDefBuilder
      .withOverride((overrideArgs) => ({
        valueGetter: (params) => {
          return params.data?.values[overrideArgs.column?.key];
        },
        ...COL_DEF_OVERRIDES[overrideArgs.columnSettings.key.toString()]?.(
          overrideArgs,
        ),
      }))
      .withFilterLabelOverrides(FILTER_COLUMN_LABELS);
    return new ColumnDefsBuilder({
      mode,
      vizConfig: widgetSection.widgetConfig.viz_config as TableVizConfig,
      colGroupDefBuilder: new ColGroupDefBuilder({
        mode,
      }),
      colDefBuilder,
      columnMatcher: columnToColumnSettingsVizConfigKeyMatcher,
    });
  }, [
    JSON.stringify(data?.columns),
    widgetSection.widgetConfig.viz_config,
    JSON.stringify(data?.filterOptions),
  ]);

  const columnDefs = useMemo<(ColDef | ColGroupDef)[]>(() => {
    return columnDefsBuilder.build({
      columns: data?.columns ?? [],
      filterOptions: data?.filterOptions ?? [],
    });
  }, [columnDefsBuilder]);

  const defaultColDef = useWidgetTableDefaultColDef({
    mode,
  });

  const handleFilterChanged = (e: FilterChangedEvent) => {
    const stateApplier = columnDefsBuilder.buildFilterChangeEventStateApplier({
      columns: data?.columns ?? [],
    });
    onStateChange({
      ...state,
      ...stateApplier(e),
    });
  };

  return (
    <DashboardWidgetTableCard
      {...props}
      className={cn(
        props.className,
        data?.rows && data?.rows.length <= 10
          ? 'min-h-[520px]'
          : 'min-h-[800px]',
      )}
      ref={wrapperDivRef}
    >
      {mode === 'pdf' &&
        isWidgetSectionPositionWidthFullSize(widgetSection.position) && (
          <DashboardWidgetCard.PDFHeader>
            {widgetSection.title}
          </DashboardWidgetCard.PDFHeader>
        )}
      {mode !== 'pdf' && (
        <>
          <DashboardWidgetCard.Header>
            <DashboardWidgetCard.Header.Title>
              {widgetSection.title}
              <GlobalLeaseLtoState
                state={state}
                onStateChange={onStateChange}
              />
            </DashboardWidgetCard.Header.Title>
            <div className="grow" />
            {!widgetStateFullScreenFeature.isFullscreen &&
              Boolean(context?.columnVisibilityEnabled) && (
                <WidgetStateColumns
                  onColumnSizeChange={columnSizeFeature.handleColumnSizeChange}
                  state={state}
                  groupsState={{
                    groups: state.displayedGroups ?? [],
                  }}
                  onColumnStateChange={columnVisibilityFeature.onChange}
                  vizConfig={
                    widgetSection.widgetConfig.viz_config as TableVizConfig
                  }
                  columns={data?.columns ?? []}
                />
              )}
            <exportFeature.ExportButtonComponent />
            <WidgetStateCalendarRangeSelector
              state={state}
              onStateChange={onStateChange}
              disableFuture={false}
            />
            <widgetStateFullScreenFeature.IconButton />
          </DashboardWidgetCard.Header>
          <DashboardWidgetCard.Panel>
            <WidgetStateTablePagination
              isLoading={isLoading ?? isFetching ?? false}
              state={state}
              onStateChange={onStateChange}
              totalSize={data?.totalSize ?? 0}
              includeTotalSizeInPerPage
            />
            <GrowDiv />
            <WidgetStateSearchInput
              state={state}
              onStateChange={onStateChange}
              suggestions={['unit label', 'asset name', 'unit #']}
            />
          </DashboardWidgetCard.Panel>
        </>
      )}
      {shouldDisplayPlaceholder && <WidgetTablePlaceholder />}

      {shouldDisplayData && (
        <WidgetTable
          onFirstDataRendered={async (e) => {
            await sleepUntilAgGridSetsRowDataInTime();
            e.api.autoSizeColumn(AUTO_GROUP_COLUMN_KEY);
            e.api.sizeColumnsToFit();
          }}
          context={{
            openModal,
          }}
          domLayout={mode === 'pdf' ? 'autoHeight' : 'normal'}
          ref={gridRef}
          treeData={false}
          mode={mode}
          autoGroupColumnDef={undefined}
          rowData={mappedData?.rows}
          columnDefs={columnDefs}
          excelStyles={columnDefsBuilder.buildExcelStyles()}
          defaultColDef={defaultColDef}
          onFilterChanged={handleFilterChanged}
          postProcessPopup={postProcessPopup}
        />
      )}
    </DashboardWidgetTableCard>
  );
}

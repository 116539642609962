import { REMOVE_WIDGET_BUTTON_TOOLTIP } from '@/bundles/Settings/components/REport/Dashboards/Dashboard/config';
import { useDashboardRemoveSection } from '@/bundles/Shared/features/dashboard/sectionActions/lib';
import { ReportDashboardSectionPositionWithId } from 'bundles/Shared/entities/dashboard';
import { IconButton } from 'stories/IconButton/IconButton';

interface Props {
  sectionId: string;
  layout: ReportDashboardSectionPositionWithId[];
  boardId?: string;
}

export function DashboardSectionRemoveButton({
  sectionId,
  boardId,
  layout,
}: Props) {
  const removeWidget = useDashboardRemoveSection();

  const handleRemove = () =>
    removeWidget({
      widgetId: sectionId,
      layout,
      boardId,
    });
  return (
    <IconButton
      tooltipProps={{
        mainText: REMOVE_WIDGET_BUTTON_TOOLTIP,
        placement: 'top-end',
        arrowPosition: 'end',
      }}
      size="s"
      onClick={handleRemove}
      iconName="closeSmall"
    />
  );
}

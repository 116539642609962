import {
  DashboardFilterObjectItem,
  type DashboardFilterObjectTypes,
} from '@/bundles/Shared/entities/dashboard';
import { ObjectSelectionModal } from '@/bundles/Shared/features/dashboard/create/ui/ObjectSelectionModal';
import { useReportBuilderEagleEyeTemplateMeta } from '@/entities/report/reportBuilder/eagleEye/lib';
import { useReportBuilderTemplateEagleEyeObjectSettingsSchemaFormContext } from '@/entities/report/reportBuilder/lib/reportBuilderTemplateEagleEyeObjectSettingsConfig';
import useBoolean from '@/shared/lib/hooks/useBoolean';
import { useController } from 'react-hook-form';

export const EagleEyeObjectSelectionItem = ({
  type,
}: {
  type: DashboardFilterObjectTypes;
}) => {
  const { segmentsFilterObjects, assetsFilterObjects } =
    useReportBuilderEagleEyeTemplateMeta();
  const objects =
    type === 'asset' ? assetsFilterObjects : segmentsFilterObjects;

  const { control } =
    useReportBuilderTemplateEagleEyeObjectSettingsSchemaFormContext();
  const { value: isOpen, toggle: toggleIsOpen } = useBoolean();
  const { field } = useController({
    control,
    name: type === 'fund' ? 'asset_ids' : `${type}_ids`,
  });

  return (
    <>
      <DashboardFilterObjectItem
        onClick={toggleIsOpen}
        count={type === 'fund' ? 0 : field.value?.length}
        type={type}
        disabled={type === 'fund'}
      />
      {isOpen && (
        <ObjectSelectionModal
          sidePanelProps={{
            position: 'left',
            size: '500',
          }}
          objectType={type}
          onClose={toggleIsOpen}
          onChange={(ids) => {
            field.onChange(ids);
          }}
          defaultObjectIds={field.value}
          objects={objects}
        />
      )}
    </>
  );
};

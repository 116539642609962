import { useSearchParams } from '@/shared/lib/hooks/navigation/useSearchParams';
import {
  ExtractParams,
  generateUrl,
  ROUTES_ROOT,
} from '@/shared/lib/hooks/useNavigation';
import { useLocation, useNavigate, useParams } from '@reach/router';
import {
  EagleEyeBoard,
  ReportDashboardAsset,
} from 'bundles/Shared/entities/dashboard/model/types';

import { useCallback, useEffect } from 'react';

export const useReportSettingsDashboardScreenParams = () =>
  useParams<
    ExtractParams<
      typeof ROUTES_ROOT.settings.report.dashboards.dashboard.fullPath
    >
  >();
export const useReportBuilderTemplateScreenParams = () =>
  useParams<
    ExtractParams<
      typeof ROUTES_ROOT.reportBuilderTemplates.reportBuilderTemplate.fullPath
    >
  >();
export const useReportSettingsDashboardScreenQueryParams = () => {
  const [searchParams] = useSearchParams();
  return {
    boardId: searchParams.get('boardId') ?? undefined,
    assetId: searchParams.get('assetId') && Number(searchParams.get('assetId')),
  };
};
export const useReportDashboardSettingsScreenQueryParams = () => {
  const [searchParams] = useSearchParams();
  const assetId = searchParams.get('assetId');
  const boardId = searchParams.get('boardId');
  return {
    assetId: assetId && Number(assetId),
    boardId,
  };
};

export const useNavigateToDashboardSettingsBySlug = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return useCallback(
    (slug: string) => {
      navigate(
        generateUrl(ROUTES_ROOT.settings.report.dashboards.dashboard.fullPath, {
          pathParams: {
            dashboardId: slug,
          },
          queryParams: {
            ...Object.fromEntries(new URLSearchParams(location.search)),
          },
        }),
        {
          replace: true,
        },
      );
    },
    [navigate, location.search],
  );
};

export const useNavigateToWidget = () => {
  const navigate = useNavigate();
  const { dashboardId } = useReportSettingsDashboardScreenParams();
  const { boardId } = useReportDashboardSettingsScreenQueryParams();

  const { assetId: selectedAssetId } =
    useReportDashboardSettingsScreenQueryParams();
  return ({
    widgetId,
    assetId,
  }: {
    widgetId: string;
    assetId?: number | string;
  }) =>
    navigate(
      generateUrl(
        ROUTES_ROOT.settings.report.dashboards.dashboard.widget.fullPath,
        {
          pathParams: {
            dashboardId,
            widgetId,
            boardId,
          },
          queryParams: {
            assetId: assetId ?? selectedAssetId,
          },
        },
      ),
    );
};

export const useNavigateToDasbhoardViewToBoard = () => {
  const [_, setSearchParams] = useSearchParams();

  const navigateToBoard = (board: Pick<EagleEyeBoard, 'id' | 'slug'>) => {
    setSearchParams((prev) => {
      const params = new URLSearchParams(prev);
      params.set('boardId', board.slug);
      return params;
    });
  };

  return navigateToBoard;
};

export const useNavigateDashboardSettingsToBoard = () => {
  const [_, setSearchParams] = useSearchParams();

  return useCallback(
    (board: Pick<EagleEyeBoard, 'id' | 'slug'>) => {
      setSearchParams((prev) => {
        const params = new URLSearchParams(prev);
        params.set('boardId', board.slug);
        return params;
      });
    },
    [setSearchParams],
  );
};
export const useNavigateToFirstBoardEffect = (
  boards: Pick<EagleEyeBoard, 'id' | 'slug'>[] | undefined,
) => {
  const navigateToBoard = useNavigateDashboardSettingsToBoard();
  const { boardId } = useReportSettingsDashboardScreenQueryParams();

  useEffect(() => {
    if (boards == null || boards.length === 0 || boardId) {
      return;
    }
    navigateToBoard(boards[0]);
  }, [boards, boardId]);
};
export const useNavigateToFirstAssetEffect = ({
  assets,
}: {
  assets: Pick<ReportDashboardAsset, 'id'>[];
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const assetId = searchParams.get('assetId');

  useEffect(() => {
    if (assetId != null || assets[0] == null) {
      return;
    }
    setSearchParams((prev) => {
      const params = new URLSearchParams(prev);
      params.set('assetId', assets[0].id.toString());
      return params;
    });
  }, [assets]);
};

export const useNavigateToFirstAssetAndBoardEffect = ({
  assets,
  boards,
}: {
  assets: ReportDashboardAsset[];
  boards: Pick<EagleEyeBoard, 'id' | 'slug'>[];
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    const assetId = searchParams.get('assetId');
    const boardId = searchParams.get('boardId');
    if (assetId != null && boardId != null) {
      return;
    }
    const newSearchParams = new URLSearchParams(searchParams);
    if (boards.length > 0 && boardId == null) {
      newSearchParams.set('boardId', boards[0].slug);
    }
    if (assets.length > 0 && assetId == null) {
      newSearchParams.set('assetId', assets[0].id.toString());
    }
    setSearchParams(newSearchParams);
  }, [assets, boards]);
};

import { WidgetSettingsListLayout } from '@/bundles/Shared/components/layouts/dashboard/WidgetLayout';
import NoDataOverlay from '@/bundles/Shared/components/NoDataOverlay';
import { OBJECT_DASHBOARD_SECTION_TYPE } from '@/bundles/Shared/entities/dashboard';
import {
  useUpdateWidgetConfigEffect,
  useWidgetConfig,
} from '@/bundles/Shared/widgets/dashboard/widgets/common/lib/config';
import { ColorField } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/fields/ColorField';
import { HideTotalField } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/fields/HideTotalField';
import { PeriodShiftField } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/fields/PeriodShiftField';
import { RecapPageField } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/fields/RecapPageField';
import { UpdateWidgetTitle } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/UpdateWidgetTitle';
import { commonTableWidgetUpdaters } from '@/bundles/Shared/widgets/dashboard/widgets/common/updaters';
import { BulkGroupConfigModal } from '@/bundles/Shared/widgets/dashboard/widgets/kpiTableSingleDate/config/bulk-group';
import { UnitTypeTableWidgetConfigColumnPanel } from '@/bundles/Shared/widgets/dashboard/widgets/unitTypeTable/config/column';
import {
  transformUnitTypeTableWidgetConfigToForm,
  UnitTypeTableWidgetConfigForm,
} from '@/bundles/Shared/widgets/dashboard/widgets/unitTypeTable/config/component.form';
import {
  SearchableUnitTypeTree,
  UnitTypeWidgetConfigRowsJsonEditor,
} from '@/bundles/Shared/widgets/dashboard/widgets/unitTypeTable/config/rows';
import { unitTypeTableUpdaters } from '@/bundles/Shared/widgets/dashboard/widgets/unitTypeTable/config/updaters';
import {
  useCloseBottomPanelOnUnmount,
  useWorkspaceContext,
} from '@/bundles/Shared/widgets/formula/panel';
import { Button } from '@/stories/Button/Button';
import { Field } from '@/stories/Field/Field';
import { FieldsContainer } from '@/stories/Field/Field';
import ThinTabGroup, {
  IThinTabItem,
} from '@/stories/Tabs/ThinTabGroup/ThinTabGroup';
import { useTabs } from '@/stories/Tabs/useTabs';
import { TumblerController } from '@/stories/Tumbler/Tumbler';
import { DefaultColumnsTab } from 'bundles/Shared/widgets/dashboard/widgets/common';
import { FormProvider, useForm } from 'react-hook-form';

const TABS = [
  {
    id: 'basic',
    label: 'Basic',
  },
  {
    id: 'columns',
    label: 'Columns',
  },
  {
    id: 'rows',
    label: 'Rows',
  },
] as const satisfies readonly IThinTabItem[];

const BasicTab = () => {
  const { widget } =
    useWidgetConfig<typeof OBJECT_DASHBOARD_SECTION_TYPE.UNIT_TYPE_TABLE>();
  const methods = useForm<UnitTypeTableWidgetConfigForm>({
    defaultValues: {
      ...transformUnitTypeTableWidgetConfigToForm(widget.widgetConfig),
    },
  });

  const { control } = methods;

  useUpdateWidgetConfigEffect<
    UnitTypeTableWidgetConfigForm,
    typeof OBJECT_DASHBOARD_SECTION_TYPE.UNIT_TYPE_TABLE
  >(methods, unitTypeTableUpdaters.updateDefaultOptions);

  return (
    <FormProvider {...methods}>
      <FieldsContainer>
        <UpdateWidgetTitle />
        <Field labelText="Period Shift">
          <PeriodShiftField control={control} name="period_shift" />
        </Field>
        <Field>
          <TumblerController control={control} name="hide_floorplan_rows">
            Disable Floorplan(s)
          </TumblerController>
        </Field>
        <HideTotalField
          fieldType="row"
          control={control}
          name="hide_total_row"
        />
        <ColorField
          component="Field"
          labelText="Header Background"
          name="header_background"
          control={control}
        />
        <RecapPageField />
      </FieldsContainer>
    </FormProvider>
  );
};

const RowsTab = () => {
  const { widget } =
    useWidgetConfig<typeof OBJECT_DASHBOARD_SECTION_TYPE.UNIT_TYPE_TABLE>();
  const hasRows = widget.widgetConfig.rows?.length > 0;
  const { renderBottomPanel, toggleBottomPanel, bottomPanelOpen } =
    useWorkspaceContext();

  const handleEditTree = () => {
    toggleBottomPanel();
  };

  useCloseBottomPanelOnUnmount();

  return (
    <WidgetSettingsListLayout>
      <NoDataOverlay
        title=""
        subTitle={
          !hasRows
            ? "You haven't configured unit type tree yet"
            : 'You have custom configured unit type tree'
        }
        customDocumentsComponent={
          <Button
            disabled={bottomPanelOpen}
            className="mt-2"
            size="xs"
            onClick={handleEditTree}
          >
            {hasRows ? 'Edit Tree' : 'Configure Tree'}
          </Button>
        }
      />
      {renderBottomPanel(
        <div className="flex h-full w-full gap-4 overflow-auto">
          <SearchableUnitTypeTree />
          <UnitTypeWidgetConfigRowsJsonEditor />
        </div>,
      )}
    </WidgetSettingsListLayout>
  );
};

export const UnitTypeWidgetConfigComponent = () => {
  const { tab, thinTabGroupProps } = useTabs(TABS, TABS[0]);
  return (
    <div className="flex flex-col gap-4">
      <ThinTabGroup fullWidth {...thinTabGroupProps} />
      <div className="p-1">
        {tab?.id === 'basic' && <BasicTab />}
        {tab?.id === 'columns' && (
          <DefaultColumnsTab
            ColumnModal={UnitTypeTableWidgetConfigColumnPanel}
            BulkGroupModal={BulkGroupConfigModal}
            widgetType={OBJECT_DASHBOARD_SECTION_TYPE.UNIT_TYPE_TABLE}
            actions={commonTableWidgetUpdaters}
            supportedColumnTypes={['numeric', 'sparkline']}
          />
        )}
        {tab?.id === 'rows' && <RowsTab />}
      </div>
    </div>
  );
};

import { useHoverSaveArea } from '@/bundles/Shared/components/LeftSidebar/hooks/useHoverSaveArea';
import { useScoreBoardMenuItemPermissions } from '@/bundles/Shared/components/LeftSidebar/hooks/useMenuItemsPermissions';
import { useGenericData } from '@/bundles/Shared/GenericContext';
import { currentUserAllowedTo } from '@/lib/permissions';
import { currentUserAllowedToSeePortfolioPage } from '@/pages/portfolio/permissions';
import { cn } from '@/shared/lib/css/cn';
import useMediaQuery, {
  MEDIUM_WIDTH_MEDIA_QUERY,
} from '@/shared/lib/hooks/useMediaQuery';
import {
  allSubPathMatches,
  generateUrl,
  ROUTES_ROOT,
} from '@/shared/lib/hooks/useNavigation';
import { Link, useMatch } from '@reach/router';
import { usePopoverTriggered } from 'bundles/Shared/components/LeftSidebar/hooks/usePopoverTriggered';
import MenuItemPopover from 'bundles/Shared/components/LeftSidebar/items/MenuItemPopover';
import { DashboardIcon } from 'bundles/Shared/entities/dashboard';
import { LeftSidebarMenu } from 'bundles/Shared/shared/ui';
import { useRef } from 'react';
import { Button } from 'stories/Button/Button';
import { Icon } from 'stories/Icon/Icon';
import { DEFAULT_DROPDOWN_OFFSET } from 'stories/Popover/Popover';
import SecondaryMenuItem from '@/bundles/Shared/components/LeftSidebar/items/SecondaryMenuItem';

const NAVIGATION_ITEM_NAME = 'Portfolio';

const resolveExtraScoreboards = (
  permissions: ReturnType<typeof useScoreBoardMenuItemPermissions>,
) => [
  {
    path: ROUTES_ROOT.scoreboards.pipeline.fullPath,
    label: 'Pipeline',
    visible: permissions.pipelineVisible,
  },
  {
    path: '/',
    label: 'Investor Homepage',
    visible: currentUserAllowedToSeePortfolioPage(),
  },
];

export const CreateNewDashboardButton = () => {
  if (!currentUserAllowedTo('configure', 'report')) return null;

  return (
    <Link to={ROUTES_ROOT.settings.report.dashboards.new.fullPath}>
      <Button variant="secondary" size="xs">
        New
      </Button>
    </Link>
  );
};

export const ReportPortfolioMenuItem = () => {
  const { triggered, ...handlers } = usePopoverTriggered();
  const { genericData } = useGenericData();
  const isMobile = useMediaQuery('(pointer:coarse)');
  const isMediumWidth = useMediaQuery(MEDIUM_WIDTH_MEDIA_QUERY);
  const { mainNavigation } = genericData!;
  const menuItemRef = useRef<HTMLAnchorElement>(null);
  const { classes: hoverClasses, onMouseMove } = useHoverSaveArea({
    popoverPlacement: 'start',
  });
  const isMatch = useMatch(allSubPathMatches(ROUTES_ROOT.scoreboards.fullPath));

  const permissions = useScoreBoardMenuItemPermissions();

  const items = resolveExtraScoreboards(permissions)
    .filter((item) => item.visible)
    .concat(
      mainNavigation.scoreboardsSubItems.map((item) => ({
        ...item,
        visible: true,
        path: generateUrl(ROUTES_ROOT.scoreboards.scoreboard.fullPath, {
          pathParams: {
            slug: item.slug,
          },
        }),
      })),
    );

  if (items.length === 0) return null;

  if (isMobile) {
    return (
      <>
        <div className="flex w-full items-center px-2 py-1">
          <div className="left-sidebar__second-menu-label inline-regular ml-2">
            {NAVIGATION_ITEM_NAME}
          </div>
        </div>
        {items.map((item) => (
          <SecondaryMenuItem
            key={item.path}
            label={item.label}
            path={item.path}
          />
        ))}
      </>
    );
  }

  return (
    <>
      <SecondaryMenuItem
        className={cn(
          triggered && 'left-sidebar__second-menu-item_hovered',
          hoverClasses,
        )}
        ref={menuItemRef}
        onLinkClick={(e) => {
          e.preventDefault();
        }}
        linkProps={{
          onMouseMove,
        }}
        rightIcon={<Icon iconName="arrowRight" className="pr-3" />}
        label={NAVIGATION_ITEM_NAME}
        isActive={() => isMatch!}
        path=""
      />
      {isMediumWidth && (
        <MenuItemPopover
          {...handlers}
          reference={menuItemRef}
          offset={DEFAULT_DROPDOWN_OFFSET}
          template={
            <LeftSidebarMenu
              title="Dashboards"
              panel={<CreateNewDashboardButton />}
            >
              {items.map((item) => (
                <LeftSidebarMenu.Item to={item.path} key={item.path}>
                  <DashboardIcon iconName="eagleEyeDashboard" /> {item.label}
                </LeftSidebarMenu.Item>
              ))}
            </LeftSidebarMenu>
          }
          placement="right-start"
          hideOnExpandedAreaClick
          maxWidth="fit-content"
        />
      )}
    </>
  );
};

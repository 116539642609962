import { ComparisonDashboardSectionTypes } from '@/bundles/Shared/entities/dashboard';
import {
  FINANCIAL_TABLE_WIDGET_CONFIG_COLUMN_GROUP_SCHEMA,
  FinancialTableWidgetColumnGroupForm,
} from '@/bundles/Shared/widgets/dashboard/widgets/common/financialTable/config/group.form';
import { financialTableUpdaters } from '@/bundles/Shared/widgets/dashboard/widgets/common/financialTable/config/updaters';
import { ColorField } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/fields/ColorField';
import { DialogProps } from '@/shared/lib/hooks/useModal';
import { Button } from '@/stories/Button/Button';
import { Field } from '@/stories/Field/Field';
import { Input } from '@/stories/FormControls/Inputs/Input/Input';
import { Modal } from '@/stories/Modals/Modal/Modal';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  useUpdateWidgetConfig,
  useWidgetConfig,
} from 'bundles/Shared/widgets/dashboard/widgets/common';
import { TableVizConfigColumnGroup } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/table/model';
import { capitalize } from 'lodash-es';
import { FormProvider, useForm } from 'react-hook-form';
import { FieldsContainer } from 'stories/Field/Field';

export type FinancialTableWidgetConfigColumnGroupPropsProps =
  DialogProps<FinancialTableWidgetColumnGroupForm> & {
    type: 'create' | 'edit';
    group?: Pick<TableVizConfigColumnGroup, 'header_name' | 'group_id'>;
  };

export function FinancialTableWidgetConfigColumnGroup({
  onClose,
  group,
  ...props
}: FinancialTableWidgetConfigColumnGroupPropsProps) {
  const { widget } = useWidgetConfig<ComparisonDashboardSectionTypes>();
  const [updateConfig, { isLoading: isSubmitting }] = useUpdateWidgetConfig(
    widget.widgetType,
  );

  const methods = useForm<FinancialTableWidgetColumnGroupForm>({
    resolver: yupResolver(FINANCIAL_TABLE_WIDGET_CONFIG_COLUMN_GROUP_SCHEMA),
    defaultValues: {
      ...(group && {
        key: group.group_id,
        label: group.header_name,
      }),
    },
  });

  const {
    register,
    handleSubmit,
    control,
    formState: { isValid, isDirty },
  } = methods;

  const onSubmit = handleSubmit((data) => {
    updateConfig({
      config: financialTableUpdaters.upsertGroup(
        {
          headerName: data.label,
          groupId: group?.group_id,
          color: data.color,
        },
        widget.widgetConfig,
      ),
    });
    onClose();
  });

  return (
    <Modal
      header={`${capitalize(props.type)} Group`}
      toggle={onClose}
      actions={
        <>
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button
            disabled={!isValid || isSubmitting || !isDirty}
            variant="success"
            onClick={onSubmit}
          >
            Save Updates
          </Button>
        </>
      }
    >
      <FormProvider {...methods}>
        <FieldsContainer>
          <Field required labelText="Group Title">
            <Input {...register('label')} placeholder="Enter Group Title" />
          </Field>
          <ColorField name="color" control={control} />
        </FieldsContainer>
      </FormProvider>
    </Modal>
  );
}

import { transformPermissionsToRequest } from '@/bundles/Shared/entities/permissions';
import { useReportBuilderEagleEyeTemplateMeta } from '@/entities/report/reportBuilder/eagleEye/lib';
import type { IAsset } from '@/types/Asset';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  getLegalEntitiesFromAssets,
  ReportDashboardType,
  useReportObjectDashboardAssetsQuery,
} from 'bundles/Shared/entities/dashboard';

import {
  usePostApiSettingsReportComparisonDashboardsMutation,
  usePostApiSettingsReportEagleEyeDashboardsMutation,
  usePostApiSettingsReportObjectDashboardsMutation,
} from '@/shared/api/dashboardSettingsEnhancedApi';

import { useCallback, useEffect, useMemo } from 'react';
import { useForm, useFormContext, UseFormReturn } from 'react-hook-form';
import {
  DASHBOARD_CREATION_SCHEMA,
  DashboardCreationFormModel,
} from '@/bundles/Shared/features/dashboard/create/config';

export const useCreateDashboardForm = (
  form?: Partial<DashboardCreationFormModel>,
) =>
  useForm<DashboardCreationFormModel>({
    defaultValues: form,
    resolver: yupResolver(DASHBOARD_CREATION_SCHEMA),
    mode: 'all',
  });

export const useCreateDashboard = () => {
  const [createObjectDashboard, objectOptions] =
    usePostApiSettingsReportObjectDashboardsMutation();
  const [createEagleEyeDashboard, eagleOptions] =
    usePostApiSettingsReportEagleEyeDashboardsMutation();
  const [createComparisonDashboard, comparisonOptions] =
    usePostApiSettingsReportComparisonDashboardsMutation();

  return [
    useCallback(async (form: DashboardCreationFormModel) => {
      if (form.dashboard_type === ReportDashboardType.OBJECT) {
        return await createObjectDashboard({
          body: {
            name: form.name,
            status: 'draft',
            asset_ids: form.asset_ids,
            excluded_legal_entity_ids: form.excluded_legal_entity_ids,
            ...transformPermissionsToRequest(form.permitted),
          },
        });
      }
      if (form.dashboard_type === ReportDashboardType.COMPARISON_MODE) {
        return await createComparisonDashboard({
          body: {
            name: form.name,
            asset_ids: form.asset_ids,
            excluded_legal_entity_ids: form.excluded_legal_entity_ids,
            ...transformPermissionsToRequest(form.permitted),
          },
        });
      }
      return await createEagleEyeDashboard({
        body: {
          name: form.name,
          status: 'draft',
          sections_attributes: [],
          asset_ids: form.asset_ids ?? [],
          segment_ids: form.segment_ids ?? [],
          excluded_legal_entity_ids: form.excluded_legal_entity_ids ?? [],
          ...transformPermissionsToRequest(form.permitted),
        },
      });
    }, []),
    {
      ...objectOptions,
      isLoading:
        objectOptions.isLoading ||
        eagleOptions.isLoading ||
        comparisonOptions.isLoading,
    },
  ] as const;
};

export const useSelectedAssets = (
  assets: Pick<IAsset, 'id' | 'name' | 'legalEntities'>[],
  selectedAssetIds: IAsset['id'][],
) => {
  return useMemo(() => {
    return assets.filter((asset) => selectedAssetIds.includes(asset.id));
  }, [assets, selectedAssetIds]);
};

export const useIncludedLegalEntities = (
  selectedAssets: Pick<IAsset, 'id' | 'name' | 'legalEntities'>[],
  excludedLegalEntityIds: string[],
) => {
  return useMemo(() => {
    const legalEntities = getLegalEntitiesFromAssets(selectedAssets);

    return legalEntities.filter(
      (le) => !excludedLegalEntityIds?.includes(le.id),
    );
  }, [selectedAssets, excludedLegalEntityIds]);
};

export function useDashboardObjectLevelItems({
  selectedAssetIds,
  excludedLegalEntityIds,
}: {
  selectedAssetIds: number[];
  excludedLegalEntityIds: string[];
}) {
  const { assets: assetsWithLEs, legalEntities } =
    useReportObjectDashboardAssetsQuery();

  const selectedAssetsWithLEs = useSelectedAssets(
    assetsWithLEs,
    selectedAssetIds,
  );

  const includedLegalEntities = useIncludedLegalEntities(
    selectedAssetsWithLEs,
    excludedLegalEntityIds,
  );

  return {
    selectedAssetsWithLEs,
    includedLegalEntities,
    allLegalEntities: legalEntities,
  };
}

export function useReportBuilderTemplateEagleEyeObjectItems({
  selectedAssetIds,
  excludedLegalEntityIds,
}: {
  selectedAssetIds: number[];
  excludedLegalEntityIds: string[];
}) {
  const { data } = useReportBuilderEagleEyeTemplateMeta();
  const assetsWithLEs = data?.assets ?? [];

  const selectedAssetsWithLEs = useSelectedAssets(
    assetsWithLEs,
    selectedAssetIds,
  );

  const includedLegalEntities = useIncludedLegalEntities(
    selectedAssetsWithLEs,
    excludedLegalEntityIds,
  );

  return {
    selectedAssetsWithLEs,
    includedLegalEntities,
  };
}

export const useSetDashboardObjectsEffect = (
  {
    assetIds,
    segmentIds,
    isNew,
  }: {
    assetIds: number[];
    segmentIds: number[];
    isNew?: boolean;
  },
  methods?: UseFormReturn,
) => {
  const { setValue, trigger } =
    useFormContext<DashboardCreationFormModel>() ?? methods;

  useEffect(() => {
    if (!isNew) {
      return;
    }
    setValue('asset_ids', assetIds);
    setValue('excluded_legal_entity_ids', []);
    setValue('segment_ids', segmentIds);
    trigger();
  }, [assetIds, segmentIds, isNew]);
};

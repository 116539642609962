import React, { useMemo, useState, useRef } from 'react';
import pluralize from 'pluralize';
import { cn } from '@/shared/lib/css/cn';
import http from 'lib/http';
import { Checkbox } from 'stories/Checkbox/Checkbox';
import { Input } from 'stories/FormControls/Inputs/Input/Input';
import { Button } from 'stories/Button/Button';
import { sumBy } from 'lodash-es';
import YesOrNo from 'bundles/Shared/components/YesOrNo';
import { TextEditor } from '@symmetre-web/text-editor';
import DocumentItem from 'bundles/Shared/components/DocumentItem';
import PermissionList from 'bundles/Shared/components/PermissionList';
import MediaItem from 'bundles/Assets/components/SharedFiles/MediaItem';
import FeedMessageFormStep from '@/bundles/InvestmentObjects/components/Overview/Posts/FeedMessageFormStep';
import FeedMessageEmailRecipientsStep from '@/bundles/InvestmentObjects/components/Overview/FeedMessage/FeedMessageEmailRecipientsStep';

const MAX_ATTACHMENT_SIZE = 10 * 1024 * 1024; // 20 MB

const EmailNotificationForm = ({
  allReplyRecipients = [],
  feedMessage,
  setCurrentFeedMessage,
  setSendEmailModalOpened,
  updateFeedMessagesList,
}) => {
  const feedMessageMediaImages = feedMessage.media.filter(
    ({ videoUrl }) => !videoUrl,
  );
  const feedMessageDocuments = feedMessage.documents;

  const [emailSubject, setEmailSubject] = useState(feedMessage.defaultSubject);
  const [emailBody, setEmailBody] = useState(feedMessage.text);
  const [withAttachments, setWithAttachments] = useState(
    feedMessageMediaImages.length === 0 && feedMessageDocuments.length === 0
      ? false
      : undefined,
  );
  const [selectedMedia, setSelectedMedia] = useState([]);
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const totalAttachmentSize = useMemo(
    () =>
      sumBy(selectedMedia, ({ size }) => size) +
      sumBy(selectedDocuments, ({ size }) => size),
    [selectedMedia, selectedDocuments],
  );

  const [permissionsApproved, setPermissionsApproved] = useState(false);

  const [permissions, setPermissions] = useState({
    roles: feedMessage.permitted.roles,
    tags: feedMessage.permitted.tags,
    investmentEntities: feedMessage.permitted.investmentEntities,
    users: feedMessage.permitted.users,
    autoSelectedUsers: feedMessage.permitted.autoSelectedUsers,
    public: feedMessage.permitted.public,
  });

  const [filters, setFilters] = useState([
    { name: 'External', excluded: false },
    { name: 'Internal', excluded: true },
  ]);

  const getNotExcludedIds = (items) =>
    items.filter(({ excluded }) => !excluded).map(({ id }) => id);

  const calculateFinalUsersList = (newPermissions, filters) => {
    const currentRoleIds = getNotExcludedIds(newPermissions.roles);
    const currentTagIds = getNotExcludedIds(newPermissions.tags);
    const currentInvestmentEntitiesIds = getNotExcludedIds(
      newPermissions.investmentEntities,
    );

    const selectedByRole = (user) => currentRoleIds.includes(user.role.id);
    const selectedByTag = (user) =>
      currentTagIds.some((tagId) =>
        user.tags.map(({ id }) => id).includes(tagId),
      );

    const selectedByInvestmentEntity = (user) =>
      currentInvestmentEntitiesIds.some((investmentEntityId) =>
        user.investmentEntities.some(({ id }) => id === investmentEntityId),
      );
    const externalFilterIndex = filters.findIndex(
      ({ name }) => name === 'External',
    );
    const internalFilterIndex = filters.findIndex(
      ({ name }) => name === 'Internal',
    );

    let users = [...newPermissions.autoSelectedUsers, ...newPermissions.users];

    users = users.map((user) => {
      let newUser = { ...user };

      const withoutIndirectFilters =
        currentRoleIds.length === 0 &&
        currentTagIds.length === 0 &&
        currentInvestmentEntitiesIds.length === 0;

      newUser = {
        ...user,
        excluded:
          !withoutIndirectFilters &&
          !selectedByRole(user) &&
          !selectedByTag(user) &&
          !selectedByInvestmentEntity(user),
      };

      if (
        user.role.category === 'external' &&
        filters[externalFilterIndex].excluded
      ) {
        newUser = { ...user, excluded: true };
      }

      if (
        user.role.category === 'internal' &&
        filters[internalFilterIndex].excluded
      ) {
        newUser = { ...user, excluded: true };
      }

      return newUser;
    });

    return users;
  };

  const [finalUsersList, setFinalUsersList] = useState(
    calculateFinalUsersList(permissions, filters),
  );
  const [replyRecipients, setReplyRecipients] = useState(allReplyRecipients);
  const includedFinalUsersList = finalUsersList.filter(
    ({ excluded }) => !excluded,
  );

  const isValid =
    emailSubject &&
    emailBody &&
    ((withAttachments &&
      (selectedDocuments.length > 0 || selectedMedia.length > 0)) ||
      withAttachments === false) &&
    permissionsApproved &&
    includedFinalUsersList.length > 0 &&
    totalAttachmentSize < MAX_ATTACHMENT_SIZE;

  const onSubmit = (e) => {
    e.preventDefault();
    if (!isValid) return false;

    const replyRecipientIds = replyRecipients
      .filter((re) => !re.excluded)
      .map(({ id }) => id);

    const data = {
      feed_message_id: feedMessage.id,
      subject: emailSubject,
      message: emailBody,
      media_list: withAttachments ? selectedMedia.map(({ id }) => id) : [],
      email_documents_list: withAttachments
        ? selectedDocuments.map(({ id }) => id)
        : [],
      user_ids: includedFinalUsersList.map(({ id }) => id),
      reply_recipient_ids: replyRecipientIds,
    };

    http
      .post('/feed_message_emails', data)
      .then((res) => {
        if (res.ok) {
          toastr.success('Emails has been successfully sent');
        } else {
          toastr.error('Emails for this post have already been sent');
        }

        updateFeedMessagesList({
          item: { ...feedMessage, emailMessages: [{ events: [] }] },
        });
      })
      .finally(() => {
        setSendEmailModalOpened(false);
        setCurrentFeedMessage(undefined);
      });
  };

  const editorRef = useRef(null);

  return (
    <form onSubmit={onSubmit}>
      <div className="email-notification-step flex flex-grow flex-col">
        <div className="flex items-start">
          <FeedMessageFormStep
            stepNumber={1}
            isActive={emailSubject}
            isWarning={!emailSubject}
          />
          <div className="mb-[1.5rem] flex w-full flex-col">
            <h6 className="dark-60 vertical-align-middle feed-message-step-title header6-regular mb-m mt-xs">
              What will be the subject for the email notification?{' '}
              <span className="red">*</span>
            </h6>
            <Input
              onChange={(e) => setEmailSubject(e.target.value)}
              id="email_subject"
              defaultValue={emailSubject}
              placeholder="Enter Subject"
              name="email_subject"
              required
            />
          </div>
        </div>
        <div className="flex items-start">
          <FeedMessageFormStep
            stepNumber={2}
            isActive={emailBody}
            isWarning={!emailBody}
          />
          <div className="ck-container mb-[1.5rem] flex flex-col">
            <h6 className="dark-60 vertical-align-middle feed-message-step-title header6-regular mb-s mt-xs">
              What would you like to write about? <span className="red">*</span>
            </h6>
            <p className="light-60 inline-regular mb-xs mt-xs">
              {feedMessage.text !== emailBody && (
                <>
                  Content is different from the original message:{' '}
                  <button
                    type="button"
                    className="blue"
                    onClick={() => {
                      setEmailBody(feedMessage.text);

                      // TODO: FE-3915 - update instance of tiptap editor
                      editorRef.current
                        ?.querySelector('.ProseMirror')
                        ?.editor?.commands?.setContent(feedMessage.text);
                    }}
                  >
                    Fill the field with Message Content
                  </button>
                </>
              )}
              {feedMessage.text === emailBody &&
                'The content is the same as the original message'}
            </p>
            <TextEditor
              ref={editorRef}
              value={emailBody}
              onChange={(text) => setEmailBody(text)}
            />
          </div>
        </div>
        <div className="flex items-start">
          <FeedMessageFormStep
            stepNumber={3}
            isActive={
              (withAttachments &&
                totalAttachmentSize > 0 &&
                totalAttachmentSize < MAX_ATTACHMENT_SIZE) ||
              withAttachments === false
            }
            isWarning={
              withAttachments === undefined ||
              (withAttachments &&
                (totalAttachmentSize === 0 ||
                  totalAttachmentSize > MAX_ATTACHMENT_SIZE))
            }
          />
          <div className={cn('w-full', { 'mb-xl': !withAttachments })}>
            <h6 className="dark-60 vertical-align-middle feed-message-step-title header6-regular mb-s">
              Would you like to attach docs or media?{' '}
              <span className="red">*</span>
              <YesOrNo
                onYesClick={
                  feedMessageMediaImages.length === 0 &&
                  feedMessageDocuments.length === 0
                    ? undefined
                    : () => setWithAttachments(true)
                }
                onNoClick={() => setWithAttachments(false)}
                active={withAttachments}
              />
            </h6>
            <div className="flex flex-col gap-2">
              {!withAttachments &&
                feedMessageMediaImages.length === 0 &&
                feedMessageDocuments.length === 0 && (
                  <p className="inline-regular text-red">
                    This post has no attachments
                  </p>
                )}
              {withAttachments && (
                <>
                  {totalAttachmentSize > MAX_ATTACHMENT_SIZE && (
                    <p className="inline-regular text-red">
                      The combined size of the attachments exceeds the maximum
                      limit of 20MB
                    </p>
                  )}
                  <p className="inline-regular text-light-60">
                    Simply select the media files and documents to attach to the
                    email notification
                  </p>
                  {feedMessageMediaImages.length > 0 && (
                    <div className="mt-2">
                      <Checkbox
                        checked={
                          feedMessageMediaImages.length === selectedMedia.length
                        }
                        onChange={() => {
                          if (
                            selectedMedia.length ===
                            feedMessageMediaImages.length
                          ) {
                            setSelectedMedia([]);
                          } else {
                            setSelectedMedia(feedMessageMediaImages);
                          }
                        }}
                      >
                        {`${feedMessageMediaImages.length} media`}
                      </Checkbox>
                      <div className="flex flex-wrap">
                        {feedMessageMediaImages.map((media) => (
                          <MediaItem
                            key={media.id}
                            media={media}
                            selectedItems={selectedMedia}
                            actionsAlwaysVisible
                            size="s"
                            toggleSelected={() => {
                              setSelectedMedia(
                                selectedMedia
                                  .map(({ id }) => id)
                                  .includes(media.id)
                                  ? selectedMedia.filter(
                                      ({ id }) => id !== media.id,
                                    )
                                  : [...selectedMedia, media],
                              );
                            }}
                          />
                        ))}
                      </div>
                    </div>
                  )}

                  {feedMessageDocuments.length > 0 && (
                    <div className="my-2">
                      <Checkbox
                        checked={
                          feedMessageDocuments.length ===
                          selectedDocuments.length
                        }
                        onChange={() => {
                          if (
                            selectedDocuments.length ===
                            feedMessageDocuments.length
                          ) {
                            setSelectedDocuments([]);
                          } else {
                            setSelectedDocuments(feedMessageDocuments);
                          }
                        }}
                      >
                        {`${feedMessageDocuments.length} ${pluralize(
                          'document',
                          feedMessageDocuments.length,
                        )}`}
                      </Checkbox>
                      <div className="my-2">
                        {feedMessageDocuments.map((doc) => (
                          <div
                            key={doc.id}
                            className="mb-2 flex w-full items-center rounded-lg border border-light-10 bg-light px-4 py-2"
                          >
                            <Checkbox
                              checked={selectedDocuments
                                .map(({ id }) => id)
                                .includes(doc.id)}
                              labelClassName="self-center"
                              onChange={() => {
                                setSelectedDocuments(
                                  selectedDocuments
                                    .map(({ id }) => id)
                                    .includes(doc.id)
                                    ? selectedDocuments.filter(
                                        ({ id }) => id !== doc.id,
                                      )
                                    : [...selectedDocuments, doc],
                                );
                              }}
                            />
                            <DocumentItem
                              key={doc.id}
                              className="ml-2"
                              id={doc.id}
                              filename={doc.filename}
                              fileSize={doc.size}
                              fileExtension={doc.extension}
                              permissions={
                                <PermissionList
                                  permissions={doc.permitted}
                                  hideIcon
                                  mainListClasses="text-[0.75rem] text-light-90"
                                />
                              }
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
        <FeedMessageEmailRecipientsStep
          permissionsApproved={permissionsApproved}
          permissions={permissions}
          emailBody={emailBody}
          emailSubject={emailSubject}
          selectedDocuments={selectedDocuments}
          selectedMedia={selectedMedia}
          feedMessageId={feedMessage.id}
          replyRecipients={replyRecipients}
          setReplyRecipients={setReplyRecipients}
          setPermissions={setPermissions}
          finalUsersList={finalUsersList}
          setFinalUsersList={setFinalUsersList}
          calculateFinalUsersList={calculateFinalUsersList}
          filters={filters}
          setFilters={setFilters}
        />
        <div>
          <div className="flex items-start">
            <div className="mr-2" />
            <div className="mb-l">
              <Checkbox
                checked={permissionsApproved}
                onChange={() => setPermissionsApproved(!permissionsApproved)}
              >
                Everything looks right. All systems go!
              </Checkbox>
            </div>
          </div>

          <div className="flex flex-col items-center">
            <p className="light-60 label-regular mb-[0.75rem]">
              {isValid
                ? 'You can now send a email notification!'
                : 'Complete all required steps to send a email notification'}
            </p>
            <div
              id="submit-feed-message"
              className="submit-button-container w-full"
            >
              <Button
                variant="success"
                type="submit"
                fluid
                className={cn({ disabled: !isValid })}
              >
                Send Email Notification
              </Button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default EmailNotificationForm;

import type { EagleEyeReportBuilderWidgetTypes } from '@/entities/report/reportBuilder';
import type { ReportBuilderEagleEyeTemplateDto } from '@/entities/report/reportBuilder/api/settingsReportBuilderTemplatesGeneratedApi';
import {
  ReportingEagleEyeObjectsConfig,
  type ReportingEagleEyeObject,
} from '@/entities/reporting/config';
import {
  ROUTES_ROOT,
  type ExtractParams,
} from '@/shared/lib/hooks/useNavigation';
import { mapListToIds } from '@/shared/lib/listHelpers';
import { useParams } from '@reach/router';
import { uniq } from 'lodash-es';

export const useEagleEyeReportIdParam = () => {
  const params =
    useParams<
      ExtractParams<typeof ROUTES_ROOT.reports.eagleEye.report.fullPath>
    >();

  return params.reportId as string | undefined;
};

export const useEagleEyeReportGroupIdParam = () => {
  const { fullPath: ROUTE } = ROUTES_ROOT.reporting.eagleEye.reportGroup;
  const params = useParams<ExtractParams<typeof ROUTE>>();

  return params.reportGroupId;
};

export const isWidgetWithObjectsConfig = (
  widgetType: StringAndOptions<EagleEyeReportBuilderWidgetTypes>,
) =>
  (
    [
      'xy_chart_single_kpi',
      'kpi_table',
      'kpi_table_single_date',
      'global_lease_table',
      'kpi_card',
    ] satisfies EagleEyeReportBuilderWidgetTypes[]
  ).includes(widgetType as string);

export const getReportingEagleEyeObjectsState = (
  template: Pick<
    ReportBuilderEagleEyeTemplateDto,
    'assets' | 'excludedLegalEntities' | 'segments'
  >,
) => {
  const assetIds = mapListToIds(template.assets);
  const segmentIds = mapListToIds(template.segments ?? []);
  const excludedLEIds = mapListToIds(template.excludedLegalEntities ?? []);
  const legalEntityIds = uniq(
    mapListToIds(template.assets.flatMap((a) => a.legalEntities ?? [])),
  );

  return {
    assetIds,
    segmentIds,
    excludedLEIds,
    legalEntityIds,
  };
};
export const getReportingEagleEyeWidgetObjectsWidgetState = (
  objects: ReportingEagleEyeObject[] | null | undefined,
) => {
  if (objects == null) {
    return {};
  }
  const assetIds = [];
  const segmentIds = [];
  const excludedLEIds = [];

  for (const object of objects) {
    if (object.type === ReportingEagleEyeObjectsConfig.Asset) {
      assetIds.push(object.id);
      continue;
    }
    if (object.type === ReportingEagleEyeObjectsConfig.Segment) {
      segmentIds.push(object.id);
      continue;
    }
    if (object.type === ReportingEagleEyeObjectsConfig.ExcludedLegalEntity) {
      excludedLEIds.push(object.id);
      continue;
    }
  }
  return {
    assetIds,
    segmentIds,
    excludedLEIds,
  };
};

import { useWidgetFullScreen } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/WidgetFullScreen';
import { useAgGridRef, useLoadingOverlayEffect } from '@/lib/ag-grid/utils';
import { useModal } from '@/shared/lib/hooks/useModal';
import { ColDef, ColGroupDef, FilterChangedEvent } from 'ag-grid-community';
import { BasicAutoGroupCellRendererProps } from 'bundles/Shared/components/AgGrid/Table/cellComponents/AutoGroupCellRenderer';
import { ReportObjectDashboardSection } from 'bundles/Shared/entities/dashboard';
import { MasterUnitTableDto } from '@/shared/api/dashboardsGeneratedApi';
import {
  columnToColumnSettingsVizConfigKeyMatcher,
  DashboardWidgetCard,
  DashboardWidgetTableCard,
  DateWidgetState,
  DisplayedGroupsWidgetContext,
  DisplayedGroupsWidgetState,
  FiltersWidgetState,
  PaginationWidgetState,
  postProcessPopup,
  QueryWidgetState,
  useWidgetTableDefaultColDef,
  WIDGET_TABLE_AUTO_GROUP_COL_DEF,
  WidgetStateColumns,
  WidgetStateDate,
} from 'bundles/Shared/widgets/dashboard/widgets/common';
import { WidgetStateTablePagination } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/state/WidgetStateTablePagination';
import {
  WidgetTable,
  WidgetTablePlaceholder,
} from 'bundles/Shared/widgets/dashboard/widgets/common/ui/table/WidgetTable';
import {
  WidgetConfigProps,
  WidgetContextProps,
  WidgetProps,
  WidgetStateProps,
} from 'bundles/Shared/widgets/dashboard/widgets/model';
import { COL_DEF_OVERRIDES } from '@/bundles/Shared/widgets/dashboard/widgets/masterUnitTable/config';
import { LeaseDetailsModal } from '@/bundles/Shared/widgets/dashboard/widgets/masterUnitTable/ui/LeaseDetailsModal';
import { useMemo, useRef } from 'react';
import { UnknownRecord } from 'type-fest/source/internal';
import { useObjectDashboardWidgetTableExport } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/table/useTableWidgetExportFeature';
import { ObjectDashboardWidgetContext } from 'bundles/Shared/widgets/dashboard/widgetsHelpers';
import { WidgetStateSearchInput } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/state/WidgetStateSearchInput';
import { cn } from '@/shared/lib/css/cn';
import {
  ColDefBuilder,
  ColGroupDefBuilder,
  ColumnDefsBuilder,
  ExcelStyleBuilder,
} from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/table/ColumnDefsBuilder';
import { TableVizConfig } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/table/model';
import { useWidgetFlags } from '@/bundles/Shared/widgets/dashboard/widgets/common/lib/useWidgetFlags';
import {
  useKpiWidgetColumnVisibilityState,
  useWidgetTableColumnSize,
} from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/table/tableStateSync';

export type UnitTableWidgetState = PaginationWidgetState &
  DateWidgetState &
  QueryWidgetState &
  FiltersWidgetState &
  DisplayedGroupsWidgetState;

export function MasterUnitTableWidget(
  props: WidgetProps<MasterUnitTableDto, ReportObjectDashboardSection> &
    WidgetConfigProps<UnknownRecord> &
    WidgetStateProps<UnitTableWidgetState> &
    WidgetContextProps<
      ObjectDashboardWidgetContext & DisplayedGroupsWidgetContext
    >,
) {
  const {
    widgetSection,
    data,
    state,
    onStateChange,
    isLoading,
    isFetching,
    mode,
    context,
  } = props;
  const { shouldDisplayPlaceholder, shouldDisplayData } = useWidgetFlags(props);
  const { openModal } = useModal();
  const gridRef = useAgGridRef();
  const wrapperDivRef = useRef<HTMLDivElement>(null);

  useLoadingOverlayEffect({
    isLoading: isFetching,
    grid: gridRef.current,
  });

  const exportFeature = useObjectDashboardWidgetTableExport({
    mode,
    gridRef,
    widgetTitle: widgetSection.title,
    widgetId: widgetSection.id,
    state,
    context,
  });
  const widgetStateFullScreenFeature = useWidgetFullScreen(wrapperDivRef);
  const columnSizeFeature = useWidgetTableColumnSize({
    gridRef,
    state,
    onStateChange,
  });

  const columnVisibilityFeature = useKpiWidgetColumnVisibilityState({
    grid: gridRef.current,
    state,
    onStateChange,
  });

  const mappedData = useMemo(
    () =>
      data
        ? {
            ...data,
            rows: data.rows.map((row) => ({
              ...row,
              values: Object.fromEntries(
                row.values.map((v) => [v.key, v.value]),
              ),
            })),
          }
        : undefined,
    [data],
  );
  const columnDefsBuilder = useMemo(() => {
    const excelStyleBuilder = new ExcelStyleBuilder({
      vizConfig: widgetSection.widgetConfig.viz_config as TableVizConfig,
      mode,
    });
    const colDefBuilder = new ColDefBuilder({
      mode,
    });

    colDefBuilder.withOverride(({ columnSettings, column }) => ({
      valueGetter: (params) => {
        return params.data?.values[column?.key];
      },
      ...COL_DEF_OVERRIDES[columnSettings.key.toString()],
    }));
    return new ColumnDefsBuilder({
      mode,
      vizConfig: widgetSection.widgetConfig.viz_config as TableVizConfig,
      colGroupDefBuilder: new ColGroupDefBuilder({
        mode,
      }),
      colDefBuilder,
      excelStyleBuilder,
      columnMatcher: columnToColumnSettingsVizConfigKeyMatcher,
    });
  }, [
    JSON.stringify(data?.columns),
    widgetSection.widgetConfig.viz_config,
    JSON.stringify(data?.filterOptions),
  ]);

  const columnDefs = useMemo<(ColDef | ColGroupDef)[]>(() => {
    return columnDefsBuilder.build({
      columns: data?.columns ?? [],
      filterOptions: data?.filterOptions ?? [],
    });
  }, [columnDefsBuilder]);

  const defaultColDef = useWidgetTableDefaultColDef({
    mode,
  });
  const autoGroupColumnDef = useMemo<ColDef>(() => {
    return {
      ...WIDGET_TABLE_AUTO_GROUP_COL_DEF,
      onCellClicked: (params) => {
        const row = params.data as MasterUnitTableDto['rows'][number];
        openModal(LeaseDetailsModal, {
          unitId: row.unitId!,
          unitLabel: row.label,
        });
      },
      headerName: 'Unit',
      maxWidth: 100,
      cellRendererParams: {
        linkable: true,
      } satisfies BasicAutoGroupCellRendererProps,
      cellClass: exportFeature.autoGroupColumnDef.cellClass,
    };
  }, [exportFeature.autoGroupColumnDef.cellClass]);

  const handleFilterChanged = (e: FilterChangedEvent) => {
    const stateApplier = columnDefsBuilder.buildFilterChangeEventStateApplier({
      columns: data?.columns ?? [],
    });
    onStateChange({
      ...state,
      ...stateApplier(e),
    });
  };

  return (
    <DashboardWidgetTableCard
      {...props}
      className={cn(
        props.className,
        data?.rows && data?.rows.length <= 10
          ? 'min-h-[520px]'
          : 'min-h-[800px]',
      )}
      ref={wrapperDivRef}
    >
      <DashboardWidgetCard.Header>
        <DashboardWidgetCard.Header.Title>
          {widgetSection.title}
        </DashboardWidgetCard.Header.Title>

        <div className="grow" />
        {!widgetStateFullScreenFeature.isFullscreen &&
          Boolean(context?.columnVisibilityEnabled) && (
            <WidgetStateColumns
              onColumnSizeChange={columnSizeFeature.handleColumnSizeChange}
              state={state}
              groupsState={{
                groups: state.displayedGroups ?? [],
              }}
              onColumnStateChange={columnVisibilityFeature.onChange}
              vizConfig={
                widgetSection.widgetConfig.viz_config as TableVizConfig
              }
              columns={data?.columns ?? []}
            />
          )}
        <WidgetStateDate state={state} onStateChange={onStateChange} />
        <exportFeature.ExportButtonComponent />
        {mode !== 'pdf' && <widgetStateFullScreenFeature.IconButton />}
      </DashboardWidgetCard.Header>
      <div className="flex items-center bg-neutral-100 p-4">
        <WidgetStateTablePagination
          isLoading={isLoading ?? isFetching ?? false}
          state={state}
          onStateChange={onStateChange}
          totalSize={data?.totalSize ?? 0}
          includeTotalSizeInPerPage
        />
        <div className="grow" />
        <WidgetStateSearchInput
          state={state}
          onStateChange={onStateChange}
          suggestions={['unit label', 'current and future resident names']}
        />
      </div>
      {shouldDisplayPlaceholder && <WidgetTablePlaceholder />}
      {shouldDisplayData && (
        <WidgetTable
          ref={gridRef}
          mode={mode}
          rowData={mappedData?.rows}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          autoGroupColumnDef={autoGroupColumnDef}
          getRowId={({ data: row }) => row.key.toString()}
          getDataPath={(row) => [row.key.toString()]}
          domLayout="normal"
          defaultParams={{
            sizeColumnsToFit: false,
          }}
          excelStyles={columnDefsBuilder.buildExcelStyles()}
          postProcessPopup={postProcessPopup}
          onFilterChanged={handleFilterChanged}
        />
      )}
    </DashboardWidgetTableCard>
  );
}

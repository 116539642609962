import {
  useDeleteApiSettingsReportComparisonDashboardsByComparisonDashboardIdBoardsAndBoardIdMutation,
  useDeleteApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdMutation,
  useDeleteApiSettingsReportObjectDashboardsByObjectDashboardIdBoardsAndBoardIdMutation,
  usePostApiSettingsReportComparisonDashboardsByComparisonDashboardIdBoardsMutation,
  usePostApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsMutation,
  usePostApiSettingsReportObjectDashboardsByObjectDashboardIdBoardsMutation,
  usePutApiSettingsReportComparisonDashboardsByComparisonDashboardIdBoardsAndBoardIdMutation,
  usePutApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdMutation,
  usePutApiSettingsReportObjectDashboardsByObjectDashboardIdBoardsAndBoardIdMutation,
} from '@/shared/api/dashboardSettingsEnhancedApi';
import { useCallback, useMemo } from 'react';
import {
  EagleEyeBoard,
  ReportDashboardType,
  useDashboardContext,
  DashboardTypesExcludingReportBuilderTemplate,
} from 'bundles/Shared/entities/dashboard';
import { CreateBoardSchema } from 'bundles/Shared/features/dashboard/boardCRUD/config';
import { useModal } from '@/shared/lib/hooks/useModal';

export const useCreateBoard = () => {
  const { dashboardId, dashboardType } = useDashboardContext();

  const eagleEyeMutation =
    usePostApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsMutation();
  const objectMutation =
    usePostApiSettingsReportObjectDashboardsByObjectDashboardIdBoardsMutation();
  const comparisonMutation =
    usePostApiSettingsReportComparisonDashboardsByComparisonDashboardIdBoardsMutation();

  const [createBoard, options] = useMemo(() => {
    return {
      [ReportDashboardType.OBJECT]: objectMutation,
      [ReportDashboardType.EAGLE_EYE]: eagleEyeMutation,
      [ReportDashboardType.COMPARISON_MODE]: comparisonMutation,
    }[dashboardType as DashboardTypesExcludingReportBuilderTemplate];
  }, [objectMutation, eagleEyeMutation, comparisonMutation]);

  const createBoardHandler = useCallback(
    async ({ data }: { data: CreateBoardSchema }) => {
      const res = await createBoard({
        eagleEyeDashboardId: dashboardId,
        objectDashboardId: dashboardId,
        comparisonDashboardId: dashboardId,
        body: {
          name: data.name,
        },
      });
      if ('error' in res) {
        return null;
      }
      return res.data as unknown as EagleEyeBoard;
    },
    [createBoard, dashboardId],
  );

  return useMemo(
    () => [createBoardHandler, options] as const,
    [createBoardHandler, options],
  );
};

export const useUpdateBoard = () => {
  const { dashboardId, dashboardType } = useDashboardContext();
  const eagleEyeMutation =
    usePutApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdMutation();
  const objectMutation =
    usePutApiSettingsReportObjectDashboardsByObjectDashboardIdBoardsAndBoardIdMutation();
  const comparisonMutation =
    usePutApiSettingsReportComparisonDashboardsByComparisonDashboardIdBoardsAndBoardIdMutation();

  const [updateBoard, options] = useMemo(() => {
    return {
      [ReportDashboardType.OBJECT]: objectMutation,
      [ReportDashboardType.EAGLE_EYE]: eagleEyeMutation,
      [ReportDashboardType.COMPARISON_MODE]: comparisonMutation,
    }[dashboardType as DashboardTypesExcludingReportBuilderTemplate];
  }, [objectMutation, eagleEyeMutation, comparisonMutation]);

  const updateBoardHandler = useCallback(
    async ({ data }: { data: CreateBoardSchema }) => {
      const res = await updateBoard({
        eagleEyeDashboardId: dashboardId,
        objectDashboardId: dashboardId,
        comparisonDashboardId: dashboardId,
        boardId: data.id!,
        body: {
          name: data.name,
        },
      });
      if ('error' in res) {
        return null;
      }
      return res.data as unknown as EagleEyeBoard;
    },
    [updateBoard, dashboardId],
  );

  return useMemo(
    () => [updateBoardHandler, options] as const,
    [updateBoardHandler, options],
  );
};

export const useDeleteBoard = () => {
  const { dashboardId, dashboardType } = useDashboardContext();
  const { confirm } = useModal();

  const objectMutation =
    useDeleteApiSettingsReportObjectDashboardsByObjectDashboardIdBoardsAndBoardIdMutation();
  const eagleEyeMutation =
    useDeleteApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdMutation();
  const comparisonMutation =
    useDeleteApiSettingsReportComparisonDashboardsByComparisonDashboardIdBoardsAndBoardIdMutation();

  const [deleteBoard, options] = useMemo(() => {
    return {
      [ReportDashboardType.OBJECT]: objectMutation,
      [ReportDashboardType.EAGLE_EYE]: eagleEyeMutation,
      [ReportDashboardType.COMPARISON_MODE]: comparisonMutation,
    }[dashboardType as DashboardTypesExcludingReportBuilderTemplate];
  }, [objectMutation, eagleEyeMutation, comparisonMutation]);

  const deleteBoardHandler = useCallback(
    async ({ boardId }: { boardId: EagleEyeBoard['id'] }) => {
      const res = await confirm({
        title: 'Delete Board',
        subtitle: 'Are you sure you want to delete this board?',
      });
      if (!res) {
        return;
      }
      return await deleteBoard({
        eagleEyeDashboardId: dashboardId,
        objectDashboardId: dashboardId,
        comparisonDashboardId: dashboardId,
        boardId,
      });
    },
    [deleteBoard, dashboardId],
  );

  return useMemo(
    () => [deleteBoardHandler, options] as const,
    [deleteBoardHandler, options],
  );
};

import Button from '@/stories/Button/Button';
import type { ComponentProps } from 'react';

const Separator = () => <div className="h-[24px] w-[1px] bg-neutral-100" />;

// TODO refactor like this https://mantine.dev/core/button/#buttongroup
export const ButtonGroup = ({
  size,
  hasDot,
  items,
}: Pick<ComponentProps<typeof Button>, 'size' | 'hasDot'> & {
  items: Omit<ComponentProps<typeof Button>, 'size' | 'hasDot'>[];
}) => {
  const buttonsVariant = 'group-secondary';

  return (
    <Button className="gap-0 p-0" size={size}>
      {items.map((item, idx, arr) => (
        <>
          <Button
            size={size}
            hasDot={arr.length - 1 === idx && hasDot}
            variant={buttonsVariant}
            key={idx}
            {...item}
          />
          {arr.length - 1 !== idx && <Separator />}
        </>
      ))}
    </Button>
  );
};

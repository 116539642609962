import { getReckonerPeriodByPeriodTypeAndDate } from '@/bundles/Shared/widgets/dashboard/widgets/common/lib/utils';
import { FinancialTableSinglePeriodPeriodType } from '@/bundles/Shared/widgets/dashboard/widgets/financialTableSingePeriod/model';
import { cn } from '@/shared/lib/css/cn';
import { SelectableMultilineButton } from '@/stories/SelectableMultilineButton/SelectableMultilineButton';
import { PeriodTypeWidgetState } from 'bundles/Shared/widgets/dashboard/widgets/common';
import {
  AVAILABLE_PERIOD_TYPES,
  AVAILABLE_PERIOD_TYPES_OPTIONS,
} from 'bundles/Shared/widgets/dashboard/widgets/common/config';
import { WidgetStateProps } from 'bundles/Shared/widgets/dashboard/widgets/model';

export function WidgetStateReckonerPeriodType({
  state,
  onStateChange,
  periodTypes,
  className,
}: WidgetStateProps<PeriodTypeWidgetState> &
  PropsWithClassName & {
    periodTypes: FinancialTableSinglePeriodPeriodType[];
  }) {
  if (
    periodTypes.length <= 1 &&
    periodTypes[0] === AVAILABLE_PERIOD_TYPES.MONTH
  ) {
    return null;
  }

  const handleChange = (
    value: (typeof AVAILABLE_PERIOD_TYPES_OPTIONS)[number]['value'],
  ) => {
    onStateChange({
      period: getReckonerPeriodByPeriodTypeAndDate(
        value,
        state.period.date ?? state.period.to_date ?? state.period.last_date,
      ),
    });
  };

  return (
    <div className={cn('flex flex-wrap gap-1', className)}>
      {AVAILABLE_PERIOD_TYPES_OPTIONS.filter((o) =>
        periodTypes.includes(o.value),
      ).map((o) => (
        <SelectableMultilineButton
          selected={state.period.type === o.value}
          title={o.label}
          className="h-[32px] w-[initial] flex-[initial] py-1"
          key={o.value}
          onClick={() => {
            handleChange(o.value);
          }}
        />
      ))}
    </div>
  );
}

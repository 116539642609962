import { useDashboardContext } from 'bundles/Shared/entities/dashboard';
import { useGetApiSettingsReportObjectDashboardsByIdQuery } from '@/shared/api/dashboardSettingsEnhancedApi';
import { ReportDashboardAsset } from '@/bundles/Shared/entities/dashboard';
import {
  ReportDashboardAssetFilter,
  ReportDashboardAssetFilterPropsProps,
} from '@/bundles/Shared/features/dashboard/object/filter/byAsset/ui/ReportDashboardAssetFilter';

export const ObjectDashboardAssetFilter = <Path extends string>(
  props: Omit<ReportDashboardAssetFilterPropsProps<Path>, 'assets'>,
) => {
  const { dashboardId } = useDashboardContext();
  const { data } = useGetApiSettingsReportObjectDashboardsByIdQuery({
    id: dashboardId,
  });

  return (
    <ReportDashboardAssetFilter
      assets={(data?.assets ?? []) as unknown as ReportDashboardAsset[]}
      {...props}
    />
  );
};

import React, { useRef } from 'react';
import { ICapitalBreakdownEntity } from '@/pages/portfolio/widgets/Dashboard/types';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themesAnimated from '@amcharts/amcharts5/themes/Animated';
import { useAmchart } from 'lib/amcharts/useAmchart';
import { capitalize, uniqBy } from 'lodash-es';
import {
  addCenteredLegend,
  addCursor,
  COLOR_SET,
  createXYChart,
  getReturnDashboardTheme,
  getSeriesColor,
  hideGrid,
} from 'lib/amcharts/utils';
import useMediaQuery, {
  MEDIUM_WIDTH_MEDIA_QUERY,
} from '@/shared/lib/hooks/useMediaQuery';
import { getCssVariableByAccessor } from 'lib/helpers';
import { CssVar } from '@/shared/config/cssVar';
import { DashboardWidgetCard } from 'bundles/Shared/widgets/dashboard/widgets/common';
import { getAmchartNumberFormatByDisplayOptions } from '@/shared/lib/formatting/charts';

interface Props {
  data: ICapitalBreakdownEntity[];
}

const ABBREVIATED_FORMATTER = getAmchartNumberFormatByDisplayOptions(
  {
    type: 'currency',
    precision: 0,
  },
  {
    abbreviate: true,
  },
);

const NOT_ABBREVIATED_FORMATTER = getAmchartNumberFormatByDisplayOptions(
  {
    type: 'currency',
    precision: 0,
  },
  {
    abbreviate: false,
  },
);

const COLUMN_BAR_HEIGHT = 60;

function CapitalBreakdownCard({ data }: Props) {
  const ref = useRef(null);
  const isMediumWidth = useMediaQuery(MEDIUM_WIDTH_MEDIA_QUERY);

  useAmchart(
    ref,
    (root) => {
      // can't use name in amcharts
      const mappedData = data.map(({ name, ...item }) => ({
        assetName: name,
        ...item,
      }));
      const myTheme = getReturnDashboardTheme(root);
      myTheme.rule('ColorSet').set('colors', COLOR_SET);

      hideGrid(myTheme);

      root.setThemes([am5themesAnimated.new(root), myTheme]);

      const chart = createXYChart(root, {});

      root.numberFormatter.setAll({
        numberFormat: ABBREVIATED_FORMATTER,
      });

      const scrollableContainer = chart.chartContainer.children.unshift(
        am5.Container.new(root, {
          width: am5.p100,
          height: am5.p100,
          verticalScrollbar: am5.Scrollbar.new(root, {
            orientation: 'vertical',
            dx: 20,
          }),
        }),
      );

      chart.yAxesAndPlotContainer.set(
        'height',
        COLUMN_BAR_HEIGHT * uniqBy(mappedData, 'assetName').length,
      );
      scrollableContainer.children.push(chart.yAxesAndPlotContainer);

      const yRenderer = am5xy.AxisRendererY.new(root, {
        cellStartLocation: 0.2,
        cellEndLocation: 0.8,
      });

      yRenderer.labels.template.setAll({
        fontWeight: '700',
        paddingRight: 10,
        maxWidth: 100,
        oversizedBehavior: 'truncate',
        tooltipText: '{category}',
      });
      const yAxis = chart.yAxes.push(
        am5xy.CategoryAxis.new(root, {
          categoryField: 'assetName',
          renderer: yRenderer,
        }),
      );
      yAxis.labelsContainer.set(
        'tooltip',
        am5.Tooltip.new(root, {
          pointerOrientation: 'down',
        }),
      );

      yRenderer.labels.template.setup = function (target) {
        target.set(
          'background',
          am5.Rectangle.new(root, {
            fill: am5.color(0x000000),
            fillOpacity: 0,
          }),
        );
      };

      yAxis.data.setAll(mappedData);

      const xAxisRenderer = am5xy.AxisRendererX.new(root, {});
      xAxisRenderer.labels.template.setAll({
        minPosition: 0,
        maxPosition: 0.95,
      });
      const xAxis = chart.xAxes.push(
        am5xy.ValueAxis.new(root, {
          renderer: xAxisRenderer,
        }),
      );

      function createSeries(field: 'contributed' | 'distributed') {
        const series = chart.series.push(
          am5xy.ColumnSeries.new(root, {
            name: capitalize(field),
            xAxis,
            yAxis,
            valueXField: field,
            categoryYField: 'assetName',
            fill: getSeriesColor(field),
          }),
        );

        series.columns.template.setAll({
          height: am5.p100,
          tooltipText: `{name}: {valueX.formatNumber('${NOT_ABBREVIATED_FORMATTER}')}`,
        });

        series.bullets.push(() => {
          const label = am5.Label.new(root, {
            text: '{valueX}',
            centerY: am5.percent(50),
            populateText: true,
          });

          label.adapters.add('centerX', (_, target) => {
            if (target.bounds().left > target.parent!.innerWidth() / 2) {
              return am5.p100;
            }
            return am5.p0;
          });
          label.adapters.add('fill', (_, target) => {
            if (target.bounds().left > target.parent!.innerWidth() / 2) {
              return am5.color(getCssVariableByAccessor(CssVar.neutral000));
            }
            return am5.color(getCssVariableByAccessor(CssVar.neutral999));
          });
          return am5.Bullet.new(root, {
            locationX: 1,
            sprite: label,
          });
        });

        series.data.setAll(mappedData);
      }

      createSeries('distributed');
      createSeries('contributed');

      const cursor = addCursor(root, chart, {});
      cursor.lineY.set('forceHidden', true);
      cursor.lineX.set('forceHidden', true);

      const legend = addCenteredLegend(root, chart, {
        centerY: isMediumWidth ? 20 : 40,
        height: 50,
        layout: am5.GridLayout.new(root, {
          fixedWidthGrid: true,
          maxColumns: 2,
        }),
      });
      legend.labels.template.setAll({
        oversizedBehavior: 'truncate',
        maxWidth: 150,
      });
    },
    [data],
  );

  return (
    <DashboardWidgetCard mode="view">
      <DashboardWidgetCard.Header>
        <DashboardWidgetCard.Header.Title>
          Capital Breakdown
        </DashboardWidgetCard.Header.Title>
      </DashboardWidgetCard.Header>
      <div className="m-4 mt-0 h-[400px]" ref={ref} />
    </DashboardWidgetCard>
  );
}

export default CapitalBreakdownCard;

import {
  ColumSizeWidgetState,
  DisplayedGroupsWidgetState,
  handleOnRowDataUpdatedAutoSizeAllColumns,
  sleepUntilWidgetStateIsChanged,
} from 'bundles/Shared/widgets/dashboard/widgets/common';
import { RefObject, useCallback, useEffect, useMemo } from 'react';
import { useAgGridApplyColumnStateEffect } from 'lib/ag-grid/useAgGridApplyColumnStateEffect';
import { FinancialTableSingeDateWidgetNS } from 'bundles/Shared/widgets/dashboard/widgets/financialTableSingeDate/model';
import {
  COL_DEF_ID,
  parseColDefId,
  stringifyColDefId,
} from 'bundles/Shared/widgets/dashboard/widgets/common/financialTable/lib';
import { FirstDataRenderedEvent, RowDataUpdatedEvent } from 'ag-grid-community';
import { AgGridReactRef } from 'lib/ag-grid/types';
import { DEFAULT_AUTO_SIZE_ALL_COLUMNS_SKIP_HEADER } from 'bundles/Shared/widgets/dashboard/widgets/financialTableSingeDate/config/config';
import { TableVizConfig } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/table/model';
import { usePrevious } from '@/shared/lib/hooks/usePrevious';
import { WidgetViewMode } from '@/bundles/Shared/widgets/dashboard/widgets/model';

const onDisplayedColumnsHiddenChange = (groupId: string, colId?: string) => {
  return (displayedGroups: DisplayedGroupsWidgetState['displayedGroups']) =>
    (displayedGroups ?? []).map((g) => {
      if (g.id !== groupId) return g;

      if (colId != null) {
        const children =
          g.children?.map((i) => {
            if (i.id !== colId) return i;
            return { ...i, hide: !i.hide };
          }) ?? [];

        return {
          ...g,
          children,
          hide: children.every((i) => i.hide),
        };
      }

      return {
        ...g,
        hide: !g.hide,
        children: g.children?.map((i) => ({ ...i, hide: !g.hide })),
      };
    });
};
// TODO: FE-3636 merge with src/bundles/Shared/entities/dashboard/model/slices/shared.ts
export const useKpiWidgetColumnVisibilityState = <
  S extends DisplayedGroupsWidgetState,
>({
  grid,
  state,
  onStateChange,
}: {
  grid: AgGridReactRef | null;
  state: S;
  onStateChange: (state: S) => void;
}) => {
  const columnState = useMemo(() => {
    return state.displayedGroups?.flatMap((g) => [
      ...(g.children?.flatMap((c) =>
        c.allColDefIds.map((colDefId) => ({
          colId: colDefId,
          sort: c.viz_config?.initial_sort ?? null,
          hide: c.hide,
        })),
      ) ?? []),
    ]);
  }, [state.displayedGroups]);

  useAgGridApplyColumnStateEffect({
    grid,
    columnState,
  });

  const onChange = (groupId: string, colId?: string) => {
    onStateChange({
      ...state,
      displayedGroups: onDisplayedColumnsHiddenChange(
        groupId,
        colId,
      )(state.displayedGroups),
    });
  };

  return {
    onChange,
  };
};
// TODO: FE-3636 merge with src/bundles/Shared/entities/dashboard/model/slices/shared.ts
export const useFinancialWidgetColumnsVisibilityState = ({
  data,
  grid,
  state,
  onStateChange,
}: {
  data: FinancialTableSingeDateWidgetNS.SnapshotData | undefined;
  grid: AgGridReactRef | null;
  state: DisplayedGroupsWidgetState;
  onStateChange: (s: DisplayedGroupsWidgetState) => void;
}) => {
  const columnState = useMemo(() => {
    if (data?.groups == null) return [];
    return (state.displayedGroups ?? []).flatMap((group) => {
      return (group.children ?? []).flatMap((column) => {
        return data.groups.flatMap((objectGroup) => {
          const parsedColDefId = parseColDefId(column.allColDefIds[0]);
          // we recieve total group in the object level, but don't render it, so no need to pass objectId
          if (objectGroup.type === 'total')
            return {
              colId: stringifyColDefId({
                groupId: parsedColDefId[COL_DEF_ID.GroupIdKey],
                columnId: parsedColDefId[COL_DEF_ID.ColumnIdKey],
              }),
              hide: column.hide,
            };
          return {
            colId: stringifyColDefId({
              objectId: String(objectGroup.key),
              groupId: parsedColDefId[COL_DEF_ID.GroupIdKey],
              columnId: parsedColDefId[COL_DEF_ID.ColumnIdKey],
            }),
            hide: column.hide,
          };
        });
      });
    });
  }, [state.displayedGroups, data?.groups]);

  const onFirstDataRendered = useCallback(
    (e: FirstDataRenderedEvent) => {
      e.api.applyColumnState({
        state: columnState,
      });
    },
    [columnState, grid],
  );

  const onChange = (groupId: string, colId?: string) => {
    onStateChange({
      ...state,
      displayedGroups: onDisplayedColumnsHiddenChange(
        groupId,
        colId,
      )(state.displayedGroups),
    });
  };

  useAgGridApplyColumnStateEffect({
    grid,
    columnState,
  });

  return {
    onChange,
    onFirstDataRendered,
  };
};

export type WidgetTableColumSize = null | 'default' | 'compact';
export type WidgetTableColumnSizeChangeHandler = (
  c: WidgetTableColumSize,
) => void;
export const useWidgetTableColumnSize = ({
  gridRef,
  state,
  onStateChange,
}: {
  gridRef: RefObject<AgGridReactRef>;
  state: ColumSizeWidgetState;
  onStateChange: (s: ColumSizeWidgetState) => void;
}) => {
  const handleColumnSizeChange = async (
    selectedColumnSize: WidgetTableColumSize,
  ) => {
    switch (selectedColumnSize) {
      case 'compact': {
        onStateChange({ ...state, selectedColumnSize: 'compact' });

        await sleepUntilWidgetStateIsChanged();

        gridRef.current?.api.autoSizeAllColumns(
          DEFAULT_AUTO_SIZE_ALL_COLUMNS_SKIP_HEADER,
        );
        break;
      }

      case 'default':
      default: {
        onStateChange({ ...state, selectedColumnSize: 'default' });

        await sleepUntilWidgetStateIsChanged();

        gridRef.current?.api.sizeColumnsToFit();
        break;
      }
    }
  };

  const handleRowDataUpdated = (event: RowDataUpdatedEvent) => {
    if (state.selectedColumnSize !== 'compact') {
      event.api.sizeColumnsToFit();
      return;
    }

    handleOnRowDataUpdatedAutoSizeAllColumns({
      event,
      skipHeader: DEFAULT_AUTO_SIZE_ALL_COLUMNS_SKIP_HEADER,
    });
  };

  return {
    handleColumnSizeChange,
    handleRowDataUpdated,
  };
};

// TODO: merge with column visibility state to sync grid state in one place
export const useWidgetTableVizConfigUpdateEffect = ({
  grid,
  vizConfig,
  mode,
}: {
  grid: AgGridReactRef | null;
  vizConfig: TableVizConfig;
  mode: WidgetViewMode;
}) => {
  const prevVizConfig = usePrevious(vizConfig);

  useEffect(() => {
    if (mode !== 'edit') {
      return;
    }
    // when column type changes, we need to redraw the rows to make sure the new column is in updated state
    // for example sparkline column type by default doesn't react to column config change
    if (prevVizConfig !== vizConfig) {
      grid?.api.redrawRows();
    }
  }, [vizConfig]);
};

import { DialogProps } from '@/shared/lib/hooks/useModal';
import { mapListToIds } from '@/shared/lib/listHelpers';
import { Field } from '@/stories/Field/Field';
import { Input } from '@/stories/FormControls/Inputs/Input/Input';
import {
  DASHBOARD_OBJECT_TYPES,
  DashboardFilterObjectTypes,
  useReportEagleEyeDashboardFiltersObjectQuery,
} from 'bundles/Shared/entities/dashboard';
import {
  ObjectSelection,
  useSetDashboardObjectsEffect,
} from 'bundles/Shared/features/dashboard/create';
import { EagleEyeDashboardLegalEntitiesField } from 'bundles/Shared/features/dashboard/create/ui/LegalEntitiesField';
import {
  DEFAULT_DASHBOARD_FILTER_SET_FORM_VALUES,
  transformFilterSetToForm,
} from 'bundles/Shared/features/dashboard/filter/filterSet/lib';
import {
  useDeleteApiSettingsReportEagleEyeDashboardsFilterSetsByIdMutation,
  usePostApiSettingsReportEagleEyeDashboardsFilterSetsMutation,
  usePutApiSettingsReportEagleEyeDashboardsFilterSetsByIdMutation,
} from '@/shared/api/dashboardSettingsFilterSetsEnhancedApi';
import { FilterSetDto } from '@/shared/api/dashboardsSettingsGeneratedApi';
import { EditPanelActions } from 'bundles/Shared/widgets/dashboard/widgets/common';
import { useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FieldsContainer } from 'stories/Field/Field';
import { SectionField } from 'stories/Field/FieldsWrappers';
import { SidePanel } from 'stories/Modals/Modal/Modal';

interface Props extends DialogProps {
  filterSet?: FilterSetDto;
}

export function DashboardFilterSetForm({ filterSet, onClose }: Props) {
  const methods = useForm({
    defaultValues: {
      ...(filterSet
        ? transformFilterSetToForm(filterSet)
        : DEFAULT_DASHBOARD_FILTER_SET_FORM_VALUES),
    },
  });
  const { register, control, handleSubmit } = methods;
  const { objects } = useReportEagleEyeDashboardFiltersObjectQuery();
  const [createFilterSet] =
    usePostApiSettingsReportEagleEyeDashboardsFilterSetsMutation();
  const [updateFilterSet] =
    usePutApiSettingsReportEagleEyeDashboardsFilterSetsByIdMutation();
  const [removeFilterSet] =
    useDeleteApiSettingsReportEagleEyeDashboardsFilterSetsByIdMutation();
  const mutate = filterSet ? updateFilterSet : createFilterSet;
  const onSubmit = handleSubmit(async (data) => {
    await mutate({
      id: filterSet?.id,
      body: {
        name: data.name!,
        asset_ids: data.asset_ids ?? [],
        segment_ids: data.segment_ids ?? [],
        excluded_legal_entity_ids: data.excluded_legal_entity_ids ?? [],
      },
    });
    onClose();
  });

  const objectIds = useMemo(
    () => ({
      assetIds: mapListToIds(objects.filter((o) => o.type === 'asset')),
      segmentIds: mapListToIds(objects.filter((o) => o.type === 'segment')),
      isNew: !filterSet,
    }),
    [objects],
  );

  useSetDashboardObjectsEffect(objectIds, methods);

  return (
    <FormProvider {...methods}>
      <SidePanel
        actions={
          <EditPanelActions
            type={filterSet ? 'edit' : 'create'}
            onClose={onClose}
            onRemove={async () => {
              if (filterSet) {
                await removeFilterSet({ id: filterSet.id });
                onClose();
              }
            }}
            onSubmit={onSubmit}
            hideRepeat
          />
        }
        toggle={onClose}
        header="Filter Set"
      >
        <FieldsContainer>
          <Field labelText="Name">
            <Input {...register('name')} />
          </Field>
          <SectionField
            labelText="Select an object"
            required
            classes={{ itemsContainer: 'gap-4' }}
          >
            <ObjectSelection.Container>
              {DASHBOARD_OBJECT_TYPES.map((type) => (
                <ObjectSelection.Item
                  key={type}
                  type={type as DashboardFilterObjectTypes}
                  objects={objects.filter((o) => o.type === type)}
                  control={control}
                  isImplemented={type !== 'fund'}
                />
              ))}
            </ObjectSelection.Container>
            <EagleEyeDashboardLegalEntitiesField />
          </SectionField>
        </FieldsContainer>
      </SidePanel>
    </FormProvider>
  );
}

import React, { useRef } from 'react';
import * as am5 from '@amcharts/amcharts5';
import * as am5hierarchy from '@amcharts/amcharts5/hierarchy';
import am5themesAnimated from '@amcharts/amcharts5/themes/Animated';

import { IContributionOverviewEntity } from '@/pages/portfolio/widgets/Dashboard/types';
import { useAmchart } from 'lib/amcharts/useAmchart';
import { COLOR_SET, getReturnDashboardTheme } from 'lib/amcharts/utils';
import { DashboardWidgetCard } from 'bundles/Shared/widgets/dashboard/widgets/common';
import { getCurrencyTooltipFormat } from '@/shared/lib/formatting/charts';

interface Props {
  data: IContributionOverviewEntity[];
}

const LABEL_LEFT_PADDING = 12;

function applyCornerRadius(series: am5hierarchy.Treemap) {
  const CORNER_RADIUS = 8;
  series.rectangles.template.setAll({
    cornerRadiusTL: CORNER_RADIUS,
    cornerRadiusTR: CORNER_RADIUS,
    cornerRadiusBL: CORNER_RADIUS,
    cornerRadiusBR: CORNER_RADIUS,
    strokeOpacity: 0,
  });
}

function applyLabelTrancate(series: am5hierarchy.Treemap) {
  // https://github.com/amcharts/amcharts5/blob/master/src/.internal/charts/hierarchy/Treemap.ts
  series.labels.template.onPrivate('maxWidth', (value, target) => {
    if (!target) {
      return;
    }
    const { dataItem } = target;
    const label = dataItem!.get('label');
    const rectangle = dataItem!.get('rectangle');
    label.setPrivateRaw('maxWidth', rectangle.width() - LABEL_LEFT_PADDING * 2);
  });
}

function ContributionOverviewCard({ data }: Props) {
  const ref = useRef(null);
  useAmchart(
    ref,
    (root) => {
      const myTheme = getReturnDashboardTheme(root);
      myTheme.rule('ColorSet').set('colors', COLOR_SET);

      root.setThemes([am5themesAnimated.new(root), myTheme]);

      const container = root.container.children.push(
        am5.Container.new(root, {
          width: am5.percent(100),
          height: am5.percent(100),
          layout: root.verticalLayout,
        }),
      );

      // Create series and set data
      const series = container.children.push(
        am5hierarchy.Treemap.new(root, {
          valueField: 'value',
          categoryField: 'name',
          childDataField: 'children',
          downDepth: 1,
          initialDepth: 2,
          topDepth: 1,
          layoutAlgorithm: 'squarify',
          nodePaddingInner: 2,
          tooltip: am5.Tooltip.new(root, {
            labelText: `{category}: ${getCurrencyTooltipFormat('value')}`,
          }),
        }),
      );

      applyCornerRadius(series);

      series.labels.template.setAll({
        fontSize: 12,
        fontWeight: '700',
        text: '{category}',
        textAlign: 'start',
        centerX: 0,
        dy: -20,
        x: LABEL_LEFT_PADDING,
        y: am5.percent(100),
        oversizedBehavior: 'truncate',
        ellipsis: '...',
      });
      applyLabelTrancate(series);

      series.data.setAll([
        {
          name: 'Root',
          children: data.map((r) => ({
            ...r,
            value: r.value,
          })),
        },
      ]);
    },
    [data],
  );

  return (
    <DashboardWidgetCard>
      <DashboardWidgetCard.Header>
        <DashboardWidgetCard.Header.Title>
          Contribution Overview
        </DashboardWidgetCard.Header.Title>
      </DashboardWidgetCard.Header>
      <div className="m-4 mt-0 h-[400px]" ref={ref} />
    </DashboardWidgetCard>
  );
}

export default ContributionOverviewCard;

import {
  getSelectorReportBuilderTemplateState,
  getSelectorReportBuilderTemplateWidgetState,
} from '@/bundles/Shared/entities/dashboard/model/slices/reportBuilderTemplatesSlice';
import {
  useIncludedLegalEntities,
  useSelectedAssets,
} from '@/bundles/Shared/features/dashboard/create';
import { useExcludeLegalEntityHandlers } from '@/bundles/Shared/features/dashboard/create/ui/LegalEntitiesField';
import { LegalEntitiesSelectionModal } from '@/bundles/Shared/features/dashboard/create/ui/LegalEntitiesSelectionModal';
import { ObjectSelectionModal } from '@/bundles/Shared/features/dashboard/create/ui/ObjectSelectionModal';
import {
  useReportingWidgetGroupIdContext,
  useReportingWidgetIdContext,
} from '@/bundles/Shared/widgets/dashboard/widgets/common/lib/reportBuilderTemplateContext';
import { useReportBuilderTemplateWidgetContext } from '@/bundles/Shared/widgets/dashboard/widgets/common/lib/reportBuilderTemplateWidgetContext';
import {
  reportWidgetsSliceActions,
  selectWidgetById,
} from '@/entities/report/reportBuilder';
import {
  usePutApiSettingsReportBuilderEagleEyeTemplatesByEagleEyeTemplateIdGroupsAndGroupIdWidgetSectionsIdMutation,
  usePutApiSettingsReportBuilderTemplatesByTemplateIdGroupsAndGroupIdWidgetSectionsIdMutation,
} from '@/entities/report/reportBuilder/api/settingsReportBuilderTemplatesEnhancedApi';
import { useReportBuilderEagleEyeTemplateMeta } from '@/entities/report/reportBuilder/eagleEye/lib';
import { useReportBuilderWidgetEagleEyeObjectSettingsForm } from '@/entities/report/reportBuilder/lib/reportBuilderTemplateEagleEyeObjectSettingsConfig';
import { ObjectCounter } from '@/entities/report/reportBuilder/ui/ObjectFilterOptionWithCounters';
import { ReportingEagleEyeObjectsConfig } from '@/entities/reporting/config';
import { useReportingEntityIdContext } from '@/entities/reporting/context/entityId';
import { useReportingEntityKindContext } from '@/entities/reporting/context/entityKind';
import { useReportingEntityStageContext } from '@/entities/reporting/context/entityStage';
import { getReportingEagleEyeWidgetObjectsWidgetState } from '@/entities/reporting/lib/eagleEye';
import { useAppDispatch, useAppSelector } from '@/shared/lib/hooks/redux';
import { useFormOnSetValue } from '@/shared/lib/reactHookForm/lib';
import { GrowDiv } from '@/shared/ui/GrowDiv';
import { Button } from '@/stories/Button/Button';
import { ButtonGroup } from '@/stories/Button/ButtonGroup';
import { omit } from 'lodash-es';
import { useMemo, useState, type ComponentProps } from 'react';
import { FormProvider } from 'react-hook-form';
import type { UnknownRecord } from 'type-fest';

// entities/reporting/lib
const useReportingWidgetState = () => {
  const entityStage = useReportingEntityStageContext();
  const entityId = useReportingEntityIdContext();
  const groupId = useReportingWidgetGroupIdContext();
  const widgetId = useReportingWidgetIdContext();

  if (entityStage === 'report') {
    const widget = useAppSelector(selectWidgetById(widgetId));
    return widget?.context ?? {};
  }

  return useAppSelector(
    getSelectorReportBuilderTemplateWidgetState(entityId, groupId, widgetId),
  );
};

// entities/reporting/lib
const useReportingEntityState = () => {
  const entityStage = useReportingEntityStageContext();
  const entityId = useReportingEntityIdContext();

  if (entityStage === 'report') {
    return useAppSelector((state) => state.reportWidgets);
  }

  return useAppSelector(getSelectorReportBuilderTemplateState(entityId));
};

const useUpdateReportingWidget = () => {
  const entityStage = useReportingEntityStageContext();
  const entityId = useReportingEntityIdContext();
  const entityKind = useReportingEntityKindContext();
  const widgetId = useReportingWidgetIdContext();
  const groupId = useReportingWidgetGroupIdContext();
  const dispatch = useAppDispatch();

  const updateTemplateWidget =
    usePutApiSettingsReportBuilderTemplatesByTemplateIdGroupsAndGroupIdWidgetSectionsIdMutation();
  const updateEagleEyeTemplateWidget =
    usePutApiSettingsReportBuilderEagleEyeTemplatesByEagleEyeTemplateIdGroupsAndGroupIdWidgetSectionsIdMutation();

  return useMemo(() => {
    // TODO create "Reporting" helpers to determine which "Variant" of entity it's
    if (entityStage === 'report') {
      if (entityKind === 'eagle_eye') {
        return [
          (widgetConfig: UnknownRecord) => {
            dispatch(
              reportWidgetsSliceActions.updateWidgetContext({
                context: {
                  ...getReportingEagleEyeWidgetObjectsWidgetState(
                    widgetConfig.default_options?.objects,
                  ),
                },
                widgetGroupId: groupId,
                widgetId,
              }),
            );
            // TODO Refactor into generic response for rtk dispatch action and rtk mutation action

            return {
              data: {
                defaultOptions: {
                  objects: widgetConfig.default_options?.objects,
                },
              },
            };
          },
          {},
        ] as const;
      }
    }
    if (entityStage === 'template') {
      if (entityKind === 'eagle_eye') {
        const [mutate, options] = updateEagleEyeTemplateWidget;
        return [
          (widgetConfig: UnknownRecord) => {
            return mutate({
              eagleEyeTemplateId: entityId,
              groupId,
              id: widgetId,
              body: {
                config: widgetConfig,
              },
            });
          },
          options,
        ] as const;
      }
      if (entityKind === 'object_level') {
        const [mutate, options] = updateTemplateWidget;
        return [
          (widgetConfig: UnknownRecord) => {
            return mutate({
              groupId,
              id: widgetId,
              templateId: entityId,
              body: widgetConfig,
            });
          },
          options,
        ] as const;
      }
    }
    return [() => {}, {}] as const;
  }, [entityStage, entityKind]);
};

export const ReportingEagleEyeWidgetObjectsConfig = () => {
  const [modalType, setModalType] =
    useState<ReportingEagleEyeObjectsConfig | null>(null);
  const isOpenAssets = modalType === ReportingEagleEyeObjectsConfig.Asset;
  const openAssets = () => setModalType(ReportingEagleEyeObjectsConfig.Asset);
  const isOpenSegments = modalType === ReportingEagleEyeObjectsConfig.Segment;
  const openSegments = () =>
    setModalType(ReportingEagleEyeObjectsConfig.Segment);
  const isOpenLegalEntities =
    modalType === ReportingEagleEyeObjectsConfig.ExcludedLegalEntity;
  const openLegalEntities = () =>
    setModalType(ReportingEagleEyeObjectsConfig.ExcludedLegalEntity);
  const onClose = () => setModalType(null);

  const widget = useReportBuilderTemplateWidgetContext();

  const {
    segmentsFilterObjects,
    assetsFilterObjects,
    legalEntities: allLegalEntities,
    data,
  } = useReportBuilderEagleEyeTemplateMeta();

  const widgetState = useReportingWidgetState();
  const reportingEntityState = useReportingEntityState();

  const [handleUpdateWidget, updateWidgetOptions] = useUpdateReportingWidget();

  const allAssets = data?.assets ?? [];

  const defaultAssetIds =
    widgetState.assetIds ?? reportingEntityState?.assetIds;
  const defaultSegmentIds =
    widgetState.segmentIds ?? reportingEntityState?.segmentIds;
  const defaultExcludedLegalEntityIds =
    widgetState.excludedLEIds ?? reportingEntityState?.excludedLEIds;

  const isDefault =
    widgetState.assetIds == null &&
    widgetState.segmentIds == null &&
    widgetState.excludedLEIds == null;

  const hasDot = !isDefault;

  const selectedAssets = useSelectedAssets(allAssets, defaultAssetIds);
  const defaultIncludedLegalEntities = useIncludedLegalEntities(
    selectedAssets,
    defaultExcludedLegalEntityIds,
  );

  const methods = useReportBuilderWidgetEagleEyeObjectSettingsForm({
    defaultAssetIds,
    defaultSegmentIds,
    defaultExcludedLegalEntityIds,
  });
  const watchValues = methods.watch();

  const onSetValue = useFormOnSetValue(methods);

  const params = useExcludeLegalEntityHandlers({
    allAssets,
    allLegalEntities,
    methods,
  });

  const onCloseAndResetForm = () => {
    methods.reset({
      asset_ids: defaultAssetIds,
      segment_ids: defaultSegmentIds,
      excluded_legal_entity_ids: defaultExcludedLegalEntityIds,
    });
    onClose();
  };

  const handleMakeObjectsDefault = async () => {
    await handleUpdateWidget({
      ...widget.widgetConfig,
      default_options: omit(widget.widgetConfig.default_options, 'objects'),
    });
    onClose();
  };

  const handleSubmit = async () => {
    const getNewObjects = () => {
      let newAssetIds = defaultAssetIds;
      let newSegmentIds = defaultSegmentIds;
      let newExcludedLEIds = defaultExcludedLegalEntityIds;

      switch (modalType) {
        case ReportingEagleEyeObjectsConfig.Asset: {
          newAssetIds = watchValues.asset_ids;
          break;
        }
        case ReportingEagleEyeObjectsConfig.ExcludedLegalEntity: {
          newAssetIds = watchValues.asset_ids;
          newExcludedLEIds = watchValues.excluded_legal_entity_ids;
          break;
        }
        case ReportingEagleEyeObjectsConfig.Segment:
        default: {
          newSegmentIds = watchValues.segment_ids;
          break;
        }
      }
      const newSegmentObjects = newSegmentIds.map((id) => ({
        type: ReportingEagleEyeObjectsConfig.Segment,
        id,
      }));
      const newAssetObjects = newAssetIds.map((id) => ({
        type: ReportingEagleEyeObjectsConfig.Asset,
        id,
      }));
      const newExcludedObjects = newExcludedLEIds.map((id) => ({
        type: ReportingEagleEyeObjectsConfig.ExcludedLegalEntity,
        id,
      }));
      return newSegmentObjects
        .concat(newAssetObjects)
        .concat(newExcludedObjects);
    };

    const objects = getNewObjects();

    const res = await handleUpdateWidget({
      ...widget.widgetConfig,
      default_options: {
        ...widget.widgetConfig.default_options,
        objects,
      },
    });
    if (res != null && 'data' in res) {
      const { assetIds, excludedLEIds, segmentIds } =
        getReportingEagleEyeWidgetObjectsWidgetState(
          res.data.defaultOptions?.objects,
        );
      methods.reset({
        asset_ids: assetIds,
        segment_ids: segmentIds,
        excluded_legal_entity_ids: excludedLEIds,
      });
      onClose();
    }
  };

  const getSidePanelProps = (): ComponentProps<
    typeof ObjectSelectionModal
  >['sidePanelProps'] => ({
    // TODO move to default Report Builder SidePanel options
    position: 'left',
    size: '500',
    actions: (
      <div className="flex w-full gap-4 items-center">
        <Button onClick={onClose}>Cancel</Button>
        <GrowDiv />
        <Button
          disabled={isDefault}
          loading={updateWidgetOptions.isLoading}
          onClick={handleMakeObjectsDefault}
        >
          Reset to Default
        </Button>
        <Button
          loading={updateWidgetOptions.isLoading}
          onClick={() => {
            handleSubmit();
          }}
          variant="success"
        >
          Submit
        </Button>
      </div>
    ),
  });

  return (
    <FormProvider {...methods}>
      {isOpenAssets && (
        <ObjectSelectionModal
          sidePanelProps={getSidePanelProps()}
          objectType="asset"
          onClose={() => {
            onCloseAndResetForm();
          }}
          onChange={(ids) => {
            onSetValue('asset_ids', ids);
          }}
          defaultObjectIds={watchValues.asset_ids}
          objects={assetsFilterObjects}
        />
      )}
      {isOpenSegments && (
        <ObjectSelectionModal
          sidePanelProps={getSidePanelProps()}
          objectType="segment"
          onClose={() => {
            onCloseAndResetForm();
          }}
          onChange={(ids) => {
            onSetValue('segment_ids', ids);
          }}
          defaultObjectIds={watchValues.segment_ids}
          objects={segmentsFilterObjects}
        />
      )}
      {isOpenLegalEntities && (
        <LegalEntitiesSelectionModal
          onClose={() => {
            onCloseAndResetForm();
          }}
          sidePanelProps={getSidePanelProps()}
          {...params}
        />
      )}
      <ButtonGroup
        size="xs"
        hasDot={hasDot}
        items={[
          {
            children: (
              <ObjectCounter
                counter={defaultAssetIds.length}
                iconName="asset"
              />
            ),
            onClick: openAssets,
          },
          {
            onClick: openSegments,
            children: (
              <ObjectCounter
                counter={defaultSegmentIds.length}
                iconName="segments"
              />
            ),
          },
          {
            onClick: openLegalEntities,
            children: (
              <ObjectCounter
                counter={defaultIncludedLegalEntities.length}
                iconName="entities"
              />
            ),
          },
        ]}
      />
    </FormProvider>
  );
};

import { TRootState } from '@/app/stores';
import { dashboardSettingsApiEnhanced } from '@/shared/api/dashboardSettingsEnhancedApi';
import { mapListToIds } from '@/shared/lib/listHelpers';
import {
  createEntityAdapter,
  createSlice,
  EntityState,
  isAnyOf,
} from '@reduxjs/toolkit';
import {
  buildLayoutsId,
  EagleEyeBoard,
  getInitialStateForEagleEyeDashboardWidget,
  getOverridableState,
  getSectionExpandedLayout,
  loadInitialWidgetState,
  loadInitialWidgetStateReducer,
  ReportDashboardFilterSet,
  ReportEagleEyeDashboard,
  ReportEagleEyeDashboardSection,
  updateDashboardWidgetState,
  updateWidgetStateReducer,
} from 'bundles/Shared/entities/dashboard';
import {
  addLayoutsReducersToSlice,
  buildLayout,
  LayoutsState,
  selectReportDashboardLayoutDirty,
} from 'bundles/Shared/entities/dashboard/model/slices/layouts';
import { setDashboardFilterSet } from 'bundles/Shared/features/dashboard/filter/filterSet/model';
import { dashboardSectionExpanded } from 'bundles/Shared/features/dashboard/sectionActions';
import { UnknownRecord } from 'type-fest/source/internal';

export type ReportDashboardState = LayoutsState<{
  id: string;
  widgetsStateInitial?: Record<string, UnknownRecord>;
  widgetsState?: Record<string, UnknownRecord>;
}>;

export type CommonDashboardState = {
  selectedFilterSet?: ReportDashboardFilterSet | null;
};

type DashboardSliceState = EntityState<ReportDashboardState> &
  CommonDashboardState;

const reportDashboardSettingsAdapter =
  createEntityAdapter<ReportDashboardState>();

export const reportDashboardSettingsSlice = createSlice({
  name: 'reportDashboardSettings',
  initialState:
    reportDashboardSettingsAdapter.getInitialState() as DashboardSliceState,
  reducers: {},
  extraReducers: (builder) => {
    addLayoutsReducersToSlice(builder);
    builder.addCase(loadInitialWidgetState, loadInitialWidgetStateReducer);
    builder.addCase(setDashboardFilterSet, (state, action) => {
      state.selectedFilterSet = action.payload.filterSet;
      Object.keys(state.entities).forEach((dashboardId) => {
        const board = state.entities[dashboardId];
        const { dashboard } = action.payload;
        if (board === undefined) {
          return;
        }
        Object.keys(board.widgetsState ?? {}).forEach((widgetId) => {
          const widgetState = board.widgetsState?.[widgetId];
          if (widgetState === undefined) {
            return;
          }
          const { assets, segments, legalEntities } =
            action.payload.filterSet ?? {};
          board.widgetsState![widgetId] = {
            ...widgetState,
            assets: assets
              ? mapListToIds(assets)
              : mapListToIds(dashboard.assets),
            segments: segments
              ? mapListToIds(segments)
              : mapListToIds(dashboard.segments),
            legalEntities: legalEntities
              ? mapListToIds(legalEntities)
              : undefined,
          };
        });
      });
    });
    builder.addCase(updateDashboardWidgetState, updateWidgetStateReducer);
    builder.addMatcher(
      dashboardSettingsApiEnhanced.endpoints
        .getApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdWidgetSectionsId
        .matchFulfilled,
      (state, action) => {
        const { id: widgetId, dashboardId, boardId } = action.payload;
        const id = buildLayoutsId({
          dashboardId,
          boardId,
        });
        const board = state.entities[id];
        const widgetsState = board?.widgetsState;
        const widgetState = widgetsState?.[widgetId];

        const section =
          action.payload as unknown as ReportEagleEyeDashboardSection;

        const initialState = getInitialStateForEagleEyeDashboardWidget(section);
        const overridedState = getOverridableState(section);

        reportDashboardSettingsAdapter.upsertOne(state, {
          id,
          widgetsState: {
            ...widgetsState,
            [widgetId]: {
              type: action.payload.widgetType,
              ...initialState,
              ...widgetState,
              ...overridedState,
            },
          },
          widgetsStateInitial: {
            ...board?.widgetsStateInitial,
            [widgetId]: {
              ...initialState,
              ...overridedState,
            },
          },
          layouts: board?.layouts ?? {
            lg: [],
          },
          initialLayouts: board?.initialLayouts ?? {
            lg: [],
          },
        } as ReportDashboardState);
      },
    );
    // build initial state for all widgets by dashboard id
    builder.addMatcher(
      isAnyOf(
        dashboardSettingsApiEnhanced.endpoints
          .getApiSettingsReportEagleEyeDashboardsById.matchFulfilled,
        dashboardSettingsApiEnhanced.endpoints.getApiReportScoreboardsById
          .matchFulfilled,
      ),
      (state, action) => {
        const dashboardData =
          'item' in action.payload ? action.payload.item : action.payload;
        dashboardData.boards!.forEach((board) => {
          const { sections } = board;
          const id = buildLayoutsId({
            dashboardId: dashboardData.id,
            boardId: board.id,
          });
          const boardState = state.entities[id];
          const getWidgetsState = (type: 'initial' | 'state') => {
            return Object.fromEntries(
              sections.map((section) => [
                section.id,
                {
                  ...getInitialStateForEagleEyeDashboardWidget(
                    section,
                    dashboardData,
                  ),
                  ...(type === 'state'
                    ? boardState?.widgetsState?.[section.id]
                    : undefined),
                  ...getOverridableState(section, dashboardData),
                },
              ]),
            );
          };

          const layouts = buildLayout(sections);

          reportDashboardSettingsAdapter.upsertOne(state, {
            id,
            widgetsState: getWidgetsState('state'),
            widgetsStateInitial: getWidgetsState('initial'),
            layouts,
            initialLayouts: layouts,
          });
        });
      },
    );
    builder.addMatcher(isAnyOf(dashboardSectionExpanded), (state, action) => {
      const { dashboardId, boardId, sectionId } = action.payload;
      const id = buildLayoutsId({
        dashboardId,
        boardId,
      });
      const board = state.entities[id];
      if (board === undefined) {
        return;
      }
      if (board.layouts === undefined) {
        return;
      }
      const section = board.layouts.lg.find((item) => item.i === sectionId);
      if (section === undefined) {
        return;
      }
      Object.assign(section, getSectionExpandedLayout(section));
    });
  },
});

export const { selectById: selectReportEagleEyeDashboardSettingsById } =
  reportDashboardSettingsAdapter.getSelectors(
    (state: TRootState) => state.reportDashboardSettings,
  );

export const selectReportDashboardSelectedFilterSet = (state: TRootState) => {
  return state.reportDashboardSettings.selectedFilterSet;
};

export const selectReportDashboardSettingsDirty =
  ({ dashboardId, boardId }: { dashboardId: string; boardId?: string }) =>
  (state: TRootState) => {
    const id = buildLayoutsId({
      dashboardId,
      boardId,
    });
    const board = selectReportEagleEyeDashboardSettingsById(state, id);
    const { layouts, initialLayouts } = board ?? {};

    if (layouts == null || initialLayouts == null) {
      return false;
    }

    return selectReportDashboardLayoutDirty(
      {
        dashboardId,
        boardId,
      },
      selectReportEagleEyeDashboardSettingsById,
    )(state);
  };

export const selectWidgetStateFromDashboardSettingsById =
  ({
    dashboardId,
    widgetId,
    boardId,
  }: {
    widgetId: string;
    dashboardId: ReportEagleEyeDashboard['id'];
    boardId?: EagleEyeBoard['id'];
  }) =>
  (state: TRootState) => {
    const board = selectReportEagleEyeDashboardSettingsById(
      state,
      buildLayoutsId({
        dashboardId,
        boardId,
      }),
    )!;

    return board?.widgetsState?.[widgetId];
  };

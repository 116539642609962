import { thresholdMinMaxValuesForColumns } from '@/bundles/Shared/widgets/dashboard/widgets/common/lib/thresholds';
import { UnitTypeTableWidgetSection } from '@/bundles/Shared/widgets/dashboard/widgets/unitTypeTable/model';
import { AgGridReactRef } from '@/lib/ag-grid/types';
import {
  KpiTableSingleDateWidgetDto,
  KpiTableWidgetDto,
  UnitTypeTableWidgetDto,
} from '@/shared/api/dashboardsGeneratedApi';
import { ColDef, ColGroupDef } from 'ag-grid-community';
import {
  formatDateRangeForPeriodType,
  formatDateRangeForWidgetGroupHeaderName,
  getFakeRowData,
  useWidgetTableDefaultColDef,
} from 'bundles/Shared/widgets/dashboard/widgets/common';
import {
  ColDefBuilder,
  ColGroupDefBuilder,
  ColumnDefsBuilder,
  ExcelStyleBuilder,
} from 'bundles/Shared/widgets/dashboard/widgets/common/ui/table/ColumnDefsBuilder';
import {
  TablePeriodConfigColumn,
  TableVizConfig,
} from 'bundles/Shared/widgets/dashboard/widgets/common/ui/table/model';
import { usePinColumn } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/table/updaters';
import { WidgetTable } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/table/WidgetTable';
import {
  findColumnByColumnSettingsKey,
  getDataPath,
  getRowHeight,
  getRowId,
  KpiTableWidgetConfig,
  KpiTableWidgetSection,
  KpiTableWidgetState,
} from 'bundles/Shared/widgets/dashboard/widgets/kpiTable';
import {
  KpiTableSingleDateWidgetConfig,
  KpiTableSingleDateWidgetSection,
} from 'bundles/Shared/widgets/dashboard/widgets/kpiTableSingleDate';
import { findFirstColumnSettingsInGroup } from 'bundles/Shared/widgets/dashboard/widgets/kpiTableSingleDate/config/updaters';
import {
  WidgetConfigProps,
  WidgetProps,
  WidgetStateProps,
} from 'bundles/Shared/widgets/dashboard/widgets/model';
import React, { forwardRef, useMemo } from 'react';

type Props = Pick<
  WidgetProps<
    KpiTableWidgetDto | KpiTableSingleDateWidgetDto | UnitTypeTableWidgetDto,
    | KpiTableWidgetSection
    | KpiTableSingleDateWidgetSection
    | UnitTypeTableWidgetSection
  >,
  'data' | 'mode'
> &
  WidgetStateProps<KpiTableWidgetState> &
  WidgetConfigProps<KpiTableWidgetConfig | KpiTableSingleDateWidgetConfig> & {
    vizConfig: TableVizConfig;
    columnsConfig: TablePeriodConfigColumn[];
  };

type OverridableTableProps = React.ComponentProps<typeof WidgetTable>;

export const KpiTableWidgetTable = forwardRef<
  AgGridReactRef,
  Props & OverridableTableProps
>(
  (
    {
      vizConfig,
      columnsConfig,
      state,
      data,
      mode,
      settings,
      onSettingsChange,
      excelStyles,
      autoGroupColumnDef,
      ...props
    },
    externalRef,
  ) => {
    const onPinColumn = usePinColumn({
      settings,
      onSettingsChange,
    });
    const defaultColDef = useWidgetTableDefaultColDef({
      mode,
      isCompactSelected: state.selectedColumnSize === 'compact',
    });

    const columnDefsBuilder = useMemo(() => {
      const colDefBuilder = new ColDefBuilder<{
        label: string;
        key: number;
        dateFrom: string;
        dateTo: string;
      }>({
        mode,
        onPinColumn,
        columnsConfig,
      })
        .withHeaderBackgroudOverride(vizConfig.header_background)
        .withSubHeaderName((params) => {
          const columnConfig = findColumnByColumnSettingsKey(
            columnsConfig ?? [],
            Number(params.columnSettings.key),
          );
          return formatDateRangeForPeriodType(
            params.column,
            columnConfig,
            params.columnSettings,
          );
        });

      const colGroupDefBuilder = new ColGroupDefBuilder({
        mode,
      })
        .withHeaderBackgroudOverride(vizConfig.header_background)
        .withHeaderName(({ group: g }) => {
          const { firstColumn: firstColumnConfig, firstColumnSettingsInGroup } =
            findFirstColumnSettingsInGroup({
              groupId: g.group_id,
              columnsConfig,
              vizConfig,
            });
          const firstColumnInGroup = findColumnByColumnSettingsKey(
            data?.columns ?? [],
            Number(firstColumnSettingsInGroup?.key),
          )!;
          return formatDateRangeForWidgetGroupHeaderName(
            firstColumnInGroup,
            firstColumnConfig,
            g,
          );
        });
      const excelStyleBuilder = new ExcelStyleBuilder({
        vizConfig,
        mode,
      }).withIndentationStyles();
      const colDefsBuilder = new ColumnDefsBuilder<{
        label: string;
        key: number;
        dateFrom: string;
        dateTo: string;
      }>({
        vizConfig,
        colDefBuilder,
        colGroupDefBuilder,
        excelStyleBuilder,
        mode,
      });
      return colDefsBuilder;
    }, [vizConfig, mode, columnsConfig, data?.columns]);

    const columnDefs = useMemo<(ColDef | ColGroupDef)[]>(() => {
      return columnDefsBuilder.build({
        columns: data?.columns ?? [],
      });
    }, [columnDefsBuilder]);

    const rowData = useMemo(() => {
      const rows = data?.data ?? [];
      const columns = data?.columns ?? [];

      if (rows.length === 0) {
        return getFakeRowData(columns);
      }
      return rows.filter((row) => row.type !== 'total');
    }, [data, mode]);

    const minMaxValues = useMemo(() => {
      const rows = data?.data ?? [];
      const columns = data?.columns ?? [];

      const filteredRows = rows.filter((row) => {
        const rowType = state.groupingType === 'assets' ? 'asset' : 'segment';
        return row.type !== 'total' && row.type === rowType;
      });

      return thresholdMinMaxValuesForColumns(filteredRows, columns);
    }, [data, state.groupingType]);

    const total = useMemo(
      () => data?.data?.find((row) => row.type === 'total'),
      [data],
    );

    return (
      <WidgetTable
        ref={externalRef}
        autoGroupColumnDef={autoGroupColumnDef}
        context={{
          minMaxValues,
          groupingType: state.groupingType,
        }}
        mode={mode}
        rowData={rowData}
        treeData
        defaultColDef={defaultColDef}
        getRowHeight={getRowHeight}
        getDataPath={getDataPath}
        getRowId={getRowId}
        columnDefs={columnDefs}
        pinnedBottomRowData={total && [total]}
        excelStyles={columnDefsBuilder.buildExcelStyles()}
        expensivePropSuppressColumnVirtualisation
        {...props}
      />
    );
  },
);

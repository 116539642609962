import { formatToDateStringForRequest } from '@/shared/lib/converters';
import { cn } from '@/shared/lib/css/cn';
import { shiftDateBackward } from '@/shared/lib/date';
import { useAppSelector } from '@/shared/lib/hooks/redux';
import { Button } from '@/stories/Button/Button';
import { FlexibleFilterByPeriods } from '@/stories/FlexibleFilterByPeriods/FlexibleFilterByPeriods';
import { generatePrevPeriods } from '@/stories/FlexibleFilterByPeriods/consts';
import {
  DateWidgetState,
  PeriodTypeWidgetState,
} from 'bundles/Shared/widgets/dashboard/widgets/common';
import {
  AVAILABLE_PERIOD_TYPES,
  getRangeToDateShiftIncludeBoundaries,
} from 'bundles/Shared/widgets/dashboard/widgets/common/config';
import { resolveActualStateDate } from 'bundles/Shared/widgets/dashboard/widgets/common/financialTable/lib';
import { WidgetStateProps } from 'bundles/Shared/widgets/dashboard/widgets/model';
import React from 'react';
import { useComputedFilterLabel } from 'stories/FlexibleFilterByPeriods/hooks';

const RANGE_PERIODS = [
  AVAILABLE_PERIOD_TYPES.T3,
  AVAILABLE_PERIOD_TYPES.T6,
  AVAILABLE_PERIOD_TYPES.T12,
] as const;

export function FinancialTableWidgetStateDate({
  state,
  onStateChange,
  ...props
}: WidgetStateProps<DateWidgetState & PeriodTypeWidgetState> &
  Omit<
    React.ComponentProps<typeof FlexibleFilterByPeriods>,
    'periodItems' | 'onUpdatePeriodItems' | 'filterByPeriodsType'
  >) {
  const fullscreen = useAppSelector((s) => s.widgetFullscreen.value);
  const isRange = RANGE_PERIODS.includes(state.periodType);
  const getPeriodItems = () => {
    if (!state.date) {
      return [];
    }
    if (isRange) {
      const shift = getRangeToDateShiftIncludeBoundaries(state.periodType);

      return [
        {
          type: 'mtd',
          period: formatToDateStringForRequest(
            shiftDateBackward(state.date, shift, 'month'),
          ),
        },
        {
          type: 'mtd',
          period: state.date,
        },
      ];
    }
    return [{ period: state.date, type: 'mtd' }];
  };
  const computedLabel = useComputedFilterLabel({
    periodItems: getPeriodItems(),
    filterByPeriodsType: 'mtd',
    fromToMode: isRange,
    disableOnlyLabel: true,
  });
  return (
    <FlexibleFilterByPeriods
      popoverProps={{
        appendTo: fullscreen ? 'parent' : () => document.body,
        children: (
          <Button
            variant="secondary"
            size="s"
            className={cn('font-medium leading-none text-info-055')}
          >
            {computedLabel}
          </Button>
        ),
      }}
      possiblePeriods={generatePrevPeriods()}
      periodItems={state.date ? [{ period: state.date, type: 'mtd' }] : []}
      isSingleSelection
      filterByPeriodsType="mtd"
      onUpdatePeriodItems={(newPeriodItems) => {
        if (newPeriodItems.length === 0) return;
        onStateChange({
          ...state,
          date: resolveActualStateDate(
            newPeriodItems.at(0)!.period,
            state.periodType,
          ),
        });
      }}
      {...props}
    />
  );
}

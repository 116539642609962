import React from 'react';
import { DashboardWidgetCard } from 'bundles/Shared/widgets/dashboard/widgets/common';
import ErrorBoundary, { ErrorBoundaryProps } from '@/app/ui/ErrorBoundary';
import * as Sentry from '@sentry/react';
import { WidgetProps } from '@/bundles/Shared/widgets/dashboard/widgets/model';

export function WidgetErrorBoundaryFallback({ title }: { title: string }) {
  return (
    <DashboardWidgetCard className={'h-full'}>
      <DashboardWidgetCard.Header>
        <span className="header5-bold text-neutral-850">{title}</span>
      </DashboardWidgetCard.Header>
      <div className="flex grow items-center justify-center p-6">
        Something went wrong. Please try change configuration.
      </div>
    </DashboardWidgetCard>
  );
}

interface WidgetErrorBoundaryProps
  extends ErrorBoundaryProps,
    Pick<WidgetProps, 'widgetSection' | 'dashboardType' | 'mode'> {}

export class WidgetErrorBoundary extends ErrorBoundary<WidgetErrorBoundaryProps> {
  constructor(props: WidgetErrorBoundaryProps) {
    super(props);
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    const newError = new Error(error.message);
    const { widgetSection, dashboardType, mode } = this.props;
    newError.name = `Widget Error: ${widgetSection.title} ${dashboardType} ${mode}`;
    newError.stack = error.stack;
    console.error(newError);
    Sentry.captureException(newError, {
      extra: {
        widgetSection,
        dashboardType,
        mode,
        errorInfo,
      },
    });
  }

  render() {
    if (this.state.hasError)
      return (
        <WidgetErrorBoundaryFallback title={this.props.widgetSection.title} />
      );

    return this.props.children;
  }
}

import { useReportBuilderTemplateContext } from '@/bundles/Shared/widgets/dashboard/widgets/common/lib/reportBuilderTemplateContext';
import {
  COMPARISON_DASHBOARD_WIDGETS_CONFIG_MAP,
  EAGLE_EYE_DASHBOARD_WIDGETS_CONFIG_MAP,
  isWidgetTypeSupported,
  OBJECT_DASHBOARD_WIDGETS_CONFIG_MAP,
  REPORT_BUILDER_EAGLE_EYE_WIDGETS_CONFIG_MAP,
  REPORT_BUILDER_WIDGETS_CONFIG_MAP,
} from '@/bundles/Shared/widgets/dashboard/widgets/config';
import {
  BASIC_AVAILABLE_WIDGETS_TABS,
  DASHBOARD_WIDGETS_TAB_ID,
  NEW_WIDGETS_TAB,
  REPORT_BUILDER_TEMPLATE_AVAILABLE_WIDGETS_TABS,
  REPORT_BUILDER_TEMPLATE_EAGLE_EYE_AVAILABLE_WIDGETS_TABS,
  TEMPLATE_WIDGETS_TAB,
} from '@/bundles/Shared/widgets/dashboard/widgetsBar/config';
import {
  useGetApiSettingsReportBuilderEagleEyeTemplatesByEagleEyeTemplateIdCopyableWidgetSectionsQuery,
  useGetApiSettingsReportBuilderTemplatesByTemplateIdCopyableWidgetSectionsQuery,
  useGetApiSettingsReportBuilderTemplatesByTemplateIdObjectDashboardsCopyableWidgetSectionsQuery,
} from '@/entities/report/reportBuilder/api/settingsReportBuilderTemplatesEnhancedApi';
import type {
  GetApiSettingsReportBuilderEagleEyeTemplatesByEagleEyeTemplateIdCopyableWidgetSectionsApiResponse,
  GetApiSettingsReportBuilderTemplatesByTemplateIdCopyableWidgetSectionsApiResponse,
  GetApiSettingsReportBuilderTemplatesByTemplateIdObjectDashboardsCopyableWidgetSectionsApiResponse,
} from '@/entities/report/reportBuilder/api/settingsReportBuilderTemplatesGeneratedApi';
import { useReportingEntityKindContext } from '@/entities/reporting/context/entityKind';
import { useItemsFilterByText } from '@/shared/lib/hooks/useItemsFilterByText';
import { DialogProps } from '@/shared/lib/hooks/useModal';
import { includesInLowerCase, mapListToIds } from '@/shared/lib/listHelpers';
import { createSelector } from '@reduxjs/toolkit';
import {
  ALL_DASHBOARD_SECTION_TYPES,
  AllWidgetTypes,
  ReportDashboardType,
  useDashboardContext,
  useDashboardCopyableWidgetSections,
} from 'bundles/Shared/entities/dashboard';
import { WidgetCard } from 'bundles/Shared/widgets/dashboard/widgets/common';
import { WidgetConfiguration } from 'bundles/Shared/widgets/dashboard/widgets/model';
import { groupBy, intersection, isEmpty, sortBy, uniqBy } from 'lodash-es';
import pluralize from 'pluralize';
import { useCallback, useMemo, useState } from 'react';
import { Button } from 'stories/Button/Button';
import { Dropdown } from 'stories/Dropdown/Dropdown';
import { DropdownItem } from 'stories/Dropdown/DropdownItem/DropdownItem';
import { Modal } from 'stories/Modals/Modal/Modal';
import { SearchInput } from 'stories/FormControls/Inputs/SearchInput/SearchInput';
import { ThinTabGroup } from 'stories/Tabs/ThinTabGroup/ThinTabGroup';
import { useTabs } from 'stories/Tabs/useTabs';

type Props = DialogProps<{
  widgetOptions: WidgetOption[];
  type: 'add_new_widget' | 'copy_widget_from_source';
  source?:
    | ReportDashboardType.OBJECT
    | ReportDashboardType.REPORT_BUILDER_TEMPLATE;
}>;

type WidgetOption = {
  id: string;
  title: string;
  widgetType: AllWidgetTypes;
  board?: {
    id: string;
    dashboard: {
      id: string;
      name: string;
    };
  };
};

// move to api file
const useReportBuilderTemplateObjectLevelCopyableWidgetSections = () => {
  const templateCtx = useReportBuilderTemplateContext()!;
  const templateKind = useReportingEntityKindContext();

  type Response =
    GetApiSettingsReportBuilderTemplatesByTemplateIdObjectDashboardsCopyableWidgetSectionsApiResponse;

  const selectDashboards = useMemo(() => {
    const emptyArray: Response[number]['board']['dashboard'][] = [];
    return createSelector(
      ({ data }: { data?: Response }) => data,
      (sections) =>
        uniqBy(sections?.map((s) => s.board?.dashboard) ?? emptyArray, 'id'),
    );
  }, []);

  return useGetApiSettingsReportBuilderTemplatesByTemplateIdObjectDashboardsCopyableWidgetSectionsQuery(
    {
      templateId: templateCtx.templateId,
    },
    {
      skip: templateKind !== 'object_level',
      selectFromResult: (res) => {
        const dashboards = selectDashboards(res);
        return {
          ...res,
          dashboards,
          dashboardsMap: new Map(dashboards.map((d) => [d.id, d])),
        };
      },
    },
  );
};

// move to api file
const useReportBuilderTemplateCopyableWidgetSectionsFromTemplates = () => {
  const templateCtx = useReportBuilderTemplateContext()!;
  const templateKind = useReportingEntityKindContext();

  type Response =
    | GetApiSettingsReportBuilderTemplatesByTemplateIdCopyableWidgetSectionsApiResponse
    | GetApiSettingsReportBuilderEagleEyeTemplatesByEagleEyeTemplateIdCopyableWidgetSectionsApiResponse;

  const selectTemplates = useMemo(() => {
    const emptyArray: Response[number]['template'][] = [];
    return createSelector(
      ({ data }: { data?: Response }) => data,
      (sections) =>
        new Map(
          uniqBy(sections?.map((s) => s.template) ?? emptyArray, 'id').map(
            (t) => [t.id, t],
          ),
        ),
    );
  }, []);

  const query = useMemo(() => {
    switch (templateKind) {
      case 'object_level': {
        return useGetApiSettingsReportBuilderTemplatesByTemplateIdCopyableWidgetSectionsQuery;
      }
      case 'eagle_eye':
      default: {
        return useGetApiSettingsReportBuilderEagleEyeTemplatesByEagleEyeTemplateIdCopyableWidgetSectionsQuery;
      }
    }
  }, [templateKind]);

  return query(
    {
      templateId: templateCtx.templateId,
      eagleEyeTemplateId: templateCtx.templateId,
    },
    {
      selectFromResult: (res) => {
        const templatesMap = selectTemplates(res);
        return {
          ...res,
          templatesMap,
        };
      },
    },
  );
};

// todo Merge with AvailableWidgetsModal FE-3693
export const ReportBuilderTemplateAvailableWidgetsModal = ({
  onClose,
  onSubmit,
}: Props) => {
  const templateKind = useReportingEntityKindContext();

  const { thinTabGroupProps, tab: selectedTab } = useTabs(() => {
    if (templateKind === 'eagle_eye') {
      return REPORT_BUILDER_TEMPLATE_EAGLE_EYE_AVAILABLE_WIDGETS_TABS;
    }
    return REPORT_BUILDER_TEMPLATE_AVAILABLE_WIDGETS_TABS;
  }, 0);

  const { data, dashboardsMap } =
    useReportBuilderTemplateObjectLevelCopyableWidgetSections();

  const { data: templateCopyableWidgetsData, templatesMap } =
    useReportBuilderTemplateCopyableWidgetSectionsFromTemplates();

  const newWidgetsOptions = useMemo(() => {
    let widgetsMap: Record<string, WidgetConfiguration> | null = null;

    switch (templateKind) {
      case 'object_level': {
        widgetsMap = REPORT_BUILDER_WIDGETS_CONFIG_MAP;
        break;
      }
      case 'eagle_eye':
      default: {
        widgetsMap = REPORT_BUILDER_EAGLE_EYE_WIDGETS_CONFIG_MAP;
        break;
      }
    }

    return Object.keys(widgetsMap).map((k) => ({
      id: k,
      title: widgetsMap[k].title,
      widgetType: k,
    })) as WidgetOption[];
  }, [templateKind]);

  const widgetOptions = useMemo(() => {
    switch (selectedTab?.id) {
      case NEW_WIDGETS_TAB.id: {
        return newWidgetsOptions;
      }
      case DASHBOARD_WIDGETS_TAB_ID: {
        return (
          (data as WidgetOption[]).filter((o) =>
            isWidgetTypeSupported(o.widgetType),
          ) ?? []
        );
      }
      case TEMPLATE_WIDGETS_TAB.id: {
        return (
          (templateCopyableWidgetsData as WidgetOption[]).filter((o) =>
            isWidgetTypeSupported(o.widgetType),
          ) ?? []
        );
      }
      default: {
        return [];
      }
    }
  }, [newWidgetsOptions, templateCopyableWidgetsData, data, selectedTab]);

  const { inputProps, filteredItems } = useItemsFilterByText(
    widgetOptions,
    (o, searchText) => {
      const filterByTitle = o.title && includesInLowerCase(o.title, searchText);
      const filterByGroup = includesInLowerCase(
        o.board?.dashboard.name ?? o.template?.name ?? '',
        searchText,
      );

      return filterByTitle || filterByGroup;
    },
    {
      skipWhenTextIsEmpty: false,
    },
  );
  const groupedItems = useMemo(() => {
    switch (selectedTab?.id) {
      case DASHBOARD_WIDGETS_TAB_ID: {
        return groupBy(
          sortBy(filteredItems, 'board.dashboard.name'),
          (o) => o.board?.dashboard.id,
        );
      }
      case TEMPLATE_WIDGETS_TAB.id: {
        return groupBy(
          sortBy(filteredItems, 'template.name'),
          (o) => o.template?.id,
        );
      }
      default: {
        return null;
      }
    }
  }, [filteredItems, selectedTab]);

  const handleAddWidget = async (newWidgets: WidgetOption[]) => {
    if (
      isEmpty(
        intersection(
          Object.values(ALL_DASHBOARD_SECTION_TYPES),
          newWidgets.map((w) => w.id),
        ),
      )
    ) {
      return onSubmit?.({
        type: 'copy_widget_from_source',
        widgetOptions: newWidgets,
        source:
          selectedTab?.id === TEMPLATE_WIDGETS_TAB.id
            ? ReportDashboardType.REPORT_BUILDER_TEMPLATE
            : ReportDashboardType.OBJECT,
      });
    }

    return onSubmit?.({
      type: 'add_new_widget',
      widgetOptions: newWidgets,
    });
  };
  const renderWidgetCard = useCallback(
    (o: WidgetOption) => {
      return (
        <WidgetCard
          withCheckbox={false}
          key={o.id}
          widgetType={o.widgetType}
          title={o.title}
        >
          <div className="grow" />
          <Button
            variant="secondary"
            size="xs"
            className="hidden group-hover/item:block"
            onClick={(e) => {
              e.stopPropagation();
              handleAddWidget([o]);
            }}
          >
            Add
          </Button>
        </WidgetCard>
      );
    },
    [selectedTab],
  );

  const getSearchPlaceholder = () => {
    switch (selectedTab?.id) {
      case DASHBOARD_WIDGETS_TAB_ID: {
        return 'Search by Widget Name or Dashboard name';
      }
      case TEMPLATE_WIDGETS_TAB.id: {
        return 'Search by Widget Name or Template name';
      }
      case NEW_WIDGETS_TAB.id:
      default: {
        return 'Search by Widget Name';
      }
    }
  };

  const renderDashboardGroup = (groupId: string, items: WidgetOption[]) => {
    const dashboardGroup = dashboardsMap.get(groupId);
    const templateGroup = templatesMap.get(groupId);
    const group = dashboardGroup ?? templateGroup;

    return (
      <WidgetCard.Group key={groupId} title={group?.name}>
        <WidgetCard.List.Header withCheckbox={false} widgetCount={items.length}>
          <div className="grow" />
        </WidgetCard.List.Header>
        <WidgetCard.List>{items.map(renderWidgetCard)}</WidgetCard.List>
      </WidgetCard.Group>
    );
  };

  return (
    <Modal
      header="Available Widgets"
      toggle={onClose}
      size="700"
      classes={{
        body: 'flex flex-col gap-4',
      }}
    >
      <ThinTabGroup fullWidth {...thinTabGroupProps} />
      <SearchInput {...inputProps} placeholder={getSearchPlaceholder()} />
      {groupedItems != null &&
        Object.entries(groupedItems).map(([groupId, items]) =>
          renderDashboardGroup(groupId, items),
        )}
      <WidgetCard.List>
        {groupedItems == null && filteredItems.map(renderWidgetCard)}
      </WidgetCard.List>
    </Modal>
  );
};

export function AvailableWidgetsModal({ onClose, onSubmit }: Props) {
  const { dashboardId, boardId, dashboardType } = useDashboardContext();
  const { thinTabGroupProps, tab: selectedTab } = useTabs(
    BASIC_AVAILABLE_WIDGETS_TABS,
    0,
  );

  const { data, boards, dashboardsMap } = useDashboardCopyableWidgetSections({
    dashboardId,
    boardId,
    dashboardType,
  });
  const [selectedWidgets, setSelectedWidgets] = useState<WidgetOption[]>([]);
  const [selectedBoards, setSelectedBoards] = useState<{
    [key: string]: string | null;
  }>({});
  const canSelectMultipleWidgets =
    dashboardType === ReportDashboardType.COMPARISON_MODE ||
    dashboardType === ReportDashboardType.EAGLE_EYE ||
    dashboardType === ReportDashboardType.OBJECT;

  const isLoading = false;

  const widgetsMap = useMemo<Record<string, WidgetConfiguration>>(() => {
    switch (dashboardType) {
      case ReportDashboardType.OBJECT: {
        return OBJECT_DASHBOARD_WIDGETS_CONFIG_MAP;
      }
      case ReportDashboardType.EAGLE_EYE: {
        return EAGLE_EYE_DASHBOARD_WIDGETS_CONFIG_MAP;
      }
      case ReportDashboardType.COMPARISON_MODE: {
        return COMPARISON_DASHBOARD_WIDGETS_CONFIG_MAP;
      }
      default: {
        return OBJECT_DASHBOARD_WIDGETS_CONFIG_MAP;
      }
    }
  }, [dashboardType]);

  const widgetOptions = Object.keys(widgetsMap).map((k) => ({
    id: k,
    title: widgetsMap[k].title,
    widgetType: k,
  })) as WidgetOption[];

  const getWidgetOptions = () => {
    switch (selectedTab?.id) {
      case NEW_WIDGETS_TAB.id: {
        return widgetOptions;
      }
      case DASHBOARD_WIDGETS_TAB_ID: {
        return (data as unknown as typeof widgetOptions) ?? [];
      }
      case TEMPLATE_WIDGETS_TAB.id: {
        return [];
      }
      default: {
        return [];
      }
    }
  };

  const { inputProps, filteredItems } = useItemsFilterByText(
    getWidgetOptions(),
    (o, searchText) => {
      const filterByTitle = includesInLowerCase(o.title, searchText);
      const filterByGroup = includesInLowerCase(
        o.board?.dashboard.name ?? '',
        searchText,
      );
      const selectedBoard = selectedBoards[o.board?.dashboard?.id ?? ''];
      const filterByBoard =
        o.board == null ||
        selectedBoard == null ||
        selectedBoard === o.board?.id;

      return (filterByTitle || filterByGroup) && filterByBoard;
    },
    {
      skipWhenTextIsEmpty: false,
    },
  );

  const groupedItems = useMemo(() => {
    switch (selectedTab?.id) {
      case DASHBOARD_WIDGETS_TAB_ID: {
        return groupBy(
          sortBy(filteredItems, 'board.dashboard.name'),
          (o) => o.board?.dashboard.id,
        );
      }
      default: {
        return null;
      }
    }
  }, [filteredItems, selectedTab]);

  const handleAddWidget = async (newWidgets: WidgetOption[]) => {
    if (
      isEmpty(
        intersection(
          Object.values(ALL_DASHBOARD_SECTION_TYPES),
          newWidgets.map((w) => w.id),
        ),
      )
    ) {
      return onSubmit?.({
        type: 'copy_widget_from_source',
        widgetOptions: newWidgets,
      });
    }

    return onSubmit?.({
      type: 'add_new_widget',
      widgetOptions: newWidgets,
    });
  };

  const handleSelectWidget = (
    widgetOption: WidgetOption[],
    checked: boolean,
  ) => {
    setSelectedWidgets((prev) =>
      checked
        ? [
            ...prev,
            ...widgetOption.filter((o) => !mapListToIds(prev).includes(o.id)),
          ]
        : prev.filter((o) => !mapListToIds(widgetOption).includes(o.id)),
    );
  };

  const renderWidgetCard = useCallback(
    (o: WidgetOption) => {
      const checked = selectedWidgets.find((ox) => ox.id === o.id) != null;

      return (
        <WidgetCard
          withCheckbox={canSelectMultipleWidgets}
          checked={checked}
          key={o.id}
          widgetType={o.widgetType}
          title={o.title}
          onClick={() => {
            handleSelectWidget([o], !checked);
          }}
        >
          <div className="grow" />
          <Button
            variant="secondary"
            size="xs"
            className="hidden group-hover/item:block"
            disabled={isLoading}
            onClick={(e) => {
              e.stopPropagation();
              handleAddWidget([o]);
            }}
          >
            Add
          </Button>
        </WidgetCard>
      );
    },
    [selectedWidgets, isLoading, selectedTab],
  );

  const getSearchPlaceholder = () => {
    switch (selectedTab?.id) {
      case DASHBOARD_WIDGETS_TAB_ID: {
        return 'Search by Widget Name or Dashboard name';
      }
      case TEMPLATE_WIDGETS_TAB.id: {
        return 'Search by Widget Name or Template name';
      }
      case NEW_WIDGETS_TAB.id:
      default: {
        return 'Search by Widget Name';
      }
    }
  };

  const renderDashboardGroup = (groupId: string, items: WidgetOption[]) => {
    const dashboardGroup = dashboardsMap.get(groupId);
    const group = dashboardGroup;

    const dashboardBoards = boards.filter((b) => b.dashboard.id === groupId);
    const dashboardBoardsOptions = [
      {
        id: null,
        name: 'All',
      },
      ...dashboardBoards,
    ];
    return (
      <WidgetCard.Group key={groupId} title={group?.name}>
        <WidgetCard.List.Header
          withCheckbox={canSelectMultipleWidgets}
          onChange={(checked) => handleSelectWidget(items, checked)}
          checked={items.every((o) => selectedWidgets.includes(o))}
          widgetCount={items.length}
        >
          <div className="grow" />
          {canSelectMultipleWidgets && (
            <Dropdown
              items={dashboardBoardsOptions.map((b) => (
                <DropdownItem
                  key={b.id}
                  active={selectedBoards[groupId] === b.id}
                  onClick={() => {
                    setSelectedBoards((prev) => ({
                      ...prev,
                      [groupId]: b.id,
                    }));
                  }}
                >
                  {b.name}
                </DropdownItem>
              ))}
            >
              <Button variant="secondary" size="xs">
                {
                  dashboardBoardsOptions.find(
                    (b) => b.id == selectedBoards[groupId],
                  )?.name
                }
              </Button>
            </Dropdown>
          )}
        </WidgetCard.List.Header>
        <WidgetCard.List>{items.map(renderWidgetCard)}</WidgetCard.List>
      </WidgetCard.Group>
    );
  };

  return (
    <Modal
      header="Available Widgets"
      toggle={onClose}
      size="700"
      classes={{
        body: 'flex flex-col gap-4',
      }}
      actions={
        canSelectMultipleWidgets ? (
          <Modal.Actions className="flex-end">
            <Button
              variant="success"
              onClick={() => handleAddWidget(selectedWidgets)}
              disabled={isLoading || selectedWidgets.length === 0}
            >
              Add {selectedWidgets.length > 0 && selectedWidgets.length}{' '}
              {pluralize('widget', selectedWidgets.length)}
            </Button>
          </Modal.Actions>
        ) : null
      }
    >
      {dashboardType !==
        ReportDashboardType.REPORT_BUILDER_TEMPLATE_EAGLE_EYE && (
        <ThinTabGroup fullWidth {...thinTabGroupProps} />
      )}
      <SearchInput {...inputProps} placeholder={getSearchPlaceholder()} />
      {groupedItems != null &&
        Object.entries(groupedItems).map(([groupId, items]) =>
          renderDashboardGroup(groupId, items),
        )}
      <WidgetCard.List>
        {groupedItems == null && filteredItems.map(renderWidgetCard)}
      </WidgetCard.List>
    </Modal>
  );
}

import EagleEyeDashboardUpdate from '@/bundles/Shared/features/dashboard/update/eagleEye/ui/EagleEyeDashboardUpdate';
import {
  DASHBOARD_ENTITY_NAME,
  ReportEagleEyeDashboard,
} from 'bundles/Shared/entities/dashboard';
import { usePutApiSettingsReportEagleEyeDashboardsByIdMutation } from '@/shared/api/dashboardSettingsEnhancedApi';
import { useModal } from '@/shared/lib/hooks/useModal';
import { mapListToIds } from '@/shared/lib/listHelpers';
import { isEqual } from 'lodash-es';
import { useCallback } from 'react';
import { EntityToastrCreator } from '@/shared/lib/toastr/entityToastrCreator';

const t = new EntityToastrCreator(DASHBOARD_ENTITY_NAME);

export const useEditEagleEyeDashboard = () => {
  const [updateDashboard] =
    usePutApiSettingsReportEagleEyeDashboardsByIdMutation();
  const { openModal } = useModal();

  return useCallback(async (dashboard: ReportEagleEyeDashboard) => {
    const res = await openModal(EagleEyeDashboardUpdate, {
      dashboard: {
        name: dashboard.name,
        asset_ids: mapListToIds(dashboard.assets),
        excluded_legal_entity_ids: mapListToIds(
          dashboard.excludedLegalEntities,
        ),
        segment_ids: mapListToIds(dashboard.segments),
      },
      type: 'edit',
    });
    if (res == null) {
      return;
    }
    const result = await updateDashboard({
      id: dashboard.id,
      body: {
        excluded_legal_entity_ids: res.excluded_legal_entity_ids,
        // todo now we pass undefined to resolve cache updates in src/bundles/Shared/shared/api/dashboardsEnhancedApi.ts
        name: isEqual(dashboard.name, res.name) ? undefined : res.name,
        asset_ids: isEqual(mapListToIds(dashboard.assets), res.asset_ids)
          ? undefined
          : res.asset_ids,
        segment_ids: isEqual(mapListToIds(dashboard.segments), res.segment_ids)
          ? undefined
          : res.segment_ids,
      },
    });
    if ('error' in result) {
      return null;
    }
    toastr.success(t.update().toString());

    return result.data;
  }, []);
};

import { selectReportComparisonDashboardMetadataById } from '@/bundles/Shared/entities/dashboard/model/slices/comparisonSlice';
import { formatToDateStringForRequest } from '@/shared/lib/converters';
import { yesterdayDate } from '@/shared/lib/date';
import { useAppSelector } from '@/shared/lib/hooks/redux';
import { ExtractParams, ROUTES_ROOT } from '@/shared/lib/hooks/useNavigation';
import { reckonerPeriod } from '@/shared/lib/reckoner/period';
import { useParams } from '@reach/router';
import { useReportSettingsDashboardByIdOrSlug } from 'bundles/Settings/components/REport/Dashboards/Dashboard/lib';
import {
  buildLayoutsId,
  ObjectDashboardState,
  ReportDashboardType,
  selectReportDashboardSelectedFilterSet,
  selectReportEagleEyeDashboardSettingsById,
  selectReportObjectDashboardMetadataById,
} from 'bundles/Shared/entities/dashboard';

import {
  usePostApiSettingsReportComparisonDashboardsByComparisonDashboardIdBoardsAndBoardIdWidgetSectionsPreviewQuery,
  usePostApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdWidgetSectionsPreviewQuery,
  usePostApiSettingsReportObjectDashboardsByObjectDashboardIdBoardsAndBoardIdWidgetSectionsPreviewQuery,
} from '@/shared/api/dashboardSettingsEnhancedApi';

import {
  PostApiSettingsReportComparisonDashboardsByComparisonDashboardIdBoardsAndBoardIdWidgetSectionsPreviewApiArg,
  PostApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdWidgetSectionsPreviewApiArg,
  PostApiSettingsReportObjectDashboardsByObjectDashboardIdBoardsAndBoardIdWidgetSectionsPreviewApiArg,
} from '@/shared/api/dashboardsSettingsGeneratedApi';
import { useWidgetConfig } from 'bundles/Shared/widgets/dashboard/widgets/common';

export const useWidgetScreenParams = () =>
  useParams<
    ExtractParams<
      typeof ROUTES_ROOT.settings.report.dashboards.dashboard.widget.fullPath
    >
  >();

export const useWidgetPreviewData = ({
  dashboardId,
  boardId,
  widgetId,
  assetId,
}: {
  dashboardId: string;
  boardId: string;
  widgetId: string;
  assetId?: string;
}) => {
  const { data: dashboard } = useReportSettingsDashboardByIdOrSlug(dashboardId);

  const resolveStateSelector = () => {
    if (dashboard == null) {
      return () => ({});
    }
    switch (dashboard?.type) {
      case ReportDashboardType.OBJECT: {
        return selectReportObjectDashboardMetadataById;
      }
      case ReportDashboardType.EAGLE_EYE: {
        return selectReportEagleEyeDashboardSettingsById;
      }
      case ReportDashboardType.COMPARISON_MODE: {
        return selectReportComparisonDashboardMetadataById;
      }
    }
  };
  const stateSelector = resolveStateSelector()!;

  const boardState = useAppSelector((state) =>
    stateSelector(
      state,
      buildLayoutsId({
        dashboardId,
        boardId,
      }),
    ),
  );
  const dashboardFilterSet = useAppSelector(
    selectReportDashboardSelectedFilterSet,
  );
  const dashboardState = useAppSelector((state) =>
    stateSelector(state, dashboardId),
  );
  const { objectLegalEntityIds } = (dashboardState ??
    {}) as ObjectDashboardState;

  const widgetState = boardState?.widgetsState?.[widgetId];
  const { data, widget } = useWidgetConfig();

  switch (dashboard?.type) {
    case ReportDashboardType.OBJECT: {
      return usePostApiSettingsReportObjectDashboardsByObjectDashboardIdBoardsAndBoardIdWidgetSectionsPreviewQuery(
        {
          objectDashboardId: dashboardId,
          boardId,
          body: {
            widget_type:
              widget?.widgetType as PostApiSettingsReportObjectDashboardsByObjectDashboardIdBoardsAndBoardIdWidgetSectionsPreviewApiArg['body']['widget_type'],
            config:
              widget?.widgetConfig as PostApiSettingsReportObjectDashboardsByObjectDashboardIdBoardsAndBoardIdWidgetSectionsPreviewApiArg['body']['config'],
            context: {
              date:
                widgetState?.date ?? formatToDateStringForRequest(new Date()),
              date_from: widgetState?.dateFrom,
              date_to: widgetState?.dateTo,
              granularity: widgetState?.granularity,
              legal_entity_ids: assetId ? objectLegalEntityIds?.[assetId] : [],
              page: widgetState?.page,
              per_page: widgetState?.perPage,
            },
          },
        },
        {
          skip: data == null || dashboard == null,
        },
      );
    }
    case ReportDashboardType.EAGLE_EYE: {
      return usePostApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdWidgetSectionsPreviewQuery(
        {
          eagleEyeDashboardId: dashboardId,
          boardId,
          body: {
            widget_type:
              widget?.widgetType as PostApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdWidgetSectionsPreviewApiArg['body']['widget_type'],
            config:
              widget?.widgetConfig as PostApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdWidgetSectionsPreviewApiArg['body']['config'],
            context: {
              date:
                widgetState?.date ?? formatToDateStringForRequest(new Date()),
              date_from: widgetState?.dateFrom,
              date_to: widgetState?.dateTo,
              period:
                widgetState?.period ??
                reckonerPeriod.t3(
                  formatToDateStringForRequest(yesterdayDate()),
                ),
              use_lto_thresholds: widgetState?.useLtoThresholds,
              granularity: widgetState?.granularity,
              grouping_type: widgetState?.groupingType,
              legal_entity_ids: dashboardFilterSet?.legalEnities,
              assets: widgetState?.assets ?? [],
              segments: widgetState?.segments ?? [],
              period_type:
                widgetState?.periodType as unknown as PostApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdWidgetSectionsPreviewApiArg['body']['context']['period_type'],
            },
          },
        },
        {
          skip: data == null || dashboard == null,
        },
      );
    }
    case ReportDashboardType.COMPARISON_MODE: // NOTE this case by default will use `comparison mode` query
    default: {
      return usePostApiSettingsReportComparisonDashboardsByComparisonDashboardIdBoardsAndBoardIdWidgetSectionsPreviewQuery(
        {
          comparisonDashboardId: dashboardId,
          boardId,
          body: {
            widget_type:
              widget?.widgetType as PostApiSettingsReportComparisonDashboardsByComparisonDashboardIdBoardsAndBoardIdWidgetSectionsPreviewApiArg['body']['widget_type'],
            config:
              widget?.widgetConfig as PostApiSettingsReportComparisonDashboardsByComparisonDashboardIdBoardsAndBoardIdWidgetSectionsPreviewApiArg['body']['config'],
            context: {
              date:
                widgetState?.date ??
                formatToDateStringForRequest(yesterdayDate()),
              grouping_type: 'assets',
              period:
                widgetState?.period ??
                reckonerPeriod.t3(
                  formatToDateStringForRequest(yesterdayDate()),
                ),
              legal_entity_ids: assetId
                ? objectLegalEntityIds?.[Number(assetId)]
                : [],
              asset_ids: [assetId!],
            },
          },
        },
        {
          skip: data == null || dashboard == null || assetId == null,
        },
      );
    }
  }
};

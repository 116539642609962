import { HistorySizeField } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/fields/HistorySizeField';
import { SparklineTypeField } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/fields/SparklineTypeField';
import { SparklineTooltipValueDisplayOptionsFields } from '@/bundles/Shared/widgets/dashboard/widgets/common';
import { FieldValues } from 'react-hook-form/dist/types';
import { Control } from 'react-hook-form';
import { ColorField } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/fields/ColorField';

export function SparklineSettingsField<
  TFieldValues extends FieldValues = FieldValues,
>({ control }: { control: Control<TFieldValues> }) {
  return (
    <>
      <SparklineTypeField
        SparklineSettingsField={control}
        name="sparkline_type"
      />
      <HistorySizeField control={control} name="history_size" />
      <ColorField
        tooltipProps={{
          mainText:
            'This is the "stroke" property, it controls the color of the chart\'s lines.',
        }}
        labelText="Line Color"
        control={control}
        name="stroke_color"
      />
      <ColorField
        labelText="Background Color"
        tooltipProps={{
          mainText:
            'This is the "fill" property, it controls the color that fills the area beneath the line.',
        }}
        control={control}
        name="fill_color"
      />
      <SparklineTooltipValueDisplayOptionsFields
        control={control}
        name="value_display_options"
      />
    </>
  );
}

import { BaseFinancialTableWidgetConfigBaseColumnDto } from '@/shared/api/dashboardsSettingsGeneratedApi';
import { transformPeriodTypeDtoToForm } from '@/bundles/Shared/widgets/dashboard/widgets/common';
import {
  DEFAULT_FINANCIAL_TABLE_WIDGET_CONFIG_COLUMN,
  FINANCIAL_TABLE_WIDGET_CONFIG_COLUMN_SCHEMA,
  transformFinancialTableWidgetColumnConfigToForm,
} from '@/bundles/Shared/widgets/dashboard/widgets/common/financialTable/config/column.form';
import { TableVizConfigColumn } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/table/model';
import {
  PERIOD_TYPE_SCHEMA,
  TRAILING_PERIOD_TYPES,
} from 'bundles/Shared/widgets/dashboard/widgets/common/config';
import * as yup from 'yup';

export const FINANCIAL_TABLE_SINGLE_DATE_WIDGET_TRAILING_PERIOD_TYPES =
  TRAILING_PERIOD_TYPES.filter((d) => d !== 'day');

export const FINANCIAL_TABLE_SINGLE_DATE_WIDGET_CONFIG_COLUMN_SCHEMA =
  FINANCIAL_TABLE_WIDGET_CONFIG_COLUMN_SCHEMA.shape({
    period_type: PERIOD_TYPE_SCHEMA.required(),
  });

export type FinancialTableSingleDateWidgetConfigColumnForm = yup.InferType<
  typeof FINANCIAL_TABLE_SINGLE_DATE_WIDGET_CONFIG_COLUMN_SCHEMA
>;

export const transformFinancialTableSingleDateWidgetColumnConfigToForm = (
  columnConfig: BaseFinancialTableWidgetConfigBaseColumnDto,
  columnSettings?: TableVizConfigColumn,
): Pick<
  FinancialTableSingleDateWidgetConfigColumnForm,
  'adjustment_expression' | 'adjustment_type' | 'key' | 'period_shift'
> => ({
  ...transformFinancialTableWidgetColumnConfigToForm(
    columnConfig,
    columnSettings,
  ),
  period_type: transformPeriodTypeDtoToForm(columnConfig.period_type),
});

export const DEFAULT_FINANCIAL_TABLE_SINGLE_DATE_WIDGET_CONFIG_COLUMN: Partial<FinancialTableSingleDateWidgetConfigColumnForm> =
  {
    ...DEFAULT_FINANCIAL_TABLE_WIDGET_CONFIG_COLUMN,
    period_type: {
      periodType: 'time',
      value: 'month',
    },
  };

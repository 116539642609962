import {
  ReportDashboardType,
  ReportEagleEyeDashboard,
} from 'bundles/Shared/entities/dashboard';

import {
  useDeleteApiSettingsReportComparisonDashboardsByIdMutation,
  useDeleteApiSettingsReportEagleEyeDashboardsByIdMutation,
  useDeleteApiSettingsReportObjectDashboardsByIdMutation,
} from '@/shared/api/dashboardSettingsEnhancedApi';

import { useCallback, useMemo } from 'react';
import { useModal } from '@/shared/lib/hooks/useModal';
import { UNSUPPORTED_DASHBOARD_INFO_TEXT } from '@/bundles/Settings/components/REport/Dashboards/Dashboard/config';

export const useDeleteDashboard = (
  type: ReportDashboardType | `${ReportDashboardType}`,
) => {
  const deleteEagleEyeMutation =
    useDeleteApiSettingsReportEagleEyeDashboardsByIdMutation();
  const deleteObjectMutation =
    useDeleteApiSettingsReportObjectDashboardsByIdMutation();
  const deleteComparisonMutation =
    useDeleteApiSettingsReportComparisonDashboardsByIdMutation();

  const [deleteDashboard, options] = useMemo(() => {
    switch (type) {
      case ReportDashboardType.OBJECT: {
        return deleteObjectMutation;
      }
      case ReportDashboardType.EAGLE_EYE: {
        return deleteEagleEyeMutation;
      }
      case ReportDashboardType.COMPARISON_MODE: {
        return deleteComparisonMutation;
      }
      default: {
        return [
          () => {
            toastr.warning(UNSUPPORTED_DASHBOARD_INFO_TEXT);
          },
        ];
      }
    }
  }, [type]);

  const { confirm } = useModal();
  return [
    useCallback(async (id: ReportEagleEyeDashboard['id']) => {
      const res = await confirm({
        title: 'Delete dashboard',
        subtitle: 'Are you sure you want to delete this dashboard?',
      });
      if (!res) return false;
      return deleteDashboard({ id });
    }, []),
    options,
  ] as const;
};

import { CheckboxWithRefreshButton } from '@/bundles/REport/components/financials/CardList';
import { OBJECTABLE_TYPE_COLOR_MAP } from '@/bundles/REport/components/financials/consts';
import { DashboardLayout } from '@/bundles/Shared/components/layouts/dashboard/DashboardLayout';
import { ComparisonDashboardLayout } from '@/bundles/Shared/entities/comparison';
import {
  buildLayoutsId,
  DashboardContext,
  DashboardContextValue,
  ReportComparisonDashboardSection,
  ReportDashboardType,
  useDashboardContext,
} from '@/bundles/Shared/entities/dashboard';
import { useGetApiReportComparisonDashboardsByIdQuery } from '@/shared/api/dashboardSettingsEnhancedApi';
import { selectReportComparisonDashboardMetadataById } from '@/bundles/Shared/entities/dashboard/model/slices/comparisonSlice';
import { useFavoriteComparisonDashboardAssets } from '@/bundles/Shared/features/comparisonDashboard/lib';
import { useNavigateToInitialStateComparisonDashboardEffect } from '@/bundles/Shared/features/comparisonDashboard/lib/useNavigateToInitialStateComparisonDashboardEffect';
import { DashboardBoards } from '@/bundles/Shared/widgets/dashboard/board/ui/DashboardBoards';
import { DashboardBoardNavigation } from '@/bundles/Shared/widgets/dashboard/board/ui/navigation/DashboardBoardNavigation';
import { DEFAULT_LAYOUT_PROPS } from '@/bundles/Shared/widgets/dashboard/layout';
import { ComparisonDashboardWidgetStateIntersection } from '@/bundles/Shared/widgets/dashboard/widgets/config';
import { FinancialTableSingeDateWidgetContext } from '@/bundles/Shared/widgets/dashboard/widgets/financialTableSingeDate';
import { ComparisonDashboardWidget } from '@/bundles/Shared/widgets/dashboard/widgetsHelpers/ui/ComparisonDashboardWidget';
import { LegalEntity } from '@/entities/core/legalEntity';
import { DashboardSettingsPageToggle } from '@/pages/report/dashboards/ui/DashboardSettingsPageToggle';
import { cn } from '@/shared/lib/css/cn';
import { useNavigateToDasbhoardViewToBoard } from '@/shared/lib/hooks/navigation/dashboardsNavitation';
import { useSearchParams } from '@/shared/lib/hooks/navigation/useSearchParams';
import { useAppSelector } from '@/shared/lib/hooks/redux';
import useBoolean from '@/shared/lib/hooks/useBoolean';
import { ExtractParams, ROUTES_ROOT } from '@/shared/lib/hooks/useNavigation';
import { xorLegalEntityIds } from '@/shared/lib/legalEntitiy/lib';
import { includesInLowerCase, mapListToIds } from '@/shared/lib/listHelpers';
import { ResponsiveGridLayout } from '@/shared/lib/react-grid-layout/ResponsiveGridLayout';
import { AnimationLoader } from '@/stories/AnimationLoader/AnimationLoader';
import { Button } from '@/stories/Button/Button';
import { Icon } from '@/stories/Icon/Icon';
import { IconButton } from '@/stories/IconButton/IconButton';
import { ProjectCard } from '@/stories/ProjectCard/ProjectCard';
import { SearchInput } from '@/stories/FormControls/Inputs/SearchInput/SearchInput';
import FavoriteIconButton from '@/stories/ProjectCard/FavoriteIconButton';
import ProjectCardList from '@/stories/ProjectCard/ProjectCardList';
import { IAsset } from '@/types/Asset';
import { RouteComponentProps, useParams } from '@reach/router';
import ListLayout from 'bundles/Shared/components/layouts/screenWithListNavigationLayout/ScreenWithListNavigationLayout';
import LegalEntitiesIconWithTooltip from 'bundles/Shared/entities/legalEntity/ui/LegalEntitiesIconWithTooltip';
import { isEqual, orderBy, sortBy } from 'lodash-es';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import React, { FC, PropsWithChildren, useMemo, useState } from 'react';

const DashboardWidget = ({
  assetIds,
  allAssets,
  widgetSection: widget,
  legalEntityIds: allLegalEntityIds,
}: {
  widgetSection: ReportComparisonDashboardSection;
  assetIds: IAsset['id'][];
  allAssets: Pick<IAsset, 'id' | 'legalEntities' | 'name'>[];
  legalEntityIds: LegalEntity['id'][];
}) => {
  const { boardId, dashboardId } = useDashboardContext();

  const reportComparisonDashboardsState = useAppSelector((state) =>
    selectReportComparisonDashboardMetadataById(
      state,
      buildLayoutsId({
        dashboardId,
        boardId,
      }),
    ),
  )!;

  const state = reportComparisonDashboardsState.widgetsState[
    widget.id
  ] as unknown as ComparisonDashboardWidgetStateIntersection;

  const legalEntityIds = xorLegalEntityIds(
    allLegalEntityIds,
    state.excludedLegalEntityIds ?? [],
  );

  const contextAssets = useMemo(() => {
    const allAssetsMap = new Map(allAssets.map((a) => [a.id, a]));

    return assetIds
      .map((assetId) => {
        return allAssetsMap.get(Number(assetId));
      })
      .filter(Boolean);
  }, [allAssets, assetIds]);

  return (
    <ComparisonDashboardWidget
      mode="view"
      dashboardId={dashboardId}
      widgetSection={widget}
      boardId={boardId}
      legalEntityIds={legalEntityIds}
      context={
        {
          columnVisibilityEnabled: true,
          assets: contextAssets as IAsset[],
        } satisfies FinancialTableSingeDateWidgetContext
      }
    />
  );
};

const ExplicitSuspense = ({
  children,
  fallback,
  isLoading,
}: PropsWithChildren & {
  isLoading: boolean;
  fallback: React.ReactNode;
}) => {
  if (isLoading) return fallback;
  return children;
};

export type ReportComparisonDashboardObject = Pick<
  IAsset,
  'id' | 'name' | 'legalEntities' | 'pictureUrl' | 'slug'
> & { _type: 'asset'; _dashboardId: string };

export const ComparisonModePage: FC<RouteComponentProps> = () => {
  const { isItemFavorite, toggleItemFavorite } =
    useFavoriteComparisonDashboardAssets();
  const { value: fullscreen, toggle: toggleFullscreen } = useBoolean();
  const [searchText, setSearchText] = useState('');
  const [searchParams] = useSearchParams();
  const boardSlug = searchParams.get('boardId') ?? '';

  const [compareObjs, setCompareObjs] = useState<
    ReportComparisonDashboardObject[]
  >([]);
  const [compareObjsForRequest, setCompareObjsForRequest] = useState<
    ReportComparisonDashboardObject[]
  >([]);

  const { dashboardId: dashboardSlug } =
    useParams<
      ExtractParams<typeof ROUTES_ROOT.report.comparisonDashboards.fullPath>
    >();

  const { data, isFetching } = useGetApiReportComparisonDashboardsByIdQuery({
    id: dashboardSlug ?? '',
  });
  const dashboardId = data?.id ?? '';
  const selectedBoard = data?.boards.find((b) => b.slug === boardSlug);

  const navigateToBoard = useNavigateToDasbhoardViewToBoard();

  const isObjCardSelected = (obj: ReportComparisonDashboardObject): boolean => {
    return Boolean(
      compareObjs?.find((o) => obj.id === o.id && obj._type === o._type),
    );
  };

  const onObjCardClick = (obj: ReportComparisonDashboardObject): void => {
    setCompareObjs((prev) =>
      isObjCardSelected(obj)
        ? prev.filter((o) => !(o._type === obj._type && o.id === obj.id))
        : [...prev, obj],
    );
  };

  const isCompareObjAndCompareObjsForRequestEqual = useMemo(
    () =>
      isEqual(sortBy(compareObjs, 'id'), sortBy(compareObjsForRequest, 'id')),
    [compareObjs, compareObjsForRequest],
  );

  useNavigateToInitialStateComparisonDashboardEffect({
    compareObjs,
    comparisonDashboardData: data,
    callbackWithFirstObjects: (firstObjects) => {
      setCompareObjs(firstObjects);
      setCompareObjsForRequest(firstObjects);
    },
  });

  const dashboardContextValue = useMemo<DashboardContextValue>(
    () => ({
      dashboardId: data?.id ?? '',
      boardId: selectedBoard?.id ?? '',
      dashboardType: ReportDashboardType.COMPARISON_MODE,
      dashboardSlug: data?.slug ?? '',
      boardSlug: selectedBoard?.slug ?? '',
    }),
    [data, selectedBoard],
  );

  const filteredAssets = useMemo(() => {
    const ordered = orderBy(data?.assets ?? [], [
      (asset) =>
        compareObjsForRequest.find(
          (o) => o._type === 'asset' && o.id === asset.id,
        )
          ? 0
          : 1,
      (asset) =>
        isItemFavorite({
          dashboardId,
          assetId: asset.id,
        })
          ? 0
          : 1,
      (asset) => asset.name,
    ]);

    return ordered.filter((asset) =>
      includesInLowerCase(asset.name, searchText),
    ) as IAsset[];
  }, [data, searchText, isItemFavorite, compareObjsForRequest]);

  const assetIds = useMemo(() => {
    return mapListToIds(compareObjs.filter(({ _type }) => _type === 'asset'));
  }, [compareObjs]);

  const assetIdsForRequest = useMemo(() => {
    return mapListToIds(
      compareObjsForRequest.filter(({ _type }) => _type === 'asset'),
    );
  }, [compareObjsForRequest]);

  const legalEntityIdsForRequest = useMemo(() => {
    return (
      compareObjsForRequest
        .map(({ id }) => data?.assets.find((a) => a.id === id))
        .filter(Boolean)
        .flatMap((a) => a.legalEntities.map((l) => l.id)) ?? []
    );
  }, [data, compareObjsForRequest]);

  const isEnoughCompareObjs = useMemo(() => {
    return compareObjs.length > 1;
  }, [compareObjs]);

  return (
    <DashboardContext.Provider value={dashboardContextValue}>
      <ComparisonDashboardLayout>
        <ComparisonDashboardLayout.SideBar>
          <ExplicitSuspense
            isLoading={isFetching}
            fallback={<ComparisonDashboardLayout.SideBarLoading />}
          >
            {data && (
              <DashboardLayout.Header className="py-6">
                <DashboardLayout.Header.Title
                  withoutBackButton
                  classes={{
                    title: 'header4-bold',
                  }}
                  subtitle="Report"
                  title="Comparison"
                />
                <div className="grow" />
                <DashboardSettingsPageToggle
                  queryParams={{
                    assetId: assetIds[0],
                  }}
                />
              </DashboardLayout.Header>
            )}

            <div className="flex flex-col gap-4 overflow-auto px-6">
              <SearchInput
                size="s"
                placeholder="Search"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
              />
              <div className="flex items-center gap-4">
                <div className="flex grow items-center gap-2 rounded-[8px] bg-neutral-200 px-3 py-2">
                  <Icon iconName="magic" />
                  <p className="secondary-semibold text-neutral-550">
                    {!isEnoughCompareObjs
                      ? 'Select at least 2 Assets to compare'
                      : `${assetIds.length} Assets selected`}
                  </p>
                  <div className="grow" />
                </div>
                {isEnoughCompareObjs && (
                  <Button
                    onClick={() => {
                      setCompareObjsForRequest(compareObjs);
                    }}
                    disabled={isCompareObjAndCompareObjsForRequestEqual}
                    variant="success"
                    size="s"
                  >
                    Compare
                  </Button>
                )}
              </div>
              <OverlayScrollbarsComponent>
                <ProjectCardList>
                  {filteredAssets.map((asset) => {
                    const obj = {
                      ...asset,
                      _type: 'asset',
                    } satisfies ReportComparisonDashboardObject;

                    return (
                      <ProjectCard
                        key={asset.id}
                        classes={{
                          image: 'hidden',
                        }}
                        className="cursor-pointer"
                        onClick={() => onObjCardClick(obj)}
                        pictureUrl={undefined}
                        selected={isObjCardSelected(obj)}
                        header={
                          <div className="flex h-full items-center gap-2">
                            <p className="inline-semibold text-neutral-800">
                              {asset.name}
                            </p>
                            <div className="grow" />
                            <LegalEntitiesIconWithTooltip
                              legalEntities={asset.legalEntities ?? []}
                            />
                            <FavoriteIconButton
                              size="m"
                              selected={isItemFavorite({
                                dashboardId,
                                assetId: asset.id,
                              })}
                              onClick={(e) => {
                                e.stopPropagation();
                                toggleItemFavorite({
                                  dashboardId,
                                  assetId: asset.id,
                                });
                              }}
                            />
                            <CheckboxWithRefreshButton
                              checked={isObjCardSelected(obj)}
                              onChange={() => onObjCardClick(obj)}
                            />
                          </div>
                        }
                      >
                        <ListLayout.NavigationCardList.Label
                          color={OBJECTABLE_TYPE_COLOR_MAP.asset}
                          text="Asset"
                        />
                      </ProjectCard>
                    );
                  })}
                </ProjectCardList>
              </OverlayScrollbarsComponent>
            </div>
          </ExplicitSuspense>
        </ComparisonDashboardLayout.SideBar>
        <ExplicitSuspense
          isLoading={isFetching}
          fallback={<AnimationLoader className="" />}
        >
          <ComparisonDashboardLayout.Body
            className={cn({
              'ml-[400px]': !fullscreen,
            })}
          >
            <DashboardLayout.StickyHeader className="gap-4 px-8 pt-4">
              <div className="flex items-center gap-4">
                <span className="header5-bold text-neutral-850">
                  {data?.name}
                </span>
                <div className="grow" />
                <IconButton
                  onClick={toggleFullscreen}
                  iconName={fullscreen ? 'collapse' : 'expand'}
                />
              </div>
              <div className="h-px w-full bg-neutral-300" />
              {data?.boards?.length > 1 && (
                <div className="flex pb-2">
                  <DashboardBoardNavigation
                    boards={data.boards}
                    selectedBoardId={selectedBoard?.id}
                    onBoardChange={navigateToBoard}
                  />
                </div>
              )}
            </DashboardLayout.StickyHeader>
            <ComparisonDashboardLayout.BoardsBody>
              {data && (
                <DashboardBoards>
                  <ResponsiveGridLayout
                    {...DEFAULT_LAYOUT_PROPS}
                    className="w-full"
                    useCSSTransforms
                  >
                    {selectedBoard?.sections.map((widgetSection) => (
                      <div
                        data-grid={widgetSection.position}
                        key={widgetSection.id}
                      >
                        <DashboardWidget
                          allAssets={data.assets}
                          assetIds={assetIdsForRequest}
                          legalEntityIds={legalEntityIdsForRequest}
                          comparisonDashboardId={dashboardId}
                          widgetSection={widgetSection}
                        />
                      </div>
                    ))}
                  </ResponsiveGridLayout>
                </DashboardBoards>
              )}
            </ComparisonDashboardLayout.BoardsBody>
          </ComparisonDashboardLayout.Body>
        </ExplicitSuspense>
      </ComparisonDashboardLayout>
    </DashboardContext.Provider>
  );
};

import { EntityTagLabel } from '@/bundles/Shared/entities/tag';
import { WidgetPreview } from '@/bundles/Shared/widgets/dashboard/widgets/common';
import { createMapBy } from '@/bundles/Shared/widgets/dashboard/widgets/common/lib/utils';
import { settingsEmailsCustomTemplateEnhancedApi } from '@/entities/emails/customTemplates/api/settingsEmailsCustomTemplateEnhancedApi';
import type {
  CustomTemplateAssetDto,
  CustomTemplateReportBuilderTemplateDto,
} from '@/entities/emails/customTemplates/api/settingsEmailsCustomTemplateGeneratedApi';
import { useItemsFilterByText } from '@/shared/lib/hooks/useItemsFilterByText';
import { useModal, type DialogProps } from '@/shared/lib/hooks/useModal';
import { includesInLowerCase } from '@/shared/lib/listHelpers';
import { addOrRemoveItemInList } from '@/shared/lib/listHelpers/addOrRemoveItemInList';
import { GrowDiv } from '@/shared/ui/GrowDiv';
import { AvailableItemsUI } from '@/shared/widgets/availableItemsUI/component';
import { Button } from '@/stories/Button/Button';
import { Modal } from '@/stories/Modals/Modal/Modal';
import { ModalHeaderWithSubtitle } from '@/stories/Modals/ModalHeader/ModalHeaderWithSubtitle/ModalHeaderWithSubtitle';
import { SearchInput } from '@/stories/FormControls/Inputs/SearchInput/SearchInput';
import type { IAsset } from '@/types/Asset';
import { useEmailCustomTemplateFormSchemaFormContext } from '@/widgets/email/custom/template/lib';
import { ReactComponent as AssetSvg } from 'fonts/sre-icons/Icons/asset.svg';
import { entries, groupBy, isEmpty, map, uniqueId } from 'lodash-es';
import { useMemo, useState } from 'react';

export const AvailableAttachmentsModal = ({
  onClose,
  onSubmit,
  attachments: currentAttachments,
}: DialogProps<
  {
    assetId: IAsset['id'];
    reportBuilderTemplateId: CustomTemplateReportBuilderTemplateDto['id'];
  }[]
> & {
  attachments: Map<
    CustomTemplateReportBuilderTemplateDto['id'],
    Set<IAsset['id']>
  >;
}) => {
  const [step, setStep] = useState<1 | 2>(1);
  const { data: metaData } =
    settingsEmailsCustomTemplateEnhancedApi.useGetApiEmailBuilderCustomTemplatesMetaQuery();

  const reportTemplates = metaData?.reportBuilderTemplates ?? [];
  const assets = metaData?.assets ?? [];

  const [selectedTemplateId, setSelectedTemplateId] = useState<
    CustomTemplateReportBuilderTemplateDto['id'] | null
  >(null);

  const [selectedAssetIds, setSelectedAssetIds] = useState<
    CustomTemplateAssetDto['id'][]
  >([]);

  const sortedReportTemplates = useMemo(
    () => reportTemplates.toSorted((a, b) => a.name.localeCompare(b.name)),
    [metaData?.reportBuilderTemplates],
  );

  const sortedAssets = useMemo(
    () => assets.toSorted((a, b) => a.name.localeCompare(b.name)),
    [metaData?.assets],
  );

  const { inputProps: templatesInputProps, filteredItems: filteredTemplates } =
    useItemsFilterByText(sortedReportTemplates, (o, searchText) => {
      const filterByTitle = includesInLowerCase(o.name, searchText);
      return filterByTitle;
    });
  const { inputProps: assetsInputProps, filteredItems: filteredAssets } =
    useItemsFilterByText(sortedAssets, (o, searchText) => {
      const filterByTitle = includesInLowerCase(o.name, searchText);
      return filterByTitle;
    });

  const isTemplatesStep = step === 1;
  const isAssetsStep = step === 2;

  const modalHeaderTitle = isTemplatesStep
    ? 'Available Templates'
    : 'Available Assets';

  const isAssetDisabled = (assetId: IAsset['id']): boolean => {
    if (selectedTemplateId == null) return false;

    const assetsSet = currentAttachments.get(selectedTemplateId);

    if (assetsSet == null) return false;

    return assetsSet.has(assetId);
  };

  const cancelButton = <Button onClick={onClose}>Cancel</Button>;
  const backButton = (
    <Button
      onClick={() => {
        setStep(1);
      }}
    >
      Back
    </Button>
  );
  const nextButton = (
    <Button
      variant="success"
      disabled={selectedTemplateId == null}
      onClick={() => {
        setStep(2);
      }}
    >
      Next
    </Button>
  );
  const submitButton = (
    <Button
      variant="success"
      disabled={selectedAssetIds.length === 0}
      onClick={() => {
        onSubmit?.(
          selectedAssetIds.map((assetId) => ({
            assetId,
            reportBuilderTemplateId: selectedTemplateId,
          })),
        );
      }}
    >
      Submit
    </Button>
  );

  return (
    <Modal
      toggle={onClose}
      size="700"
      contentClassName="min-h-full"
      classes={{
        body: 'flex flex-col gap-4 max-h-full',
      }}
      header={
        <ModalHeaderWithSubtitle
          title={modalHeaderTitle}
          subtitle={`Step ${step} of 2`}
          order="subtitle-title"
        />
      }
      actions={
        <Modal.Actions>
          {isTemplatesStep && cancelButton}
          {isTemplatesStep && nextButton}
          {isAssetsStep && backButton}
          {isAssetsStep && submitButton}
        </Modal.Actions>
      }
    >
      {isTemplatesStep && (
        <SearchInput
          {...templatesInputProps}
          placeholder="Search By Template Name"
        />
      )}
      {isAssetsStep && (
        <SearchInput {...assetsInputProps} placeholder="Search By Asset Name" />
      )}
      <AvailableItemsUI>
        {isTemplatesStep &&
          filteredTemplates.map((o) => {
            return (
              <AvailableItemsUI.Card
                key={o.id}
                withRadioButton
                checked={selectedTemplateId === o.id}
                onChange={() => {
                  setSelectedTemplateId((prev) =>
                    prev === o.id ? null : o.id,
                  );
                }}
              >
                <WidgetPreview
                  classes={{
                    default: 'group-hover/item:opacity-0',
                    hovered: 'group-hover/item:opacity-100',
                  }}
                />
                <span className="body-semibold text-neutral-800">{o.name}</span>
                <div className="grow" />
                {!isEmpty(o.tags) && (
                  <EntityTagLabel.List className="ml-auto">
                    {o.tags.map((tag) => (
                      <EntityTagLabel key={tag} label={tag} />
                    ))}
                  </EntityTagLabel.List>
                )}
              </AvailableItemsUI.Card>
            );
          })}

        {isAssetsStep &&
          filteredAssets.map((o) => {
            const assetDisabled = isAssetDisabled(o.id);
            return (
              <AvailableItemsUI.Card
                key={o.id}
                disabled={assetDisabled}
                tooltipProps={{
                  disabled: !assetDisabled,
                  mainText:
                    'Asset is already used with selected Report Builder Template',
                }}
                withCheckbox
                checked={selectedAssetIds.includes(o.id) || assetDisabled}
                onChange={() => {
                  setSelectedAssetIds((prev) => {
                    return addOrRemoveItemInList(o.id, prev);
                  });
                }}
              >
                <AssetSvg className="h-8 w-8 text-neutral-500 hover:fill-attention-100" />
                <span className="body-semibold text-neutral-800">{o.name}</span>
                <GrowDiv />
              </AvailableItemsUI.Card>
            );
          })}
      </AvailableItemsUI>
    </Modal>
  );
};

const EMAIL_TEMPLATE_FORM_REPORT_ID_PREFIX = 'report-id-created-for-form';

/**
 * Check if the id is a form email template report unique id
 * For FE state management purposes
 */
export const isFormEmailTemplateReportUniqueId = (id: string) =>
  id.startsWith(EMAIL_TEMPLATE_FORM_REPORT_ID_PREFIX);

/**
 * Create a unique id for form email template report
 * For FE state management purposes
 */
export const createFormEmailTemplateReportUniqueId = () =>
  uniqueId(EMAIL_TEMPLATE_FORM_REPORT_ID_PREFIX);

export const useEmailsCustomTemplateAddToFormAttachments = () => {
  const { openModal } = useModal();
  const formContext = useEmailCustomTemplateFormSchemaFormContext();
  const formReportsWatch = formContext.watch('reports');

  const { data: metaData } =
    settingsEmailsCustomTemplateEnhancedApi.useGetApiEmailBuilderCustomTemplatesMetaQuery();

  const handleAddAttachments = async () => {
    const attachments = new Map(
      map(
        entries(groupBy(formReportsWatch, 'reportBuilderTemplate.id')),
        ([key, value]) =>
          [key, new Set(value.flatMap((a) => a.asset.id))] as const,
      ),
    );
    const res = await openModal(AvailableAttachmentsModal, {
      attachments,
    });

    if (res == null) {
      return;
    }
    const reportTemplatesMap = createMapBy(
      metaData?.reportBuilderTemplates ?? [],
      'id',
    );
    const assetsMap = createMapBy(metaData?.assets ?? [], 'id');

    const newReports = res.map((o) => {
      return {
        id: createFormEmailTemplateReportUniqueId(),
        reportBuilderTemplate: reportTemplatesMap.get(
          o.reportBuilderTemplateId,
        )!,
        asset: assetsMap.get(String(o.assetId))!,
      };
    });

    const newFormReports = (formReportsWatch ?? []).concat(newReports);

    formContext.setValue('reports', newFormReports, {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    });
  };
  return handleAddAttachments;
};

import RecapPageButton from '@/bundles/Shared/components/RecapPageButton';
import {
  ColumSizeWidgetState,
  DashboardWidgetCard,
  DashboardWidgetTableCard,
  DateWidgetState,
  DisplayedGroupsWidgetContext,
  DisplayedGroupsWidgetState,
  PeriodTypeWidgetState,
  WidgetStateColumns,
  WidgetStatePeriod,
} from '@/bundles/Shared/widgets/dashboard/widgets/common';
import { useWidgetFlags } from '@/bundles/Shared/widgets/dashboard/widgets/common/lib/useWidgetFlags';
import { TableVizConfig } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/table/model';
import {
  useKpiWidgetColumnVisibilityState,
  useWidgetTableColumnSize,
  useWidgetTableVizConfigUpdateEffect,
} from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/table/tableStateSync';
import { useTableWidgetExportFeature } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/table/useTableWidgetExportFeature';
import { WidgetTablePlaceholder } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/table/WidgetTable';
import { useWidgetFullScreen } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/WidgetFullScreen';
import { useAgGridRef, useLoadingOverlayEffect } from '@/lib/ag-grid/utils';
import { KpiTableWidgetDto } from '@/shared/api/dashboardsGeneratedApi';
import { formatToDateStringForRequest } from '@/shared/lib/converters';
import { cn } from '@/shared/lib/css/cn';
import { ThinTabGroup } from '@/stories/Tabs/ThinTabGroup/ThinTabGroup';
import { WidgetStateReckonerPeriodType } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/state/WidgetStatePeriodType';
import {
  KPI_TABLE_WIDGET_EXPORT_PARAMS,
  KpiTableGroupingType,
  KpiTableWidgetConfig,
  KpiTableWidgetSection,
  useAutoGroupColDef,
} from 'bundles/Shared/widgets/dashboard/widgets/kpiTable';
import { KpiTableWidgetTable } from 'bundles/Shared/widgets/dashboard/widgets/kpiTable/ui/KpiTableWidgetTable';
import {
  WidgetConfigProps,
  WidgetContextProps,
  WidgetProps,
  WidgetStateProps,
} from 'bundles/Shared/widgets/dashboard/widgets/model';
import dayjs from 'dayjs';
import { capitalize } from 'lodash-es';
import { useRef } from 'react';
import Calendar from 'stories/FlexibleFilterByPeriods/calendar/Calendar';

const GROUPING_TYPES = [
  'assets',
  'segments',
] as const satisfies readonly KpiTableGroupingType[];

export type KpiTableWidgetState = DateWidgetState &
  ColumSizeWidgetState &
  DisplayedGroupsWidgetState & {
    groupingType: KpiTableGroupingType;
  } & PeriodTypeWidgetState;

export function KpiTableWidget(
  props: WidgetProps<KpiTableWidgetDto, KpiTableWidgetSection> &
    WidgetStateProps<KpiTableWidgetState> &
    WidgetConfigProps<KpiTableWidgetConfig> &
    WidgetContextProps<DisplayedGroupsWidgetContext> &
    PropsWithClassName,
) {
  const {
    widgetSection,
    data,
    state,
    onStateChange,
    settings,
    onSettingsChange,
    isFetching,
    context,
    mode,
    className,
  } = props;
  const { shouldDisplayPlaceholder, shouldDisplayData } = useWidgetFlags(props);

  const { viz_config } = widgetSection.widgetConfig;
  const gridRef = useAgGridRef();
  const wrapperDivRef = useRef<HTMLDivElement>(null);
  useLoadingOverlayEffect({
    isLoading: isFetching,
    grid: gridRef.current,
  });

  const headerBackground =
    widgetSection.widgetConfig.viz_config?.header_background;

  const exportFeature = useTableWidgetExportFeature(
    {
      gridRef,
      mode,
      widgetTitle: widgetSection.title,
      widgetId: widgetSection.id,
      state,
    },
    KPI_TABLE_WIDGET_EXPORT_PARAMS,
  );

  const columnSizeFeature = useWidgetTableColumnSize({
    gridRef,
    state,
    onStateChange,
  });

  useWidgetTableVizConfigUpdateEffect({
    grid: gridRef.current,
    vizConfig: viz_config as TableVizConfig,
    mode,
  });
  const columnVisibilityFeature = useKpiWidgetColumnVisibilityState({
    grid: gridRef.current,
    state,
    onStateChange,
  });

  const widgetStateFullScreenFeature = useWidgetFullScreen(wrapperDivRef);

  const autoGroupColDef = useAutoGroupColDef({
    groupingType: state.groupingType,
    ref: gridRef,
    mode,
    colDef: {
      cellClass: exportFeature.autoGroupColumnDef.cellClass,
    },
    headerBackground,
  });

  const { default_options } = widgetSection.widgetConfig;

  return (
    <DashboardWidgetTableCard
      {...props}
      ref={wrapperDivRef}
      className={cn(mode === 'edit' ? 'h-[500px]' : 'h-full', className)}
    >
      <DashboardWidgetCard.Header>
        <div className="flex">
          <DashboardWidgetCard.Header.Title>
            {widgetSection.title}
          </DashboardWidgetCard.Header.Title>
          {mode !== 'pdf' && (
            <RecapPageButton
              name={widgetSection.recapPage?.name}
              slug={widgetSection.recapPage?.slug}
            />
          )}
        </div>
        <div className="grow" />
        {!widgetStateFullScreenFeature.isFullscreen &&
          context.columnVisibilityEnabled && (
            <WidgetStateColumns
              onColumnSizeChange={columnSizeFeature.handleColumnSizeChange}
              state={state}
              groupsState={{
                groups: state.displayedGroups ?? [],
              }}
              onColumnStateChange={columnVisibilityFeature.onChange}
              vizConfig={viz_config as TableVizConfig}
              columns={data?.columns ?? []}
            />
          )}
        {mode !== 'pdf' && (
          <div className="flex gap-2">
            <ThinTabGroup
              selectedItem={state.groupingType}
              onSelectedItemChange={(tab) =>
                onStateChange({
                  ...state,
                  groupingType: tab.id as KpiTableGroupingType,
                })
              }
              items={GROUPING_TYPES.map((type) => ({
                id: type,
                label: capitalize(type),
              }))}
            />
          </div>
        )}
        {mode !== 'pdf' && <widgetStateFullScreenFeature.IconButton />}
      </DashboardWidgetCard.Header>
      {mode !== 'pdf' && (
        <DashboardWidgetCard.Panel>
          {default_options.period_type === 'week' ? (
            // move week to WidgetStatePeriod
            <Calendar
              selectionMode="weekly"
              value={[
                dayjs(state.date).startOf('w'),
                dayjs(state.date).endOf('w'),
              ]}
              onChange={(date) => {
                onStateChange({
                  ...state,
                  date: formatToDateStringForRequest(date[0]),
                });
              }}
            />
          ) : (
            <WidgetStatePeriod state={state} onStateChange={onStateChange} />
          )}
          {default_options.period_type !== 'week' && (
            <WidgetStateReckonerPeriodType
              state={state}
              onStateChange={onStateChange}
              periodTypes={
                widgetSection.widgetConfig?.period_types ??
                widgetSection.defaultOptions?.availablePeriodTypes ??
                []
              }
            />
          )}
          <div className="grow" />

          <exportFeature.ExportButtonComponent />
        </DashboardWidgetCard.Panel>
      )}
      {shouldDisplayPlaceholder && <WidgetTablePlaceholder />}
      {shouldDisplayData && (
        <KpiTableWidgetTable
          headerBackground={headerBackground}
          ref={gridRef}
          vizConfig={viz_config as TableVizConfig}
          data={data}
          state={state}
          onStateChange={onStateChange}
          settings={settings}
          onSettingsChange={onSettingsChange}
          mode={mode}
          columnsConfig={widgetSection.widgetConfig.columns}
          onRowDataUpdated={columnSizeFeature.handleRowDataUpdated}
          excelStyles={exportFeature.excelStyles}
          autoGroupColumnDef={autoGroupColDef}
        />
      )}
    </DashboardWidgetTableCard>
  );
}

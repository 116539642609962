import { COLUMN_CONFIGURATION_LABEL_INFO_TEXT } from '@/bundles/Shared/widgets/dashboard/widgets/common/config';
import { TextDisplayStrategyField } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/fields/TextDisplayStrategyField';
import { WidgetTableColumnSettingsColumnType } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/table/form';
import { DialogProps } from '@/shared/lib/hooks/useModal';
import { EAGLE_EYE_DASHBOARD_SECTION_TYPE } from 'bundles/Shared/entities/dashboard';
import {
  CreateNewForm,
  EditPanelActions,
  FormulaField,
  PeriodShiftField,
  TextVariableField,
  useUpdateWidgetConfig,
  useWidgetConfig,
} from 'bundles/Shared/widgets/dashboard/widgets/common';
import {
  NumericColumnSettingsFields,
  TextColumnSettingsFields,
} from 'bundles/Shared/widgets/dashboard/widgets/common/ui/fields/ColumnSettingsFields';
import { TotalCalculationStrategyField } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/fields/TotalCalculationStrategyField';
import { TableVizConfigColumn } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/table/model';
import {
  getKpiTableDefaultColumnForm,
  getKpiTableResolver,
  KpiTableWidgetConfigColumn,
  KpiTableWidgetConfigColumnForm,
  removeColumn,
  transformColumnConfigToForm,
  upsertColumn,
} from 'bundles/Shared/widgets/dashboard/widgets/kpiTable';
import { FormProvider, useForm } from 'react-hook-form';
import { Field } from 'stories/Field/Field';
import { Input } from 'stories/FormControls/Inputs/Input/Input';
import { FieldsContainer } from 'stories/Field/Field';
import { SectionField } from 'stories/Field/FieldsWrappers';
import { SidePanel } from 'stories/Modals/Modal/Modal';
import { capitalize } from 'lodash-es';
import { ColumnHeaderField } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/fields/ColumnHeaderField';
import { SparklineSettingsField } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/fields/SparklineSettingsField';
import { AdjustmentField } from '@/bundles/Shared/widgets/dashboard/widgets/common/features/adjustments/ui/AdjustmentField';

interface Props extends DialogProps<CreateNewForm> {
  type: 'create' | 'edit';
  columnType: WidgetTableColumnSettingsColumnType;
  columnConfig?: KpiTableWidgetConfigColumn;
  columnSettings?: TableVizConfigColumn;
  groupId?: string;
}

export function KpiTableWidgetConfigColumnPanel({
  type,
  columnConfig,
  columnSettings,
  columnType,
  groupId,
  onClose,
  onSubmit,
}: Props) {
  const methods = useForm<KpiTableWidgetConfigColumnForm & CreateNewForm>({
    resolver: getKpiTableResolver(columnType),
    mode: 'all',
    defaultValues: {
      ...getKpiTableDefaultColumnForm(columnType),
      ...(columnConfig &&
        columnSettings &&
        transformColumnConfigToForm(columnConfig, columnSettings)),
    },
  });

  const { control, register, handleSubmit } = methods;

  const { widget } =
    useWidgetConfig<typeof EAGLE_EYE_DASHBOARD_SECTION_TYPE.KPI_TABLE>();
  const [updateConfig] = useUpdateWidgetConfig(
    EAGLE_EYE_DASHBOARD_SECTION_TYPE.KPI_TABLE,
  );

  const handleRemove = () => {
    updateConfig({
      config: removeColumn(
        {
          columnKey: columnConfig!.key.toString(),
        },
        widget.widgetConfig,
      ),
    });
    onClose();
  };

  const handleSubmitClick = handleSubmit(async (values) => {
    const config = upsertColumn(values, widget.widgetConfig, { groupId });
    await updateConfig({ config });

    onSubmit?.({ createNew: values.createNew });
  });

  const renderLabelField = () => {
    return (
      <Field note={COLUMN_CONFIGURATION_LABEL_INFO_TEXT} labelText="Title">
        <Input
          placeholder="Enter Primary Title"
          className="w-full"
          {...register('label')}
        />
      </Field>
    );
  };

  const renderNumericColumnFields = () => {
    return (
      <>
        {renderLabelField()}
        <FormulaField control={control} name="expression" required />
        <AdjustmentField control={control} name="adjustment" />
        <SectionField labelText="Period Shift">
          <PeriodShiftField control={control} name="period_shift" />
        </SectionField>
        <TotalCalculationStrategyField
          control={control}
          name="total_calculation_strategy"
        />
        <NumericColumnSettingsFields
          columnsConfig={widget.widgetConfig.columns}
          vizConfig={widget.widgetConfig.viz_config}
          control={control}
        />
      </>
    );
  };

  const renderTextColumnFields = () => {
    return (
      <>
        {renderLabelField()}
        <TextVariableField control={control} name="expression" required />
        <SectionField labelText="Period Shift">
          <PeriodShiftField control={control} name="period_shift" />
        </SectionField>
        <TextDisplayStrategyField
          control={control}
          name="text_display_strategy"
        />
        <TextColumnSettingsFields control={control} />
      </>
    );
  };

  const renderSparklineColumnFields = () => {
    return (
      <>
        {renderLabelField()}
        <FormulaField control={control} name="expression" required />
        <AdjustmentField control={control} name="adjustment" />
        <SparklineSettingsField control={control} />
        <ColumnHeaderField control={control} name="header" />
      </>
    );
  };

  const renderColumnFields = () => {
    switch (columnType) {
      case 'numeric':
        return renderNumericColumnFields();
      case 'text':
        return renderTextColumnFields();
      case 'sparkline':
        return renderSparklineColumnFields();
      default:
        return null;
    }
  };

  return (
    <FormProvider {...methods}>
      <SidePanel
        header={
          type === 'create'
            ? `Create ${capitalize(columnType)} Column`
            : `Edit ${capitalize(columnType)} Column`
        }
        toggle={onClose}
        actions={
          <EditPanelActions
            type={type}
            onClose={onClose}
            onSubmit={handleSubmitClick}
            onRemove={handleRemove}
          />
        }
      >
        <FieldsContainer>{renderColumnFields()}</FieldsContainer>
      </SidePanel>
    </FormProvider>
  );
}

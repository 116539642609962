import { TRootState } from '@/app/stores';
import { listenerMiddleware } from '@/app/stores/listener';
import {
  getInitialStateForReportChartWidget,
  getInitialStateForReportKpiTableSingleDateWidget,
  getInitialStateForReportKpiTableWidget,
  ReportDashboardType,
} from '@/bundles/Shared/entities/dashboard';
import { DEFAULT_WIDGET_GROUPING_TYPE } from '@/bundles/Shared/widgets/dashboard/widgets/common/config';
import { getReckonerPeriodByPeriodTypeAndDate } from '@/bundles/Shared/widgets/dashboard/widgets/common/lib/utils';
import { isChartCategorical } from '@/bundles/Shared/widgets/dashboard/widgets/xyChartSingleKpi/lib/common';
import type { XYChartSingleKpiWidgetSection } from '@/bundles/Shared/widgets/dashboard/widgets/xyChartSingleKpi/model';
import type { XYChartSingleKpiWidgetState } from '@/bundles/Shared/widgets/dashboard/widgets/xyChartSingleKpi/widget';
import { coreAssetsReportBuilderReportsEnhancedApi as objectLevelApi } from '@/entities/report/reportBuilder/api';
import {
  AssetsReportBuilderReportGroupDto,
  AssetsReportBuilderReportWidgetDto,
  PostApiCoreAssetsReportBuilderReportsByReportIdWidgetsAndIdSnapshotApiArg,
} from '@/entities/report/reportBuilder/api/coreAssetsReportBuilderReportsGeneratedApi';
import { reportingEnhancedApi } from '@/entities/reporting/api/reportingEnhancedApi';
import type { ReportWidgetDto } from '@/entities/reporting/api/reportingGeneratedApi';
import {
  getReportingEagleEyeObjectsState,
  getReportingEagleEyeWidgetObjectsWidgetState,
} from '@/entities/reporting/lib/eagleEye';
import { formatToDateStringForRequest } from '@/shared/lib/converters';
import {
  endOf,
  shiftDateSixMonthsBackward,
  startOf,
  yesterdayDate,
} from '@/shared/lib/date';
import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit';
import { getInitialStateForGlobalLeaseTableWidget } from 'bundles/Shared/widgets/dashboard/widgets/globalLeaseTable/lib';
import { omit } from 'lodash-es';
import { CamelCasedProperties } from 'type-fest';
import { REPORT_BUILDER_WIDGET_TYPES } from '@/entities/report/reportBuilder/model/types';

type GroupedWidgetState = OverrideField<
  AssetsReportBuilderReportGroupDto,
  'widgets',
  (AssetsReportBuilderReportWidgetDto & { context: Context })[]
>;

type State = {
  reportId: string | null;
  date: string | null;
  assetIds: number[];
  segmentIds: number[];
  excludedLEIds: string[];
  groupedWidgets: GroupedWidgetState[];
};

type Context = CamelCasedProperties<
  PostApiCoreAssetsReportBuilderReportsByReportIdWidgetsAndIdSnapshotApiArg['body']['context']
>;

type UpdateWidgetPayload = {
  context: Context;
  widgetGroupId: string;
  widgetId: string;
};

const initialState: State = {
  reportId: null,
  date: null,
  assetIds: [],
  segmentIds: [],
  excludedLEIds: [],
  groupedWidgets: [],
};

const getInitialWidgetSnapshotContext = (
  widget: AssetsReportBuilderReportWidgetDto | ReportWidgetDto,
  reportDate: string,
): Context => {
  switch (widget.widgetType) {
    case REPORT_BUILDER_WIDGET_TYPES.XY_CHART: {
      return getInitialStateForReportChartWidget(widget, reportDate);
    }
    case REPORT_BUILDER_WIDGET_TYPES.GLOBAL_LEASE_TABLE: {
      return getInitialStateForGlobalLeaseTableWidget(widget, {
        dashboardType: ReportDashboardType.REPORT_BUILDER_TEMPLATE,
      });
    }
    // TODO merge with getInitialStateForEagleEyeDashboardWidget
    case 'xy_chart_single_kpi': {
      const xyChartSingleKpiWidgetSection =
        widget as unknown as XYChartSingleKpiWidgetSection;
      const { dateFrom, dateTo, granularity, groupingType } =
        xyChartSingleKpiWidgetSection.defaultOptions;
      const isCategorical = isChartCategorical(
        xyChartSingleKpiWidgetSection.widgetConfig.am_chart_config,
      );

      return {
        groupingType: groupingType ?? DEFAULT_WIDGET_GROUPING_TYPE,
        granularity: granularity ?? 'day',
        kpi: isCategorical
          ? null
          : xyChartSingleKpiWidgetSection.widgetConfig.kpis?.[0]?.key,
        dateFrom: isCategorical
          ? formatToDateStringForRequest(startOf(dateFrom, granularity))
          : dateFrom,
        dateTo: isCategorical
          ? formatToDateStringForRequest(endOf(dateFrom, granularity))
          : (dateTo ?? formatToDateStringForRequest(new Date())),
      } satisfies XYChartSingleKpiWidgetState;
    }
    case 'kpi_table_single_date': {
      return getInitialStateForReportKpiTableSingleDateWidget(
        widget,
        reportDate,
      );
    }
    case 'kpi_table': {
      return getInitialStateForReportKpiTableWidget(widget, reportDate);
    }
    case 'media':
    case 'text_area': {
      return {};
    }
    default: {
      return {
        date: reportDate,
      };
    }
  }
};

const filterNullableFields = <T extends Record<string, unknown>>(
  obj: T,
): Partial<T> => {
  return Object.fromEntries(
    Object.entries(obj).filter(([, value]) => value !== null),
  ) as Partial<T>;
};

const {
  getApiCoreAssetsByAssetIdReportBuilderReportsAndId: getObjectLevelReport,
} = objectLevelApi.endpoints;
const { getApiReportBuilderEagleEyeReportsById: getEagleEyeReport } =
  reportingEnhancedApi.endpoints;

export const reportWidgetsSlice = createSlice({
  name: 'reportWidgetsContext',
  initialState,
  reducers: {
    updateWidgetContext: (
      state,
      action: PayloadAction<UpdateWidgetPayload>,
    ) => {
      state.groupedWidgets = state.groupedWidgets.map((g) => {
        if (g.id !== action.payload.widgetGroupId) return g;

        return {
          ...g,
          widgets: g.widgets.map((w) => {
            if (w.id !== action.payload.widgetId) return w;

            return {
              ...w,
              context: {
                ...w.context,
                ...action.payload.context,
              },
            };
          }),
        };
      });
    },
    updateReportDate: (
      state,
      { payload: { date: newReportDate } }: PayloadAction<{ date: string }>,
    ) => {
      state.date = newReportDate;

      state.groupedWidgets = state.groupedWidgets.map((g) => {
        return {
          ...g,
          widgets: g.widgets.map((w) => {
            if (w.widgetType === REPORT_BUILDER_WIDGET_TYPES.XY_CHART) {
              return {
                ...w,
                context: {
                  ...w.context,
                  dateTo: newReportDate,
                  // by default PDF chart should show 6 months data
                  // https://linear.app/symmetre/issue/FE-3178/[bug]-report-builder-switching-date-in-pdf-changes-charts-default#comment-f3939b26
                  dateFrom: formatToDateStringForRequest(
                    shiftDateSixMonthsBackward(new Date(newReportDate)),
                  ),
                },
              };
            }
            if (w.widgetType === 'kpi_table_single_date') {
              return {
                ...w,
                context: {
                  ...w.context,
                  date: newReportDate,
                },
              };
            }
            if (w.widgetType === 'kpi_table') {
              return {
                ...w,
                context: {
                  ...w.context,
                  date: newReportDate,
                  period: getReckonerPeriodByPeriodTypeAndDate(
                    w?.defaultOptions?.periodType,
                    newReportDate,
                  ),
                },
              };
            }
            if (
              w.widgetType === REPORT_BUILDER_WIDGET_TYPES.GLOBAL_LEASE_TABLE
            ) {
              return {
                ...w,
                context: {
                  ...w.context,
                  ...getInitialStateForGlobalLeaseTableWidget(),
                },
              };
            }
            if (
              w.widgetType === REPORT_BUILDER_WIDGET_TYPES.TEXT_AREA ||
              w.widgetType === REPORT_BUILDER_WIDGET_TYPES.MEDIA
            ) {
              return {
                ...w,
                context: {},
              };
            }
            return {
              ...w,
              context: {
                ...w.context,
                date: newReportDate,
              },
            };
          }),
        };
      });
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(getObjectLevelReport.matchFulfilled),
      (state, action) => {
        const newReportDate =
          action.payload.report?.date ??
          formatToDateStringForRequest(yesterdayDate());

        if (
          state.date === newReportDate &&
          state.reportId === action.payload.report.id
        ) {
          return;
        }

        // Object Level Report doesn't rely on this state.
        state.assetIds = [];
        state.segmentIds = [];
        state.excludedLEIds = [];

        state.date = newReportDate;
        state.reportId = action.payload.report.id;
        state.groupedWidgets =
          action.payload.report?.groups.map((g) => {
            return {
              ...g,
              widgets: g.widgets.map((w) => ({
                ...w,
                context: {
                  ...getInitialWidgetSnapshotContext(w, newReportDate),
                  ...filterNullableFields(
                    omit(w.snapshotContext ?? {}, 'objects'),
                  ),
                  ...getReportingEagleEyeWidgetObjectsWidgetState(
                    w.snapshotContext?.objects,
                  ),
                },
              })),
            };
          }) ?? [];
      },
    );
    // TODO merge with object level when endpoints are ready
    builder.addMatcher(
      isAnyOf(getEagleEyeReport.matchFulfilled),
      (state, action) => {
        const newReportDate =
          action.payload.date ?? formatToDateStringForRequest(yesterdayDate());

        if (
          state.date === newReportDate &&
          state.reportId === action.payload.id
        ) {
          return;
        }

        const { assetIds, excludedLEIds, segmentIds } =
          getReportingEagleEyeObjectsState(action.payload);

        state.assetIds = assetIds;
        state.segmentIds = segmentIds;
        state.excludedLEIds = excludedLEIds;

        state.date = newReportDate;
        state.reportId = action.payload.id;
        state.groupedWidgets =
          action.payload?.groups.map((g) => {
            return {
              ...g,
              widgets: g.widgetSections.map((w) => ({
                ...w,
                context: {
                  ...getReportingEagleEyeWidgetObjectsWidgetState(
                    w.defaultOptions?.objects,
                  ),
                  ...getInitialWidgetSnapshotContext(w, newReportDate),
                  ...filterNullableFields(w.snapshotContext ?? {}),
                },
              })),
            };
          }) ?? [];
      },
    );
  },
});

export const reportWidgetsSliceActions = reportWidgetsSlice.actions;

listenerMiddleware.startListening({
  actionCreator: reportWidgetsSliceActions.updateWidgetContext,
  effect: (_, listenerApi) => {
    listenerApi.dispatch(objectLevelApi.util.invalidateTags(['Report']));
    listenerApi.dispatch(
      reportingEnhancedApi.util.invalidateTags(['eagleEye:report']),
    );
  },
});

export const selectWidgetById = (widgetId: string) => (state: TRootState) => {
  const widget = state.reportWidgets.groupedWidgets
    .flatMap((g) => g.widgets.map((w) => ({ ...w, group: g })))
    .find((w) => w.id === widgetId);
  return widget;
};

import NoDataOverlay from '@/bundles/Shared/components/NoDataOverlay';
import {
  DashboardContext,
  ReportDashboardType,
} from '@/bundles/Shared/entities/dashboard';
import { EntityTagField } from '@/bundles/Shared/entities/tag';
import { ReportDashboardDateFilterBlock } from '@/bundles/Shared/features/dashboard/object/filter/byDate';
import { ReportBuilderTemplateObjectLevelObjectsFilter } from '@/bundles/Shared/features/dashboard/object/filter/byObject/ui/ReportBuilderTemplateObjectLevelObjectsFilter';
import {
  ReportBuilderTemplateContext,
  ReportingWidgetGroupIdContext,
  ReportingWidgetIdContext,
} from '@/bundles/Shared/widgets/dashboard/widgets/common/lib/reportBuilderTemplateContext';
import { ReportBuilderTemplateWidgetContext } from '@/bundles/Shared/widgets/dashboard/widgets/common/lib/reportBuilderTemplateWidgetContext';
import { BUILDER_UI_WIDGET_CARD_WIDGET_TYPE_ICON_MAP } from '@/bundles/Shared/widgets/dashboard/widgets/config';
import { ReportBuilderTemplateAvailableWidgetsModal } from '@/bundles/Shared/widgets/dashboard/widgetsBar/ui/AvailableWidgetsModal';
import {
  REPORT_BUILDER_BASIC_TAB,
  REPORT_BUILDER_WIDGETS_TAB,
} from '@/entities/report/reportBuilder';
import type {
  RecentRentrollWidgetConfigDto,
  ReportBuilderTemplateDto,
  ReportBuilderTemplateGroupDto,
  ReportBuilderTemplateGroupWidgetSectionDto,
} from '@/entities/report/reportBuilder/api/settingsReportBuilderTemplatesGeneratedApi';
import {
  ReportBuilderTemplateMetaContext,
  useReportBuilderTemplateMetaContext,
} from '@/entities/report/reportBuilder/context/reportBuilderTemplateMetaContext';
import {
  isReportBuilderTempalteWidgetEditable,
  isReportBuilderTempalteWidgetIncludeSourceUnitTypesEditable,
  useGetReportBuilderTemplate,
  useGetReportBuilderTemplateMetaWithSearchParamAssetId,
  useReportingFormTabs,
} from '@/entities/report/reportBuilder/lib';
import {
  useNavigateReportBuilderTemplateViewPage,
  useNavigateReportBuilderTemplateWidgetPreviewPage,
} from '@/entities/report/reportBuilder/lib/navigate';
import { useReportBuilderTemplateEagleEyeObjectSettingsForm } from '@/entities/report/reportBuilder/lib/reportBuilderTemplateEagleEyeObjectSettingsConfig';
import {
  REPORT_BUILDER_TEMPLATE_SETTINGS_BASIC_SCHEMA,
  type ReportBuilderTemplateSettingsBasicSchema,
} from '@/entities/report/reportBuilder/lib/reportBuilderTemplateSettingsBasic';
import {
  useGetApiReportBuilderTemplatePreviewPdfQuery,
  useGetSelectedObjectsSearchQuery,
} from '@/entities/report/reportBuilder/lib/useGetApiReportBuilderTemplatePreviewPdfQuery';
import { ExpandCollapseRowsIconButton } from '@/entities/report/reportBuilder/ui/ExpandCollapseRowsIconButton';
import { ReportBuilderTemplateInUseBanner } from '@/entities/report/reportBuilder/ui/ReportBuilderTemplateInUseBanner';
import { ReportBuilderTemplateWidgetWidthDropdown } from '@/entities/report/reportBuilder/ui/ReportBuilderTemplateWidgetWidthDropdown';
import { TemplateGroupHeaderDropdown } from '@/entities/report/reportBuilder/ui/TemplateGroupHeaderDropdown';
import { TemplateGroupPageOrientationDropdown } from '@/entities/report/reportBuilder/ui/TemplateGroupPageOrientationDropdown';
import { ReportingEntityIdContext } from '@/entities/reporting/context/entityId';
import { useReportingEntityKindContext } from '@/entities/reporting/context/entityKind';
import { isWidgetWithObjectsConfig } from '@/entities/reporting/lib/eagleEye';
import { useReportBuilderTemplateDelete } from '@/features/report/report/deleteReportBuilderTemplate/lib';
import { useReportBuilderTemplateUpdate } from '@/features/report/report/updateTemplate/lib';
import { useCopyReportBuilderTemplateWidget } from '@/features/report/reportBuilderTemplate/copyTemplateWidget/lib';
import { useCreateReportBuilderTemplateGroup } from '@/features/report/reportBuilderTemplate/createTemplateGroup/lib';
import { useCreateReportBuilderTemplateWidget } from '@/features/report/reportBuilderTemplate/createTemplateWidget/lib';
import { useDeleteReportBuilderTemplateGroup } from '@/features/report/reportBuilderTemplate/deleteTemplateGroup/lib';
import { useDeleteReportBuilderTemplateWidget } from '@/features/report/reportBuilderTemplate/deleteTemplateWidget/lib';
import { useUpdateReportBuilderTemplateGroup } from '@/features/report/reportBuilderTemplate/updateTemplateGroup/lib';
import { useUpdateReportBuilderTemplateWidget } from '@/features/report/reportBuilderTemplate/updateTemplateWidget/lib';
import { currentUserAllowedToSeeNavigateToReportBuilderTemplateViewPage } from '@/pages/reportBuilderTemplate/[reportBuilderTemplateId]/permissions';
import { useNavigateBack } from '@/shared/lib/hooks/navigation';
import { useAppSelector } from '@/shared/lib/hooks/redux';
import { useModal } from '@/shared/lib/hooks/useModal';
import { ROUTES_ROOT } from '@/shared/lib/hooks/useNavigation';
import { mapListToIds } from '@/shared/lib/listHelpers';
import { sortByPosition } from '@/shared/lib/listHelpers/toSorted';
import { AssetsInUsePopover } from '@/shared/ui/AssetsInUsePopover';
import { BuilderUI } from '@/shared/ui/BuilderUI';
import { GrowDiv } from '@/shared/ui/GrowDiv';
import { SaveStatus } from '@/shared/ui/SaveStatus';
import { Button } from '@/stories/Button/Button';
import { Icon } from '@/stories/Icon/Icon';
import { IconButton } from '@/stories/IconButton/IconButton';
import { Input } from '@/stories/FormControls/Inputs/Input/Input';
import { ThinTabGroup } from '@/stories/Tabs/ThinTabGroup/ThinTabGroup';
import { TumblerController } from '@/stories/Tumbler/Tumbler';
import { ReportBuilderTemplateEagleEyeObjectsForm } from '@/widgets/reportBuilder/reportBuillderTemplate/eagleEye/ObjectsForm';
import { ReportingEagleEyeWidgetObjectsConfig } from '@/widgets/reporting/widgetObjectsConfig/component';
import {
  DragDropContext,
  Draggable,
  Droppable,
  type DropResult,
} from '@hello-pangea/dnd';
import { yupResolver } from '@hookform/resolvers/yup';
import { ReactComponent as LandscapeSvg } from 'fonts/sre-icons/Icons/landscape.svg';
import { ReactComponent as PortraitSvg } from 'fonts/sre-icons/Icons/portrait.svg';
import { startCase } from 'lodash-es';
import { useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

const Builder = ({ template }: { template: ReportBuilderTemplateDto }) => {
  const { navigateBack } = useNavigateBack({
    fallbackUrl: ROUTES_ROOT.settings.report.reportTemplates.fullPath,
  });
  const { thinTabGroupProps, tab } = useReportingFormTabs();

  const templateKind = useReportingEntityKindContext();
  const meta = useReportBuilderTemplateMetaContext();
  const { openModal, modalList } = useModal();
  const savingUpdates = useAppSelector(
    (s) => s.reportBuilderTemplate.pendingRequestIds.length > 0,
  );
  const [handleUpdateTemplate, updateOptions] =
    useReportBuilderTemplateUpdate();

  const payloadParams = useGetSelectedObjectsSearchQuery();

  const methods = useForm<ReportBuilderTemplateSettingsBasicSchema>({
    resolver: yupResolver(REPORT_BUILDER_TEMPLATE_SETTINGS_BASIC_SCHEMA),
    defaultValues: {
      name: template.name,
      tags: template.templateTags,
      displayDate: template.displayDate,
      displayHeader: template.displayHeader,
      displayPageNumber: template.displayPageNumber,
    },
  });
  const { register, control, formState } = methods;
  const { isDirty } = formState;

  const objectSelectionFormMethods =
    useReportBuilderTemplateEagleEyeObjectSettingsForm(template);

  const [handleDeleteTemplate, deleteOptions] =
    useReportBuilderTemplateDelete(navigateBack);

  const [handleCreateGroup, createGroupOptions] =
    useCreateReportBuilderTemplateGroup();

  const [handleDeleteGroup, deleteGroupOptions] =
    useDeleteReportBuilderTemplateGroup();

  const [handleUpdateWidget, updateWidgetOptions] =
    useUpdateReportBuilderTemplateWidget();

  const [handleDeleteWidget, deleteWidgetOptions] =
    useDeleteReportBuilderTemplateWidget();

  const [handleUpdateGroup, updateGroupOptions] =
    useUpdateReportBuilderTemplateGroup();

  const [handleCreateWidget, createWidgetOptions] =
    useCreateReportBuilderTemplateWidget();

  const [handleCopyWidget, copyWidgetOptions] =
    useCopyReportBuilderTemplateWidget();

  const templateStateDate = useAppSelector(
    (state) => state.reportBuilderTemplate.entities[template.id]?.date,
  );

  const handleAddWidgetToGroup = async (groupId: string) => {
    const res = await openModal(
      ReportBuilderTemplateAvailableWidgetsModal,
      {},
      {
        topLevelRender: false,
      },
    );
    if (res == null) return;

    if (res.type === 'add_new_widget') {
      return handleCreateWidget({
        groupId,
        templateId: template.id,
        type: res.widgetOptions.map((o) => o.widgetType),
      });
    }

    return handleCopyWidget({
      groupId,
      templateId: template.id,
      sectionsIds: mapListToIds(res.widgetOptions),
      source: res.source,
    });
  };

  const handleSaveUpdates = methods.handleSubmit(async (values) => {
    try {
      const res = await handleUpdateTemplate({
        body: {
          name: values.name.trim(),
          tags: values.tags?.map((tag) => tag.name) ?? [],
          display_date: values.displayDate,
          display_header: values.displayHeader,
          display_page_number: values.displayPageNumber,
        },
        id: template.id,
      });
      if ('error' in res) {
        throw new Error('Failed to update template');
      }
      methods.reset({
        displayDate: res.data.displayDate,
        displayHeader: res.data.displayHeader,
        displayPageNumber: res.data.displayPageNumber,
        name: res.data.name,
        tags: res.data.templateTags,
      });
    } catch {
      methods.reset();
    }
  });

  const handleNaviageToViewPage = useNavigateReportBuilderTemplateViewPage();

  const handleNavigateToWidget =
    useNavigateReportBuilderTemplateWidgetPreviewPage();

  const widgetsMap = useMemo(() => {
    return new Map(
      template.groups.flatMap((g) =>
        g.widgetSections.map((w) => [w.id, { ...w, groupId: g.id }]),
      ),
    );
  }, [template.groups]);

  const handleOnDragEnd = (result: DropResult) => {
    if (result.destination == null) return;

    if (result.type !== 'groups') {
      const widgetId = result.draggableId;
      const widgetData = widgetsMap.get(widgetId);
      const newPosition = result.destination.index;

      const newGroupId =
        result.source.droppableId !== result.destination.droppableId
          ? result.destination.droppableId
          : undefined;

      if (widgetData == null) return;

      handleUpdateWidget({
        id: widgetData.id,
        groupId: widgetData.groupId,
        templateId: template.id,
        body: {
          position: newPosition,
          destination_group_id: newGroupId,
        },
      });
      return;
    }
    const currentGroup = template.groups.find(
      (g) => g.id === result.draggableId,
    );
    if (currentGroup == null) return;

    handleUpdateGroup({
      id: currentGroup.id,
      position: result.destination.index,
    });
  };

  const handleWidgetWidthChange = (
    widget: ReportBuilderTemplateGroupWidgetSectionDto & { groupId: string },
    newWidth: number,
  ) => {
    handleUpdateWidget({
      id: widget.id,
      groupId: widget.groupId,
      templateId: template.id,
      body: {
        width_percent: newWidth,
      },
    });
  };

  const handleWidgetIncludeSourceUnitTypesChange = (
    widget: ReportBuilderTemplateGroupWidgetSectionDto & { groupId: string },
    include_source_unit_types: boolean,
  ) => {
    handleUpdateWidget({
      id: widget.id,
      groupId: widget.groupId,
      templateId: template.id,
      body: {
        config: {
          include_source_unit_types,
        },
      },
    });
  };

  const { dataObjectURL: pdfSrc, isLoading: isPDFLoading } =
    useGetApiReportBuilderTemplatePreviewPdfQuery();

  const isLoading =
    deleteOptions.isLoading ||
    updateOptions.isLoading ||
    createGroupOptions.isLoading ||
    deleteWidgetOptions.isLoading ||
    updateGroupOptions.isLoading ||
    updateWidgetOptions.isLoading ||
    createWidgetOptions.isLoading ||
    copyWidgetOptions.isLoading ||
    deleteGroupOptions.isLoading;

  const showInUseBanner = (template.assets?.length ?? 0) > 0;

  const header = useMemo<React.ReactNode>(() => {
    switch (templateKind) {
      case 'object_level': {
        return (
          <>
            <ReportBuilderTemplateObjectLevelObjectsFilter />
            <ReportDashboardDateFilterBlock date={templateStateDate} />
          </>
        );
      }
      case 'eagle_eye':
      default: {
        return null;
      }
    }
  }, [templateKind, meta, payloadParams.assetIds, templateStateDate]);

  // TODO refactor widget state components. Repeat the same like in dashboard widget state components
  const renderWidgetCard = (
    group: ReportBuilderTemplateGroupDto,
    w: ReportBuilderTemplateGroupWidgetSectionDto,
    wIdx: number,
  ) => (
    <ReportingWidgetIdContext.Provider value={w.id} key={w.id}>
      <Draggable draggableId={w.id} index={wIdx}>
        {(providedWidget) => (
          <div ref={providedWidget.innerRef} {...providedWidget.draggableProps}>
            <BuilderUI.Settings.WidgetLayout.WidgetGroup.WidgetCardGrid className="group/widget">
              <BuilderUI.Settings.WidgetLayout.WidgetGroup.WidgetCardGrid.Row>
                <Icon
                  className="hidden group-hover/widget:block"
                  iconName="move"
                  {...providedWidget.dragHandleProps}
                />
                <Icon
                  className="group-hover/widget:hidden"
                  iconName={
                    BUILDER_UI_WIDGET_CARD_WIDGET_TYPE_ICON_MAP[w.widgetType] ??
                    'widget'
                  }
                />
                <BuilderUI.Settings.WidgetLayout.WidgetGroup.WidgetCard.Title>
                  {w.title ?? startCase(w.widgetType)}
                </BuilderUI.Settings.WidgetLayout.WidgetGroup.WidgetCard.Title>
                <GrowDiv />
                {isReportBuilderTempalteWidgetEditable(w.widgetType) && (
                  <IconButton
                    iconName="edit"
                    onClick={() => {
                      handleNavigateToWidget(w.id);
                    }}
                  />
                )}
                <IconButton
                  onClick={() =>
                    handleDeleteWidget({
                      groupId: group.id,
                      widgetId: w.id,
                    })
                  }
                  iconName="trash"
                  isLoading={deleteWidgetOptions.isLoading}
                  disabled={isLoading}
                />
              </BuilderUI.Settings.WidgetLayout.WidgetGroup.WidgetCardGrid.Row>
              <BuilderUI.Settings.WidgetLayout.WidgetGroup.WidgetCardGrid.Row>
                {templateKind === 'eagle_eye' &&
                  isWidgetWithObjectsConfig(w.widgetType) && (
                    <ReportBuilderTemplateWidgetContext.Provider
                      value={{ ...w }}
                    >
                      <ReportingEagleEyeWidgetObjectsConfig />
                    </ReportBuilderTemplateWidgetContext.Provider>
                  )}
                <GrowDiv />
                {isReportBuilderTempalteWidgetIncludeSourceUnitTypesEditable(
                  w.widgetType,
                ) && (
                  <ExpandCollapseRowsIconButton
                    disabled={isLoading}
                    includeSourceUnitTypes={
                      (w.widgetConfig as RecentRentrollWidgetConfigDto)
                        .include_source_unit_types
                    }
                    onClick={(newIncludeSourceUnit_types) => {
                      handleWidgetIncludeSourceUnitTypesChange(
                        {
                          ...w,
                          groupId: group.id,
                        },

                        newIncludeSourceUnit_types,
                      );
                    }}
                  />
                )}
                <ReportBuilderTemplateWidgetWidthDropdown
                  widgetWidth={w.position.w}
                  disabled={isLoading}
                  onClick={(width) => {
                    handleWidgetWidthChange(
                      {
                        ...w,
                        groupId: group.id,
                      },
                      width,
                    );
                  }}
                />
              </BuilderUI.Settings.WidgetLayout.WidgetGroup.WidgetCardGrid.Row>
            </BuilderUI.Settings.WidgetLayout.WidgetGroup.WidgetCardGrid>
          </div>
        )}
      </Draggable>
    </ReportingWidgetIdContext.Provider>
  );

  const renderWidgetGroup = (
    group: ReportBuilderTemplateGroupDto,
    gIdx: number,
  ) => (
    <ReportingWidgetGroupIdContext.Provider key={group.id} value={group.id}>
      <Draggable draggableId={group.id} index={gIdx}>
        {(providedDraggableGroup) => (
          <div
            className="group/templateGroup"
            ref={providedDraggableGroup.innerRef}
            {...providedDraggableGroup.draggableProps}
          >
            <Droppable type="group" droppableId={group.id}>
              {(providedDroppableGroup) => (
                <div
                  ref={providedDroppableGroup.innerRef}
                  {...providedDroppableGroup.droppableProps}
                  className="mb-4"
                >
                  <BuilderUI.Settings.WidgetLayout.WidgetGroup key={group.id}>
                    <div className="flex items-center gap-2 p-4 text-neutral-550">
                      <Icon
                        className="hidden group-hover/templateGroup:block"
                        iconName="move"
                        {...providedDraggableGroup.dragHandleProps}
                      />
                      {group.pageOrientation === 'portrait' && (
                        <PortraitSvg className="group-hover/templateGroup:hidden" />
                      )}
                      {group.pageOrientation === 'landscape' && (
                        <LandscapeSvg className="group-hover/templateGroup:hidden" />
                      )}
                      <BuilderUI.Settings.WidgetLayout.WidgetGroup.Title>
                        {`Group ${gIdx + 1}`}
                      </BuilderUI.Settings.WidgetLayout.WidgetGroup.Title>
                      <GrowDiv />
                      <IconButton
                        onClick={() => handleDeleteGroup(group.id)}
                        iconName="trash"
                        isLoading={deleteGroupOptions.isLoading}
                        disabled={isLoading}
                      />
                      <TemplateGroupHeaderDropdown
                        displayHeader={group.displayHeader}
                        isLoading={isLoading}
                        onChange={(displayHeader) => {
                          handleUpdateGroup({
                            id: group.id,
                            display_header: displayHeader,
                          });
                        }}
                      />
                      <TemplateGroupPageOrientationDropdown
                        isLoading={isLoading}
                        pageOrientation={group.pageOrientation}
                        onChange={(pageOrientation) => {
                          handleUpdateGroup({
                            id: group.id,
                            page_orientation: pageOrientation,
                          });
                        }}
                      />
                      <IconButton
                        onClick={() => handleAddWidgetToGroup(group.id)}
                        tooltipProps={{
                          mainText: 'Add Widget',
                        }}
                        iconName="addSmall"
                        disabled={isLoading}
                      />
                    </div>

                    <BuilderUI.Settings.WidgetLayout.WidgetGroup.Children>
                      {group.widgetSections.map((widget, wIdx) =>
                        renderWidgetCard(group, widget, wIdx),
                      )}
                      {providedDroppableGroup.placeholder}
                    </BuilderUI.Settings.WidgetLayout.WidgetGroup.Children>
                  </BuilderUI.Settings.WidgetLayout.WidgetGroup>
                </div>
              )}
            </Droppable>
          </div>
        )}
      </Draggable>
    </ReportingWidgetGroupIdContext.Provider>
  );

  return (
    <BuilderUI>
      {modalList}
      <BuilderUI.Settings>
        <BuilderUI.Settings.Header>
          <BuilderUI.Settings.Header.BackIconButton
            disabled={isLoading}
            onClick={navigateBack}
          />
          <BuilderUI.Settings.Header.TitleInfo
            subtitle="Builders"
            title={template.name}
          >
            {templateKind !== 'eagle_eye' &&
              template.assets != null &&
              template.assets.length > 0 && (
                <AssetsInUsePopover assets={template.assets} />
              )}
          </BuilderUI.Settings.Header.TitleInfo>
          <BuilderUI.Settings.Header.Actions>
            <BuilderUI.Settings.Header.DeleteIconButton
              onClick={() => handleDeleteTemplate(template)}
              disabled={isLoading}
              isLoading={deleteOptions.isLoading}
            />
            {currentUserAllowedToSeeNavigateToReportBuilderTemplateViewPage() && (
              <BuilderUI.Settings.Header.ViewIconButton
                onClick={handleNaviageToViewPage}
              />
            )}
            <SaveStatus saving={savingUpdates} />
          </BuilderUI.Settings.Header.Actions>
        </BuilderUI.Settings.Header>
        {showInUseBanner && templateKind !== 'eagle_eye' && (
          <ReportBuilderTemplateInUseBanner />
        )}
        <div className="grid w-full px-6 py-2">
          <ThinTabGroup fullWidth {...thinTabGroupProps} />
        </div>
        <BuilderUI.Settings.Main>
          {tab?.id === REPORT_BUILDER_BASIC_TAB.id && (
            <>
              <BuilderUI.Settings.Main.Basic
                label="Key Info"
                actions={
                  <Button
                    size="xs"
                    variant="success"
                    onClick={handleSaveUpdates}
                    loading={updateOptions.isLoading}
                    disabled={isLoading || !isDirty}
                    className="w-max"
                  >
                    Save Updates
                  </Button>
                }
              >
                <BuilderUI.Settings.Main.Basic.Field
                  required
                  label="Template Name"
                >
                  <Input
                    classes={{ backLayer: 'bg-transparent' }}
                    {...register('name')}
                  />
                </BuilderUI.Settings.Main.Basic.Field>
                <EntityTagField
                  options={meta.tags}
                  control={control}
                  name="tags"
                />
                <BuilderUI.Settings.Main.Basic.Hr />
                <BuilderUI.Settings.Main.Basic.Field
                  className="flex-row justify-between"
                  label="Header (Default)"
                >
                  <TumblerController control={control} name="displayHeader" />
                </BuilderUI.Settings.Main.Basic.Field>
                <BuilderUI.Settings.Main.Basic.Hr className="-mt-2" />
                <BuilderUI.Settings.Main.Basic.Field
                  className="flex-row justify-between"
                  label="Page numbers"
                >
                  <TumblerController
                    control={control}
                    name="displayPageNumber"
                  />
                </BuilderUI.Settings.Main.Basic.Field>
                <BuilderUI.Settings.Main.Basic.Hr className="-mt-2" />
                <BuilderUI.Settings.Main.Basic.Field
                  className="flex-row justify-between"
                  label="Date"
                >
                  <TumblerController control={control} name="displayDate" />
                </BuilderUI.Settings.Main.Basic.Field>
              </BuilderUI.Settings.Main.Basic>

              {templateKind === 'eagle_eye' && (
                <FormProvider {...objectSelectionFormMethods}>
                  <ReportBuilderTemplateEagleEyeObjectsForm />
                </FormProvider>
              )}
            </>
          )}

          {tab?.id === REPORT_BUILDER_WIDGETS_TAB.id && (
            <BuilderUI.Settings.WidgetLayout>
              <BuilderUI.Settings.WidgetLayout.Header title="Widgets">
                <Button
                  size="xs"
                  loading={createGroupOptions.isLoading}
                  disabled={isLoading}
                  onClick={handleCreateGroup}
                >
                  Add Group
                </Button>
              </BuilderUI.Settings.WidgetLayout.Header>

              <DragDropContext onDragEnd={handleOnDragEnd}>
                <Droppable type="groups" droppableId="droppableId">
                  {(rootProvided) => (
                    <div
                      ref={rootProvided.innerRef}
                      {...rootProvided.droppableProps}
                    >
                      <BuilderUI.Settings.WidgetLayout className="gap-0">
                        {template.groups
                          .toSorted(sortByPosition)
                          .map((group, index) =>
                            renderWidgetGroup(group, index),
                          )}
                      </BuilderUI.Settings.WidgetLayout>
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </BuilderUI.Settings.WidgetLayout>
          )}
        </BuilderUI.Settings.Main>
      </BuilderUI.Settings>

      {isPDFLoading && pdfSrc == null && <BuilderUI.PdfPreview.Loading />}
      {pdfSrc && (
        <BuilderUI.PdfPreview.Iframe
          header={
            templateKind === 'object_level' ? (
              <BuilderUI.PdfPreview.Header>
                {header}
              </BuilderUI.PdfPreview.Header>
            ) : null
          }
          src={pdfSrc}
        >
          {isPDFLoading && <BuilderUI.PdfPreview.IframeFetching />}
        </BuilderUI.PdfPreview.Iframe>
      )}
      {!isPDFLoading && pdfSrc == null && (
        <BuilderUI.PdfPreview.NoData>
          {templateKind === 'object_level' && (
            <BuilderUI.PdfPreview.Header>{header}</BuilderUI.PdfPreview.Header>
          )}
        </BuilderUI.PdfPreview.NoData>
      )}
    </BuilderUI>
  );
};

export const ReportBuilderTemplateBuilder = () => {
  const templateKind = useReportingEntityKindContext();
  const { data, isLoading } = useGetReportBuilderTemplate();

  const { data: metaData, isLoading: isMetaDataLoading } =
    useGetReportBuilderTemplateMetaWithSearchParamAssetId();

  if (isLoading || isMetaDataLoading) {
    return <BuilderUI.Loading />;
  }

  if (data == null || metaData == null) {
    return <NoDataOverlay title="No data" />;
  }

  return (
    <DashboardContext.Provider
      value={{
        boardId: '',
        dashboardId: '',
        dashboardSlug: '',
        boardSlug: '',
        dashboardType:
          templateKind === 'eagle_eye'
            ? ReportDashboardType.REPORT_BUILDER_TEMPLATE_EAGLE_EYE
            : ReportDashboardType.REPORT_BUILDER_TEMPLATE,
      }}
    >
      <ReportBuilderTemplateContext.Provider
        value={{
          widgetId: '',
          templateId: data.id,
        }}
      >
        <ReportingEntityIdContext.Provider value={data.id}>
          <ReportBuilderTemplateMetaContext.Provider value={metaData}>
            <Builder template={data} />
          </ReportBuilderTemplateMetaContext.Provider>
        </ReportingEntityIdContext.Provider>
      </ReportBuilderTemplateContext.Provider>
    </DashboardContext.Provider>
  );
};

import {
  PageParamsProvider,
  PageParamsSearch,
} from '@/bundles/Shared/components/pageParams';
import {
  DashboardFilterByTypeTabs,
  type PageParamsDashboardFilterByType,
} from '@/bundles/Shared/features/dashboard/filter/byType';
import { ReportBuilderTemplateTags } from '@/bundles/Shared/features/tag/filter/ui/ReportBuilderTemplateTags';
import type { ReportBuilderTemplateDto } from '@/entities/report/reportBuilder/api/reportBuilderTemplatesGeneratedApi';
import { useGetApiSettingsReportBuilderTemplatesQuery } from '@/entities/report/reportBuilder/api/settingsReportBuilderTemplatesEnhancedApi';
import type { ReportBuilderTemplateTagDto } from '@/entities/report/reportBuilder/api/settingsReportBuilderTemplatesGeneratedApi';
import { ReportBuilderTemplateCard } from '@/entities/report/reportBuilder/ui/ReportBuilderTemplateCard';
import { ReportingEntityKindContext } from '@/entities/reporting/context/entityKind';
import { useCreateReportBuilderTemplate } from '@/features/report/reportBuilderTemplate/createTemplate/lib';
import { useFavoriteItemIds } from '@/shared/lib/hooks/useFavoriteItemIds';
import { includesInLowerCase, mapListToIds } from '@/shared/lib/listHelpers';
import { GrowDiv } from '@/shared/ui/GrowDiv';
import { TileList } from '@/shared/ui/TileList';
import { Tumbler } from '@/stories/Tumbler/Tumbler';
import { intersection, isEmpty } from 'lodash-es';
import { useMemo, useState } from 'react';

const areTagsIntersect = (
  templateTags: ReportBuilderTemplateTagDto[],
  tags: ReportBuilderTemplateDto['tags'],
) => intersection(mapListToIds(templateTags), tags).length > 0;

export const ReportTemplatesPage = () => {
  const [pageParams, setPageParams] = useState<
    {
      query: string;
      inUse: boolean;
      tags: ReportBuilderTemplateDto['tags'];
    } & PageParamsDashboardFilterByType
  >({
    query: '',
    inUse: false,
    tags: [],
    type: null,
  });
  const { data, isLoading } = useGetApiSettingsReportBuilderTemplatesQuery();

  const [handleCreateTemplate, createTemplateOptions] =
    useCreateReportBuilderTemplate();

  const { favouriteItems, isItemFavorite } = useFavoriteItemIds(
    'reportBuilderTemplateIds',
  );

  const handleInUseChange = (inUse: boolean) => {
    setPageParams((prev) => ({
      ...prev,
      inUse,
    }));
  };
  const handleTagsChange = (tags: ReportBuilderTemplateDto['tags']) => {
    setPageParams((prev) => ({
      ...prev,
      tags,
    }));
  };

  const sortedItems = useMemo(() => {
    return (data?.items ?? []).toSorted((a, b) => {
      const isAFavorite = isItemFavorite(a.id);
      const isBFavorite = isItemFavorite(b.id);

      if (isAFavorite && isBFavorite) {
        return 0;
      }
      if (isAFavorite) return -1;
      if (isBFavorite) return 1;

      return 0;
    });
  }, [data, favouriteItems]);

  const computedItems = useMemo(() => {
    return sortedItems.filter((template) => {
      if (pageParams.inUse && template.assets.length === 0) {
        return false;
      }
      if (
        pageParams.tags.length > 0 &&
        !areTagsIntersect(template.templateTags, pageParams.tags)
      ) {
        return false;
      }
      const matchesQuery = includesInLowerCase(
        template.name,
        pageParams.query ?? '',
      );
      const matchesType =
        pageParams.type == null || template.kind === pageParams.type;
      return matchesQuery && matchesType;
    });
  }, [pageParams, sortedItems]);

  return (
    <PageParamsProvider pageParams={pageParams} setPageParams={setPageParams}>
      <TileList>
        <TileList.Header>
          <DashboardFilterByTypeTabs
            excludeTabList={['comparison_mode']}
            type={pageParams.type}
            onChange={(type) => {
              setPageParams((prev) => ({ ...prev, type }));
            }}
          />
          <ReportBuilderTemplateTags
            value={pageParams.tags}
            onChange={handleTagsChange}
          />
          <Tumbler
            checked={pageParams.inUse}
            onChange={(e) => handleInUseChange(e.target.checked)}
          >
            Only In Use
          </Tumbler>
          <GrowDiv />
          <PageParamsSearch placeholder="Template Name" />
          <TileList.CreateButton
            onClick={handleCreateTemplate}
            loading={createTemplateOptions.isLoading}
            disabled={createTemplateOptions.isLoading}
            entityName="Template"
          />
        </TileList.Header>
        <TileList.ItemCounter count={computedItems.length} />
        <TileList.Items
          isLoading={isLoading}
          isListEmpty={computedItems.length === 0}
          isQueryEmpty={isEmpty(pageParams.query)}
          noDataOverlayTitle="No Templates found"
        >
          {computedItems.map((template) => (
            <ReportingEntityKindContext.Provider
              key={template.id}
              value={template.kind}
            >
              <ReportBuilderTemplateCard template={template} />
            </ReportingEntityKindContext.Provider>
          ))}
        </TileList.Items>
      </TileList>
    </PageParamsProvider>
  );
};

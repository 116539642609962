import { WidgetStatePeriod } from '@/bundles/Shared/widgets/dashboard/widgets/common';
import { useContextChangeHandler } from '@/bundles/Shared/widgets/dashboard/widgets/common/lib/reportBuilder';
import { ReportingWidgetIdContext } from '@/bundles/Shared/widgets/dashboard/widgets/common/lib/reportBuilderTemplateContext';
import { ReportBuilderTemplateWidgetContext } from '@/bundles/Shared/widgets/dashboard/widgets/common/lib/reportBuilderTemplateWidgetContext';
import { getReckonerPeriodByPeriodTypeAndDate } from '@/bundles/Shared/widgets/dashboard/widgets/common/lib/utils';
import { WidgetStateReckonerPeriodType } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/state/WidgetStatePeriodType';
import { selectWidgetById } from '@/entities/report/reportBuilder';
import { BaseWidgetConfigCard } from '@/entities/report/reportBuilder/ui/BaseWidgetConfigCard';
import { formatToDateStringForRequest } from '@/shared/lib/converters';
import { useAppSelector } from '@/shared/lib/hooks/redux';
import Calendar from '@/stories/FlexibleFilterByPeriods/calendar/Calendar';
import { widgetHasData } from 'bundles/Shared/widgets/dashboard/widgets/financialTableSingeDate/lib';
import { WidgetConfigCardProps } from 'bundles/Shared/widgets/dashboard/widgets/model';
import dayjs from 'dayjs';

export const KpiTableWidgetConfigCard = (
  props: Omit<WidgetConfigCardProps, 'hasData'>,
) => {
  const widget = useAppSelector(selectWidgetById(props.widgetSection.id));
  const handleContextChange = useContextChangeHandler({
    widgetId: widget?.id ?? '',
    widgetGroupId: widget?.group.id ?? '',
  });

  return (
    <BaseWidgetConfigCard
      labelSlot={
        widget && (
          <>
            <div className="flex">
              {widget.defaultOptions?.periodType === 'week' ? (
                // move week to WidgetStatePeriod
                <Calendar
                  selectionMode="weekly"
                  value={[
                    dayjs(widget.context.date).startOf('w'),
                    dayjs(widget.context.date).endOf('w'),
                  ]}
                  onChange={(date) => {
                    handleContextChange({
                      ...widget.context,
                      date: formatToDateStringForRequest(date[0]),
                      period: getReckonerPeriodByPeriodTypeAndDate(
                        'week',
                        formatToDateStringForRequest(date[0]),
                      ),
                    });
                  }}
                />
              ) : (
                <WidgetStatePeriod
                  state={widget.context}
                  onStateChange={(args) => {
                    handleContextChange({
                      ...widget.context,
                      ...args,
                    });
                  }}
                />
              )}
            </div>
          </>
        )
      }
      hasData={widgetHasData(props.data)}
      {...props}
    >
      {widget.defaultOptions?.periodType !== 'week' && (
        <WidgetStateReckonerPeriodType
          state={widget.context}
          onStateChange={(state) => {
            handleContextChange({
              ...widget.context,
              period: state.period,
            });
          }}
          periodTypes={
            widget.widgetConfig?.period_types ??
            widget.defaultOptions?.availablePeriodTypes ??
            []
          }
        />
      )}
      <ReportBuilderTemplateWidgetContext.Provider value={widget}>
        <ReportingWidgetIdContext.Provider value={widget.id}>
          {/* TODO resolve where to put widgetObjectsConfig when Design is ready */}
          {props.widgetObjectsConfig}
        </ReportingWidgetIdContext.Provider>
      </ReportBuilderTemplateWidgetContext.Provider>
    </BaseWidgetConfigCard>
  );
};

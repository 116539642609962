import { COLUMN_CONFIGURATION_LABEL_INFO_TEXT } from '@/bundles/Shared/widgets/dashboard/widgets/common/config';
import { ColumnHeaderField } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/fields/ColumnHeaderField';
import { SparklineSettingsField } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/fields/SparklineSettingsField';
import { WidgetTableColumnSettingsColumnType } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/table/form';
import {
  UnitTypeTableWidgetConfigNumericColumnForm,
  transformColumnConfigToForm,
  getUnitTypeTableDefaultColumnForm,
  getUnitTypeTableResolver,
} from '@/bundles/Shared/widgets/dashboard/widgets/unitTypeTable/config/column.form';
import { unitTypeTableUpdaters } from '@/bundles/Shared/widgets/dashboard/widgets/unitTypeTable/config/updaters';
import { UnitTypeTableWidgetConfigColumn } from '@/bundles/Shared/widgets/dashboard/widgets/unitTypeTable/model';
import { DialogProps } from '@/shared/lib/hooks/useModal';
import { FieldsContainer } from '@/stories/Field/Field';
import {
  SectionField,
  SectionFieldsContainer,
} from '@/stories/Field/FieldsWrappers';
import { OBJECT_DASHBOARD_SECTION_TYPE } from 'bundles/Shared/entities/dashboard';
import {
  CreateNewForm,
  EditPanelActions,
  FormulaField,
  PeriodShiftField,
  PeriodTypeField,
  useUpdateWidgetConfig,
  useWidgetConfig,
} from 'bundles/Shared/widgets/dashboard/widgets/common';
import { NumericColumnSettingsFields } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/fields/ColumnSettingsFields';
import {
  TableVizConfig,
  TableVizConfigColumn,
} from 'bundles/Shared/widgets/dashboard/widgets/common/ui/table/model';
import { FormProvider, useForm } from 'react-hook-form';
import { Field } from 'stories/Field/Field';
import { InlineAlert } from 'stories/InlineAlert/InlineAlert';
import { Input } from 'stories/FormControls/Inputs/Input/Input';
import { SidePanel } from 'stories/Modals/Modal/Modal';

interface Props extends DialogProps<CreateNewForm> {
  type: 'create' | 'edit';
  columnType: WidgetTableColumnSettingsColumnType;
  columnConfig?: UnitTypeTableWidgetConfigColumn;
  columnSettings?: TableVizConfigColumn;
  groupId?: string;
}

export function UnitTypeTableWidgetConfigColumnPanel({
  type,
  columnConfig,
  columnSettings,
  groupId,
  columnType,
  onClose,
  onSubmit,
}: Props) {
  const resolver = getUnitTypeTableResolver(columnType);
  const methods = useForm<
    UnitTypeTableWidgetConfigNumericColumnForm & CreateNewForm
  >({
    resolver,
    mode: 'all',
    defaultValues: {
      ...getUnitTypeTableDefaultColumnForm(columnType),
      ...(columnConfig &&
        columnSettings &&
        transformColumnConfigToForm(columnConfig, columnSettings)),
    },
  });

  const { control, register, handleSubmit } = methods;
  const { widget } =
    useWidgetConfig<typeof OBJECT_DASHBOARD_SECTION_TYPE.UNIT_TYPE_TABLE>();
  const [updateConfig] = useUpdateWidgetConfig(
    OBJECT_DASHBOARD_SECTION_TYPE.UNIT_TYPE_TABLE,
  );

  const group = widget.widgetConfig.viz_config.column_groups?.find(
    (cg) => cg.group_id === groupId,
  );

  const cantOverridePeriod = group?.child_can_override_period === false;

  const handleRemove = () => {
    updateConfig({
      config: unitTypeTableUpdaters.removeColumn(
        {
          columnKey: columnConfig!.key.toString(),
        },
        widget.widgetConfig,
      ),
    });
    onClose();
  };

  const handleSubmitClick = handleSubmit(async (values) => {
    const config = unitTypeTableUpdaters.upsertColumn(
      values,
      widget.widgetConfig,
      { groupId },
    );
    await updateConfig({ config });

    onSubmit?.({ createNew: values.createNew });
  });

  const renderBaseColumnFields = () => {
    return (
      <>
        <Field note={COLUMN_CONFIGURATION_LABEL_INFO_TEXT} labelText="Title">
          <Input
            placeholder="Enter Primary Title"
            className="w-full"
            {...register('label')}
          />
        </Field>
        <SectionFieldsContainer>
          <FormulaField
            control={control}
            name="expression"
            required
            label="Floorplan Value"
          />
        </SectionFieldsContainer>
        <SectionFieldsContainer>
          <SectionField labelText="Specify Period">
            {cantOverridePeriod && (
              <InlineAlert
                status="attention"
                message="The period has been set for all columns in the group configuration"
              />
            )}
            <PeriodTypeField
              disabled={cantOverridePeriod}
              control={control}
              name="period_type"
            />
            <Field labelText="Period shift">
              <PeriodShiftField
                disabled={cantOverridePeriod}
                control={control}
                name="period_shift"
              />
            </Field>
          </SectionField>
          <ColumnHeaderField name="header" control={control} />
        </SectionFieldsContainer>
      </>
    );
  };

  const renderNumericColumnFields = () => {
    return (
      <>
        {renderBaseColumnFields()}
        <NumericColumnSettingsFields
          columnsConfig={widget.widgetConfig.columns}
          vizConfig={widget.widgetConfig.viz_config as TableVizConfig}
          control={control}
        />
      </>
    );
  };

  const renderSparklineColumnFields = () => {
    return (
      <>
        {renderBaseColumnFields()}
        <SparklineSettingsField control={control} />
      </>
    );
  };

  const renderColumnFields = () => {
    if (columnType === 'sparkline') {
      return renderSparklineColumnFields();
    }
    return renderNumericColumnFields();
  };

  return (
    <FormProvider {...methods}>
      <SidePanel
        header={type === 'create' ? 'Create' : 'Edit'}
        toggle={onClose}
        actions={
          <EditPanelActions
            type={type}
            onClose={onClose}
            onSubmit={handleSubmitClick}
            onRemove={handleRemove}
          />
        }
      >
        <FieldsContainer>{renderColumnFields()}</FieldsContainer>
      </SidePanel>
    </FormProvider>
  );
}

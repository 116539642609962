import {
  REPORT_BUILDER_BASIC_TAB,
  REPORT_BUILDER_DATE_FORMAT_OPTIONS,
  REPORT_BUILDER_DATE_FORMAT_QUARTER,
  REPORT_BUILDER_FORM_SCHEMA,
  REPORT_BUILDER_WIDGETS_TAB,
  reportWidgetsSliceActions,
  useGetApiCoreAssetsByAssetIdReportBuilderReportsAndIdQuery,
  type ReportBuilderForm,
} from '@/entities/report/reportBuilder';

import { useResetAllModalsOnUnmount } from '@/shared/lib/hooks/useModal';

import {
  DashboardContext,
  ReportDashboardType,
} from '@/bundles/Shared/entities/dashboard';
import { ReportingWidgetGroupIdContext } from '@/bundles/Shared/widgets/dashboard/widgets/common/lib/reportBuilderTemplateContext';
import type { LegalEntity } from '@/entities/core/legalEntity';
import { GetApiCoreAssetsByAssetIdReportBuilderReportsAndIdApiResponse } from '@/entities/report/reportBuilder/api/coreAssetsReportBuilderReportsGeneratedApi';
import { useReportBuilderEagleEyeTemplateMeta } from '@/entities/report/reportBuilder/eagleEye/lib';
import { useReportingFormTabs } from '@/entities/report/reportBuilder/lib';
import {
  filterKnownWidgetTypes,
  filterRedundantFieldsFromPayloadBody,
  isReportPayloadBodyAndReportDataEqual,
} from '@/entities/report/reportBuilder/lib/helpers';
import { useReportBuilderTemplateEagleEyeObjectSettingsForm } from '@/entities/report/reportBuilder/lib/reportBuilderTemplateEagleEyeObjectSettingsConfig';
import { useGetApiCoreAssetsByAssetIdReportBuilderReportsAndIdPreviewPdfQuery } from '@/entities/report/reportBuilder/lib/useGetApiCoreAssetsByAssetIdReportBuilderReportsAndIdPreviewPdfQuery';
import { ReportBuilderFormLegalEntities } from '@/entities/report/reportBuilder/ui/ReportBuilderFormLegalEntities';
import { reportingEnhancedApi } from '@/entities/reporting/api/reportingEnhancedApi';
import { ReportingEntityIdContext } from '@/entities/reporting/context/entityId';
import {
  ReportingEntityKindContext,
  useReportingEntityKindContext,
} from '@/entities/reporting/context/entityKind';
import { ReportingEntityStageContext } from '@/entities/reporting/context/entityStage';
import { useEagleEyeReportIdParam } from '@/entities/reporting/lib/eagleEye';
import { useDeleteReport } from '@/features/report/report/deleteReport/lib';
import { useReportBuilderNavigateBackFromBuilder } from '@/features/report/report/navigateBack/lib';
import { useReportBuilderNavigateToViewPage } from '@/features/report/report/navigateToViewPage/lib';
import { PublishReportButton } from '@/features/report/report/publishReport/ui/component';
import { useUpdateReport } from '@/features/report/report/updateReport/lib';
import { asserts } from '@/lib/typeHelpers/assertsType';
import { currentUserAllowedToSeeNavigateToReportViewPage } from '@/pages/reports/report/view/permissions';
import { formatToDateStringForRequest } from '@/shared/lib/converters';
import { useAssetIdSearchParam } from '@/shared/lib/hooks/navigation/useAssetIdSearchParam';
import { useAppDispatch, useAppSelector } from '@/shared/lib/hooks/redux';
import { mapListToIds } from '@/shared/lib/listHelpers';
import { useFormOnSetValue } from '@/shared/lib/reactHookForm/lib';
import { BuilderUI } from '@/shared/ui/BuilderUI';
import { GrowDiv } from '@/shared/ui/GrowDiv';
import { SaveStatus } from '@/shared/ui/SaveStatus';
import { Button } from '@/stories/Button/Button';
import { Input } from '@/stories/FormControls/Inputs/Input/Input';
import { Select } from '@/stories/FormControls/Select/Select';
import { ThinTabGroup } from '@/stories/Tabs/ThinTabGroup/ThinTabGroup';
import { Tumbler } from '@/stories/Tumbler/Tumbler';
import Calendar from '@/stories/FlexibleFilterByPeriods/calendar/Calendar';
import type { ISelectOption } from '@/stories/FormControls/Select/Select';
import type { IAsset } from '@/types/Asset';
import { ReportBuilderReportEagleEyeObjectsForm } from '@/widgets/reportBuilder/reportBuillderTemplate/eagleEye/ObjectsForm';
import { yupResolver } from '@hookform/resolvers/yup';
import { RouteComponentProps, useParams } from '@reach/router';
import {
  EagleEyeReportWidgetConfigCard,
  EagleEyeReportWidgetSnapshotLoader,
  ObjectLevelReportWidgetConfigCard,
  ObjectLevelReportWidgetSnapshotLoader,
} from 'bundles/Shared/widgets/dashboard/widgetsHelpers/ui/ReportWidgetConfigCard';
import dayjs from 'dayjs';
import { useMemo } from 'react';
import { FormProvider, useForm, type UseFormReturn } from 'react-hook-form';

// features/report/reportBuilder/updateReport
const useUpdateReportBuilderReport = ({
  assetId,
  methods,
  reportData,
}: {
  reportData:
    | GetApiCoreAssetsByAssetIdReportBuilderReportsAndIdApiResponse
    | undefined;
  assetId: IAsset['id'];
  methods: UseFormReturn<
    {
      name: string;
      subtitle: string;
      date: string | undefined;
      legalEntityIds: string[];
      dateFormat: string;
    },
    unknown,
    undefined
  >;
}) => {
  const [updateReport, updateOptions] = useUpdateReport();

  const handleUpdate = methods.handleSubmit(
    async (values) => {
      asserts(reportData, 'Fire this func where "data" exists!');

      const payloadBody = {
        name: values.name,
        subtitle: values.subtitle,
        date: values.date,
        date_format: values.dateFormat,
        legal_entity_ids: values.legalEntityIds,
      };

      const { isFormAndDataEqual, isFormDateAndDataDateEqual } =
        isReportPayloadBodyAndReportDataEqual(reportData.report, payloadBody);

      if (isFormAndDataEqual) return;

      const filteredPayloadBody = filterRedundantFieldsFromPayloadBody({
        payloadBody,
        isFormDateAndDataDateEqual,
      });

      try {
        const res = await updateReport({
          assetId,
          id: reportData.report.id,
          body: filteredPayloadBody,
        });
        if ('error' in res) {
          throw new Error('Failed to update template');
        }
        methods.reset({
          name: res.data.name,
          date: payloadBody.date,
          legalEntityIds: payloadBody.legal_entity_ids,
          subtitle: res.data.subtitle,
          dateFormat: res.data.dateFormat,
        });
      } catch (e) {
        console.error(e);
        methods.reset();
      }
    },
    (values) => {
      console.error(values);
    },
  );

  return [handleUpdate, updateOptions] as const;
};

// widgets/report/reportBuilder/builder.tsx
const Builder = ({
  data,
  isFetching,
}: {
  data: GetApiCoreAssetsByAssetIdReportBuilderReportsAndIdApiResponse;
  isFetching: boolean;
}) => {
  const { thinTabGroupProps, tab } = useReportingFormTabs();
  const assetId = useAssetIdSearchParam();
  const dispatch = useAppDispatch();
  const reportKind = useReportingEntityKindContext();

  const savingUpdates = useAppSelector(
    (s) => s.reportBuilder.pendingRequestIds.length > 0,
  );

  const methods = useForm<ReportBuilderForm>({
    resolver: yupResolver(REPORT_BUILDER_FORM_SCHEMA),
    defaultValues: {
      name: data?.report?.name ?? '',
      subtitle: data?.report?.subtitle ?? '',
      date: data?.report?.date,
      legalEntityIds: mapListToIds(data?.report?.legalEntities ?? []),
      dateFormat: data?.report.dateFormat,
    },
  });
  const { register, watch, formState } = methods;

  const dateFormatField = watch('dateFormat');
  const formLegalEntityIds = watch('legalEntityIds');

  const objectSelectionFormMethods =
    useReportBuilderTemplateEagleEyeObjectSettingsForm(data.report);

  const onSetValue = useFormOnSetValue(methods);

  const reportWidgetsState = useAppSelector((state) => state.reportWidgets);

  const navigateBack = useReportBuilderNavigateBackFromBuilder();
  const navigateToViewPage = useReportBuilderNavigateToViewPage();

  const reportGroups = useMemo(() => {
    return data?.report?.groups.map((g) => ({
      ...g,
      // TODO remove `widgets` when BE & FE migrates to new endpoints
      widgets: (g.widgets ?? g.widgetSections).filter(filterKnownWidgetTypes),
    }));
  }, [data]);

  const { dataObjectURL: pdfSrc, isLoading: isPDFLoading } =
    useGetApiCoreAssetsByAssetIdReportBuilderReportsAndIdPreviewPdfQuery();

  const [handleUpdateReport, updateOptions] = useUpdateReportBuilderReport({
    assetId: Number(assetId),
    reportData: data,
    methods,
  });

  const [handleDeleteReport, { isLoading: isDeletingReport }] =
    useDeleteReport(navigateBack);

  const handleResetForm = () => {
    methods.reset(
      {
        date: data.report.date,
        name: data.report.name,
        subtitle: data.report.subtitle,
        dateFormat: data.report.dateFormat,
        legalEntityIds: mapListToIds(data.report.legalEntities ?? []),
      },
      {
        keepDirty: false,
        keepTouched: false,
      },
    );
  };

  const handleDateFormatTumblerChange = () => {
    onSetValue(
      'dateFormat',
      dateFormatField ? null : REPORT_BUILDER_DATE_FORMAT_QUARTER,
    );
  };

  const handleDateFormatSelectChange = (
    option: ISelectOption<NonNullable<ReportBuilderForm['dateFormat']>>,
  ) => {
    onSetValue('dateFormat', option?.id ?? null);
  };

  const handleLEChange = (ids: LegalEntity['id'][]) => {
    onSetValue('legalEntityIds', ids);
  };

  useResetAllModalsOnUnmount();

  const isDateFormatTumblerChecked = watch('dateFormat') != null;
  const isReportLockedToUpdate = data?.report.status === 'published';

  const ReportWidgetConfigCard =
    reportKind === 'eagle_eye'
      ? EagleEyeReportWidgetConfigCard
      : ObjectLevelReportWidgetConfigCard;

  const ReportWidgetLoader =
    reportKind === 'eagle_eye'
      ? EagleEyeReportWidgetSnapshotLoader
      : ObjectLevelReportWidgetSnapshotLoader;

  const renderWidgetGroup = (
    g: (typeof reportGroups)[number],
    index: number,
  ) => (
    <ReportingWidgetGroupIdContext.Provider key={g.id} value={g.id}>
      <BuilderUI.Settings.WidgetLayout.WidgetGroup>
        <BuilderUI.Settings.WidgetLayout.WidgetGroup.Header>
          <BuilderUI.Settings.WidgetLayout.WidgetGroup.Title>
            {`Group ${index + 1}`}
          </BuilderUI.Settings.WidgetLayout.WidgetGroup.Title>
        </BuilderUI.Settings.WidgetLayout.WidgetGroup.Header>
        <BuilderUI.Settings.WidgetLayout.WidgetGroup.Children>
          {g.widgets.map((w, idx) => (
            <ReportWidgetConfigCard
              disabled={isReportLockedToUpdate}
              key={w.id}
              widgetSection={w}
              index={idx}
              isLoading={isFetching}
            />
          ))}
        </BuilderUI.Settings.WidgetLayout.WidgetGroup.Children>
      </BuilderUI.Settings.WidgetLayout.WidgetGroup>
    </ReportingWidgetGroupIdContext.Provider>
  );

  return (
    <BuilderUI>
      <BuilderUI.Settings>
        <BuilderUI.Settings.Header>
          <BuilderUI.Settings.Header.BackIconButton
            disabled={updateOptions.isLoading}
            onClick={navigateBack}
          />
          <BuilderUI.Settings.Header.TitleInfo
            subtitle={
              reportKind === 'object_level' ? data?.meta.asset.name : undefined
            }
            title={data?.report.name}
          />
          <BuilderUI.Settings.Header.Actions>
            <BuilderUI.Settings.Header.DeleteIconButton
              onClick={() => handleDeleteReport(data.report.id, assetId)}
              disabled={isDeletingReport || updateOptions.isLoading}
            />
            {currentUserAllowedToSeeNavigateToReportViewPage() && (
              <BuilderUI.Settings.Header.ViewIconButton
                onClick={() => {
                  navigateToViewPage(data.report.id);
                }}
              />
            )}
            <SaveStatus saving={savingUpdates} />
          </BuilderUI.Settings.Header.Actions>
        </BuilderUI.Settings.Header>

        {/* TODO move to Builder UI. DRY with `src/widgets/reportBuilder/reportBuillderTemplate/builder.tsx` */}
        <div className="grid w-full px-6 py-2">
          <ThinTabGroup fullWidth {...thinTabGroupProps} />
        </div>
        <BuilderUI.Settings.Main>
          {/* TODO move to a separate component. DRY with `src/widgets/reportBuilder/reportBuillderTemplate/builder.tsx` */}
          {tab?.id === REPORT_BUILDER_BASIC_TAB.id && (
            <>
              <BuilderUI.Settings.Main.Basic
                label="Key Info"
                actions={
                  <>
                    {/* TODO create a reset button and use in template builder as well*/}
                    <Button
                      size="xs"
                      onClick={handleResetForm}
                      disabled={
                        updateOptions.isLoading ||
                        isFetching ||
                        !formState.isDirty
                      }
                      className="w-max"
                    >
                      Reset
                    </Button>
                    <Button
                      size="xs"
                      variant="success"
                      onClick={handleUpdateReport}
                      disabled={
                        updateOptions.isLoading ||
                        isFetching ||
                        !formState.isDirty
                      }
                      className="w-max"
                    >
                      Save Updates
                    </Button>
                  </>
                }
              >
                <BuilderUI.Settings.Main.Basic.Field label="Report Title">
                  <Input
                    disabled={isReportLockedToUpdate}
                    classes={{ backLayer: 'bg-transparent' }}
                    {...register('name')}
                  />
                </BuilderUI.Settings.Main.Basic.Field>
                <BuilderUI.Settings.Main.Basic.Field label="Report Subtitle">
                  <Input
                    disabled={isReportLockedToUpdate}
                    placeholder="Enter subtitle"
                    classes={{ backLayer: 'bg-transparent' }}
                    {...register('subtitle')}
                  />
                </BuilderUI.Settings.Main.Basic.Field>
                <BuilderUI.Settings.Main.Basic.Field>
                  <div className="flex items-center gap-2">
                    <Tumbler
                      checked={isDateFormatTumblerChecked}
                      onChange={handleDateFormatTumblerChange}
                    />
                    <p className="inline-regular text-neutral-800">
                      Display Date
                    </p>
                  </div>
                </BuilderUI.Settings.Main.Basic.Field>
                {isDateFormatTumblerChecked && (
                  <BuilderUI.Settings.Main.Basic.Field>
                    <Select
                      onSelectedChange={handleDateFormatSelectChange}
                      selected={REPORT_BUILDER_DATE_FORMAT_OPTIONS.find(
                        (o) => o.id === dateFormatField,
                      )}
                      options={REPORT_BUILDER_DATE_FORMAT_OPTIONS}
                    />
                  </BuilderUI.Settings.Main.Basic.Field>
                )}
                {reportKind === 'object_level' && (
                  <>
                    <BuilderUI.Settings.Main.Basic.Hr />
                    <ReportBuilderFormLegalEntities
                      options={data.meta?.asset.legalEntities ?? []}
                      value={formLegalEntityIds}
                      hidden={isReportLockedToUpdate}
                      onChange={handleLEChange}
                    />
                  </>
                )}
              </BuilderUI.Settings.Main.Basic>

              {reportKind === 'eagle_eye' && (
                <FormProvider {...objectSelectionFormMethods}>
                  <ReportBuilderReportEagleEyeObjectsForm />
                </FormProvider>
              )}
            </>
          )}

          {reportGroups.map((g) =>
            g.widgets.map((w) => (
              <ReportWidgetLoader
                reportId={data.report.id}
                widgetId={w.id}
                key={w.id}
                assetId={assetId}
              />
            )),
          )}

          {/* TODO move to a separate component. DRY with `src/widgets/reportBuilder/reportBuillderTemplate/builder.tsx` */}
          {tab?.id === REPORT_BUILDER_WIDGETS_TAB.id && (
            <BuilderUI.Settings.WidgetLayout>
              <BuilderUI.Settings.WidgetLayout.Header title="Widgets">
                {!isReportLockedToUpdate && (
                  <Calendar
                    closeOnDateUpdate
                    selectionMode="daily"
                    value={[dayjs(reportWidgetsState.date ?? new Date())]}
                    buttonProps={{
                      disabled: isPDFLoading,
                    }}
                    onChange={([item]) => {
                      dispatch(
                        reportWidgetsSliceActions.updateReportDate({
                          date: formatToDateStringForRequest(item),
                        }),
                      );
                      onSetValue('date', formatToDateStringForRequest(item));
                    }}
                  />
                )}
              </BuilderUI.Settings.WidgetLayout.Header>
              <BuilderUI.Settings.WidgetLayout>
                {reportGroups.map(renderWidgetGroup)}
              </BuilderUI.Settings.WidgetLayout>
            </BuilderUI.Settings.WidgetLayout>
          )}
        </BuilderUI.Settings.Main>
        <BuilderUI.Settings.Footer>
          <Button onClick={navigateBack}>Cancel</Button>
          <GrowDiv />
          {data && !isReportLockedToUpdate && (
            <PublishReportButton
              // TODO update behaviour
              tooltipProps={{
                disabled:
                  // TODO Update
                  !formState.isDirty &&
                  reportKind === 'eagle_eye' &&
                  !objectSelectionFormMethods.formState.isDirty,
                mainText: `The Key Info Form${
                  reportKind === 'eagle_eye' ? ' or The Objects Form are' : 'is'
                } not saved`,
              }}
              disabled={
                formState.isDirty ||
                (reportKind === 'eagle_eye' &&
                  objectSelectionFormMethods.formState.isDirty)
              }
              assetId={Number(assetId)}
              reportId={data.report.id}
            />
          )}
        </BuilderUI.Settings.Footer>
      </BuilderUI.Settings>
      {isPDFLoading && pdfSrc == null && <BuilderUI.PdfPreview.Loading />}
      {savingUpdates && pdfSrc == null && <BuilderUI.PdfPreview.Loading />}
      {pdfSrc && (
        <BuilderUI.PdfPreview.Iframe src={pdfSrc}>
          {isPDFLoading && <BuilderUI.PdfPreview.IframeFetching />}
        </BuilderUI.PdfPreview.Iframe>
      )}
      {!isPDFLoading && !savingUpdates && pdfSrc == null && (
        <BuilderUI.PdfPreview.NoData />
      )}
    </BuilderUI>
  );
};
export const ObjectLevelReportPage: React.FC<RouteComponentProps> = () => {
  const params = useParams();
  const assetId = useAssetIdSearchParam();
  useReportBuilderEagleEyeTemplateMeta();

  const { data, isFetching, isLoading } =
    useGetApiCoreAssetsByAssetIdReportBuilderReportsAndIdQuery(
      {
        id: params.id,
        assetId: assetId!,
      },
      {
        skip: assetId == null,
      },
    );

  return (
    // TODO stop relying on DashboardContext in Reporting module.
    <DashboardContext.Provider
      value={{
        boardId: '',
        boardSlug: '',
        dashboardId: '',
        dashboardSlug: '',
        dashboardType: ReportDashboardType.REPORT_BUILDER_TEMPLATE,
      }}
    >
      <ReportingEntityStageContext.Provider value="report">
        <ReportingEntityKindContext.Provider value="object_level">
          {isLoading && <BuilderUI.Loading />}
          {!isLoading && data != null && (
            <ReportingEntityIdContext.Provider value={data.id}>
              <Builder data={data} isFetching={isFetching} />
            </ReportingEntityIdContext.Provider>
          )}
        </ReportingEntityKindContext.Provider>
      </ReportingEntityStageContext.Provider>
    </DashboardContext.Provider>
  );
};

// TODO move to pages/reportings/reportGroups/[reportGroupId]/reports/[reportId]/page.tsx
export const EagleEyeReportPage: React.FC<RouteComponentProps> = () => {
  const reportId = useEagleEyeReportIdParam();
  useReportBuilderEagleEyeTemplateMeta();

  const { data, isFetching, isLoading } =
    reportingEnhancedApi.useGetApiReportBuilderEagleEyeReportsByIdQuery({
      id: reportId,
    });

  return (
    <DashboardContext.Provider
      value={{
        boardId: '',
        boardSlug: '',
        dashboardId: '',
        dashboardSlug: '',
        dashboardType: ReportDashboardType.REPORT_BUILDER_TEMPLATE_EAGLE_EYE,
      }}
    >
      <ReportingEntityStageContext.Provider value="report">
        <ReportingEntityKindContext.Provider value="eagle_eye">
          {isLoading && <BuilderUI.Loading />}
          {!isLoading && data != null && (
            <ReportingEntityIdContext.Provider value={data.id}>
              <Builder data={{ report: data }} isFetching={isFetching} />
            </ReportingEntityIdContext.Provider>
          )}
        </ReportingEntityKindContext.Provider>
      </ReportingEntityStageContext.Provider>
    </DashboardContext.Provider>
  );
};

import { getReckonerPeriodByPeriodTypeAndDate } from '@/bundles/Shared/widgets/dashboard/widgets/common/lib/utils';
import { formatToDateStringForRequest } from '@/shared/lib/converters';
import { useAppSelector } from '@/shared/lib/hooks/redux';
import { RangePeriod } from '@/shared/lib/reckoner/model';
import { reckonerPeriod } from '@/shared/lib/reckoner/period';
import { FlexibleFilterByPeriods } from '@/stories/FlexibleFilterByPeriods/FlexibleFilterByPeriods';
import { generatePrevPeriods } from '@/stories/FlexibleFilterByPeriods/consts';
import { IPeriodItem } from '@/stories/FlexibleFilterByPeriods/types';
import { createMonthPeriodItem } from '@/stories/FlexibleFilterByPeriods/utils';
import {
  DateWidgetState,
  PeriodTypeWidgetState,
} from 'bundles/Shared/widgets/dashboard/widgets/common';
import { WidgetStateProps } from 'bundles/Shared/widgets/dashboard/widgets/model';
import dayjs from 'dayjs';
import { ComponentProps, useMemo } from 'react';
import Calendar from 'stories/FlexibleFilterByPeriods/calendar/Calendar';

/**
 * @deprecated Migrate to `WidgetStatePeriod` using `ReckonerPeriod`
 */
export function WidgetStateDate({
  state,
  onStateChange,
  ...props
}: WidgetStateProps<DateWidgetState> &
  Omit<React.ComponentProps<typeof Calendar>, 'value' | 'onChange'>) {
  const fullscreen = useAppSelector((s) => s.widgetFullscreen.value);
  return (
    <Calendar
      popoverProps={{
        appendTo: fullscreen ? 'parent' : () => document.body,
      }}
      selectionMode="daily"
      value={[dayjs(state.date ?? new Date())]}
      onChange={(dates) =>
        onStateChange({ date: formatToDateStringForRequest(dates[0]) })
      }
      {...props}
    />
  );
}

export function WidgetStatePeriod({
  state,
  onStateChange,
}: WidgetStateProps<DateWidgetState & PeriodTypeWidgetState>) {
  const fullscreen = useAppSelector((s) => s.widgetFullscreen.value);

  const value = useMemo(() => {
    switch (true) {
      case reckonerPeriod.isToDatePeriod(state.period): {
        return [createMonthPeriodItem(state.period.last_date)];
      }
      case reckonerPeriod.isRangePeriod(state.period): {
        return state.period.from_date != null && state.period.to_date != null
          ? [
              createMonthPeriodItem(state.period.from_date),
              createMonthPeriodItem(state.period.to_date),
            ]
          : [];
      }
      default: {
        return [createMonthPeriodItem(state.period.date)];
      }
    }
  }, [state.period]);

  const popoverProps = {
    appendTo: fullscreen ? 'parent' : () => document.body,
    placement: 'top-start',
  } satisfies ComponentProps<typeof FlexibleFilterByPeriods>['popoverProps'];

  if (reckonerPeriod.isRangePeriod(state.period)) {
    const handleChange = (periodItems: IPeriodItem[]) => {
      const from = periodItems.at(0);
      const to = periodItems.at(-1);

      if (from == null || to == null) return;

      onStateChange({
        date: formatToDateStringForRequest(from.period),
        period: reckonerPeriod.getRaw<RangePeriod>({
          type: state.period.type,
          from_date: formatToDateStringForRequest(from.period),
          to_date: formatToDateStringForRequest(to.period),
        }),
      });
    };
    return (
      <FlexibleFilterByPeriods
        popoverProps={popoverProps}
        fromToMode
        isSingleSelection
        filterByPeriodsType="mtd"
        periodItems={value}
        possiblePeriods={generatePrevPeriods()}
        onUpdatePeriodItems={handleChange}
      />
    );
  }
  const handleChange = (dates: dayjs.Dayjs[]) => {
    onStateChange({
      date: formatToDateStringForRequest(dates[0]),
      period: getReckonerPeriodByPeriodTypeAndDate(
        state.period.type,
        formatToDateStringForRequest(dates[0]),
      ),
    });
  };

  return (
    <FlexibleFilterByPeriods
      filterByPeriodsType="mtd"
      popoverProps={popoverProps}
      isSingleSelection
      periodItems={value}
      possiblePeriods={generatePrevPeriods()}
      onUpdatePeriodItems={(items) =>
        handleChange(items.map((i) => dayjs(i.period)))
      }
    />
  );
}

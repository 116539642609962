import { useRef } from 'react';
import { useIsTextTruncated } from '@/shared/lib/hooks/useIsTextTruncated';
import Tooltip from '@/stories/Tooltip/Tooltip';
import { cn } from '@symmetre-web/tailwindcss/utils/cn';

export const TruncatedText = ({
  text,
  className,
}: {
  text: string;
  className?: string;
}) => {
  const ref = useRef<HTMLSpanElement>(null);
  const isTextTruncated = useIsTextTruncated(ref, text);

  return (
    <>
      <span ref={ref} className={cn('truncate', className)}>
        {text}
      </span>
      <Tooltip reference={ref} disabled={!isTextTruncated} mainText={text} />
    </>
  );
};

import {
  COMPARISON_DASHBOARD_WIDGETS_CONFIG_MAP,
  ComparisonDashboardWidgetStateIntersection,
} from '@/bundles/Shared/widgets/dashboard/widgets/config';
import { useAppDispatch, useAppSelector } from '@/shared/lib/hooks/redux';
import { WidgetProps } from 'bundles/Shared/widgets/dashboard/widgets/model';
import { useCallback } from 'react';

import {
  ErrorResponse,
  RtkQueryErrorLoggerMiddlewareArgs,
} from '@/app/stores/errorHandling';
import { selectReportComparisonDashboardMetadataById } from '@/bundles/Shared/entities/dashboard/model/slices/comparisonSlice';
import { DEFAULT_WIDGET_GROUPING_TYPE } from '@/bundles/Shared/widgets/dashboard/widgets/common/config';
import { useWidgetInView } from '@/bundles/Shared/widgets/dashboard/widgets/common/lib/useWidgetInView';
import { WidgetErrorBoundary } from '@/bundles/Shared/widgets/dashboard/widgetsHelpers/ui/WidgetErrorBoundary';
import { LegalEntity } from '@/entities/core/legalEntity';
import { mapListToIds } from '@/shared/lib/listHelpers';
import {
  buildLayoutsId,
  GetApiReportComparisonDashboardsByComparisonDashboardIdWidgetSectionsAndIdApiArg,
  ReportDashboardType,
  updateDashboardWidgetState,
} from 'bundles/Shared/entities/dashboard';
import { useGetApiReportComparisonDashboardsByComparisonDashboardIdWidgetSectionsAndIdQuery } from '@/shared/api/dashboardSettingsEnhancedApi';
import { UnknownRecord } from 'type-fest/source/internal';

export const ComparisonDashboardWidget = (
  props: Omit<
    WidgetProps,
    | 'dashboardType'
    | 'data'
    | 'onStateChange'
    | 'widgetId'
    | 'state'
    | 'isFetching'
    | 'isLoading'
    | 'isError'
  > & {
    legalEntityIds: LegalEntity['id'][];
    selectedAssetId?: number;
  },
) => {
  const {
    mode,
    dashboardId,
    widgetSection,
    boardId,
    legalEntityIds,
    selectedAssetId,
  } = props;
  const { ref, inView } = useWidgetInView();
  const dispatch = useAppDispatch();
  const widgetId = widgetSection.id;
  const { widgetsState } = useAppSelector((state) =>
    selectReportComparisonDashboardMetadataById(
      state,
      buildLayoutsId({
        dashboardId,
        boardId,
      }),
    ),
  )!;
  const state = widgetsState[
    widgetId
  ] as unknown as ComparisonDashboardWidgetStateIntersection;

  const getRequestArgs = () => {
    if (!state) {
      return {} as GetApiReportComparisonDashboardsByComparisonDashboardIdWidgetSectionsAndIdApiArg;
    }
    return {
      legalEntityIds,
      id: widgetId,
      groupingType: DEFAULT_WIDGET_GROUPING_TYPE,
      assetIds:
        mode === 'view'
          ? mapListToIds(props.context.assets)
          : [selectedAssetId],
      comparisonDashboardId: dashboardId,
      date: state.date,
      period: state.period,
      disableToast: true,
    } as GetApiReportComparisonDashboardsByComparisonDashboardIdWidgetSectionsAndIdApiArg &
      RtkQueryErrorLoggerMiddlewareArgs;
  };
  const { data, isLoading, isFetching, isError, error, isUninitialized } =
    useGetApiReportComparisonDashboardsByComparisonDashboardIdWidgetSectionsAndIdQuery(
      getRequestArgs(),
      {
        skip:
          !inView ||
          legalEntityIds.length === 0 ||
          (mode === 'edit' && !selectedAssetId),
      },
    );

  const handleStateChange = useCallback(
    (newState: UnknownRecord) => {
      dispatch(
        updateDashboardWidgetState({
          boardId,
          id: widgetId,
          widgetState: newState,
          dashboardId,
        }),
      );
    },
    [dashboardId, widgetId],
  );

  const { Component } =
    COMPARISON_DASHBOARD_WIDGETS_CONFIG_MAP[widgetSection.widgetType] ?? {};

  if (Component == null) {
    return null;
  }

  return (
    <WidgetErrorBoundary
      widgetSection={widgetSection}
      dashboardType={ReportDashboardType.COMPARISON_MODE}
      mode={props.mode}
    >
      <div className="h-full" ref={ref}>
        <Component
          widgetId={widgetId}
          dashboardId={dashboardId}
          widgetSection={widgetSection}
          dashboardType={ReportDashboardType.COMPARISON_MODE}
          isLoading={isLoading}
          isError={isError}
          isFetching={isFetching}
          isUninitialized={isUninitialized}
          errorMessage={(error?.data as ErrorResponse)?.errors}
          onStateChange={handleStateChange}
          data={data}
          state={state ?? {}}
          {...props}
        />
      </div>
    </WidgetErrorBoundary>
  );
};

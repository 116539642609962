import { EntityTagLabel } from '@/bundles/Shared/entities/tag';
import {
  BaseWidgetCard,
  WidgetPreview,
} from '@/bundles/Shared/widgets/dashboard/widgets/common';
import { ReportBuilderTemplate } from '@/entities/report/reportBuilder';
import { RadioButton } from '@/stories/RadioButton/RadioButton';
import { isEmpty } from 'lodash-es';

export const ReportTemplateCard = ({
  title,
  onClick,
  selected,
  tags,
}: {
  title: string;
  tags: ReportBuilderTemplate['tags'];
  onClick: VoidFunction;
  selected?: boolean;
}) => {
  return (
    <BaseWidgetCard onClick={onClick}>
      {selected != null && <RadioButton checked={selected} />}
      <WidgetPreview
        classes={{
          default: 'group-hover/item:opacity-0',
          hovered: 'group-hover/item:opacity-100',
        }}
      />
      <p className="body-semibold text-neutral-800">{title}</p>
      {!isEmpty(tags) && (
        <EntityTagLabel.List className="ml-auto">
          {tags.map((tag) => (
            <EntityTagLabel key={tag} label={tag} />
          ))}
        </EntityTagLabel.List>
      )}
    </BaseWidgetCard>
  );
};

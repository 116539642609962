import React, { useRef } from 'react';
import { WidgetProps } from 'bundles/Shared/widgets/dashboard/widgets/model';
import { DelinquencySnapshotColumnChartWidgetDto } from '@/shared/api/dashboardsGeneratedApi';
import { useAmchart } from 'lib/amcharts/useAmchart';
import am5themesAnimated from '@amcharts/amcharts5/themes/Animated';
import { XYChart } from '@amcharts/amcharts5/xy';
import {
  DasbhoardWidgetChartCard,
  DashboardWidgetCard,
} from 'bundles/Shared/widgets/dashboard/widgets/common';
import AnimationLoader from 'stories/AnimationLoader/AnimationLoader';
import * as am5plugins_json from '@amcharts/amcharts5/plugins/json';
import { DELINQUENCY_SNAPSHOT_CHART_CONFIG } from 'bundles/Shared/widgets/dashboard/widgets/delinquencySnapshot/config';
import { COLOR_SET, getReturnDashboardTheme } from 'lib/amcharts/utils';
import { ExportChartButton } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/ExportChartButton';
import { useWidgetFullScreen } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/WidgetFullScreen';
import { ChartContainer } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/ChartContainer';
import {
  addExportToChart,
  buildDataFieldsFromSeries,
} from 'bundles/Shared/widgets/dashboard/widgets/xyChartSingleKpi/lib/export';
import * as am5plugins_exporting from '@amcharts/amcharts5/plugins/exporting';
import { useWidgetFlags } from '@/bundles/Shared/widgets/dashboard/widgets/common/lib/useWidgetFlags';
const WIDGET_TITLE = 'Delinquency Snapshot';

export function DelinquencySnapshotWidget(
  props: WidgetProps<DelinquencySnapshotColumnChartWidgetDto>,
) {
  const { data, state, context, widgetSection } = props;
  const { shouldDisplayData, shouldDisplayLoader } = useWidgetFlags(props);
  const ref = useRef(null);
  const wrapperDivRef = useRef<HTMLDivElement | null>(null);
  const widgetStateFullScreenFeature = useWidgetFullScreen(wrapperDivRef);

  useAmchart(
    ref,
    (root) => {
      if (!data) {
        return;
      }
      const myTheme = getReturnDashboardTheme(root);
      myTheme.rule('ColorSet').set('colors', COLOR_SET);
      root.setThemes([am5themesAnimated.new(root), myTheme]);

      const parser = am5plugins_json.JsonParser.new(root);
      parser
        .parse(
          {
            ...DELINQUENCY_SNAPSHOT_CHART_CONFIG,
            refs: [
              {
                data: data.columns,
              },
              ...DELINQUENCY_SNAPSHOT_CHART_CONFIG.refs,
            ],
          },
          {
            parent: root.container,
          },
        )
        .then(function (chart: XYChart) {
          // Chart is ready
          chart.series.getIndex(0)?.appear(1000);
          chart.appear(1000, 100);
          const { exportingSettings } = addExportToChart({
            widgetTitle: WIDGET_TITLE,
            widgetId: widgetSection.id,
            state,
            data: data.columns,
            dataFields: {
              label: 'Label',
              ...buildDataFieldsFromSeries(chart.series.values),
            },
            withoutDate: true,
            context,
          });
          ref.current!.exporting = am5plugins_exporting.Exporting.new(
            root,
            exportingSettings,
          );
        });
    },
    [data],
  );

  return (
    <DasbhoardWidgetChartCard {...props} ref={wrapperDivRef}>
      <DashboardWidgetCard.Header>
        <span className="header5-bold text-neutral-850">{WIDGET_TITLE}</span>
        <div className="grow" />
        <ExportChartButton chartRef={ref} widgetSectionId={widgetSection.id} />
        <widgetStateFullScreenFeature.IconButton />
      </DashboardWidgetCard.Header>
      {shouldDisplayLoader && <AnimationLoader />}
      {shouldDisplayData && <ChartContainer ref={ref} />}
    </DasbhoardWidgetChartCard>
  );
}

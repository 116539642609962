/* eslint-disable no-restricted-imports */
/* eslint-disable check-file/no-index */
import { ReportDashboardSectionPosition } from '@/bundles/Shared/entities/dashboard/model';

export * from './config';
export * from './data';

export const isWidgetSectionPositionHalfSized = (
  p: Pick<ReportDashboardSectionPosition, 'w'>,
): boolean => {
  return p.w === 50;
};

export const isWidgetSectionPositionWidthFullSize = (
  p: Pick<ReportDashboardSectionPosition, 'w'>,
): boolean => {
  return p.w === 100;
};

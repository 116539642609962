import { UNSUPPORTED_DASHBOARD_INFO_TEXT } from '@/bundles/Settings/components/REport/Dashboards/Dashboard/config';
import { useDashboardObjectLevelItems } from '@/bundles/Shared/features/dashboard/create';
import { LegalEntitiesSelectionModal } from '@/bundles/Shared/features/dashboard/create/ui/LegalEntitiesSelectionModal';
import { useDashboardExportSettingsFeature } from '@/bundles/Shared/features/dashboard/exportSettings/lib';
import type { ReportObjectDashboardMetaDto } from '@/shared/api/dashboardsSettingsGeneratedApi';
import { LegalEntity } from '@/entities/core/legalEntity';
import { cn } from '@/shared/lib/css/cn';
import useBoolean from '@/shared/lib/hooks/useBoolean';
import { excludeLegalEntityHandlers } from '@/shared/lib/legalEntitiy/lib';
import { mapListToIds } from '@/shared/lib/listHelpers';
import { IconsId } from '@/types/sre-icons';
import {
  DashboardFilterObject,
  DashboardFilterObjectItem,
  ReportDashboardType,
  ReportObjectDashboard,
  useDashboardContext,
  useReportComparisonDashboardAssetsQuery,
  useReportObjectDashboardAssetsQuery,
} from 'bundles/Shared/entities/dashboard';

import {
  usePutApiSettingsReportComparisonDashboardsByIdMutation,
  usePutApiSettingsReportObjectDashboardsByIdMutation,
} from '@/shared/api/dashboardSettingsEnhancedApi';

import { ObjectSelectionModal } from 'bundles/Shared/features/dashboard/create/ui/ObjectSelectionModal';
import { DashboardPermissionListLine } from 'bundles/Shared/features/dashboard/updatePermissions';
import { debounce } from 'lodash-es';
import React, { useMemo } from 'react';
import { Field } from 'stories/Field/Field';
import { Icon } from 'stories/Icon/Icon';
import { Input } from 'stories/FormControls/Inputs/Input/Input';
import { SectionField } from 'stories/Field/FieldsWrappers';
import { useObjectDashboardUpdate } from '@/bundles/Shared/features/dashboard/update/object/lib';
import { useNavigateToDashboardSettingsBySlug } from '@/shared/lib/hooks/navigation/dashboardsNavitation';
interface Props {
  model: ReportObjectDashboard;
}

export const ObjectItem = ({
  icon,
  label,
  disabled,
  ...props
}: {
  icon: IconsId;
  label: string;
  disabled?: boolean;
} & Pick<React.HTMLAttributes<HTMLDivElement>, 'onClick'>) => {
  const textColor = disabled ? 'text-neutral-400' : 'text-neutral-550';
  return (
    <div
      className={cn(
        'flex cursor-pointer flex-col items-center justify-center gap-2 rounded-[8px] border-2 border-neutral-100 p-2 hover:bg-neutral-100',
        disabled && 'cursor-default bg-neutral-100',
      )}
      {...props}
    >
      <Icon className={cn('text-[24px]', textColor)} iconName={icon} />
      <span className={cn('secondary-semibold', textColor)}>{label}</span>
    </div>
  );
};

export const ReportDashboardUpdate = ({
  model,
  assetObjects,
  reportBuilderTemplates,
}: Props & {
  assetObjects: DashboardFilterObject[];
  reportBuilderTemplates?: ReportObjectDashboardMetaDto['reportBuilderTemplates'];
}) => {
  const { dashboardType } = useDashboardContext();
  const updateObjectDashboardMutation = useObjectDashboardUpdate();
  usePutApiSettingsReportObjectDashboardsByIdMutation();
  const updateComparisonDashboardMutation =
    usePutApiSettingsReportComparisonDashboardsByIdMutation();
  const navigateToDashboardSettingsBySlug =
    useNavigateToDashboardSettingsBySlug();
  const [updateDashboard, { isLoading }] = useMemo(() => {
    switch (dashboardType) {
      case ReportDashboardType.OBJECT: {
        return updateObjectDashboardMutation;
      }
      case ReportDashboardType.COMPARISON_MODE: {
        return updateComparisonDashboardMutation;
      }
      default: {
        return [
          () => {
            toastr.warning(UNSUPPORTED_DASHBOARD_INFO_TEXT);
          },
          {
            isLoading: false,
          },
        ];
      }
    }
  }, []);
  const {
    value: legalEntitiesModalOpened,
    toggle: toggleLegalEntitiesModalOpened,
  } = useBoolean();
  const { value: assetModalOpen, toggle: toggleAssetModal } = useBoolean();

  const onAssetsChange = debounce((assetIds: number[]) => {
    updateDashboard({
      id: model.id,
      body: {
        asset_ids: assetIds,
        excluded_legal_entity_ids: mapListToIds(model.excludedLegalEntities),
      },
    });
  }, 600);

  const onExcludedLEChange = debounce(
    (excludedLegalEntitiesIds: LegalEntity['id'][]) => {
      updateDashboard({
        id: model.id,
        body: {
          asset_ids: mapListToIds(model.assets),
          excluded_legal_entity_ids: excludedLegalEntitiesIds,
        },
      });
    },
    600,
  );

  const handleNameChange = async (e: React.FocusEvent<HTMLInputElement>) => {
    if (e.target.value === model.name) {
      return;
    }
    const res = await updateDashboard({
      id: model.id,
      body: { name: e.target.value },
    });
    if ('data' in res) {
      navigateToDashboardSettingsBySlug(res.data.slug);
    }
  };

  const assetIds = useMemo(() => {
    return mapListToIds(model.assets);
  }, [model.assets]);

  const excludedLegalEntityIds = useMemo(() => {
    return mapListToIds(model.excludedLegalEntities);
  }, [model.excludedLegalEntities]);

  const { allLegalEntities, includedLegalEntities } =
    useDashboardObjectLevelItems({
      selectedAssetIds: assetIds,
      excludedLegalEntityIds,
    });

  const dashboardExportSettingsFeature = useDashboardExportSettingsFeature({
    dashboardId: model.id,
    dashboardReportBuilderTemplate: model.reportBuilderTemplate,
    dashboardUpdateMutation: updateObjectDashboardMutation,
    reportBuilderTemplates,
  });

  const {
    changeAll: onChangeAll,
    excludeAsset: onExcludeAsset,
    excludeLegalEntity: onExcludeLegalEntity,
    includeAsset: onIncludeAsset,
    includeLegalEntity: onIncludeLegalEntity,
    excludeAssetAndFilterOutExcludedLegalEntities:
      onExcludeAssetAndFilterOutExcludedLegalEntities,
  } = excludeLegalEntityHandlers({
    allLegalEntityIds: mapListToIds(allLegalEntities),
    excludedLegalEntityIds,
    selectedAssetIds: assetIds,
  });

  return (
    <div className="flex flex-col gap-4">
      <SectionField labelText="Basic">
        <Field required labelText="Dashboard Name">
          <Input defaultValue={model.name} onBlur={handleNameChange} />
        </Field>
      </SectionField>
      <SectionField labelText="Objects">
        <div className="grid grid-cols-3 gap-2">
          <DashboardFilterObjectItem
            onClick={toggleAssetModal}
            count={model.assets.length}
            type="asset"
          />
          <DashboardFilterObjectItem count={0} type="fund" disabled />
          <DashboardFilterObjectItem count={0} type="segment" disabled />
        </div>
      </SectionField>
      <SectionField labelText="Legal Entities">
        <LegalEntitiesSelectionModal.Wrapper
          legalEntitiesCounter={includedLegalEntities.length}
          toggleLegalEntitiesModalOpened={toggleLegalEntitiesModalOpened}
        >
          {legalEntitiesModalOpened && (
            <LegalEntitiesSelectionModal
              disableAllButtons={isLoading}
              selectedAssets={model.assets}
              excludedLegalEntityIds={excludedLegalEntityIds}
              includedLegalEntities={includedLegalEntities}
              onChangeAll={(type) => {
                onExcludedLEChange(onChangeAll(type));
              }}
              onExcludeAsset={(assetId) => {
                onAssetsChange(onExcludeAsset(assetId));
              }}
              onIncludeAsset={(assetId, assetExcludedLegalEntityIds) => {
                const res = onIncludeAsset(
                  assetId,
                  assetExcludedLegalEntityIds,
                );
                if (res.type === 'excludedLegalEntityChange') {
                  return onExcludedLEChange(res.result);
                }
                onAssetsChange(res.result);
              }}
              onExcludeLegalEntity={(id) => {
                onExcludedLEChange(onExcludeLegalEntity(id));
              }}
              onIncludeLegalEntity={(id) => {
                onExcludedLEChange(onIncludeLegalEntity(id));
              }}
              onExcludeAssetAndFilterOutExcludedLegalEntities={(
                assetId,
                assetLegalEntityIds,
              ) => {
                const res = onExcludeAssetAndFilterOutExcludedLegalEntities(
                  assetId,
                  assetLegalEntityIds,
                );
                onAssetsChange(res.assetIds);
                onExcludedLEChange(res.excludedLegalEntityIds);
              }}
              onClose={toggleLegalEntitiesModalOpened}
            />
          )}
        </LegalEntitiesSelectionModal.Wrapper>
      </SectionField>
      <dashboardExportSettingsFeature.ExportSettingsField />
      <SectionField labelText="Permissions">
        <div className="flex items-center gap-2 rounded-[8px] border-2 border-neutral-100 p-2">
          <Icon
            className="rounded-[8px] bg-neutral-100 p-1.5"
            iconName="users"
          />
          <DashboardPermissionListLine
            dashboardType={dashboardType}
            dashboardId={model.id}
            permissions={model.permitted}
            withEdit
            className="grow items-center"
            emptyFallback={
              <span className="inline-semibold text-neutral-800">NOBODY</span>
            }
          />
        </div>
      </SectionField>
      {assetModalOpen && (
        <ObjectSelectionModal
          objects={assetObjects}
          onClose={toggleAssetModal}
          objectType="asset"
          defaultObjectIds={assetIds}
          onChange={onAssetsChange}
        />
      )}
    </div>
  );
};

export function ObjectDashboardUpdate(props: Props) {
  const { assetObjects, reportBuilderTemplates } =
    useReportObjectDashboardAssetsQuery();

  return (
    <ReportDashboardUpdate
      {...props}
      reportBuilderTemplates={reportBuilderTemplates}
      assetObjects={assetObjects}
    />
  );
}

export function ComparisonDashboardUpdate(props: Props) {
  const { assetObjects } = useReportComparisonDashboardAssetsQuery();

  return <ReportDashboardUpdate {...props} assetObjects={assetObjects} />;
}

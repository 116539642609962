import { DialogProps } from '@/shared/lib/hooks/useModal';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import {
  CreateNewForm,
  EditPanelActions,
  FormulaField,
  useUpdateListPropertyWidgetConfig,
  ValueDisplayOptionsFields,
} from 'bundles/Shared/widgets/dashboard/widgets/common';
import { EAGLE_EYE_DASHBOARD_SECTION_TYPE } from 'bundles/Shared/entities/dashboard';
import { SidePanel } from 'stories/Modals/Modal/Modal';
import { Field, FieldsContainer } from 'stories/Field/Field';
import { Input } from '@/stories/FormControls/Inputs/Input/Input';
import React from 'react';
import {
  XYCHART_SINGLE_KPI_CONFIG_ROW_SCHEMA,
  XYChartSingleKpiWidgetRowForm,
} from 'bundles/Shared/widgets/dashboard/widgets/xyChartSingleKpi/config/row.form';
import { XYChartWidgetConfigKpi } from '@/bundles/Shared/widgets/dashboard/widgets/xyChartSingleKpi/model';

interface Props
  extends DialogProps<{
    createNew: boolean;
  }> {
  type: 'create' | 'edit';
  rowConfig?: XYChartWidgetConfigKpi | null;
}

export function XYChartSingleKpiWidgetConfigRow({
  type,
  rowConfig,
  onClose,
  onSubmit,
}: Props) {
  const resolver = yupResolver(XYCHART_SINGLE_KPI_CONFIG_ROW_SCHEMA);
  const methods = useForm<XYChartSingleKpiWidgetRowForm & CreateNewForm>({
    resolver,
    mode: 'all',
    defaultValues: {
      value_display_options: {
        type: 'number',
        precision: 0,
      },
      ...rowConfig,
    },
  });
  const { control, register, handleSubmit } = methods;
  const { upsertItem: upsertRow, removeItem: removeRow } =
    useUpdateListPropertyWidgetConfig(
      EAGLE_EYE_DASHBOARD_SECTION_TYPE.XY_CHART_SINGLE_KPI,
      'kpis',
    );

  const handleRemove = () => {
    removeRow(rowConfig!.key);
    onClose();
  };

  const handleSubmitClick = handleSubmit(async (values) => {
    await upsertRow(values);
    onSubmit?.({
      createNew: values.createNew,
    });
  });

  return (
    <FormProvider {...methods}>
      <SidePanel
        header={type === 'create' ? 'Create' : 'Edit Row'}
        toggle={onClose}
        actions={
          <EditPanelActions
            type={type}
            onClose={onClose}
            onSubmit={handleSubmitClick}
            onRemove={handleRemove}
          />
        }
      >
        <FieldsContainer>
          <Field note="Add text to your card (Optional)" labelText="Label">
            <Input
              placeholder="Enter Label"
              className="w-full"
              {...register('label')}
            />
          </Field>
          <FormulaField control={control} name="expression" required />
          <ValueDisplayOptionsFields
            name="value_display_options"
            control={control}
          />
        </FieldsContainer>
      </SidePanel>
    </FormProvider>
  );
}

import { UNGROUPED_GROUP_HEADER_NAME } from '@/bundles/Shared/entities/dashboard';
import { createMapBy } from '@/bundles/Shared/widgets/dashboard/widgets/common/lib/utils';
import { TableVizConfig } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/table/model';
import { Field } from '@/stories/Field/Field';
import { InlineAlert } from '@/stories/InlineAlert/InlineAlert';
import { Tumbler } from '@/stories/Tumbler/Tumbler';
import { SharedSelect } from 'bundles/Shared/components/GroupForm/FormItems/new/SharedSelect';
import { COMPARE_TO_COLOR_RULE_OPTIONS } from 'bundles/Shared/widgets/dashboard/widgets/common/config';
import { Control, Controller, useFormContext } from 'react-hook-form';
import { FieldPath, FieldValues } from 'react-hook-form/dist/types';
import {
  SectionField,
  SectionFieldsContainer,
} from 'stories/Field/FieldsWrappers';
import { RadioGroupController } from 'stories/RadioButton/RadioGroup';
import * as yup from 'yup';

export const COMPARE_TO_SCHEMA = yup.object().shape({
  rule: yup.string().required(),
  compareTo: yup.string().required(),
});

export type CompareToForm = yup.InferType<typeof COMPARE_TO_SCHEMA>;

export function CompareToField<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  control,
  name,
  columnsConfig,
  disabled,
  disabledNote,
  vizConfig,
}: {
  name: TName;
  control: Control<TFieldValues>;
  columnsConfig: { key: string; label: string }[];
  vizConfig: TableVizConfig;
  disabled?: boolean;
  disabledNote?: string;
}) {
  const { setValue, watch } = useFormContext<{
    [T in TName]: CompareToForm;
  }>();
  const getFieldName = (fieldName: keyof CompareToForm) =>
    `${name}.${fieldName}` as FieldPath<TFieldValues>;

  const key = watch('key');
  const comparison = watch(name);
  const vizConfigColumnGroupsMap = createMapBy(
    vizConfig.column_groups,
    'group_id',
  );
  const vizConfigColumnsMap = createMapBy(vizConfig.columns, 'key');
  const compareToOptions = columnsConfig
    .filter((c) => c.key.toString() !== key?.toString())
    .map((c) => {
      const groupId = vizConfigColumnsMap.get(c.key.toString())?.group_id;
      const groupName =
        (groupId && vizConfigColumnGroupsMap.get(groupId)?.header_name) ??
        UNGROUPED_GROUP_HEADER_NAME;

      return {
        label: `${groupName} - ${c.label}`,
        value: c.key,
      };
    });

  const hasNoColumns =
    columnsConfig.length === 0 || compareToOptions.length === 0;

  const handleComparisonChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setValue(
        name,
        {
          rule: null,
          compareTo: null,
        } as unknown as CompareToForm,
        {
          shouldValidate: true,
        },
      );
    } else {
      setValue(name, null, {
        shouldDirty: true,
        shouldValidate: true,
      });
    }
  };

  return (
    <SectionFieldsContainer>
      <SectionField
        button={
          <Tumbler
            disabled={disabled || hasNoColumns}
            onChange={handleComparisonChange}
            checked={comparison != null}
          />
        }
        labelText="Comparison"
        note={'Here you can set the comparison rules for the column'}
      >
        {hasNoColumns && (
          <InlineAlert
            status="secondary"
            message="No columns available for comparison"
          />
        )}
        {disabled && disabledNote && (
          <InlineAlert status="secondary" message={disabledNote} />
        )}
        {comparison != null && (
          <Field
            labelText="Compare with"
            note="Which column you would like to compare the result to?"
          >
            <Controller
              control={control}
              name={getFieldName('compareTo')}
              render={({ field: compareToField }) => (
                <SharedSelect
                  placeholder="Select column"
                  menuPortalTarget={document.body}
                  value={compareToOptions.find(
                    (o) => o.value.toString() === compareToField.value,
                  )}
                  onChange={(e) => compareToField.onChange(e.value)}
                  options={compareToOptions}
                />
              )}
            />
          </Field>
        )}
      </SectionField>
      {comparison != null && (
        <SectionField labelText="Color Rule">
          <RadioGroupController
            control={control}
            name={getFieldName('rule')}
            options={COMPARE_TO_COLOR_RULE_OPTIONS}
          />
        </SectionField>
      )}
    </SectionFieldsContainer>
  );
}

import { DialogProps } from '@/shared/lib/hooks/useModal';
import {
  KpiCardWidgetConfig,
  KpiCardWidgetRowType,
} from 'bundles/Shared/widgets/dashboard/widgets/kpiCard';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import {
  CreateNewForm,
  EditPanelActions,
  FormulaField,
  PeriodShiftField,
  PeriodTypeField,
  TextVariableField,
  useUpdateWidgetConfig,
  useWidgetConfig,
  ValueDisplayOptionsFields,
} from 'bundles/Shared/widgets/dashboard/widgets/common';
import {
  DEFAULT_TEXT_VALUE_DISPLAY_OPTIONS,
  DEFAULT_NUMBER_VALUE_DISPLAY_OPTIONS,
} from '@/shared/lib/formatting/displayOptions';
import { OBJECT_DASHBOARD_SECTION_TYPE } from 'bundles/Shared/entities/dashboard';
import { SidePanel } from 'stories/Modals/Modal/Modal';
import { Field, FieldsContainer } from 'stories/Field/Field';
import { Input } from '@/stories/FormControls/Inputs/Input/Input';
import { SectionField } from 'stories/Field/FieldsWrappers';
import React from 'react';
import {
  KPI_CARD_WIDGET_CONFIG_ROW_SCHEMA,
  KpiCardWidgetRowForm,
  transformKpiCardRowConfigToForm,
} from 'bundles/Shared/widgets/dashboard/widgets/kpiCard/config/row.form';
import { kpiCardUpdaters } from '@/bundles/Shared/widgets/dashboard/widgets/kpiCard/config/updaters';
import { TextDisplayStrategyField } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/fields/TextDisplayStrategyField';
import { TIME_PERIOD_TYPES } from '@/bundles/Shared/widgets/dashboard/widgets/common/config';

interface Props
  extends DialogProps<{
    createNew: boolean;
  }> {
  type: 'create' | 'edit';
  rowType: KpiCardWidgetRowType;
  rowConfig?: KpiCardWidgetConfig['kpis'][number];
  rowSettings?: KpiCardWidgetVizConfigKpi;
}

const KPI_CARD_AVAILABLE_TIME_PERIOD_TYPES = TIME_PERIOD_TYPES.filter(
  (type) => type !== 'itd' && type !== 'mtd',
);

export function KpiCardWidgetConfigRow({
  type,
  rowType,
  rowConfig,
  rowSettings,
  onClose,
  onSubmit,
}: Props) {
  const { widget } =
    useWidgetConfig<typeof OBJECT_DASHBOARD_SECTION_TYPE.KPI_CARD>();
  const [updateWidgetConfig] = useUpdateWidgetConfig(
    OBJECT_DASHBOARD_SECTION_TYPE.KPI_CARD,
  );
  const resolver = yupResolver(KPI_CARD_WIDGET_CONFIG_ROW_SCHEMA);

  const methods = useForm<KpiCardWidgetRowForm & CreateNewForm>({
    resolver,
    mode: 'all',
    defaultValues: {
      value_display_options:
        rowType === 'numeric'
          ? DEFAULT_NUMBER_VALUE_DISPLAY_OPTIONS
          : DEFAULT_TEXT_VALUE_DISPLAY_OPTIONS,
      type: rowType,
      ...(rowConfig && transformKpiCardRowConfigToForm(rowConfig, rowSettings)),
    },
  });
  const { control, register, handleSubmit } = methods;

  const handleRemove = () => {
    kpiCardUpdaters.removeKpi(rowConfig!.key.toString(), widget.widgetConfig);
    onClose();
  };

  const handleSubmitClick = handleSubmit(async (values) => {
    await updateWidgetConfig({
      config: kpiCardUpdaters.upsertKpi(values, widget.widgetConfig),
    });
    onSubmit?.({
      createNew: values.createNew,
    });
  });

  return (
    <FormProvider {...methods}>
      <SidePanel
        header={type === 'create' ? 'Create' : 'Edit Row'}
        toggle={onClose}
        actions={
          <EditPanelActions
            type={type}
            onClose={onClose}
            onSubmit={handleSubmitClick}
            onRemove={handleRemove}
          />
        }
      >
        <FieldsContainer>
          <Field note="Add text to your card (Optional)" labelText="Label">
            <Input
              placeholder="Enter Label"
              className="w-full"
              {...register('label')}
            />
          </Field>
          {rowType === 'numeric' ? (
            <FormulaField control={control} name="expression" required />
          ) : (
            <TextVariableField control={control} name="expression" required />
          )}
          <SectionField labelText="Specify Period">
            <PeriodTypeField
              control={control}
              name="period_type"
              availableTimePeriodTypes={KPI_CARD_AVAILABLE_TIME_PERIOD_TYPES}
            />
            <Field labelText="Period shift">
              <PeriodShiftField control={control} name="period_shift" />
            </Field>
          </SectionField>
          {rowType === 'numeric' && (
            <ValueDisplayOptionsFields
              control={control}
              name="value_display_options"
            />
          )}
          {rowType === 'text' && <TextDisplayStrategyField control={control} />}
        </FieldsContainer>
      </SidePanel>
    </FormProvider>
  );
}

import { ColorField } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/fields/ColorField';
import { RecapPageField } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/fields/RecapPageField';
import {
  resolveRowSettingsType,
  WIDGET_TABLE_WIDGET_ROW_TYPES,
  WidgetTableRowSettingsRowType,
} from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/table/form';
import { commonTableWidgetUpdaters } from '@/bundles/Shared/widgets/dashboard/widgets/common/updaters';
import { HistoricalTableWidgetConfigColumn } from '@/bundles/Shared/widgets/dashboard/widgets/historicalTable/config/column';
import { HistoricalTableWidgetConfigRow } from '@/bundles/Shared/widgets/dashboard/widgets/historicalTable/config/row';
import { HistoricalReviewTableWidgetConfigRow } from '@/bundles/Shared/widgets/dashboard/widgets/historicalTable/config/row.form';
import { DragDropContext } from '@hello-pangea/dnd';
import { WidgetSettingsListLayout } from 'bundles/Shared/components/layouts/dashboard/WidgetLayout';
import { OBJECT_DASHBOARD_SECTION_TYPE } from 'bundles/Shared/entities/dashboard';
import { isFormulaByExpression } from 'bundles/Shared/entities/formula';
import { InspectFormulaButton } from 'bundles/Shared/features/formula/editFormula';
import {
  DefaultColumnsTab,
  PeriodShiftField,
  UpdateWidgetTitle,
} from 'bundles/Shared/widgets/dashboard/widgets/common';
import {
  useUpdateListPropertyWidgetConfig,
  useUpdateWidgetConfigEffect,
  useWidgetConfig,
} from 'bundles/Shared/widgets/dashboard/widgets/common/lib';
import {
  HistoricalTableWidgetConfigForm,
  transformHistoricalTableWidgetConfigToForm,
} from 'bundles/Shared/widgets/dashboard/widgets/historicalTable/config/component.form';
import {
  moveColumn,
  updateDefaultOptions,
} from 'bundles/Shared/widgets/dashboard/widgets/historicalTable/config/updaters';
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Dropdown } from 'stories/Dropdown/Dropdown';
import { DropdownItem } from 'stories/Dropdown/DropdownItem/DropdownItem';
import { IconButton } from 'stories/IconButton/IconButton';
import Button from 'stories/Button/Button';
import Field, { FieldsContainer } from 'stories/Field/Field';
import ThinTabGroup, {
  IThinTabItem,
} from 'stories/Tabs/ThinTabGroup/ThinTabGroup';
import { useTabs } from 'stories/Tabs/useTabs';

const TABS = [
  {
    id: 'basic',
    label: 'Basic',
  },
  {
    id: 'columns',
    label: 'Columns',
  },
  {
    id: 'rows',
    label: 'Rows',
  },
] as const satisfies readonly IThinTabItem[];

const RowsTab = () => {
  const [modalState, setModalState] = useState<Omit<
    React.ComponentProps<typeof HistoricalTableWidgetConfigRow>,
    'onClose' | 'onSubmit'
  > | null>(null);
  const { widget } =
    useWidgetConfig<
      typeof OBJECT_DASHBOARD_SECTION_TYPE.HISTORICAL_REVIEW_TABLE
    >();
  const { rows = [] } = widget.widgetConfig;
  const {
    removeItem: removeRow,
    moveItem: moveRow,
    cloneItem: cloneRow,
  } = useUpdateListPropertyWidgetConfig(
    OBJECT_DASHBOARD_SECTION_TYPE.HISTORICAL_REVIEW_TABLE,
    'rows',
  );

  const handleAddRow = (rowType: WidgetTableRowSettingsRowType) => {
    setModalState({
      type: 'create',
      rowType,
    });
  };

  const handleEditRow = (row: HistoricalReviewTableWidgetConfigRow) => {
    const rowSettings = widget.widgetConfig.viz_config?.rows.find(
      (r) => r.key === row.key.toString(),
    );
    const rowType = resolveRowSettingsType(rowSettings);

    setModalState({
      type: 'edit',
      rowConfig: row,
      rowType,
      rowSettings,
    });
  };

  return (
    <Field
      labelText="Explore Computed Rows"
      note="This interactive panel allows users to add rows for various financial formulas and variables."
    >
      <WidgetSettingsListLayout>
        <WidgetSettingsListLayout.Header
          label={rows.length === 0 ? 'No Rows Yet' : `${rows.length} ROWS`}
        >
          <Dropdown
            className="sre-dropdown-v2"
            items={
              <>
                <DropdownItem
                  onClick={() => {
                    handleAddRow(WIDGET_TABLE_WIDGET_ROW_TYPES.NUMERIC);
                  }}
                >
                  Num Row
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    handleAddRow(WIDGET_TABLE_WIDGET_ROW_TYPES.TEXT);
                  }}
                >
                  Text Row
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    handleAddRow(WIDGET_TABLE_WIDGET_ROW_TYPES.SPARKLINE);
                  }}
                >
                  Sparkline Row
                </DropdownItem>
              </>
            }
          >
            <Button
              iconName="arrowBottom"
              iconPosition="right"
              size="xs"
              variant="secondary"
            >
              Add
            </Button>
          </Dropdown>
        </WidgetSettingsListLayout.Header>
        <DragDropContext
          onDragEnd={({ source, destination }) => {
            if (destination == null) {
              return;
            }
            moveRow(source.index, destination.index);
          }}
        >
          <WidgetSettingsListLayout.SortableList>
            {rows.map((row, index) => (
              <WidgetSettingsListLayout.SortableListItem
                index={index}
                key={row.key}
                draggableId={row.key.toString()}
                listIndex={index}
                className="group/item"
              >
                <WidgetSettingsListLayout.ListItem.Text>
                  {row.label}
                </WidgetSettingsListLayout.ListItem.Text>
                <div className="grow" />
                <WidgetSettingsListLayout.ListItem.ActionsPanel>
                  {isFormulaByExpression(row.expression) && (
                    <InspectFormulaButton reference={row.expression} />
                  )}
                  <IconButton
                    iconName="copy"
                    onClick={() => {
                      cloneRow(row.key);
                    }}
                  />
                  <IconButton
                    onClick={() => {
                      handleEditRow(row);
                    }}
                    iconName="edit"
                  />
                  <IconButton
                    onClick={() => {
                      removeRow(row.key);
                    }}
                    iconName="trash"
                  />
                </WidgetSettingsListLayout.ListItem.ActionsPanel>
              </WidgetSettingsListLayout.SortableListItem>
            ))}
          </WidgetSettingsListLayout.SortableList>
        </DragDropContext>
      </WidgetSettingsListLayout>
      {modalState != null && (
        <HistoricalTableWidgetConfigRow
          {...modalState}
          onClose={() => setModalState(null)}
          onSubmit={(res) => {
            setModalState(null);
            if (res?.createNew) {
              setTimeout(() => {
                setModalState({
                  type: 'create',
                });
              });
            }
          }}
        />
      )}
    </Field>
  );
};

const BasicTab = () => {
  const { widget } =
    useWidgetConfig<
      typeof OBJECT_DASHBOARD_SECTION_TYPE.HISTORICAL_REVIEW_TABLE
    >();
  const methods = useForm<HistoricalTableWidgetConfigForm>({
    defaultValues: {
      ...transformHistoricalTableWidgetConfigToForm(widget.widgetConfig),
    },
  });
  const { control } = methods;

  useUpdateWidgetConfigEffect<
    HistoricalTableWidgetConfigForm,
    typeof OBJECT_DASHBOARD_SECTION_TYPE.HISTORICAL_REVIEW_TABLE
  >(methods, updateDefaultOptions);

  return (
    <FormProvider {...methods}>
      <FieldsContainer>
        <UpdateWidgetTitle />
        <Field labelText="Period Shift">
          <PeriodShiftField control={control} name="period_shift" />
        </Field>
        <ColorField
          component="Field"
          labelText="Header Background"
          name="header_background"
          control={control}
        />
        <RecapPageField />
      </FieldsContainer>
    </FormProvider>
  );
};

export function HistoricalTableWidgetConfig() {
  const { tab, thinTabGroupProps } = useTabs(TABS, TABS[0]);

  return (
    <div className="flex flex-col gap-4">
      <ThinTabGroup fullWidth {...thinTabGroupProps} />
      <div className="p-1">
        {tab?.id === 'basic' && <BasicTab />}
        {tab?.id === 'columns' && (
          <DefaultColumnsTab
            ColumnModal={HistoricalTableWidgetConfigColumn}
            widgetType={OBJECT_DASHBOARD_SECTION_TYPE.HISTORICAL_REVIEW_TABLE}
            actions={{
              ...commonTableWidgetUpdaters,
              moveColumn,
            }}
            supportedColumnTypes={['numeric']}
          />
        )}
        {tab?.id === 'rows' && <RowsTab />}
      </div>
    </div>
  );
}

import React, { useEffect, useRef, useState } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-symmetre';
import { Icon } from 'stories/Icon/Icon';
import { cn } from '@/shared/lib/css/cn';
import { isEmpty } from 'lodash-es';
import useFileDialog from '@/shared/lib/hooks/useFileDialog';
import viewToPlainText from '@ckeditor/ckeditor5-clipboard/src/utils/viewtoplaintext';
import {
  MENTION_MARKER,
  processMentions,
} from 'bundles/REconcile/components/comments/CommentEditor/utils';
import { disableStylingKeystrokes } from '@/shared/lib/ckEditor/utils';
import styles from '@/bundles/REconcile/components/comments/CommentEditor/commentEditor.module.scss';
import CommentsDocumentCard from '@/bundles/REconcile/components/comments/CommentsDocumentCard/CommentsDocumentCard';

CKEditor.displayName = 'CKEditors';

export interface ICommentSubmitBody {
  text: string;
  files: File[];
}

interface Props {
  text?: string;
  onTextChange?: (text: string) => void;
  variant: 'dark' | 'light';
  onSubmit: (content: ICommentSubmitBody) => void;
  resetOnSubmit?: boolean;
  onCancel?: () => void;
  className?: string;
  mentionableUsers?: { id: number; fullName: string }[];
}

function CommentEditor({
  variant,
  text,
  mentionableUsers = [],
  onTextChange,
  onSubmit,
  onCancel,
  resetOnSubmit,
  className,
}: Props) {
  const isStateControlled = onTextChange != null;
  const editorRef = useRef<any | null>(null);
  const [internalText, setInternalText] = useState(text ?? '');
  const { files, openFileDialog, setFiles } = useFileDialog({
    fileMode: 'replace',
  });

  useEffect(() => {
    setInternalText(text ?? '');
  }, [text]);

  const getIconClassName = () =>
    cn(
      'cursor-pointer',
      variant === 'dark'
        ? 'cursor-pointer bg-dark-40 white'
        : 'cursor-pointer bg-white light-60',
    );

  const handleFileRemove = (file: File) => {
    setFiles(Array.from(files).filter((f) => f !== file));
  };

  const handleMentionClick = () => {
    editorRef.current.model.change((writer) => {
      editorRef.current.model.insertContent(writer.createText('@'));
    });
    editorRef.current?.editing.view.focus();
  };

  const handleSubmit = () => {
    const plainText = viewToPlainText(
      editorRef.current.editing.view.document.getRoot(),
    );

    onSubmit({
      text: processMentions({
        users: mentionableUsers,
        text: plainText,
        direction: 'backend',
      }),
      files: Array.from(files),
    });
    if (resetOnSubmit) {
      setInternalText('');
      setFiles([]);
    }
  };

  const handleChange = (event, editor) => {
    const data = editor.getData();
    const changeHandler = isStateControlled ? onTextChange : setInternalText;
    changeHandler(data);
  };

  const editorReady = (editor) => {
    editorRef.current = editor;
    disableStylingKeystrokes(editor);
    editor.keystrokes.set('Esc', () => {
      setInternalText('');
      setFiles([]);
      onCancel?.();
    });
  };

  return (
    <div
      className={cn(
        styles.editor,
        {
          [styles.editor_dark]: variant === 'dark',
          [styles.editor_light]: variant === 'light',
        },
        className,
      )}
    >
      <div className="flex items-end gap-s">
        <div className="flex-grow secondary-regular ">
          <CKEditor
            className="a s"
            editor={Editor}
            onReady={editorReady}
            data={internalText}
            onChange={handleChange}
            config={{
              placeholder: 'Write a comment',
              mention: {
                feeds: [
                  {
                    marker: MENTION_MARKER,
                    feed: mentionableUsers.map((u) => `@${u.fullName}`),
                  },
                ],
              },
            }}
          />
        </div>

        <div className="flex gap-xs">
          <Icon
            onClick={() => openFileDialog()}
            padding="xs"
            className={getIconClassName()}
            iconName="attach"
          />
          <Icon
            onClick={handleMentionClick}
            padding="xs"
            className={getIconClassName()}
            iconName="userAdd"
          />
          <Icon
            padding="xs"
            className={cn(
              'white cursor-pointer',
              isEmpty(internalText)
                ? 'disabled bg-dark-40 opacity-50'
                : 'bg-blue',
            )}
            iconName="longArrowUp"
            onClick={handleSubmit}
          />
        </div>
      </div>

      {files && files.length > 0 && (
        <div className="flex flex-col mt-s gap-s">
          {Array.from(files).map((f, i) => (
            <CommentsDocumentCard
              key={i}
              filename={f.name}
              fileSize={f.size}
              fileExtension={f.name.split('.').pop()}
              variant={variant}
              onRemove={() => handleFileRemove(f)}
            />
          ))}
        </div>
      )}
    </div>
  );
}

export default CommentEditor;

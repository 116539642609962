import { maxIdGenerator } from '@/bundles/Shared/widgets/dashboard/widgets/common';
import { FinancialTableIntermeidateConfigRow } from '@/bundles/Shared/widgets/dashboard/widgets/common/financialTable/lib';
import {
  TableVizConfig,
  TableVizConfigRow,
} from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/table/model';
import { commonTableWidgetUpdaters } from '@/bundles/Shared/widgets/dashboard/widgets/common/updaters';
import { FinancialTableSingleDateWidgetSection } from '@/bundles/Shared/widgets/dashboard/widgets/financialTableSingeDate/model';
import { FinancialTableSinglePeriodWidgetSection } from '@/bundles/Shared/widgets/dashboard/widgets/financialTableSingePeriod/model';
import { addIndexesPathToTree, transformTree } from '@/lib/treeHelpers';
import { produce } from 'immer';
import { omit } from 'lodash-es';
import { UnknownRecord } from 'type-fest/source/internal';

type FinancialTableWidgetSection =
  | FinancialTableSinglePeriodWidgetSection
  | FinancialTableSingleDateWidgetSection;

type Updater<Args extends UnknownRecord> = <
  Section extends FinancialTableWidgetSection,
>(
  args: Args,
  widgetConfig: Section['widgetConfig'],
) => Section['widgetConfig'];

const upsertGroup: Updater<{
  headerName: string;
  groupId?: string;
  color?: string;
}> = ({ headerName, groupId, color }, widgetConfig) => {
  return produce(widgetConfig, (draft) => {
    if (groupId) {
      const index =
        draft.viz_config?.column_groups?.findIndex(
          (cd) => cd.group_id.toString() === groupId,
        ) ?? -1;

      if (index === -1) return;

      draft.viz_config.column_groups[index].header_name = headerName;
      draft.viz_config.column_groups[index].background = color ?? null;
      return;
    }

    draft.viz_config?.column_groups?.push({
      group_id: String(
        maxIdGenerator(draft.viz_config.column_groups, 'group_id'),
      ),
      header_name: headerName,
      icon: null,
      background: color ?? null,
      border_color: null,
      order: draft.viz_config.column_groups.length,
    });
  });
};

export const updateWidgetConfigFromIntermediateRows = (
  widgetConfig: {
    rows: UnknownRecord[];
    viz_config: TableVizConfig;
  },
  rows: FinancialTableIntermeidateConfigRow[],
) => {
  const indexedTree = addIndexesPathToTree(rows);
  return produce(widgetConfig, (draft) => {
    const vizConfigRows: TableVizConfigRow[] = [];
    draft.rows = transformTree(indexedTree, (c) => {
      if (c.viz_config != null) {
        vizConfigRows.push({
          ...c.viz_config,
          key: c.path,
        });
      }
      return omit(c, 'viz_config');
    });
    draft.viz_config.rows = vizConfigRows;
  });
};

export const financialTableUpdaters = {
  cloneColumn: commonTableWidgetUpdaters.cloneColumn,
  removeColumn: commonTableWidgetUpdaters.removeColumn,
  moveColumn: commonTableWidgetUpdaters.moveColumn,
  toggleColumnHidden: commonTableWidgetUpdaters.toggleColumnHidden,
  toggleAllColumnsHidden: commonTableWidgetUpdaters.toggleAllColumnsHidden,

  removeGroup: commonTableWidgetUpdaters.removeGroup,
  upsertGroup,
  moveGroup: commonTableWidgetUpdaters.moveGroup,
  toggleGroupHidden: commonTableWidgetUpdaters.toggleGroupHidden,
  cloneGroup: commonTableWidgetUpdaters.cloneGroup,
  updateWidgetConfigFromIntermediateRows,
};

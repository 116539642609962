import {
  DEFAULT_CONFIG_MAP,
  WIDGETS_CONFIG_MAP,
} from '@/bundles/Shared/widgets/dashboard/widgets/config';
import {
  addSectionsToLayout,
  AllWidgetTypes,
  mapLayoutToLayoutDto,
  ReportDashboardSectionPositionWithId,
  ReportDashboardType,
  useDashboardContext,
} from 'bundles/Shared/entities/dashboard';

import {
  usePostApiSettingsReportComparisonDashboardsByComparisonDashboardIdBoardsAndBoardIdWidgetSectionsMutation,
  usePostApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdWidgetSectionsMutation,
  usePostApiSettingsReportObjectDashboardsByObjectDashboardIdBoardsAndBoardIdWidgetSectionsMutation,
} from '@/shared/api/dashboardSettingsEnhancedApi';

const WIDGET_ADD_MUTATIONS = {
  [ReportDashboardType.OBJECT]:
    usePostApiSettingsReportObjectDashboardsByObjectDashboardIdBoardsAndBoardIdWidgetSectionsMutation,
  [ReportDashboardType.EAGLE_EYE]:
    usePostApiSettingsReportEagleEyeDashboardsByEagleEyeDashboardIdBoardsAndBoardIdWidgetSectionsMutation,
  [ReportDashboardType.COMPARISON_MODE]:
    usePostApiSettingsReportComparisonDashboardsByComparisonDashboardIdBoardsAndBoardIdWidgetSectionsMutation,
} as const;

export const useAddDashboardWidgetSection = () => {
  const { dashboardId, dashboardType } = useDashboardContext();
  const useAddWidgetMutation = WIDGET_ADD_MUTATIONS[dashboardType];
  const [createWidgetMutation, options] = useAddWidgetMutation();

  const handleCreateWidget = async <T extends AllWidgetTypes>(params: {
    type: T | T[];
    layout: ReportDashboardSectionPositionWithId[];
    layoutItem?: ReportDashboardSectionPositionWithId;
    boardId?: string;
  }) => {
    const { layout, layoutItem, boardId } = params;

    if (boardId == null) return;

    const types = Array.isArray(params.type) ? params.type : [params.type];
    const newLayout = addSectionsToLayout(
      layout,
      types.map((type) => ({
        i: type,
        type,
        ...WIDGETS_CONFIG_MAP[type].layout,
        ...layoutItem,
      })),
      { toTheEnd: layoutItem == null },
    );

    const dashboard_layout = mapLayoutToLayoutDto(
      layout.filter((l) => !types.includes(l.i as unknown as T)),
    );

    return await createWidgetMutation({
      objectDashboardId: dashboardId,
      eagleEyeDashboardId: dashboardId,
      comparisonDashboardId: dashboardId,
      boardId,
      body: {
        // @ts-expect-error
        widget_sections: types.map((type) => ({
          title: `${WIDGETS_CONFIG_MAP[type].title}`,
          widget_type: type,
          position: newLayout.find((l) => l.i === type)!,
          // @ts-expect-error
          config: DEFAULT_CONFIG_MAP[dashboardType][type],
        })),
        dashboard_layout,
      },
    });
  };

  return [handleCreateWidget, options] as const;
};

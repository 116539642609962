import {
  getLegalEntitiesFromAssets,
  mapAssetToObject,
  mapSegmentToObject,
} from '@/bundles/Shared/entities/dashboard';
import { useGetApiSettingsReportBuilderEagleEyeTemplatesMetaQuery } from '@/entities/report/reportBuilder/api/settingsReportBuilderTemplatesEnhancedApi';
import type { GetApiSettingsReportBuilderEagleEyeTemplatesMetaApiResponse } from '@/entities/report/reportBuilder/api/settingsReportBuilderTemplatesGeneratedApi';
import { xorLegalEntityIds } from '@/shared/lib/legalEntitiy/lib';
import { mapListToIds } from '@/shared/lib/listHelpers';
import { createSelector } from '@reduxjs/toolkit';
import { useMemo } from 'react';

export const useReportBuilderEagleEyeTemplateMeta = () => {
  const selectSegments = useMemo(() => {
    return createSelector(
      (
        state:
          | GetApiSettingsReportBuilderEagleEyeTemplatesMetaApiResponse
          | undefined,
      ) => state?.segments ?? [],
      (segments) => segments.map(mapSegmentToObject),
    );
  }, []);

  const selectAssets = useMemo(() => {
    return createSelector(
      (
        state:
          | GetApiSettingsReportBuilderEagleEyeTemplatesMetaApiResponse
          | undefined,
      ) => state?.assets ?? [],
      (assets) => assets.map(mapAssetToObject),
    );
  }, []);

  const selectLegalEntities = useMemo(() => {
    return createSelector(
      (
        state:
          | GetApiSettingsReportBuilderEagleEyeTemplatesMetaApiResponse
          | undefined,
      ) => state?.assets ?? [],
      (assets) => getLegalEntitiesFromAssets(assets),
    );
  }, []);

  const selectDashboards = useMemo(() => {
    return createSelector(
      (
        state:
          | GetApiSettingsReportBuilderEagleEyeTemplatesMetaApiResponse
          | undefined,
      ) => state?.dashboards ?? [],
      (dashboards) =>
        dashboards.map((item) => {
          const excludedLegalEntityIds = mapListToIds(
            item.excludedLegalEntities,
          );

          const legalEntityIds = mapListToIds(
            getLegalEntitiesFromAssets(item.assets),
          );
          return {
            id: item.id,
            name: item.name,
            assetsLen: item.assets.length,
            assetIds: mapListToIds(item.assets),
            segmentIds: mapListToIds(item.segments),
            segmentsLen: item.segments.length,
            excludedLegalEntitiesLen: item.excludedLegalEntities.length,
            excludedLegalEntityIds,
            legalEntitiesLen: xorLegalEntityIds(
              legalEntityIds,
              excludedLegalEntityIds,
            ).length,
          };
        }),
    );
  }, []);

  const selectFilterSets = useMemo(() => {
    return createSelector(
      (
        state:
          | GetApiSettingsReportBuilderEagleEyeTemplatesMetaApiResponse
          | undefined,
      ) => state?.filterSets ?? [],
      (filterSets) =>
        filterSets.map((item) => {
          const excludedLegalEntityIds = mapListToIds(
            item.excludedLegalEntities,
          );
          const legalEntityIds = mapListToIds(
            getLegalEntitiesFromAssets(item.assets),
          );
          return {
            id: item.id,
            name: item.name,
            assetsLen: item.assets.length,
            assetIds: mapListToIds(item.assets),
            segmentIds: mapListToIds(item.segments),
            segmentsLen: item.segments.length,
            excludedLegalEntitiesLen: item.excludedLegalEntities.length,
            excludedLegalEntityIds,
            legalEntitiesLen: xorLegalEntityIds(
              legalEntityIds,
              excludedLegalEntityIds,
            ).length,
          };
        }),
    );
  }, []);

  return useGetApiSettingsReportBuilderEagleEyeTemplatesMetaQuery(undefined, {
    selectFromResult: (result) => {
      return {
        ...result,
        legalEntities: selectLegalEntities(result.data),
        segmentsFilterObjects: selectSegments(result.data),
        assetsFilterObjects: selectAssets(result.data),
        dashboardsFilterObjects: selectDashboards(result.data),
        filterSetsFilterObjects: selectFilterSets(result.data),
      };
    },
  });
};

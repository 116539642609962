import { BasicAutoGroupCellRenderer } from '@/bundles/Shared/components/AgGrid/Table/cellComponents/AutoGroupCellRenderer';
import { AutoGroupHeaderComponent } from '@/bundles/Shared/components/AgGrid/Table/cellComponents/AutoGroupHeaderComponent';
import { BasicCellRenderer } from '@/bundles/Shared/components/AgGrid/Table/cellComponents/BasicCellRenderer';
import { buildTableCellStyles } from '@/bundles/Shared/components/AgGrid/Table/utils/useTableCellStyleApplier';
import {
  TOTAL_BG_CLASS,
  TOTAL_BG_CSS_CUSTOM_PROPERTY_REF,
  TOTAL_NUMBER_FORMATTER_CLASSES,
  TOTAL_TEXT_CLASS,
} from '@/bundles/Shared/widgets/dashboard/widgets/common/config';
import { useWidgetFullScreen } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/WidgetFullScreen';
import { cn } from '@/shared/lib/css/cn';
import { getExcelStyleNumberFormat } from '@/shared/lib/formatting/excel';
import { createAgGridTableFormattedColDef } from '@/shared/lib/formatting/table';
import { GrowDiv } from '@/shared/ui/GrowDiv';
import { AsOfDate } from '@/stories/AsOfDate/AsOfDate';
import {
  ColDef,
  ExcelStyle,
  ICellRendererParams,
  ValueGetterParams,
} from 'ag-grid-community';
import { CELL_CLASS_NAMES } from 'bundles/Shared/components/AgGrid/Table/classNames';
import { NEXT_GEN_TABLE_CONFIG } from 'bundles/Shared/components/AgGrid/Table/consts';
import {
  OBJECT_DASHBOARD_SECTION_TYPE,
  ReportDashboardType,
} from 'bundles/Shared/entities/dashboard';
import { RecentRentRollTableWidgetDto } from '@/shared/api/dashboardsGeneratedApi';
import {
  autosizeColumnsOnFirstDataRendered,
  DashboardWidgetCard,
  DashboardWidgetTableCard,
  DateWidgetState,
  isWidgetSectionPositionWidthFullSize,
  useWidgetTableDefaultColDef,
  WidgetStateDate,
} from 'bundles/Shared/widgets/dashboard/widgets/common';
import { HeaderComponentWithSubHeader } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/table/HeaderComponentWithSubHeader';
import {
  buildExcelStyleId,
  INDENTATION_EXCEL_STYLES,
  useObjectDashboardWidgetTableExport,
} from 'bundles/Shared/widgets/dashboard/widgets/common/ui/table/useTableWidgetExportFeature';
import {
  WidgetTable,
  WidgetTablePlaceholder,
} from 'bundles/Shared/widgets/dashboard/widgets/common/ui/table/WidgetTable';
import {
  WidgetContextProps,
  WidgetProps,
  WidgetStateProps,
} from 'bundles/Shared/widgets/dashboard/widgets/model';
import { ObjectDashboardWidgetContext } from 'bundles/Shared/widgets/dashboard/widgetsHelpers';
import dayjs from 'dayjs';
import { useGridDefaultParams } from 'lib/ag-grid/useGridDefaultParams';
import { useLoadingOverlayEffect } from 'lib/ag-grid/utils';
import { isEmpty } from 'lodash-es';
import { ComponentProps, useMemo, useRef } from 'react';
import { CurrencyFormatterClasses } from 'stories/ValueFormatters/CurrencyFormatter';
import { resolveComparisonTextColorForColDef } from 'bundles/Shared/widgets/dashboard/widgets/common/lib/comparison';
import { useWidgetFlags } from '@/bundles/Shared/widgets/dashboard/widgets/common/lib/useWidgetFlags';
import { useAgGridRef } from '@/lib/ag-grid/utils';

export type RecentRentrollWidgetState = DateWidgetState;

export type RecentRentRollRow = RecentRentRollTableWidgetDto['rows'][number];

const isTotalRow = (params: ICellRendererParams) =>
  params.data?.type === 'total' || params.data?.type === 'psf';

const getRowId = ({
  data,
}: {
  data: RecentRentRollRow & {
    type: 'total' | 'psf';
  };
}) => {
  if (data.type === 'total' || data.type === 'psf') {
    return data.type;
  }
  return data.path;
};

const getDataPath = (row: RecentRentRollRow) => row.path.split('/');

export function RecentRentrollWidget(
  props: WidgetProps<RecentRentRollTableWidgetDto> &
    WidgetStateProps<RecentRentrollWidgetState> &
    WidgetContextProps<ObjectDashboardWidgetContext>,
) {
  const {
    mode,
    widgetSection,
    isFetching,
    data,
    state,
    className,
    onStateChange,
    context,
    dashboardType,
  } = props;
  const { shouldDisplayPlaceholder, shouldDisplayData } = useWidgetFlags(props);

  const gridRef = useAgGridRef();
  const wrapperDivRef = useRef<HTMLDivElement>(null);
  const { onGridReady } = useGridDefaultParams({
    enableExpandOnClick: true,
  });
  useLoadingOverlayEffect({
    isLoading: isFetching,
    grid: gridRef.current,
  });

  const headerBackground = data?.headerBackground ?? undefined;
  const widgetSectionFontSize = widgetSection.fontSize ?? undefined;
  const exportFeature = useObjectDashboardWidgetTableExport({
    mode,
    gridRef,
    isTotalRow,
    state,
    widgetTitle: widgetSection.title,
    widgetId: widgetSection.id,
    context,
  });
  const widgetStateFullScreenFeature = useWidgetFullScreen(wrapperDivRef);

  const finalExcelStyles = useMemo<ExcelStyle[]>(() => {
    return (data?.columns ?? [])
      .map((column) => ({
        id: buildExcelStyleId({ id: column.key.toString() }),
        ...getExcelStyleNumberFormat(column.valueDisplayOptions!),
      }))
      .concat(INDENTATION_EXCEL_STYLES)
      .concat(exportFeature.excelStyles ?? []);
  }, [exportFeature.excelStyles, data?.columns]);

  const rowData = useMemo(() => {
    const rows = [
      ...(data?.rows ?? []),
      ...(data?.psf ? [data.psf] : []).map((psf) => ({ ...psf, type: 'psf' })),
      ...(data?.total ? [data.total] : []).map((total) => ({
        ...total,
        type: 'total',
      })),
    ];

    return rows.map((row) => ({
      ...row,
      ...Object.fromEntries(
        row.values.map((value) => [value.key, value.value]),
      ),
    }));
  }, [data]);

  const columnDefs = useMemo(() => {
    const columns = data?.columns ?? [];

    return columns.map((column, index) => ({
      colId: column.key.toString(),
      field: column.key.toString(),
      headerName: column.label,
      headerValueGetter: () => {
        return mode === 'pdf' && column.shortLabel != null
          ? column.shortLabel
          : column.label;
      },
      cellClass: exportFeature.cellClass,
      cellClassRules: exportFeature.cellClassRules,
      ...(column.valueDisplayOptions &&
        createAgGridTableFormattedColDef(column.valueDisplayOptions)),
      headerComponentParams: {
        // TODO: refactor https://linear.app/symmetre/issue/FE-2700/%5Bchore%5D-add-ability-to-control-items-alignment-and-text-alignment-in
        classes: {
          container: mode === 'pdf' ? 'justify-center text-center' : '',
          header: mode === 'pdf' ? 'whitespace-normal' : '',
          subHeader: mode === 'pdf' ? 'whitespace-normal' : '',
        },
        style: buildTableCellStyles({
          background: headerBackground,
          fontSize: widgetSectionFontSize,
        }),
      } satisfies Partial<ComponentProps<typeof HeaderComponentWithSubHeader>>,
      cellRendererParams: (params: ICellRendererParams) => {
        const isTotal = isTotalRow(params);
        const actualInPlaceIndex = columns.findIndex(
          (c) => c.label === 'Actual-in-Place',
        );

        // todo FE-2271
        // if broken, check labels which come from BE response

        const field = params.data?.values.find(
          (value) => value.key === column.key,
        );
        const shouldApplyComparsion =
          !field.withFallback &&
          index > actualInPlaceIndex &&
          widgetSection.widgetType ===
            OBJECT_DASHBOARD_SECTION_TYPE.RECENT_RENT_ROLL_TABLE;

        const compareTextColor = shouldApplyComparsion
          ? resolveComparisonTextColorForColDef(
              {
                rule: 'negative_red_positive_green',
                to_col_id: columns[actualInPlaceIndex].key.toString(),
              },
              params,
              (rowx, key) => rowx?.[key],
            )
          : undefined;
        const formatterParams = {
          styles: {
            allParts: {
              color: compareTextColor,
            },
          },
          classes: {
            ...(isTotal && TOTAL_NUMBER_FORMATTER_CLASSES),
            allParts: cn(isTotal && TOTAL_NUMBER_FORMATTER_CLASSES.allParts),
          } satisfies CurrencyFormatterClasses,
        };
        return {
          formatterParams,
          classes: {
            inner: cn(
              CELL_CLASS_NAMES.CommonCell.inner.basic,
              isTotal && TOTAL_TEXT_CLASS,
            ),
            wrapper: cn(
              CELL_CLASS_NAMES.AutoGroupCell.wrapper.basic,
              isTotal && TOTAL_BG_CLASS,
            ),
          },
          styles: {
            wrapper: buildTableCellStyles({
              background: isTotal
                ? TOTAL_BG_CSS_CUSTOM_PROPERTY_REF
                : undefined,
              fontSize: widgetSectionFontSize,
              color: compareTextColor,
            }),
          },
        } satisfies Partial<ComponentProps<typeof BasicCellRenderer>>;
      },
    }));
  }, [data, mode, headerBackground, widgetSectionFontSize]);
  const defaultColDef = useWidgetTableDefaultColDef({
    mode,
    isCompactSelected: true,
  });
  const autoGroupColumnDef = useMemo<ColDef>(() => {
    return {
      ...NEXT_GEN_TABLE_CONFIG.column.autoGroupDef,
      headerName: 'UNIT TYPE',
      headerComponentParams: {
        classes: {
          header: 'secondary-semibold',
        },
        style: buildTableCellStyles({
          background: headerBackground,
          fontSize: widgetSectionFontSize,
        }),
      } satisfies Partial<ComponentProps<typeof AutoGroupHeaderComponent>>,
      suppressSizeToFit: true,
      cellClass: exportFeature.autoGroupColumnDef.cellClass,
      cellClassRules: exportFeature.cellClassRules,
      cellRendererParams: (params: ICellRendererParams) => {
        const isTotal = isTotalRow(params);
        return {
          classes: {
            wrapper: cn(
              CELL_CLASS_NAMES.AutoGroupCell.wrapper.basic,
              isTotal && TOTAL_BG_CLASS,
            ),
            inner: cn(
              CELL_CLASS_NAMES.AutoGroupCell.inner.basic,
              isTotal && TOTAL_TEXT_CLASS,
            ),
          },
          styles: {
            wrapper: buildTableCellStyles({
              fontSize: widgetSectionFontSize,
            }),
          },
          disableDeepExpandButton: true,
        } satisfies Partial<ComponentProps<typeof BasicAutoGroupCellRenderer>>;
      },
      valueGetter: ({ data: row }: ValueGetterParams) => {
        if (row.type === 'total') {
          return row.label ?? 'Total';
        }
        if (row.type === 'psf') {
          return row.label ?? 'PSF';
        }

        return row.label;
      },
    };
  }, [
    headerBackground,
    widgetSectionFontSize,
    exportFeature.cellClassRules,
    exportFeature.autoGroupColumnDef.cellClass,
  ]);

  const getLatestParsingsDate = () => {
    if (data?.latestParsings == null || isEmpty(data.latestParsings)) {
      return null;
    }
    if ((context.objectLegalEntityIds?.length ?? 0) === 1) {
      const [legalEntityId] = context.objectLegalEntityIds;
      const dateForSelectedLegalEntity = data.latestParsings.find(
        (p) => p.legalEntityId === legalEntityId,
      )?.date;
      return dateForSelectedLegalEntity ?? data.latestParsings.at(-1)!.date;
    }
    return data.latestParsings.at(-1)!.date;
  };

  return (
    <DashboardWidgetTableCard
      {...props}
      ref={wrapperDivRef}
      className={cn(
        {
          'h-full': mode === 'edit',
        },
        className,
      )}
    >
      {mode === 'pdf' &&
        widgetSection.title &&
        isWidgetSectionPositionWidthFullSize(widgetSection.position) && (
          <DashboardWidgetCard.PDFHeader>
            {widgetSection.title}
          </DashboardWidgetCard.PDFHeader>
        )}
      {mode !== 'pdf' && (
        <DashboardWidgetCard.Header className="justify-start gap-4">
          <DashboardWidgetCard.Header.Title>
            {widgetSection.title}
          </DashboardWidgetCard.Header.Title>
          {data?.latestParsings && !isEmpty(data.latestParsings) && (
            <AsOfDate
              variant="v3"
              block
              iconIsBadge
              date={dayjs(getLatestParsingsDate())}
            />
          )}
          <GrowDiv />
          <WidgetStateDate state={state} onStateChange={onStateChange} />
          <exportFeature.ExportButtonComponent />
          <widgetStateFullScreenFeature.IconButton />
        </DashboardWidgetCard.Header>
      )}
      {shouldDisplayPlaceholder && <WidgetTablePlaceholder />}

      {shouldDisplayData && (
        <WidgetTable
          mode={mode}
          groupDefaultExpanded={mode === 'pdf' ? -1 : 0}
          domLayout={
            mode === 'pdf' || dashboardType === ReportDashboardType.OBJECT
              ? 'autoHeight'
              : 'normal'
          }
          defaultColDef={defaultColDef}
          onGridReady={onGridReady}
          autoGroupColumnDef={autoGroupColumnDef}
          ref={gridRef}
          rowData={rowData}
          columnDefs={columnDefs}
          getRowId={getRowId}
          getDataPath={getDataPath}
          excelStyles={finalExcelStyles}
          expensivePropSuppressColumnVirtualisation
          onFirstDataRendered={autosizeColumnsOnFirstDataRendered}
        />
      )}
    </DashboardWidgetTableCard>
  );
}

import ErrorBoundaryPage from '@/app/ui/ErrorBoundaryPage';
import { NotFound } from '@/bundles/Errors';
import { plainPreviewEagleEyeReportPdf } from '@/entities/report/reportBuilder/lib/plainPreviewReportPdf';
import {
  useFetchPreviewPdf,
  usePdfBlobCreateObjectURL,
} from '@/entities/report/reportBuilder/lib/previewPdf';
import { useEagleEyeReportIdParam } from '@/entities/reporting/lib/eagleEye';
import { useHostedUrl } from '@/shared/lib/hooks/useHostedUrl';
import { AnimationLoader } from '@/stories/AnimationLoader/AnimationLoader';
import type { RouteComponentProps } from '@reach/router';
import { type FC } from 'react';

export const EagleEyeGeneratedReportPage: FC<RouteComponentProps> = () => {
  const eagleEyeReportId = useEagleEyeReportIdParam();
  const hostedUrl = useHostedUrl();

  const { pdfBlob, error, isLoading } = useFetchPreviewPdf({
    effectDeps: [],
    skip: () => false,
    fetchCallback: () => {
      return plainPreviewEagleEyeReportPdf({
        reportId: eagleEyeReportId,
        renderPdfHost: hostedUrl,
      });
    },
  });
  const dataObjectURL = usePdfBlobCreateObjectURL(pdfBlob);

  if (isLoading) return <AnimationLoader />;
  if (error) return <NotFound />;

  if (dataObjectURL == null) return <ErrorBoundaryPage />;

  return (
    dataObjectURL && (
      <iframe
        title="pdf-preview"
        src={dataObjectURL}
        className="flex min-h-screen w-full"
      />
    )
  );
};

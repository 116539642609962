import {
  useFavoriteObjectDashboardAssets,
  useReportDashboardFromScreenParams,
  useReportDashboardNavigate,
  useReportDashboardRedirectToFirstOrFavoriteAssetEffect,
  useReportDashboardSelectedAsset,
} from '@/pages/report/dashboards/lib';
import { cn } from '@/shared/lib/css/cn';
import { useAppSelector } from '@/shared/lib/hooks/redux';
import { ROUTES_ROOT } from '@/shared/lib/hooks/useNavigation';
import { includesInLowerCase } from '@/shared/lib/listHelpers';
import { IconButton } from '@/stories/IconButton/IconButton';
import { ProjectCard } from '@/stories/ProjectCard/ProjectCard';
import {
  DashboardLayout,
  ObjectLevelDashboardLayout,
} from 'bundles/Shared/components/layouts/dashboard/DashboardLayout';
import {
  DashboardContext,
  DashboardContextValue,
  ReportDashboardType,
  ReportObjectDashboardSection,
  selectReportObjectDashboardMetadataById,
} from 'bundles/Shared/entities/dashboard';
import LegalEntitiesIconWithTooltip from 'bundles/Shared/entities/legalEntity/ui/LegalEntitiesIconWithTooltip';
import { ObjectDashboardAssetFilter } from 'bundles/Shared/features/dashboard/object/filter/byAsset';
import {
  ReportDashboardDateFilter,
  ReportDashboardDateFilterBlock,
} from 'bundles/Shared/features/dashboard/object/filter/byDate';
import { ObjectDashboardLegalEntitiesFilter } from 'bundles/Shared/features/dashboard/object/filter/byLegalEnitty';
import { DashboardBoards } from 'bundles/Shared/widgets/dashboard/board';

import { useDashboardExportPDFFeature } from '@/bundles/Shared/features/dashboard/exportSettings/lib';
import { DashboardSettingsPageToggle } from '@/pages/report/dashboards/ui/DashboardSettingsPageToggle';
import { useSearchParams } from '@/shared/lib/hooks/navigation/useSearchParams';
import { ObjectDashboardWidget } from 'bundles/Shared/widgets/dashboard/widgetsHelpers';
import { orderBy } from 'lodash-es';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { useMemo, useState } from 'react';
import SearchInput from 'stories/FormControls/Inputs/SearchInput/SearchInput';
import FavoriteIconButton from 'stories/ProjectCard/FavoriteIconButton';
import ProjectCardList from 'stories/ProjectCard/ProjectCardList';
import { useNavigateToDasbhoardViewToBoard } from '@/shared/lib/hooks/navigation/dashboardsNavitation';
import { DashboardBoardNavigation } from '@/bundles/Shared/widgets/dashboard/board/ui/navigation/DashboardBoardNavigation';
import { GrowDiv } from '@/shared/ui/GrowDiv';

export function ReportObjectLevelDashboard() {
  const navigateToDashboard = useReportDashboardNavigate();
  const [fullScreen, setFullScreen] = useState(false);
  const { toggleItemFavorite, isItemFavorite } =
    useFavoriteObjectDashboardAssets();
  const [searchParams] = useSearchParams();
  const assetId = searchParams.get('assetId');
  const boardId = searchParams.get('boardId');

  const [searchText, setSearchText] = useState('');
  const selectedAsset = useReportDashboardSelectedAsset();
  const { data, isFetching } = useReportDashboardFromScreenParams();
  const dashboardId = data?.id ?? '';

  const dashboardState = useAppSelector((state) =>
    selectReportObjectDashboardMetadataById(state, dashboardId),
  )!;
  const selectedBoard = data?.boards.find((b) => b.slug === boardId);

  const { date: selectedDate } = dashboardState ?? {};

  const dashboardExportPDFFeature = useDashboardExportPDFFeature({
    assetId,
    date: selectedDate as DateString,
    reportBuilderTemplate: data?.reportBuilderTemplate ?? null,
  });
  useReportDashboardRedirectToFirstOrFavoriteAssetEffect();

  const filteredAssets = useMemo(() => {
    const ordered = orderBy(data?.assets ?? [], [
      (asset) =>
        isItemFavorite({
          dashboardId,
          assetId: asset.id,
        })
          ? 0
          : 1,
      (asset) => asset.name,
    ]);
    return ordered.filter((asset) =>
      includesInLowerCase(asset.name, searchText),
    );
  }, [data, searchText, isItemFavorite]);

  const dashboardContextValue = useMemo<DashboardContextValue>(
    () => ({
      dashboardId: data?.id ?? '',
      dashboardSlug: data?.slug ?? '',
      boardId: selectedBoard?.id ?? '',
      boardSlug: selectedBoard?.slug ?? '',
      dashboardType: ReportDashboardType.OBJECT,
    }),
    [data, selectedBoard],
  );
  const navigateToBoard = useNavigateToDasbhoardViewToBoard();

  if (data == null || isFetching) {
    return <ObjectLevelDashboardLayout.LoadingPlaceholder />;
  }

  const generateLayout = () => {
    return orderBy(selectedBoard?.sections ?? [], [
      (section) => section.position.y,
      (section) => section.position.x,
    ]).map((widget) => {
      return (
        <div
          style={{
            gridColumn: `${widget.position.x + 1} / span ${widget.position.w}`,
          }}
          data-grid={widget.position}
          key={widget.id}
        >
          <ObjectDashboardWidget
            dashboardId={dashboardId}
            widgetSection={widget as unknown as ReportObjectDashboardSection}
            boardId={selectedBoard.id}
            mode="view"
            selectedAssetId={selectedAsset?.id}
            assets={data?.assets ?? []}
          />
        </div>
      );
    });
  };

  return (
    <DashboardContext.Provider value={dashboardContextValue}>
      <ObjectLevelDashboardLayout className={cn(fullScreen && 'grid-cols-1')}>
        <ObjectLevelDashboardLayout.Sidebar
          className={cn('h-screen', fullScreen && 'd-none')}
        >
          <DashboardLayout.StickyHeader className="bg-neutral-100">
            <DashboardLayout.Header className="py-6">
              <DashboardLayout.Header.Title
                withoutBackButton
                classes={{
                  title: 'header4-bold',
                }}
                subtitle="Report"
                title={data.name}
              />
              <GrowDiv />
              <DashboardSettingsPageToggle
                queryParams={{
                  assetId: selectedAsset?.id,
                }}
              />
            </DashboardLayout.Header>
          </DashboardLayout.StickyHeader>
          <div className="flex flex-col gap-6 overflow-auto px-6">
            <div className="flex justify-between gap-4">
              <ReportDashboardDateFilter
                dashboardId={dashboardId}
                date={selectedDate}
              />
              <SearchInput
                inputClassName="max-w-[160px]"
                placeholder="Search"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
              />
            </div>
            <OverlayScrollbarsComponent>
              <ProjectCardList>
                {filteredAssets.map((asset) => (
                  <ProjectCard
                    key={asset.id}
                    classes={{
                      image: 'h-[56px] w-[56px]',
                    }}
                    className="cursor-pointer"
                    onClick={() => {
                      navigateToDashboard({
                        assetId: asset.id.toString(),
                      });
                    }}
                    pictureUrl={asset.pictureUrl}
                    selected={selectedAsset?.id === asset.id}
                    header={
                      <div className="flex h-full items-center gap-2">
                        <p className="inline-semibold text-neutral-800">
                          {asset.name}
                        </p>
                        <GrowDiv />
                        <LegalEntitiesIconWithTooltip
                          legalEntities={asset.legalEntities ?? []}
                        />
                        <FavoriteIconButton
                          size="m"
                          selected={isItemFavorite({
                            dashboardId,
                            assetId: asset.id,
                          })}
                          onClick={(e) => {
                            e.stopPropagation();
                            toggleItemFavorite({
                              dashboardId,
                              assetId: asset.id,
                            });
                          }}
                        />
                      </div>
                    }
                  />
                ))}
              </ProjectCardList>
            </OverlayScrollbarsComponent>
          </div>
        </ObjectLevelDashboardLayout.Sidebar>
        <ObjectLevelDashboardLayout.Body>
          <DashboardLayout.StickyHeader className="gap-4 px-8 pt-4">
            <div className="flex items-center gap-4">
              <span className="header5-bold text-neutral-850">
                {selectedAsset?.name}
              </span>
              {fullScreen && (
                <>
                  <ReportDashboardDateFilterBlock
                    dashboardId={dashboardId}
                    date={selectedDate}
                  />
                  <ObjectDashboardAssetFilter
                    path={ROUTES_ROOT.report.dashboards.fullPath}
                    assetId={Number(assetId!)}
                    pathParams={{
                      dashboardId,
                    }}
                  />
                </>
              )}
              <GrowDiv />
              <dashboardExportPDFFeature.Button />
              <IconButton
                size="m"
                onClick={() => setFullScreen(!fullScreen)}
                iconName="expand"
              />
            </div>
            <div className="h-px w-full bg-neutral-300" />
            <ObjectDashboardLegalEntitiesFilter
              dashboardId={dashboardId}
              objectId={selectedAsset?.id ?? -1}
              legalEntities={selectedAsset?.legalEntities ?? []}
            />
            {data?.boards?.length > 1 && (
              <div className="flex pb-2">
                <DashboardBoardNavigation
                  boards={data.boards}
                  selectedBoardId={selectedBoard?.id}
                  onBoardChange={navigateToBoard}
                />
              </div>
            )}
          </DashboardLayout.StickyHeader>
          <DashboardBoards className="px-8 pb-8 pt-4">
            <div className="grid grid-cols-2 gap-6">{generateLayout()}</div>
          </DashboardBoards>
        </ObjectLevelDashboardLayout.Body>
      </ObjectLevelDashboardLayout>
    </DashboardContext.Provider>
  );
}

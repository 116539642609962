import {
  EAGLE_EYE_DASHBOARD_SECTION_TYPE,
  PeriodShiftConfig,
  ReportEagleEyeDashboardSection,
  TrailingPeriodType,
} from 'bundles/Shared/entities/dashboard';
import {
  KpiTableWidgetColumnType,
  KpiTableWidgetSection,
} from 'bundles/Shared/widgets/dashboard/widgets/kpiTable';

import { TableVizConfig } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/table/model';
import { ValueDisplayOptions } from '@/shared/lib/formatting/displayOptions';
import { KpiTableSingleDateWidgetConfigDto } from '@/shared/api/dashboardsSettingsGeneratedApi';
import {
  TimePeriodType,
  TotalCalculationStrategy,
} from '@/bundles/Shared/widgets/dashboard/widgets/common/config';

import { AdjustmentExpression } from 'bundles/Shared/widgets/dashboard/widgets/common/features/adjustments/config/base';

export type KpiTableSingleDateGroupingType = 'segments' | 'assets';
export const isKpiTableSingleDateWidgetSection = (
  section: ReportEagleEyeDashboardSection,
): section is KpiTableWidgetSection =>
  section.widgetType === EAGLE_EYE_DASHBOARD_SECTION_TYPE.KPI_TABLE_SINGLE_DATE;
export type KpiTableSingleDateWidgetConfigColumn = {
  key: number;
  label: string;
  expression: string;
  type: KpiTableWidgetColumnType;
  history_size: number;
  period_type: TimePeriodType | TrailingPeriodType;
  value_display_options?: ValueDisplayOptions;
  period_shift?: PeriodShiftConfig;
  adjustment_expression?: AdjustmentExpression;
  total_calculation_strategy?: TotalCalculationStrategy;
};
export type KpiTableSingleDateWidgetDataColumn = {
  key: number;
  label: string;
  dateFrom: string;
  dateTo: string;
  valueDisplayOptions: ValueDisplayOptions;
  values?: {
    key: number;
    value: number | null;
  }[];
};

export type KpiTableSingleDateWidgetConfigDefaultOptions = {
  grouping_type: KpiTableSingleDateGroupingType;
  date: string;
};

export interface KpiTableSingleDateWidgetConfig {
  columns: KpiTableSingleDateWidgetConfigColumn[];
  period_type: 'week';
  default_options: {
    grouping_type: KpiTableSingleDateGroupingType;
    date: {
      period_shift: PeriodShiftConfig;
    };
    period_type: TimePeriodType | TrailingPeriodType;
  };
  viz_type: 'table';
  viz_config: TableVizConfig;
}

export type KpiTableSingleDateWidgetSection = ReportEagleEyeDashboardSection & {
  widgetType: typeof EAGLE_EYE_DASHBOARD_SECTION_TYPE.KPI_TABLE_SINGLE_DATE;
  widgetConfig: KpiTableSingleDateWidgetConfigDto;
  defaultOptions: KpiTableSingleDateWidgetConfigDefaultOptions;
};
